import * as React from 'react'
import {isFunction} from 'lodash'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import {useTranslation} from 'react-i18next'
import Card from 'components/molecules/card'
import Modal, {ModalSize, ModalType} from 'components/atoms/modal'

import styles from './style.module.css'

export type ConfirmModalCardProps = {
    title: string
    description: string
    confirmButtonText: string
    cancelButtonText?: string
    isVisible: boolean
    onModalClose?: () => void
    onConfirmButtonClick: () => void
    onCancelButtonClick?: () => void
    hideCancelButton?: boolean
    loading?: boolean
}

const ConfirmModalCard = (
    {
        isVisible,
        onModalClose,
        onCancelButtonClick,
        onConfirmButtonClick,
        title,
        description,
        confirmButtonText,
        cancelButtonText,
        hideCancelButton,
        loading,
    }: ConfirmModalCardProps) => {
    const {t} = useTranslation('organisms')

    const onModalCloseHandler = () => {
        isFunction(onModalClose) && onModalClose()
    }

    const onCancelButtonClickHandler = () => {
        isFunction(onCancelButtonClick) && onCancelButtonClick()
    }

    const onConfirmButtonClickHandler = () => {
        isFunction(onConfirmButtonClick) && onConfirmButtonClick()
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} type={ModalType.ActionSheet} size={ModalSize.Large}>
            <div className={styles.container}>
                <Card
                    type={'default'}
                    actionSheet
                    heading={{
                        title,
                        size: 'lg',
                    }}
                    paddingles={true}
                >
                    <div className={styles.descriptionWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            {description}
                        </Text>
                    </div>

                    <div className={styles.buttonsWrapper}>
                        {!hideCancelButton && (
                            <Button size={'large'} type={'pink-link'} onClick={onCancelButtonClickHandler}>
                                {cancelButtonText ?? t('confirmModal.cancel')}
                            </Button>
                        )}
                        <Button style={{whiteSpace: 'nowrap'}} size={'large'} type={'gradient'} onClick={onConfirmButtonClickHandler} loading={loading ?? false}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ConfirmModalCard

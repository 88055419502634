import atoms from './atoms.json'
import common from './common.json'
import molecules from './molecules.json'
import organisms from './organisms.json'

const tr = {
    tr: {
        atoms,
        common,
        molecules,
        organisms,
    },
}

export default tr

import * as React from 'react'
import Config from '../../../config'
import Text from 'components/atoms/text'
import ContactModal from '../contact-modal'
import Button from 'components/atoms/button'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Page404, Page500, PageBanned, PageMaintenance, PageUnauthorized} from 'components/icons'

import styles from './style.module.css'

export type ErrorCardProps = {
    type: 'unauthorized' | '404' | '500' | 'banned' | 'maintenance' | 'updateRequired'
}

const ErrorCard = ({ type }: ErrorCardProps) => {
    const { t } = useTranslation('organisms')
    const router = useHistory()
    const [showContact, setShowContact] = React.useState(false)

    let svg: JSX.Element
    let title: string
    let description: string
    let button: string
    let computedOnClick

    const backToHome = () => {
        router.push({ pathname: '/' })
    }

    const contactUs = () => {
        setShowContact(true)
    }

    const updateRedirect = () => {
        window.open(Config.linkLy.url)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    switch (type) {
        case '404':
            svg = <Page404 />
            title = t('error.404')
            description = t('error.404Desc')
            button = t('error.goHome')
            computedOnClick = backToHome
            break
        case '500':
            svg = <Page500 />
            title = t('error.500')
            description = t('error.500Desc')
            button = t('error.goHome')
            computedOnClick = backToHome
            break
        case 'unauthorized':
            svg = <PageUnauthorized />
            title = t('error.unauthorized')
            description = t('error.unauthorizedDesc')
            button = t('error.goHome')
            computedOnClick = backToHome
            break
        case 'banned':
            svg = <PageBanned />
            title = t('error.banned')
            description = t('error.bannedDesc')
            button = t('error.contactUs')
            computedOnClick = contactUs
            break
        case 'maintenance':
            svg = <PageMaintenance />
            title = t('error.maintenance')
            description = t('error.maintenanceDesc')
            button = t('error.tryAgain')
            computedOnClick = refreshPage
            break
        case 'updateRequired':
            svg = <PageBanned />
            title = t('update-needed')
            description = t('new-version-available')
            button = t('update')
            computedOnClick = updateRedirect
            break
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.background}>{svg}</div>
            <Text size={'4xl'} type={'default'} weight={'medium'} decoration={'default'}>
                {title}
            </Text>
            <Text size={'base'} type={'dimmed-light'} weight={'default'} decoration={'default'}>
                {description}
            </Text>
            <Button type={'gradient'} onClick={computedOnClick}>
                {button}
            </Button>
            <ContactModal isVisible={showContact} onModalClose={() => setShowContact(false)}/>
        </div>
    )
}

export default ErrorCard

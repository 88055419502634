import Card from '../card'
import * as React from 'react'
import {UserRole} from 'types'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import useChat from 'hooks/useChat'
import {toast} from 'react-toastify'
import moment from 'moment-timezone'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Badge from 'components/atoms/badge'
import Avatar from 'components/atoms/avatar'
import Button from 'components/atoms/button'
import {useTranslation} from 'react-i18next'
import {differenceInMinutes} from 'date-fns'
import {DeleteBinLine, FlagLine} from 'components/icons'
import {isoToHumanFull, isoToTime} from 'utils/time-helpers'
import ConfirmModalCard from '../../organisms/confirm-modal-card'
import InformationModalCard from '../../organisms/information-modal-card'
import ReportAppointmentModalCard from '../../organisms/report-appointment-modal-card'
import RemainingConversationDuration from '../../atoms/remaining-conversation-duration'
import {AppointmentQueriesAppointmentListItem as Appointment} from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import { Device } from '@capacitor/device'

type AppointmentCardProps = {
    as?: UserRole
    appointment: Appointment
    onCancelled?: (appointmentId: number) => void
}

const usePageVisibility = () => {
    const [isVisible, setIsVisible] = React.useState(!document.hidden);
  
    React.useEffect(() => {
      const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);
      };
  
      // Add event listeners
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      // Remove event listeners on cleanup
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []); // Empty array ensures effect is only run on mount and unmount
  
    return isVisible;
  };

const AppointmentCard: React.FC<AppointmentCardProps> =  ({as, appointment, onCancelled}) => {
    const api = useApi()
    const chat = useChat()

    const {t} = useTranslation('molecules')
    const isMobileOrTablet = useMedia('(max-width: 1024px)')

    const [showReportCard, setShowReportCard] = React.useState(false)
    const [showCancelCard, setShowCancelCard] = React.useState(false)
    const [visibleSuccessSend, setVisibleSuccessSend] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const [isStarted, setStarted] = React.useState(moment().isAfter(appointment.startDateTime) && moment().isBefore(appointment.endDateTime))
    const [isEnded] = React.useState(moment().isAfter(appointment.endDateTime))


    const isPageVisible = usePageVisibility();

    React.useEffect(() => {
      if (isPageVisible) {
        console.log('Browser tab is active');
        // Perform the task when the tab is active
      } else {
        console.log('Browser tab is inactive');
        // Perform another task when the tab is inactive
      }
    }, [isPageVisible]);

    const diffInMin = React.useMemo(() => {
        return differenceInMinutes(moment(appointment.endDateTime).toDate(), moment(appointment.startDateTime).toDate())
    }, [appointment.endDateTime, appointment.startDateTime])

    const openChat = () => {
        switch (as) {
            case 'Teacher':
                chat.methods?.createConversation(appointment.student.studentGuid)
                break
            case 'Student':
                chat.methods?.createConversation(appointment.teacher.teacherGuid)
                break
        }
    }

    const handleCancel = async () => {
        setLoading(true)

        try {
            if (as === 'Student') {
                await api.Appointment.cancelAppointmentAsStudent(appointment.appointmentId, 'Cancel Appointment As Student')
            } else if (as === 'Teacher') {
                await api.Appointment.cancelAppointmentAsTeacher(appointment.appointmentId, 'Cancel Appointment As Teacher')
            }

            onCancelled && onCancelled(appointment.appointmentId)
            toast.success(t('appointments.cancelled'))
            setShowCancelCard(false)
        } catch {
        }

        setLoading(false)
    }

    const handleReport = async (title: string, description?: string) => {
        setLoading(true)
        try {
            if (as === 'Student') {
                await api.Appointment.reportAppointmentAsStudent(appointment.appointmentId, title, description)
            } else if (as === 'Teacher') {
                await api.Appointment.reportAppointmentAsTeacher(appointment.appointmentId, title, description)
            }
            setVisibleSuccessSend(true)
            setShowReportCard(false)
        } catch {
        }

        setLoading(false)
    }

    const zoomCallRedirect=()=>{
        window.open(`/live/${appointment.appointmentId}`, "_blank")
    }

    return (
        <Card>
            <div className={classnames(styles.wrapper)}>
                <div className={styles.body}>
                    <div className={styles.top}>
                            <Badge>
                                <Text size="sm" type="dimmed-light">
                                    {isoToHumanFull(appointment.startDateTime)}
                                </Text>
                            </Badge>

                        {!isMobileOrTablet && (
                            <>
                                <Button
                                    size="small"
                                    type="warning-link"
                                    leftIcon={<FlagLine width={20} height={20}/>}
                                    leftIconPlacement="relative"
                                    onClick={() => setShowReportCard(true)}
                                >
                                    {t('appointments.report')}
                                </Button>

                                {!isStarted && !isEnded && (
                                    <Button
                                        size="small"
                                        type="danger-link"
                                        leftIcon={<DeleteBinLine width={20} height={20}/>}
                                        leftIconPlacement="relative"
                                        onClick={() => setShowCancelCard(true)}
                                    >
                                        {t('appointments.cancel')}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>

                    <div className={styles.bottom}>
                        <div className={classnames(styles['time-slot'])}>
                            <div className={classnames(styles['time-slot-indicator'])}/>
                            <div>
                                {isoToTime(appointment.startDateTime)} - {isoToTime(appointment.endDateTime)}
                            </div>
                        </div>

                        <div className={classnames(styles.user)}>
                            <Avatar rounded user={as === 'Teacher' ? appointment.student : appointment.teacher}/>
                            <div className={styles.userBody}>
                                <div className={styles.userName}>{as === 'Teacher' ? appointment.student.name : appointment.teacher.name}</div>
                                <div className={styles.userDesc}>
                                    {t(appointment.isFreeCall ? 'appointments.free-appointment' : 'appointments.appointment')} - {diffInMin} {t('appointments.timeDiff')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.actions}>
                    {isStarted && !isEnded && (
                        <Button as="anchor" href={`/live/${appointment.appointmentId}`} type="success-2">
                            {t('appointments.join')}
                        </Button>
                    )}

                    {!isStarted && !isEnded && (
                        <RemainingConversationDuration
                            className={styles.remainingDuration}
                            duration={moment(appointment.startDateTime).diff(moment(), 'seconds')}
                            onEnd={() => setStarted(true)}
                            maxDuration={1800}
                        />
                    )}

                    <Button onClick={openChat}>{t('appointments.sendMessage')}</Button>
                </div>

                {isMobileOrTablet && (
                    <div className={styles.actions}>
                        <Button
                            size="small"
                            type="warning-link"
                            leftIcon={<FlagLine width={20} height={20}/>}
                            leftIconPlacement="relative"
                            onClick={() => setShowReportCard(true)}
                        >
                            {t('appointments.report')}
                        </Button>

                        {!isStarted && !isEnded && (
                            <Button
                                size="small"
                                type="danger-link"
                                leftIcon={<DeleteBinLine width={20} height={20}/>}
                                leftIconPlacement="relative"
                                onClick={() => setShowCancelCard(true)}
                            >
                                {t('appointments.cancel')}
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <ReportAppointmentModalCard isVisible={showReportCard} onConfirm={handleReport} onCancel={() => setShowReportCard(false)}/>

            <InformationModalCard
                title={t('appointments.successTitle')}
                description={t('appointments.successDescription')}
                isVisible={visibleSuccessSend}
                onModalClose={() => setVisibleSuccessSend(false)}
            />

            {!isStarted && !isEnded && (
                <ConfirmModalCard
                    title={t('appointmentCard.cancelTitle')}
                    description={t('appointmentCard.cancelDescription')}
                    confirmButtonText={t('appointmentCard.confirmButtonText')}
                    cancelButtonText={t('appointmentCard.cancelButtonText')}
                    isVisible={showCancelCard}
                    onModalClose={() => setShowCancelCard(false)}
                    onConfirmButtonClick={handleCancel}
                    onCancelButtonClick={() => setShowCancelCard(false)}
                    loading={loading}
                />
            )}
        </Card>
    )
}

export default AppointmentCard

import * as React from 'react'
import Input from '../../atoms/input'
import Button from '../../atoms/button'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

type ForgottenPasswordCardProps = {
    loading?: boolean
    onConfirm: (email: string) => void
}

const ForgottenPasswordCard: React.FC<ForgottenPasswordCardProps> = ({ loading, onConfirm }) => {
    const { t } = useTranslation('organisms')

    const [email, setEmail] = React.useState('')
    const [emailCheck, setEmailCheck] = React.useState(false)

    const handlePasswordReset = () => {
        if(email !== '' && emailCheck){
            onConfirm(email)
            setEmail('')
            setEmailCheck(false)
        }
    }

    return (
        <div className={styles.wrapper}>
            <Input
                value={email}
                onChangeText={setEmail}
                placeholder={t('directAuth.email')}
                regexType={'mail'}
                onRegexFailed={setEmailCheck}
                status={email === '' || emailCheck ? 'default' : 'danger'}
                enterKeyPress={handlePasswordReset}
            />
            <Button onClick={handlePasswordReset} loading={loading} disabled={!emailCheck}>
                {t('directAuth.send')}
            </Button>
        </div>
    )
}

export default ForgottenPasswordCard

import * as React from 'react'
import classNames from 'classnames'

import styles from './style.module.css'

type AccordionCardBodyProps = {
    hasDivider?: boolean
    children: React.ReactNode
}

const AccordionCardBody = ({ children, hasDivider }: AccordionCardBodyProps) => {
    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.hasDivider]: hasDivider,
            })}
        >
            {children}
        </div>
    )
}

export default AccordionCardBody

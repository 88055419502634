import Moment from 'moment'
import * as React from 'react'
import { format } from 'date-fns'
import useApi from 'hooks/useApi'
import { toast } from 'react-toastify'
import { getUserRole } from 'utils/auth'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import Calendar from 'components/atoms/calendar'
import { isoToHumanFull } from 'utils/time-helpers'
import ErrorBox from 'components/atoms/error-box'
import { DateRange, extendMoment } from 'moment-range'
import { useHistory, useParams } from 'react-router-dom'
import { Dictionary, groupBy, isUndefined } from 'lodash'
import SectionHeading from 'components/molecules/section-heading'
import TimeRangePicker from 'components/molecules/time-range-picker'
import TeacherAppointmentCard from 'components/molecules/teacher-appointment-card'
import TeacherDetailTemplate, { styles } from 'components/templates/teacher-detail'
import { AppointmentCommandsSelectedTimeRange, TeacherQueriesFreeAppointmentRange, TeacherQueriesGetDetailsQueryResult as Teacher } from '@mohsininsignia/advancerapiclient'
import useAuth from 'hooks/useAuth'

const moment = extendMoment(Moment as any)

export default function Appointment() {
    const { t } = useTranslation('common')
    const auth = useAuth()
    const api = useApi()
    const role = getUserRole()
    const router = useHistory()
    const { id } = useParams<{ id: string }>()

    const [teacher, setTeacher] = React.useState<Teacher | undefined>(undefined)

    const [days, setDays] = React.useState<Dictionary<TeacherQueriesFreeAppointmentRange[]>>({})
    const [loading, setLoading] = React.useState<boolean>(false)
    const [availableDates, setAvailableDates] = React.useState<Date[]>([])
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date())
    const [selectedTimeRange, setSelectedTimeRange] = React.useState<TeacherQueriesFreeAppointmentRange | undefined>(undefined)

    const toggleInTimeRange = (date: any) => {
        setSelectedTimeRange(date)
    }
    function getTimezoneName() {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

            // by this time `trimmed` should be the timezone's name with some punctuation -
            // trim it from both sides
            return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

        } else {
            // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
            return full;
        }
    }
    const createAppointment = async () => {
        if (!auth.isLoggedIn) {
            auth.setModalShown?.(true)
            return
        }

        if (!selectedTimeRange) return

        setLoading(true)
        try {
            const range: AppointmentCommandsSelectedTimeRange = {
                startDateTime: selectedTimeRange.start!,
                endDateTime: selectedTimeRange.end!,
            }

            await api.Appointment.createAppointment(range, teacher?.teacherId, getTimezoneName().toString())
            router.push({ pathname: '/profile/appointments' })
            toast.success(t('appointments.successMessage'))
        } catch {
        }

        setLoading(false)
    }

    const disabledDays = React.useMemo(() => {
        const fromDate = moment()
        const toDate = moment().add(14, 'days')
        const fullDateRange: DateRange = moment.range(fromDate, toDate)
        const snappedRange = fullDateRange.snapTo('days')
        const fullDateList = Array.from(snappedRange.by('days'))

        if (!availableDates || availableDates.length < 1) {
            return fullDateList.map(x => x.toDate())
        }

        let mapped: Date[] = []

        fullDateList.forEach(x => {
            let add = true

            availableDates.forEach(y => {
                if (x.isSame(moment(y), 'days')) {
                    add = false
                }
            })

            add && mapped.push(x.toDate())
        })

        return mapped
    }, [availableDates])

    React.useEffect(() => {
        ; (async () => {
            try {
                const { data } = await api.Teacher.getTeacherDetails(parseInt(id))
                setTeacher(data)
            } catch {
            }
        })()
    }, [api.Teacher, id])
    console.log('teacher,teacher');
    React.useEffect(() => {
        const groupedDays = groupBy(
            teacher?.freeAppointmentRanges.filter(r => !r.isMine),
            r => {
                if (r.start) {
                    return format(new Date(r.start), 'yyyy-MM-dd')
                }
            }
        )
        setDays(groupedDays)
        setAvailableDates(Object.keys(groupedDays).map(r => new Date(r)))
    }, [teacher?.freeAppointmentRanges])
    console.log(teacher?.freeAppointmentRanges);
    React.useEffect(() => {
        setSelectedTimeRange(undefined)
    }, [selectedDate])

    if (role === 'Teacher') {
        router.push({ pathname: '/' })
        return <></>
    }

    return (
        <Master title={`${t('appointments.title')}${t('title')}`}>
            <TeacherDetailTemplate>
                <div>
                    {teacher && (
                        <TeacherAppointmentCard
                            loading={loading}
                            onConfirm={createAppointment}
                            canCreateAppointment={!!selectedTimeRange}
                            selectedDates={selectedTimeRange ? [selectedTimeRange] : []}
                            teacher={teacher}
                        />
                    )}
                </div>

                <div>
                    <Calendar
                        availableDates={availableDates}
                        label={t('appointments.selectDate')}
                        onDateSelected={setSelectedDate}
                        fromDate={moment().toDate()}
                        toDate={moment().add('14', 'days').toDate()}
                        disabledDays={disabledDays}
                        required
                    />
                </div>

                <div className={styles.list}>
                    <SectionHeading>{isoToHumanFull(selectedDate.toString())}</SectionHeading>
                    <div className={styles.listItemsWrapper}>
                        {days[format(selectedDate, 'yyyy-MM-dd')]?.map((r: any, i: number) => {
                            r.startTimeUTC = r?.start
                            r.startTime = moment(r?.start).toISOString()
                            r.endTimeUTC = r?.end
                            r.endTime = moment(r?.end).toISOString()
                            if (moment(r.start).isSameOrBefore(moment().add(40, 'minutes'))) {
                                return undefined
                            }

                            return (
                                <div className={styles.listItem} key={i.toString()}>
                                    <TimeRangePicker
                                        isSelected={selectedTimeRange === r}
                                        onSelect={() => toggleInTimeRange(r)}
                                        label={`${format(new Date(r.start), 'HH:mm')} - ${format(new Date(r.end), 'HH:mm')}`}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    {isUndefined(days[format(selectedDate, 'yyyy-MM-dd')]) && <ErrorBox text={t('appointments.notAvailable')} size={'sm'} />}
                </div>
            </TeacherDetailTemplate>
        </Master>
    )
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/index'
import translationTR from './locales/tr/index'

// import Cookies from 'universal-cookie'
// import { lang } from 'moment'
// let cookies = new Cookies()
let language: string = localStorage.getItem('lang') ?? 'tr'
// let language: string = 'tr'
localStorage.setItem('lang',language)

i18n.use(initReactI18next).init({
    resources: {
        ...translationEN,
        ...translationTR,
    },
    ns: ['common', 'atoms', 'molecules', 'organisms'],
    defaultNS: 'common',
    // lng: cookies.get('lang'),
    lng: language,
    fallbackLng: 'tr',
    debug: false,
    cleanCode: true,
    interpolation: {
        escapeValue: false,
    },
    load: 'languageOnly',
    react: {
        useSuspense: false,
    },
})

export default i18n

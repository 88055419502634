import * as React from 'react'

function SvgTurtleSlow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 175" {...props}>
            <path d="M185 29.7v.2l15-12.6c-11.4-6.1-23.9-9.5-37.5-9.5s-26.2 3.4-37.1 9.3l15 12.6H185zm-79.9 63.5c-24.7 0-45 20.3-45 45v4.5c.2 9.5 7.9 17 17.4 17s17.2-7.5 17.4-17v-4.5c0-5.7 4.5-10.1 10.1-10.1 9.7 0 17.6-7.9 17.6-17.6s-7.8-17.3-17.5-17.3zm116.8-.4c-9.7 0-17.6 7.9-17.6 17.6v32.4c.4 9.3 7.9 16.8 17.4 16.8s17-7.5 17.4-16.8v-32.7c.2-9.6-7.7-17.3-17.2-17.3zm30.8 9.1c0-4.3-1.4-8.5-3.4-12-2.2 1.6-4.7 2.6-7.7 2.6 4.1 4.9 6.5 11 6.5 17.6v5.5c3.2-3.8 4.6-8.6 4.6-13.7zm-121 8.7c0 5.7-1.8 10.7-4.7 15 11.6 2.6 23.5 4.1 35.7 4.1 11.4 0 22.1-1.2 32.7-3.4v-16c0-6.5 2.4-12.6 6.3-17h-76.5c3.8 4.7 6.5 10.6 6.5 17.3zM125 93.4c.2 0 0-.2 0-.2v.2zm120.8-13.8c0-2.4-2-4.7-4.5-4.7h-.6c-3.4-21.7-15.4-40.6-32.7-52.5l-17.6 14.8c9.7 11.4 16.4 24.1 20.3 37.9h-9.5c-4.3-13-11.4-25.6-21.5-35.9h-32.9c-10.1 10.5-17 22.7-21.5 35.9h-9.5c3.9-13.4 10.3-26 19.9-36.9l-18.5-15.6c-17 12.2-29.4 30.8-32.7 52.5h-1.2c-2.4 0-4.5 2-4.5 4.5 0 2.4 2 4.5 4.5 4.5h157.8c2.7 0 4.7-2.1 4.7-4.5zM81.6 89.5c-4.9-.6-8.9-4.7-8.9-9.9v-8.9c0-18.7-15-33.9-33.9-33.9-18.7 0-33.9 15-33.9 33.9v8.9c0 4.9 4.1 8.9 8.9 8.9h24.5c2.2 11.4 8.5 20.7 17.6 27.4 5.2-11.6 14.3-20.7 25.7-26.4zM24.2 64.4c-3 0-5.7-2.4-5.7-5.7 0-3 2.4-5.7 5.7-5.7s5.7 2.4 5.7 5.7c-.1 3.2-2.5 5.7-5.7 5.7z" />
        </svg>
    )
}

export default SvgTurtleSlow

import * as React from 'react'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Input from 'components/atoms/input'
import useDebounce from 'hooks/useDebounce'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import {useTranslation} from 'react-i18next'
import Modal, {ModalType} from 'components/atoms/modal'
import Checkbox from 'components/atoms/checkbox'
import {CloseLine, Filter3Line, Search2Line} from 'components/icons'
import {Accents, LanguageLevels, LessonTypes, TeachingStyles} from './filters'
import TeacherSpecialitiesSelectionCard, {TeacherSpecialities} from 'components/organisms/teacher-specialities-selection-card'

import styles from './style.module.css'

export type EmittedFilters = {
    query?: string
    teachingStyles?: string[]
    lessonTypes?: string[]
    accents?: string[]
    languageLevels?: string[]
}

interface FiltersChanged {
    (params: EmittedFilters): void
}

type TeacherFiltersProps = {
    onChange: FiltersChanged
}

const TeacherFilters = ({onChange}: TeacherFiltersProps) => {
    const {t} = useTranslation('organisms')
    const isMobile = useMedia('(max-width: 640px)')

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)
    const [filtersShown, setFiltersShown] = React.useState<boolean>(false)

    const [teachingStyles, setTeachingStyles] = React.useState(TeachingStyles.map(r => ({...r, isSelected: false})))
    const [lessonTypes, setLessonTypes] = React.useState(LessonTypes.map(r => ({...r, isSelected: false})))
    const [accents, setAccents] = React.useState(Accents.map(r => ({...r, isSelected: false})))
    const [languageLevels, setLanguageLevels] = React.useState(LanguageLevels.map(r => ({...r, isSelected: false})))

    const [teacherSpecialities, setTeacherSpecialities] = React.useState<TeacherSpecialities>({
        teachingStyles: [],
        lessonTypes: [],
        accents: [],
        languageLevels: [],
    })

    React.useEffect(() => {
        onChange({
            query: debouncedQuery,
            accents: accents.filter(r => r.isSelected).map(r => r.value),
            lessonTypes: lessonTypes.filter(r => r.isSelected).map(r => r.value),
            teachingStyles: teachingStyles.filter(r => r.isSelected).map(r => r.value),
            languageLevels: languageLevels.filter(r => r.isSelected).map(r => r.value),
        })
    }, [isMobile, onChange, debouncedQuery, teachingStyles, lessonTypes, accents, languageLevels, teacherSpecialities])

    React.useEffect(() => {
        const listener = (event: any) => {
            if (event.keyCode === 27) setFiltersShown(false)
        }
        window.addEventListener('keydown', listener)

        return () => {
            window.removeEventListener('keydown', listener)
        }
    }, [])

    const toggleFilters = () => {
        setFiltersShown(r => !r)
    }

    const onTeachingStyleChanged = (isSelected: boolean, item: any) => {
        setTeachingStyles(i => i.map(k => ({...k, isSelected: item.value === k.value ? isSelected : k.isSelected})))
    }

    const onLessonTypeChanged = (isSelected: boolean, item: any) => {
        setLessonTypes(i => i.map(k => ({...k, isSelected: item.value === k.value ? isSelected : k.isSelected})))
    }

    const onAccentChanged = (isSelected: boolean, item: any) => {
        setAccents(i => i.map(k => ({...k, isSelected: item.value === k.value ? isSelected : k.isSelected})))
    }

    const onLanguageLeveChanged = (isSelected: boolean, item: any) => {
        setLanguageLevels(i => i.map(k => ({...k, isSelected: item.value === k.value ? isSelected : k.isSelected})))
    }

    const handleTeacherSpecialitiesSelection = (specialities: TeacherSpecialities) => {
        setTeachingStyles(i => i.map(k => ({...k, isSelected: specialities.teachingStyles?.find(x => x === k.value) !== undefined})))
        setLessonTypes(i => i.map(k => ({...k, isSelected: specialities.lessonTypes?.find(x => x === k.value) !== undefined})))
        setAccents(i => i.map(k => ({...k, isSelected: specialities.accents?.find(x => x === k.value) !== undefined})))
        setLanguageLevels(i => i.map(k => ({...k, isSelected: specialities.languageLevels?.find(x => x === k.value) !== undefined})))
        setTeacherSpecialities(specialities)
        setFiltersShown(false)
    }

    return (
        <div className={classnames(styles['filters-wrapper'])}>
            <div className={classnames(styles['top-bar'])}>
                <div className={classnames(styles.toggler)}>
                    <Button
                        type={filtersShown ? 'danger' : 'dimmed'}
                        onClick={toggleFilters}
                        variation="block"
                        alignment={isMobile ? 'left' : 'center'}
                        leftIcon={<Filter3Line/>}
                        leftIconPlacement={'relative'}
                    >
                        {filtersShown ? t('teacherFilters.filtersHide') : t('teacherFilters.filtersShow')}
                    </Button>
                </div>
                <div className={classnames(styles['search-box'])}>
                    <Input
                        placeholder={t('teacherFilters.search')}
                        value={query}
                        onChangeText={setQuery}
                        leadingComponent={<Search2Line className={classnames(styles.searchIcon, {[styles.searched]: query.length > 0})}/>}
                    />
                </div>
            </div>

            {filtersShown && !isMobile && (
                <div className={classnames(styles.filters)}>
                    <Card heading={{title: t('teachingStyles.title'), alignment: 'left'}}>
                        <div className={classnames(styles.choices)}>
                            {teachingStyles.map(r => (
                                <Checkbox key={r.value} label={t(`filters.${r.value}`)} checked={r.isSelected} onChange={i => onTeachingStyleChanged(i, r)}/>
                            ))}
                        </div>
                    </Card>

                    <Card heading={{title: t('lessonTypes.title'), alignment: 'left'}}>
                        <div className={classnames(styles.choices, styles['grid-2'])}>
                            {lessonTypes.map(r => (
                                <Checkbox key={r.value} label={t(`lessonTypes.${r.value}`)} checked={r.isSelected} onChange={i => onLessonTypeChanged(i, r)}/>
                            ))}
                        </div>
                    </Card>

                    <Card heading={{title: t('accents.title'), alignment: 'left'}}>
                        <div className={classnames(styles.choices, styles['grid-1'])}>
                            {accents.map(r => (
                                <Checkbox key={r.value} label={t(`accents.${r.value}`)} checked={r.isSelected} onChange={i => onAccentChanged(i, r)}/>
                            ))}
                        </div>
                    </Card>

                    <Card heading={{title: t('languageLevels.title'), alignment: 'left'}}>
                        <div className={classnames(styles.choices, styles['grid-1'])}>
                            {languageLevels.map(r => (
                                <Checkbox key={r.value} label={t(`languageLevels.${r.value}`)} checked={r.isSelected} onChange={i => onLanguageLeveChanged(i, r)}/>
                            ))}
                        </div>
                    </Card>
                </div>
            )}

            {isMobile && (
                <Modal isVisible={filtersShown} onModalClose={() => setFiltersShown(false)} type={ModalType.Fullscreen}>
                    <div className={styles.modalTitle}>
                        <Text size={'lg'} type={'default'} weight={'medium'} decoration={'default'}>
                            {t('teacherFilters.filters')}
                        </Text>
                        <CloseLine className={styles.closeLine} onClick={() => setFiltersShown(false)}/>
                    </div>
                    <div className={classnames(styles['filters-modal'])}>
                        {teacherSpecialities && (
                            <TeacherSpecialitiesSelectionCard
                                specialities={teacherSpecialities}
                                onConfirm={handleTeacherSpecialitiesSelection}
                                isAccentMultiple
                                isLanguageLevelMultiple
                            />
                        )}
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default TeacherFilters

import {Chat} from "./chat"
import {Theme} from './theme'
import * as React from 'react'
import useApi from "../hooks/useApi"
import useMedia from "../hooks/useMedia"
import {PaymentProvider} from "./payment"
import {ToastContainer} from 'react-toastify'
import {BrowserRouter} from 'react-router-dom'
import {HelmetProvider} from 'react-helmet-async'
import {Auth, AuthContext, AuthContextProps} from './auth'
import {CallContext, CallContextProps, CallProvider} from "./call"

export default function Providers({children}: { children: React.ReactNode }) {
    const api = useApi();
    const isMobile = useMedia('(max-width: 1024px)')

    return (
        <BrowserRouter>
            <Auth>
                <AuthContext.Consumer>
                    {(authProvider: AuthContextProps | {}) => (
                        <PaymentProvider>
                            <CallProvider>
                                <CallContext.Consumer>
                                    {(callProvider: CallContextProps | {}) => (
                                        <Chat api={api} auth={authProvider as AuthContextProps} call={callProvider as CallContextProps}>
                                            <HelmetProvider>
                                                <Theme>
                                                    {children}

                                                    <ToastContainer
                                                        position={isMobile ? 'top-center' : 'top-right'}
                                                        autoClose={2000}
                                                        hideProgressBar
                                                        newestOnTop
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss={false}
                                                        draggable
                                                        pauseOnHover
                                                        limit={isMobile ? 1 : 3}
                                                        closeButton={<></>}
                                                        theme={'colored'}
                                                    />
                                                </Theme>
                                            </HelmetProvider>
                                        </Chat>
                                    )}
                                </CallContext.Consumer>
                            </CallProvider>
                        </PaymentProvider>
                    )}
                </AuthContext.Consumer>
            </Auth>
        </BrowserRouter>
    )
}

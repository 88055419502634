import Card from '../card'
import {User} from 'types'
import * as React from 'react'
import Badge from 'components/atoms/badge'
import Button from 'components/atoms/button'
import Avatar from 'components/atoms/avatar'
import {useTranslation} from 'react-i18next'
import {isoToHuman} from 'utils/time-helpers'
import {VideoRecordQueriesVideoRecordListItem as VideoRecord} from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import {getUserRole} from "../../../utils/auth";

export default function VideoRecordCard({video, onClick}: { video: VideoRecord, onClick: (video: string) => void }) {
    const {t} = useTranslation('molecules')
    const {teacher, student} = video
    const role = getUserRole()

    return (
        <Card paddingles={false}>
            <div className={styles.wrapper}>
                <Avatar user={role === 'Student' ? teacher as User : student as User} rounded/>
                <div className={styles.teacher}>{role === 'Student' ? teacher.name : student.name}</div>
                <div className={styles.date}>{isoToHuman(video.videoCreatedAt)}</div>
                <div className={styles.duration}>
                    <Badge status="danger">{video.videoDuration}</Badge>
                </div>
                <Button type="gradient" onClick={() => onClick(video.videoUrl)}>{t('videoRecords.replay')}</Button>
            </div>
        </Card>
    )
}

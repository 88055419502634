import * as React from 'react'
import useAuth from 'hooks/useAuth'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'
import { getUserRole } from 'utils/auth'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NavigationItem, { NavigationItemProps } from 'components/atoms/navigation-item'
import { CalendarTodoLine, CustomerService2Line, Home3Line, Search2Line, User3Line, MessagesDesktop, MessagesMobile } from 'components/icons'

import styles from './style.module.css'

export default function Navigation() {
    const { t } = useTranslation('common')

    const location = useLocation()
    const userRole = getUserRole()
    const { isLoggedIn, toggleModal } = useAuth()
    const isMobile = useMedia('(max-width: 1024px)')
    const [items, setItems] = React.useState<NavigationItemProps[]>([])

    const profileHandler = (e: any) => {
        if (!isLoggedIn) {
            e.preventDefault()
            toggleModal?.()
        }
    }

    const isActiveRoute = React.useCallback(
        (prefix = null, exact: boolean = false) => {
            if (exact) return location.pathname === prefix
            if (prefix) return location.pathname.startsWith(`${prefix}`)
        },
        [location]
    )

    const swapArrayElements = function (arr: Array<NavigationItemProps>, indexA: number, indexB: number) {
        const temp = arr[indexA]
        arr[indexA] = arr[indexB]
        arr[indexB] = temp
    }

    React.useEffect(
        () => {
            const navigationItems = [
                {
                    label: t('navigation.home'),
                    icon: <Home3Line />,
                    url: '/',
                    active: isActiveRoute('/', true),
                    isLink: true,
                },
                {
                    label: t('navigation.teachers'),
                    icon: <CustomerService2Line />,
                    url: '/teachers',
                    showTippy: false,
                    active: isActiveRoute('/teachers'),
                    isLink: true,
                },
                {
                    label: t('navigation.wordTutorials'),
                    icon: <Search2Line />,
                    url: '/word-tutorials',
                    showTippy: false,
                    active: isActiveRoute('/word-tutorials'),
                    isLink: true,
                },
                {
                    label: t('navigation.messages'),
                    icon: <MessagesMobile />,
                    url: '/messages',
                    showTippy: false,
                    active: isActiveRoute('/messages'),
                    isLink: true,
                },
                {
                    label: t('navigation.profile'),
                    icon: <User3Line />,
                    url: '/profile',
                    showTippy: false,
                    active: isActiveRoute('/profile'),
                    isLink: isLoggedIn,
                    handler: profileHandler,
                },
            ]
        
            if (userRole === 'Teacher') {
                navigationItems[1] = {
                    label: t('navigation.calendar'),
                    icon: <CalendarTodoLine />,
                    url: '/calendar',
                    showTippy: false,
                    active: isActiveRoute('/calendar'),
                    isLink: true,
                }
            }

            if (!isMobile) {
                navigationItems[3] = {
                    label: t('navigation.messages'),
                    icon: <MessagesDesktop />,
                    url: '/',
                    active: isActiveRoute('/messages'),
                    showTippy: true,
                    handler: () => {},
                    isLink: false,
                }
            } else {
                swapArrayElements(navigationItems, 1, 2)
            }
            console.log('[user]',userRole);
console.log('[Nav Menu]',navigationItems);
            setItems(navigationItems)
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, isLoggedIn, userRole, isMobile, t]
    )

    return (
        <div className={classnames(styles.navigation)}>
            {items.map((item, i) => (
                <NavigationItem key={i.toString()} {...item} />
            ))}
        </div>
    )
}

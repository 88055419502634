import * as React from 'react'
import classNames from 'classnames'
import Text from 'components/atoms/text'
import FavoriteButton from 'components/atoms/favorite-button'
import { WordTutorialQueriesFavoriteWordTutorial as Word } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import { Link } from 'react-router-dom'

type FavoriteWordButtonProps = {
    word: Word
    onChangeFavorite?: (isFavorited: boolean) => void
}

const FavoriteWordButton = ({ word, onChangeFavorite }: FavoriteWordButtonProps) => {
    const favoriteChangeHandler = (isFavorited: boolean) => {
        if (typeof onChangeFavorite === 'function') {
            onChangeFavorite(isFavorited)
        }
    }

    return (
        <div className={classNames(styles.container)}>
            <FavoriteButton favorited={word.isFavorited} onChange={favoriteChangeHandler} />
            <Link to={`/word-tutorials/${word.wordTutorialCategorySlug}?selected=${word.slug}`}>
                <div className={classNames(styles.wrapper)}>
                    <div className={styles.translations}>
                        <Text size="xl" weight="medium">
                            {word.translations[0].content}
                        </Text>
                        <Text weight="bold" type="danger">
                            {word.translations[1].content}
                        </Text>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default FavoriteWordButton

import * as React from 'react'
import AccordionCardBody from 'components/atoms/accordion-card-body'
import AccordionCardHeading from 'components/atoms/accordion-card-heading'

type AccordionCardProps = {
    title: string
    subtitle?: string
    isIndicatorShown?: boolean
    hasDivider?: boolean
    children: React.ReactNode
    initialStatus?: boolean
}

const AccordionCard = ({ title, subtitle, isIndicatorShown, hasDivider, children, initialStatus = false }: AccordionCardProps) => {
    const [active, setActive] = React.useState<boolean>(initialStatus)

    return (
        <div>
            <AccordionCardHeading title={title} subtitle={subtitle} isIndicatorShown={isIndicatorShown} isActive={active} onChange={setActive} />
            {active && <AccordionCardBody hasDivider={hasDivider}>{children}</AccordionCardBody>}
        </div>
    )
}

export default AccordionCard

import * as React from 'react'
import classnames from 'classnames'
import Navigation from 'components/molecules/navigation'

import styles from './style.module.css'

export default function PageBottom() {
    return (
        <div className={classnames(styles.pageBottom)}>
            <Navigation />
        </div>
    )
}

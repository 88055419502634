import * as React from 'react'
import classnames from 'classnames'
import {Loader2Line, StarFill, StarLine} from 'components/icons'

import styles from './style.module.css'

export default function FavoriteButton({favorited, onChange, loading}: { favorited: boolean; onChange?: (isFavorite: boolean) => void, loading?: boolean }) {
    const [isFavorite, setIsFavorite] = React.useState<boolean>(favorited)

    const toggleFavorite = React.useCallback(() => {
        setIsFavorite(r => {
            onChange && onChange(!r)
            return !r
        })
    }, [onChange])

    return (
        <button
            onClick={toggleFavorite}
            className={classnames(styles['favorite-button'], {
                [styles['is-favorited']]: isFavorite,
            })}
        >
            {!loading && (isFavorite ? <StarFill fill="currentColor"/> : <StarLine fill="currentColor"/>)}

            {loading && <Loader2Line className={styles.loadingIcon}/>}
        </button>
    )
}

import * as React from 'react'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { CheckLine } from 'components/icons'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

export enum SubtitleStatus {
    None,
    English,
    BothTurkishAndEnglish,
}

type SubtitleLanguageSelectorProps = {
    isVisible: boolean
    onClose: () => void
    selected: SubtitleStatus
    onSelect: (status: SubtitleStatus) => void
}

const SubtitleLanguageSelector: React.FC<SubtitleLanguageSelectorProps> = ({ selected, onSelect, isVisible, onClose }) => {
    const { t } = useTranslation('organisms')
    return (
        <Modal isVisible={isVisible} onModalClose={onClose} size={ModalSize.Small} type={ModalType.ActionSheet}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('subtitle.selection'),
                    }}
                    type="darken"
                >
                    <div className={styles.actions}>
                        <button onClick={() => onSelect(SubtitleStatus.BothTurkishAndEnglish)}>
                            {t('subtitle.turkish-english')}
                            {selected === SubtitleStatus.BothTurkishAndEnglish && <CheckLine />}
                        </button>
                        <button onClick={() => onSelect(SubtitleStatus.English)}>
                            {t('subtitle.english')}
                            {selected === SubtitleStatus.English && <CheckLine />}
                        </button>
                        <button onClick={() => onSelect(SubtitleStatus.None)}>
                            {t('subtitle.close')}
                            {selected === SubtitleStatus.None && <CheckLine />}
                        </button>
                    </div>

                    <Button onClick={onClose} type="dimmed" variation="block">
                        {t('subtitle.give-up')}
                    </Button>
                </Card>
            </div>
        </Modal>
    )
}

export default SubtitleLanguageSelector

import * as React from 'react'
import {LegalDocumentQueriesLegalDocumentsListItem} from "@mohsininsignia/advancerapiclient";

type AppInfoContextProps = {
    currentAndroidVersion: string | undefined
    setCurrentAndroidVersion: React.Dispatch<React.SetStateAction<string | undefined>>

    currentIOSVersion: string | undefined
    setCurrentIOSVersion: React.Dispatch<React.SetStateAction<string | undefined>>

    minimumAndroidVersion: string | undefined
    setMinimumAndroidVersion: React.Dispatch<React.SetStateAction<string | undefined>>

    minimumIOSVersion: string | undefined
    setMinimumIOSVersion: React.Dispatch<React.SetStateAction<string | undefined>>

    inMaintenance: boolean | undefined
    setInMaintenance: React.Dispatch<React.SetStateAction<boolean | undefined>>

    legalDocuments: LegalDocumentQueriesLegalDocumentsListItem[] | undefined
    setLegalDocuments: React.Dispatch<React.SetStateAction<LegalDocumentQueriesLegalDocumentsListItem[] | undefined>>

    playerId: string | undefined
    setPlayerId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const AppInfoContext = React.createContext<Partial<AppInfoContextProps>>({})

export const AppInfoProvider: React.FC = ({ children }) => {
    const [currentAndroidVersion, setCurrentAndroidVersion] = React.useState<string | undefined>(undefined)
    const [currentIOSVersion, setCurrentIOSVersion] = React.useState<string | undefined>(undefined)
    const [minimumAndroidVersion, setMinimumAndroidVersion] = React.useState<string | undefined>(undefined)
    const [minimumIOSVersion, setMinimumIOSVersion] = React.useState<string | undefined>(undefined)
    const [inMaintenance, setInMaintenance] = React.useState<boolean | undefined>(undefined)
    const [legalDocuments, setLegalDocuments] = React.useState<LegalDocumentQueriesLegalDocumentsListItem[] | undefined>(undefined)
    const [playerId, setPlayerId] = React.useState<string | undefined>(undefined)

    return (
        <AppInfoContext.Provider
            value={{
                currentAndroidVersion,
                setCurrentAndroidVersion,
                currentIOSVersion,
                setCurrentIOSVersion,
                minimumAndroidVersion,
                setMinimumAndroidVersion,
                minimumIOSVersion,
                setMinimumIOSVersion,
                inMaintenance,
                setInMaintenance,
                legalDocuments,
                setLegalDocuments,
                playerId,
                setPlayerId,
            }}>{children}</AppInfoContext.Provider>
    )
}

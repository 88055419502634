import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import moment from 'moment-timezone'

type PageHeadingProps = {
    title: string
    trailing?: React.ReactNode
    centered?: boolean
}

export default function PageHeading({ title, trailing, centered }: PageHeadingProps) {
    console.log("moment().tz(moment.tz.guess()).utcOffset() / 60", moment().tz(moment.tz.guess()).utcOffset() / 60)
    return (
        <div className={classnames(styles.wrapper)}>
            <div className={classnames(styles.title, { [styles.centered]: centered })}>{title}</div>
            {trailing && <div className={classnames(styles.trailing)}>{trailing}</div>}

            <div>
                <span className={styles.timezone}>
                    <b>Time zone:</b> {moment.tz.guess()} {'(UTC+' + (moment().tz(moment.tz.guess()).utcOffset() / 60).toFixed(0) + ':00)'}
                </span>
            </div>
        </div>
    )
}

export function readFile(file: File): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result as string), false)
        reader.readAsDataURL(file)
    })
}

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.src = url
    })

export type CropType = {
    x?: number
    y?: number
    width?: number
    height?: number
}

export async function getCroppedImg(imageSrc: string | ArrayBuffer | null, pixelCrop: CropType, type: 'BASE64' | 'BLOB' = 'BLOB'): Promise<string> {
    const image = await createImage(imageSrc as string)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2)
    ctx?.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx?.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
    const data = ctx?.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    if(pixelCrop.width && pixelCrop.height){
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
    }

    // paste generated rotate image with correct offsets for x,y crop values.
    if(data && typeof pixelCrop.x !== 'undefined' && typeof pixelCrop.y !== 'undefined'){
        ctx?.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y))
    }

    switch (type) {
        case 'BLOB':
            // As a blob
            return new Promise(resolve => {
                canvas.toBlob((file: any) => {
                    resolve(URL.createObjectURL(file))
                }, 'image/png')
            })
        case 'BASE64':
            // As Base64 string
            return canvas.toDataURL('image/jpeg')
    }
}

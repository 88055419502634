import * as React from 'react'
import classNames from 'classnames'
import useMedia from 'hooks/useMedia'
import {Menu} from '@headlessui/react'
import {useTranslation} from 'react-i18next'
import Avatar, {AvatarProps} from 'components/atoms/avatar'
import {ArrowLeftSLine, CloseLine, More2Fill, SubtractLine} from 'components/icons'

import styles from './style.module.css'

export type ConversationHeadingProps = {
    avatarUrl: string
    userName: string
    userStatus: AvatarProps['status']
    onMinimizeClick?: () => void
    onCloseClick?: () => void
    onUserClick?: () => void
    onUserReported?: () => void
    onUserBlocked?: () => void
    onConversationDeleted?: () => void
    isUserBlocked: boolean
}

const ConversationHeading = (
    {
        avatarUrl,
        userName,
        userStatus,
        onMinimizeClick,
        onCloseClick,
        onUserClick,
        onUserReported,
        onUserBlocked,
        onConversationDeleted,
        isUserBlocked,
    }: ConversationHeadingProps) => {
    const {t} = useTranslation('molecules')
    const isMobileOrTablet = useMedia('(max-width: 1024px)')

    const onMinimizeClickHandler = () => {
        if (typeof onMinimizeClick === 'function') {
            onMinimizeClick()
        }
    }

    const onCloseClickHandler = () => {
        if (typeof onCloseClick === 'function') {
            onCloseClick()
        }
    }

    const onUserClickHandler = () => {
        if (typeof onUserClick === 'function') {
            onUserClick()
        }
    }

    const moreActions = [
        {
            label: t('conversations.more.report'), // 'Kullanıcıyı Şikayet Et',
            handler: onUserReported,
        },
        {
            label: isUserBlocked ? t('conversations.more.unBlock') : t('conversations.more.block'), // 'Kullanıcıyı Engelle',
            handler: onUserBlocked,
        },
        {
            label: t('conversations.more.delete'), // 'Konuşmayı Sil',
            handler: onConversationDeleted,
            danger: true,
        },
    ]

    return (
        <div className={styles.wrapper}>
            <div onClick={onUserClickHandler} className={styles.avatar}>
                <Avatar url={avatarUrl} rounded={true} size={isMobileOrTablet ? 2.5 : 1.5} status={userStatus}/>
            </div>
            <div onClick={onUserClickHandler} className={styles.username}>
                {userName}
            </div>
            {!isMobileOrTablet && (
                <div className={styles['action-button']}>
                    <SubtractLine className={styles.subtractLine} onClick={onMinimizeClickHandler}/>
                </div>
            )}
            <div className={classNames(styles['action-button'], styles.close)}>
                {isMobileOrTablet ? <ArrowLeftSLine className={styles.closeLine} onClick={onCloseClickHandler}/> :
                    <CloseLine className={styles.closeLine} onClick={onCloseClickHandler}/>}
            </div>
            <div className={styles.more}>
                <Menu>
                    {({open}) => (
                        <>
                            <Menu.Button
                                className={classNames(styles['action-button'], {
                                    [styles.active]: open,
                                })}
                            >
                                <More2Fill className={styles.more2Line} onClick={onUserClickHandler}/>
                            </Menu.Button>
                            <Menu.Items className={styles['more-container']}>
                                {moreActions.map((r, i) => (
                                    <Menu.Item key={i.toString()}>
                                        <button
                                            className={classNames(styles['more-dialog-button'], {
                                                [styles.danger]: r.danger,
                                            })}
                                            onClick={r.handler}
                                        >
                                            {r.label}
                                        </button>
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </>
                    )}
                </Menu>
            </div>
        </div>
    )
}

export default ConversationHeading

import * as React from 'react'
import classnames from 'classnames'
import useApi from 'hooks/useApi'
import Card from 'components/molecules/card'
import ListBox from 'components/atoms/listbox'
import { useTranslation } from 'react-i18next'
import SubscriptionPackageDurationItem from 'components/molecules/subscription-package-duration-item'
import { PaymentQueriesListSubscriptionOptionsQueryResult as Option } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import { toast } from 'react-toastify'

export type SubscriptionOnChange = {
    subscriptionId: number | undefined
    // checkedIndex?: number
    // checkedMinutesPerDayPossibilities?: number
    // checkedDayPossibilities?: number
    // checkedPrice?: BigNumber
}

type SubscriptionSelectionProps = {
    options: Option
    disabled: boolean
    onChange: ({ subscriptionId }: SubscriptionOnChange) => void
    initialDayPossibilities: number
    initialMinutesPerDayPossibilities: number
    initialIndex: number
    setReferralApplied?: any
    setReferralTotalMinutes?: any
}

export default function SubscriptionSelection({
    options,
    disabled,
    onChange,
    initialDayPossibilities,
    initialMinutesPerDayPossibilities,
    initialIndex,
    setReferralApplied,
    setReferralTotalMinutes,
}: SubscriptionSelectionProps) {
    const { t } = useTranslation('common')
    const api = useApi()

    const [checked, setChecked] = React.useState<number | null>(initialIndex)

    const [minutesPerDayValue, setMinutesPerDayValue] = React.useState(initialMinutesPerDayPossibilities)
    const [dayPossibilitiesValue, setDayPossibilitiesValue] = React.useState(initialDayPossibilities)
    const [minutesPerDayPossibility, setMinutesPerDayPossibility] = React.useState<any[]>([])
    const [dayPossibility, setDayPossibility] = React.useState<any[]>([])
    const [dayID, setDayID] = React.useState<number>()
    const [minuteID, setMinuteID] = React.useState<number>()
    const [ids, setIds] = React.useState<Number[] | any>([])

    const onChangeFunction = (props: SubscriptionOnChange) => {
        if (typeof onChange === 'function') {
            onChange(props)
            if (setReferralApplied) {
                setReferralApplied(false)
            }
        }
    }

    React.useEffect(() => {
        let minutesArray = options.minutesPerDayPossibilities.map((obj: any) => obj.minutesPerDayOption)
        setMinutesPerDayPossibility(minutesArray)
        let daysArray = options.dayPossibilities.map((res: any) => res.dayOption)
        setDayPossibility(daysArray)
    }, [options.dayPossibilities, options.minutesPerDayPossibilities])

    const onChangeHandler = (index: number) => {
        if (localStorage.getItem('platform') === 'ios') {
            toast.error(t('subscription.iosPaymentMessage'))
        } else {
            if (!disabled) {
                onChangeFunction({ subscriptionId: index })
                setChecked(index)
            }
        }
            // toast.error(t('subscription.iosPaymentMessage'))

    }

    const findIdForMinutes = (data: any[], valueToSearch: number) => {
        const foundItem = data.find(item => item.minutesPerDayOption === valueToSearch)
        return foundItem ? foundItem.id : null
    }

    const findIdForDays = (data: any[], valueToSearch: number) => {
        const foundItem = data.find(item => item.dayOption === valueToSearch)
        return foundItem ? foundItem.id : null
    }

    React.useEffect(() => {
        // console.log(findIdForMinutes(options.minutesPerDayPossibilities, minutesPerDayValue));
        setMinuteID(findIdForMinutes(options.minutesPerDayPossibilities, minutesPerDayValue))
        // console.log(findIdForDays(options.dayPossibilities, dayPossibilitiesValue));
        setDayID(findIdForDays(options.dayPossibilities, dayPossibilitiesValue))
    }, [dayPossibilitiesValue, minutesPerDayValue, options.dayPossibilities, options.minutesPerDayPossibilities])
    // console.log(findIdForDays(options.dayPossibilities, dayPossibilitiesValue));
    // console.log(findIdForMinutes(options.minutesPerDayPossibilities, minutesPerDayValue));

    const onMinutesPerDayPossibilitiesChange = (value: number) => {
        if (!disabled) {
            onChangeFunction({ subscriptionId: undefined })
            setChecked(null)
            setMinutesPerDayValue(value)
            // console.log(findIdForMinutes(options.minutesPerDayPossibilities, minutesPerDayValue));
            // setMinuteID(findIdForMinutes(options.minutesPerDayPossibilities, minutesPerDayValue))
        }
    }

    const onDayPossibilitiesChange = async (value: number) => {
        if (!disabled) {
            onChangeFunction({ subscriptionId: undefined })
            setChecked(null)
            setDayPossibilitiesValue(value)
            // console.log(findIdForDays(options.dayPossibilities, dayPossibilitiesValue))
            // setDayID(await findIdForDays(options.dayPossibilities, dayPossibilitiesValue))
        }
    }

    // const calculatePrice = (option: AdvancerModelsSubscriptionSubscriptionPackageDurationOptionModel, initialMinutesPerDayPossibilities?: number, initialDayPossibilities?: number) => {
    //     // ((((Günde Kaç Dakika Ders Aldığı * Haftada Kaç Gün Ders Alacağı) * 4 Hafta) * Kaç Aylık Paket Aldığı) * Dakika başı ücret) * ((100 - İndirim oranı) / 100)
    //     // Dakika başına ücret

    //     // Kaç aylık paket
    //     // const durationValue = new BigNumber(option.durationAsMonths)

    //     // Günde kaç dakika

    //     // Haftada kaç gün

    //     // Haftada toplam kaç dakika

    //     // Haftada kaç lira

    //     // Ayda kaç lira

    //     // Toplam fiyat
    //     let price = 0

    //     // İndirim Hesaplaması
    //     if (typeof option.discountedAmount === 'number' && option.discountedAmount > 0) {
    //         // indirim yüzdesi

    //         price = 0
    //     }

    //     return price
    // }

    if (options.minutesPerDayPossibilities.length < 1 || options.dayPossibilities.length < 1) return null

    return (
        // <div className={classnames(styles.items)} style={{ zIndex: '0' }}>
        <div className={classnames(styles.items)}>
            <Card
                heading={{
                    title: t('subscription.pickMinuteAndDay'),
                    size: 'xl',
                    divider: true,
                }}
            >
                <div className={classnames(styles.selections)}>
                    {/* <div className={styles.space} > */}
                    <ListBox
                        options={minutesPerDayPossibility}
                        value={minutesPerDayValue}
                        text={`${t('subscription.dailyMinute', { count: minutesPerDayValue ?? '...' })}`}
                        onChange={onMinutesPerDayPossibilitiesChange}
                        disabled={disabled}
                    />
                    {/* </div> */}
                    <div className={classnames(styles.space)}></div>
                    <ListBox
                        value={dayPossibilitiesValue}
                        text={`${t('subscription.weeklyDay', { count: dayPossibilitiesValue ?? '...' })}`}
                        onChange={onDayPossibilitiesChange}
                        options={dayPossibility}
                        disabled={disabled}
                    />
                    {/* </div> */}
                </div>
            </Card>

            {minutesPerDayValue &&
                dayPossibilitiesValue &&
                options.packageDurationPossibilities.map((r: any, i) => {
                    if (r.dayOptionId === dayID && r.minuteOptionId === minuteID) {
                        return (
                            <div key={i}>
                                <SubscriptionPackageDurationItem
                                    price={r.totalDiscounted?.toString()}
                                    description={r.description}
                                    pricePer={r.durationAsMonths?.toString()}
                                    discountRate={r.discountRate ?? 0}
                                    TotalMinutes={r.totalMinutes ?? 0}
                                    isChecked={checked === r.id}
                                    onChange={isChecked => (isChecked ? onChangeHandler(r.id) : undefined)}
                                    disabled={disabled}
                                    key={r.durationAsMonths?.toString()}
                                    setReferralTotalMinutes={setReferralTotalMinutes}
                                />
                            </div>
                        )
                    }
                })}
        </div>
    )
}

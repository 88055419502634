import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export default function ProfileTemplate({ children }: { children: React.ReactNode }) {
    return <div className={classnames(styles['profile-template'])}>{children}</div>
}

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import {Loader2Line} from "../../icons";

export interface ToggleChange {
    (value: boolean): void
}

type ToggleProps = {
    active: boolean
    onChange: ToggleChange
    activeIcon: React.ReactNode
    inactiveIcon: React.ReactNode
    type: 'default' | 'colorful'
    loading?: boolean
}

const Toggle = ({active: initialStatus = false, onChange, activeIcon, inactiveIcon, loading, type = 'default'}: ToggleProps) => {
    const [active, setActive] = React.useState<boolean>(initialStatus)

    const toggle = () => {
        setActive(r => !r)
        onChange(active)
    }

    return (
        <button
            onClick={toggle}
            className={classnames(styles.toggle, styles[type], {
                [styles.active]: active,
                [styles.inactive]: !active,
            })}
        >
            {!loading && (
                <div className={classnames(styles.indicator)}>
                    {active ? activeIcon : inactiveIcon}
                </div>
            )}

            {loading && <Loader2Line className={styles.loadingIcon} /> }
        </button>
    )
}

export default Toggle

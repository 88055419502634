import * as React from 'react'
import Text from '../../atoms/text'
import useQuery from 'hooks/useQuery'
import useMedia from 'hooks/useMedia'
import Button from '../../atoms/button'
import useAppInfo from 'hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'
import { use100vh } from 'react-div-100vh'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import CardHeading from '../../atoms/card-heading'
import Modal, {ModalSize, ModalType} from '../../atoms/modal'

import styles from './style.module.css'

const LegalDocumentModal = () => {
    const {t} = useTranslation('organisms')

    const params = useQuery()
    const history = useHistory()
    const appInfo = useAppInfo()
    const height = use100vh()

    const isMobile = useMedia('(max-width: 1024px)')

    const [showLegalDocModal, setLegalDocModal] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        const getParam = params.get('document')
        switch (getParam) {
            case 'privacy-policy':
                setLegalDocModal(t('legalDocumentModalCard.privacyPolicy'))
                break
            case 'terms-of-use':
                setLegalDocModal(t('legalDocumentModalCard.termsOfUse'))
                break
            case 'about':
                setLegalDocModal(t('legalDocumentModalCard.about'))
                break
            case 'return-and-warranty':
                setLegalDocModal(t('legalDocumentModalCard.returnAndWarranty'))
                break
            case 'contact':
                setLegalDocModal(t('legalDocumentModalCard.contact'))
                break
            default:
                setLegalDocModal(undefined)
                break
        }
    }, [params, t])

    return (
        <Modal isVisible={typeof showLegalDocModal !== 'undefined'} onModalClose={() => {
            params.delete('document')
            history.replace({search: params.toString()})
        }} size={ModalSize.Large} type={ModalType.Fullscreen}>
            <CardHeading title={appInfo.legalDocuments?.find(x => x.slug === params.get('document'))?.title ?? ''}/>
            <div className={styles.modal}>
                <div className={styles.contentWrapper} style={{maxHeight: height ? height - (isMobile ? 172 : 352) : '100vh'}}>
                    <Text size='sm'>
                        {showLegalDocModal && <ReactMarkdown>{appInfo.legalDocuments?.find?.(x => x.slug === params.get('document'))?.content ?? ''}</ReactMarkdown>}
                    </Text>
                </div>

                <Button
                    variation={'wide'}
                    type={'danger-2'}
                    onClick={() => {
                        params.delete('document')
                        history.replace({search: params.toString()})
                    }}
                >
                    {t('legalDocumentModalCard.close')}
                </Button>
            </div>

        </Modal>
    )
}

export default LegalDocumentModal

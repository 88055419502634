import * as React from 'react'

function useFlashTitle() {
    const [config,] = React.useState<{ title: string | null, func: NodeJS.Timer | null }>({
        title: document.title,
        func: null
    })

    const on = (notificationText: string, intervalSpeed: number, closerTime?: number) => {
        if (config.func !== null) {
            config.func && clearInterval(config.func)
            config.func = null
        }

        let mainTitle = document.title
        config.title = mainTitle
        config.func = setInterval(function () {
            document.title = (mainTitle === document.title) ? notificationText : mainTitle
        }, (intervalSpeed) ? intervalSpeed : 1000)
        closerTime && setTimeout(() => off(), closerTime)
    };

    const off = () => {
        config.func && clearInterval(config.func)
        config.func = null
        if (config.title) {
            document.title = config.title
            config.title = null
        }
    };

    return {on, off}
}

export default useFlashTitle

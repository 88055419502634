import * as React from 'react'

// https://usehooks.com/usePrevious/
export default function usePrevious(value: any) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef()
    // Store current value in ref
    React.useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current
}

import * as React from 'react'
// import Master from 'components/layouts/master'
// import ProfileTemplate, { styles as templateStyles } from 'components/templates/profile'
// import ProfileNavigation from 'components/organisms/profile-navigation'
// import PageHeading from 'components/molecules/page-heading'
// import { useTranslation } from 'react-i18next'
// import { Search2Line } from 'components/icons'
// import Input from 'components/atoms/input'
// import useDebounce from 'hooks/useDebounce'
// import { WordTutorialCategoryQueriesWordTutorial as Word } from '@mohsininsignia/advancerapiclient/models/word-tutorial-category-queries-word-tutorial'
// import useApi from 'hooks/useApi'
import Error from '../error'

export default function BlockedUsers() {
    return <Error statusCode={404} />
    // const api = useApi()
    // const { t } = useTranslation('common')
    //
    // const [query, setQuery] = React.useState<string>('')
    // const debouncedQuery = useDebounce<string>(query, 500)
    // const [results, setResults] = React.useState<Word[]>([])
    // const [loading, setLoading] = React.useState<boolean>(false)
    //
    // React.useEffect(() => {
    //     search(debouncedQuery)
    // }, [debouncedQuery])
    //
    // const search = React.useCallback(async q => {
    //     try {
    //         setLoading(true)
    //         // const { data } = await api.WordTutorialCategory.searchInWordTutorialCategory('slug', q)
    //         // setResults(data.wordTutorials)
    //     } catch (error) {
    //     }
    //
    //     setLoading(false)
    // }, [])
    //
    //
    // return (
    //     <Master title='Home'>
    //         <ProfileTemplate>
    //             <ProfileNavigation />
    //             <div>
    //                 <div className={templateStyles.list}>
    //                     <PageHeading
    //                         title={t('blockedUsers.heading')}
    //                         trailing={
    //                             <Input
    //                                 onChangeText={setQuery}
    //                                 leadingComponent={<Search2Line />}
    //                                 placeholder='Kullanıcılarda ara..'
    //                             />
    //                         }
    //                     />
    //                 </div>
    //             </div>
    //         </ProfileTemplate>
    //     </Master>
    // )
}

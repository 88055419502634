import * as React from 'react'
import classNames from 'classnames'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/atoms/checkbox'
import AccordionCard from 'components/molecules/accordion-card'
import { Accents, LanguageLevels } from '../student-filters/filters'

import styles from './style.module.css'

export type StudentSpecialities = {
    accents?: string[]
    languageLevels?: string[]
}

type StudentSpecialitiesSelectionCardProps = {
    specialities?: StudentSpecialities
    onConfirm: (specialities: StudentSpecialities) => void
    loading?: boolean
    isAccentMultiple?: boolean
    isLanguageLevelMultiple?: boolean
}

const StudentSpecialitiesSelectionCard = ({ specialities, onConfirm, loading, isAccentMultiple, isLanguageLevelMultiple }: StudentSpecialitiesSelectionCardProps) => {
    const { t } = useTranslation('organisms')

    const [accents, setAccents] = React.useState(Accents.map(r => ({ ...r, isSelected: specialities?.accents?.includes(r.value) })))
    const [languageLevels, setLanguageLevels] = React.useState(LanguageLevels.map(r => ({ ...r, isSelected: specialities?.languageLevels?.includes(r.value) })))

    const onAccentChanged = (isSelected: boolean, item: any) => {
        setAccents(i => i.map(k => ({ ...k, isSelected: item.value === k.value ? isSelected : isAccentMultiple ? k.isSelected : false })))
    }

    const onLanguageLevelChanged = (isSelected: boolean, item: any) => {
        setLanguageLevels(i => i.map(k => ({ ...k, isSelected: item.value === k.value ? isSelected : isLanguageLevelMultiple ? k.isSelected : false })))
    }

    const onConfirmHandler = () => {
        onConfirm({
            accents: accents.filter(r => r.isSelected).map(r => r.value),
            languageLevels: languageLevels.filter(r => r.isSelected).map(r => r.value),
        })
    }

    return (
        <div className={styles.wrapper}>
            <AccordionCard
                title={t('teacherSpecialities.accentTitle')}
                subtitle={t('teacherSpecialities.atLeastSelectOne')}
                isIndicatorShown
                initialStatus={specialities?.accents?.length! > 0}
            >
                <div className={classNames(styles.scrollable, styles.choices)}>
                    {accents.map(r => (
                        <Checkbox key={r.value} label={t(`accents.${r.value}`)} checked={r.isSelected ?? false} onChange={i => onAccentChanged(i, r)} />
                    ))}
                </div>
            </AccordionCard>
            <AccordionCard
                title={t('teacherSpecialities.languageLevelTitle')}
                subtitle={t('teacherSpecialities.atLeastSelectOne')}
                isIndicatorShown
                initialStatus={specialities?.languageLevels?.length!! > 0}
            >
                <div className={classNames(styles.scrollable, styles.choices)}>
                    {languageLevels.map(r => (
                        <Checkbox key={r.value} label={t(`languageLevels.${r.value}`)} checked={r.isSelected ?? false} onChange={i => onLanguageLevelChanged(i, r)} />
                    ))}
                </div>
            </AccordionCard>
            <div className={styles.actions}>
                <Button onClick={onConfirmHandler} type="gradient" variation="block" loading={loading}>
                    {t('teacherSpecialities.confirm')}
                </Button>
            </div>
        </div>
    )
}

export default StudentSpecialitiesSelectionCard

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

type BadgeProps = {
    children: React.ReactNode
    status?: 'default' | 'danger'
}

const Badge = ({ children, status = 'default' }: BadgeProps) => {
    return (
        <div className={classnames(styles['badge'], styles[`status-${status}`])}>
            <div className={classnames(styles.children)}>{children}</div>
        </div>
    )
}

export default Badge

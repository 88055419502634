import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import classNames from 'classnames'
import Input from 'components/atoms/input'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import ErrorBox from 'components/atoms/error-box'
import PageInfo from 'components/atoms/page-info'
import { ArrowRightSLine } from 'components/icons'
import { styles } from 'components/templates/profile'
import PageHeading from 'components/molecules/page-heading'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import InputWithActions from 'components/molecules/input-with-actions'
import ProfilePhotoChanger from 'components/molecules/profile-photo-changer'
import { TeacherCommandsUpdateMySpecalitiesParemeters } from '@mohsininsignia/advancerapiclient'
import TeacherSpecialitiesSelectionCard, { TeacherSpecialities } from 'components/organisms/teacher-specialities-selection-card'
import { TeacherQueriesGetMyDetailsQueryResult as Teacher } from '@mohsininsignia/advancerapiclient/models/teacher-queries-get-my-details-query-result'
import { toast } from 'react-toastify'

import componentStyles from './style.module.css'

export default function ProfilePageTeacher({ teacher }: { teacher: Teacher }) {
    const api = useApi()
    const auth = useAuth()
    const { t } = useTranslation()

    const [description, setDescription] = React.useState<string | undefined>(teacher.description)
    const [initialDescription, setInitialDescription] = React.useState<string | undefined>(teacher.description)
    const [isDescriptionLoading, setDescriptionLoading] = React.useState<boolean>(false)

    const [youtubeLink, setYoutubeLink] = React.useState<string | undefined>(teacher.youtubeVideoUrl)
    const [initialYoutubeLink, setInitialYoutubeLink] = React.useState<string | undefined>(teacher.youtubeVideoUrl)
    const [isYoutubeLinkLoading, setYoutubeLinkLoading] = React.useState<boolean>(false)

    const [isPhotoLoading, setPhotoLoading] = React.useState<boolean>(false)

    const [isTeacherSpecialitiesLoading, setTeacherSpecialitiesLoading] = React.useState<boolean>(false)
    const [isSpecialitiesShow, setSpecialitiesShow] = React.useState<boolean>(false)
    const [teacherSpecialities, setTeacherSpecialities] = React.useState<TeacherSpecialities>({
        teachingStyles: [...teacher.teachingStyles],
        lessonTypes: [...teacher.lessonTypes],
        languageLevels: [...teacher.languageLevels],
        accents: [teacher.accentType],
    })

    const descriptionReverseHandler = () => {
        setDescription(initialDescription)
    }

    const descriptionSubmitHandler = async () => {
        setDescriptionLoading(true)

        try {
            await api.Teacher.changeMyDescription(description)
            setInitialDescription(description)
            toast.success(t('common:toast.success'))
        } catch (e) {}

        setDescriptionLoading(false)
    }

    const youtubeLinkReverseHandler = () => {
        setYoutubeLink(initialYoutubeLink)
    }

    const youtubeLinkSubmitHandler = async () => {
        setYoutubeLinkLoading(true)

        try {
            await api.Teacher.changeMyYoutubeVideoUrl(youtubeLink)
            setInitialYoutubeLink(youtubeLink)
            toast.success(t('common:toast.success'))
        } catch (e) {}

        setYoutubeLinkLoading(false)
    }

    const photoSubmitHandler = async (imagePath: string) => {
        setPhotoLoading(true)

        const pathToBlob:any = await fetch(imagePath).then(r => r.blob())

        try {
            const { data } = await api.Teacher.updateMyProfilePhotoAsTeacher(pathToBlob)

            if (auth.user) {
                auth.setUser?.({
                    ...auth.user,
                    avatar1X: data.createdPhotoInfo.x1_64x64,
                    avatar2X: data.createdPhotoInfo.x2_128x128,
                    avatar3X: data.createdPhotoInfo.x3_192x192,
                    avatar4X: data.createdPhotoInfo.x4_256x256,
                })
            }

            toast.success(t('common:toast.success'))
        } catch (e) {}

        setPhotoLoading(false)
    }

    const specialitiesConfirmHandler = async (options: TeacherSpecialities) => {
        setTeacherSpecialitiesLoading(true)

        const mappedData: TeacherCommandsUpdateMySpecalitiesParemeters = {
            teachingStyles: options.teachingStyles ?? [],
            lessonTypes: options.lessonTypes ?? [],
            languageLevel: options.languageLevels ?? [],
            accentType: options.accents?.[0] ?? '',
        }

        try {
            await api.Teacher.updateMySpecalities(mappedData)
            setTeacherSpecialities(options)
            setSpecialitiesShow(false)
            toast.success(t('common:toast.success'))
        } catch (e) {}

        setTeacherSpecialitiesLoading(false)
    }

    return (
        <div>
            <div className={styles.list}>
                <PageHeading title={t('common:profile.title')} />
                <div className={classNames(styles['result-grid'])}>
                    <div>
                        <PageInfo>{t('common:profile.immutableInfo')}</PageInfo>

                        <div className={styles['margin-grid']}>
                            <Input label={t('common:profile.fullName')} value={teacher.name ?? ''} borderless disabled />
                            <Input label={t('common:profile.email')} value={teacher.email ?? ''} borderless disabled />
                            <Input label={t('common:profile.phoneNumber')} value={teacher.phoneNumber ?? ''} borderless disabled />
                            <ErrorBox text={t('common:profile.immutableDescription')} size={'sm'} align={'left'} />
                        </div>
                    </div>

                    <div>
                        <PageInfo>{t('common:profile.updateInfo')}</PageInfo>

                        <div className={styles['margin-grid']}>
                            <ProfilePhotoChanger avatar={teacher.avatar1X} onChange={photoSubmitHandler} loading={isPhotoLoading} />

                            <InputWithActions
                                value={description}
                                initialValue={initialDescription}
                                onSubmit={descriptionSubmitHandler}
                                onReverse={descriptionReverseHandler}
                                onChangeText={setDescription}
                                label={t('common:profile.descriptionText')}
                                rows={6}
                                loading={isDescriptionLoading}
                                multiline
                            />

                            <Button
                                type="danger-3"
                                alignment="left"
                                rightIcon={<ArrowRightSLine />}
                                rightIconPlacement="absolute"
                                onClick={() => setSpecialitiesShow(r => !r)}
                                style={{ maxWidth: '100%' }}
                            >
                                <div className="truncated" style={{ paddingRight: '1rem' }}>
                                    {teacherSpecialities
                                        ? Object.values(teacherSpecialities)
                                              .filter(x => x.length)
                                              .map(e => e.map(x => t(`organisms:filters.${x}`)))
                                              .join(',')
                                        : null ?? t('common:profile.specialities')}
                                </div>
                            </Button>

                            <InputWithActions
                                value={youtubeLink}
                                initialValue={initialYoutubeLink}
                                onSubmit={youtubeLinkSubmitHandler}
                                onReverse={youtubeLinkReverseHandler}
                                onChangeText={setYoutubeLink}
                                label={t('common:profile.youtubeLink')}
                                loading={isYoutubeLinkLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal isVisible={isSpecialitiesShow} onModalClose={() => setSpecialitiesShow(false)} size={ModalSize.Small} type={ModalType.Centered}>
                <div className={componentStyles.container}>
                    <Card heading={{ title: t('common:profile.specialities'), size: 'lg' }}>
                        <TeacherSpecialitiesSelectionCard specialities={teacherSpecialities} loading={isTeacherSpecialitiesLoading} onConfirm={specialitiesConfirmHandler} />
                    </Card>
                </div>
            </Modal>
        </div>
    )
}

import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import styles from './style.module.css'

export type MasterProps = {
    title?: string
    children?: React.ReactNode
}

const Master = ({ title, children }: MasterProps) => {
    return (
        <div className={styles.body}>
            <Helmet>
                <title>{title}</title>
                {/*<link rel="icon" href="/favicon.ico"/>*/}
                {/*<link rel="preconnect" href="https://fonts.gstatic.com"/>*/}
                {/*<link href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap" rel="stylesheet"/>*/}
            </Helmet>

            {children}
        </div>
    )
}

export default Master

import * as React from 'react'
import classnames from 'classnames'
import { Search2Line } from 'components/icons'
import Input, { TextChange } from 'components/atoms/input'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

export default function WordSearchBar({ onChangeText, query }: { onChangeText: TextChange; query: string }) {
    const { t } = useTranslation('organisms')

    return (
        <div className={classnames(styles['input-wrapper'])}>
            <Input
                onChangeText={onChangeText}
                borderless={true}
                leadingComponent={<Search2Line className={classnames(styles.searchIcon, { [styles.searched]: query.length > 0 })} />}
                placeholder={t('wordSearchBar.search')}
            />
        </div>
    )
}

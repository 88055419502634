import classNames from 'classnames'
import * as React from 'react'

import Text from '../text'
import styles from './style.module.css'

type SegmentCardItemProps = {
    text: string
    label?: string
    truncated?: boolean
}

const SegmentCardItem = ({ text, label, truncated }: SegmentCardItemProps) => {
    return (
        <div
            className={classNames(styles['segmented-card-item'], {
                [styles.truncated]: truncated,
            })}
        >
            {label && (
                <Text type="dimmed" weight="medium">
                    {label}
                </Text>
            )}
            <Text type={label ? 'default' : 'dimmed'} size={label ? '5xl' : 'base'} weight="medium">
                {text}
            </Text>
        </div>
    )
}

export default SegmentCardItem

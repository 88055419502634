export const numberFormat = (count: number) => {
    const formatter = new Intl.NumberFormat('en', {
        notation: 'compact',
    })
    return formatter.format(count)
}

export const roundHalf = (num?: number) => {
    if (!num) return 0
    return Math.round(num * 2) / 2
}

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

const Divider = ({ className }: { className?: string }) => {
    return <div className={classnames(styles.divider, className)} />
}

export default Divider

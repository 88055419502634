import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useQuery from 'hooks/useQuery'
import Input from 'components/atoms/input'
import {useHistory} from 'react-router-dom'
import Button from 'components/atoms/button'
import {useTranslation} from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import PageInfo from 'components/atoms/page-info'
import PageHeading from 'components/molecules/page-heading'
import {ArrowRightSLine, InformationLine} from 'components/icons'
import ProfileTemplate, {styles} from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import InformationModalCard from 'components/organisms/information-modal-card'
import LegalDocumentModalCard from 'components/organisms/legal-document-modal-card'

export default function Help() {
    const {t} = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const params = useQuery()
    const router = useHistory()

    const [title, setTitle] = React.useState<string>('')
    const [message, setMessage] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    const [visibleSuccessSend, setVisibleSuccessSend] = React.useState<boolean>(false)

    const sendForm = async () => {
        setLoading(true)
        try {
            await api.CustomerService.sendContactMessage(title, message)
            setTitle('')
            setMessage('')
            setVisibleSuccessSend(true)
        } catch (e) {
            console.log('e',e)
        }
        setLoading(false)
    }

    const isButtonDisabled = React.useMemo(() => {
        return loading || (title.length < 4) || (message.length < 10)
    }, [loading, title, message])

    if (!auth.isLoggedIn) {
        router.push({pathname: '/profile'})
        return <></>
    }

    return (
        <Master title={`${t('help.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                <div>
                    <div className={styles.list}>
                        <PageHeading title={t('help.title')}/>

                        <div className={styles['grid-2']}>
                            <div className={styles.list}>
                                <PageInfo>{t('help.contactUs')}</PageInfo>

                                <div className={styles['narrow-list']}>
                                    <Input label={t('help.subject')} value={title} onChangeText={setTitle}/>
                                    {title && title !== '' && title.length < 4 && <ErrorBox text={t('help.minCharacter', {count: 4})} size={'sm'} align={'left'}/>}
                                    <Input multiline rows={8} label={t('help.message')} value={message} onChangeText={setMessage}/>
                                    {message && message !== '' && message.length < 10 && <ErrorBox text={t('help.minCharacter', {count: 10})} size={'sm'} align={'left'}/>}
                                    <div style={{marginTop: '1rem'}}>
                                        <Button variation="block" type="gradient" onClick={sendForm} disabled={isButtonDisabled}>
                                            {t('help.send')}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={styles.list}>
                                    <Button
                                        type="light"
                                        variation="block"
                                        size={'large'}
                                        alignment={'left'}
                                        onClick={() => {
                                            params.set('document', 'privacy-policy')
                                            router.replace({search: params.toString()})
                                        }}
                                        leftIcon={<InformationLine/>}
                                        rightIcon={<ArrowRightSLine/>}
                                    >
                                        {t('help.securityPolicy')}
                                    </Button>
                                    <Button
                                        type="light"
                                        variation="block"
                                        size={'large'}
                                        alignment={'left'}
                                        onClick={() => {
                                            params.set('document', 'terms-of-use')
                                            router.replace({search: params.toString()})
                                        }}
                                        leftIcon={<InformationLine/>}
                                        rightIcon={<ArrowRightSLine/>}
                                    >
                                        {t('help.provisionOfUse')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ProfileTemplate>

            <LegalDocumentModalCard/>

            <InformationModalCard
                title={t('help.successTitle')}
                description={t('help.successDescription')}
                isVisible={visibleSuccessSend}
                onModalClose={() => setVisibleSuccessSend(false)}
            />
        </Master>
    )
}

import * as React from 'react'
import { AccentType } from 'types'

import styles from './style.module.css'
import { NativeBritish, NativeUs, QuestionFill, Turkish } from 'components/icons'

type FlagProps = {
    countryCode: AccentType
}

export default function Flag({ countryCode }: FlagProps) {
    const renderFlag = () => {
        if (countryCode) {
            let flagIcon: JSX.Element
            switch (countryCode) {
                case 'NATIVE_BRITISH':
                    flagIcon = <NativeBritish />
                    break
                case 'NATIVE_US':
                    flagIcon = <NativeUs />
                    break
                case 'TURKISH':
                    flagIcon = <Turkish />
                    break
                case 'OTHER':
                    flagIcon = <QuestionFill />
                    break
            }
            return flagIcon
        }
    }

    return <div className={styles.flag}>{renderFlag()}</div>
}

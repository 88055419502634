import i18n from 'i18next'
import * as React from 'react'
import classnames from 'classnames'
import Text from '../../atoms/text'
import useTheme from 'hooks/useTheme'
import Cookies from 'universal-cookie'
import {Link} from 'react-router-dom'
import Toggle from 'components/atoms/toggle'
import Navigation from 'components/molecules/navigation'
import {LogoDark, LogoLight, MoonFill, NativeUs, SunFill, Turkish} from 'components/icons'

import styles from './style.module.css'
import { WhatsappIcon } from 'react-share'

export default function PageTop() {
    const cookies = new Cookies()

    const {theme, toggleTheme} = useTheme()
    const [currentLang, setCurrentLang] = React.useState<string>(i18n.language)

    const changeLanguage = async () => {
        const locale = i18n.language === 'tr' ? 'en' : 'tr'

        setCurrentLang(locale)
        cookies.set('lang', locale, {path: '/'})
        localStorage.setItem('lang', locale)

        await i18n.changeLanguage(locale)
    }
    const openWhatsapp = async () => {
        let url ='https://wa.me/message/GYBVOF4CQPP7I1';
        window.open(url);
    }
    return (
        <div className={classnames(styles.pageTop)}>
            <div className={classnames(styles.container)}>
                <Link to={'/'}>
                    <div className={styles.logo}>{theme === 'dark' ? <LogoLight/> : <LogoDark/>}</div>
                </Link>

                <div className={classnames(styles.navigationWrapper)}>
                    <Navigation/>
                </div>
                <div className={classnames(styles.trailing)}>
                    <div onClick={changeLanguage} className={styles.lang}>
                        {currentLang !== 'tr' ? <Turkish/> : <NativeUs/>}
                        <Text type={'default'}>{currentLang === 'tr' ? 'EN' : 'TR'}</Text>
                    </div>
                    <Toggle active={theme !== 'dark'} onChange={toggleTheme} activeIcon={<SunFill/>} inactiveIcon={<MoonFill/>} type={'default'}/>
                    <div onClick={openWhatsapp} className={styles.whatsappicon}>
                        <WhatsappIcon className={styles.iconWhatsapp}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

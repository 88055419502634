import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import useDebounce from 'hooks/useDebounce'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import PageInfo from 'components/atoms/page-info'
import ErrorBox from 'components/atoms/error-box'
import { styles } from 'components/templates/profile'
import PageHeading from 'components/molecules/page-heading'
import PasswordInput from 'components/molecules/password-input'
import InputWithActions from 'components/molecules/input-with-actions'
import ProfilePhotoChanger from 'components/molecules/profile-photo-changer'
import { StudentQueriesGetMyDetailsQueryResult as Student } from '@mohsininsignia/advancerapiclient/models/student-queries-get-my-details-query-result'
import InputPhoneNumber from '../../atoms/input-phone-number'
import StudentSpecialitiesSelectionCard, { StudentSpecialities } from '../student-specialities-selection-card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { ArrowRightSLine } from 'components/icons'
import Card from 'components/molecules/card'
import componentStyles from './style.module.css'
import { StudentCommandsUpdateMySpecalitiesParemeters } from '@mohsininsignia/advancerapiclient'
import Input from 'components/atoms/input'
import moment from 'moment'

export default function ProfilePageStudent({ student }: { student: Student }) {
    const api = useApi()
    const auth = useAuth()
    const { t } = useTranslation('common')

    const [fullName, setFullName] = React.useState<string | undefined>(student.name)
    const [remainingCredits, setRemainingCredits] = React.useState<number | undefined>(student.remainingCredits)
    const [creditsExpiryDate, setCreditsExpiryDate] = React.useState<any>(moment(student.remainingExpired).format('MMMM Do YYYY'))
    const [subscriptionMessage, setSubscriptionMessage] = React.useState<any>(student.subscriptionMessage)
    const [initialFullName, setInitialFullName] = React.useState<string | undefined>(student.name)
    const [isFullNameLoading, setFullNameLoading] = React.useState<boolean>(false)

    const [email, setEmail] = React.useState<string | undefined>(student.email)
    const [initialEmail, setInitialEmail] = React.useState<string | undefined>(student.email)
    const [isEmailLoading, setEmailLoading] = React.useState<boolean>(false)

    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(student.phoneNumber)
    const [initialPhoneNumber, setInitialPhoneNumber] = React.useState<string | undefined>(student.phoneNumber)
    const [isPhoneNumberLoading, setPhoneNumberLoading] = React.useState<boolean>(false)

    const [isPhotoLoading, setPhotoLoading] = React.useState<boolean>(false)

    const [newPassword, setNewPassword] = React.useState<string>('')
    const [newPasswordAgain, setNewPasswordAgain] = React.useState<string>('')
    const [isPasswordLoading, setPasswordLoading] = React.useState<boolean>(false)
    const debouncedNewPassword = useDebounce<string>(newPassword, 500)
    const debouncedNewPasswordAgain = useDebounce<string>(newPasswordAgain, 500)
    const [isMatchPasswords, setMatchPasswords] = React.useState<boolean>(false)
    const [emailCheck, setEmailCheck] = React.useState(false)
    const [isStudentSpecialitiesLoading, setStudentSpecialitiesLoading] = React.useState<boolean>(false)
    const [isSpecialitiesShow, setSpecialitiesShow] = React.useState<boolean>(false)
    const [studentSpecialities, setStudentSpecialities] = React.useState<StudentSpecialities>({
        languageLevels: [...student?.languageLevels],
        accents: [student.accentType],
    })

    const debouncedErrorBox = React.useMemo(() => {
        if (debouncedNewPassword !== '' && debouncedNewPassword.length < 6) {
            return <ErrorBox text={t('profile.minPasswordLength')} size={'sm'} />
        }

        if (debouncedNewPassword !== '' && debouncedNewPasswordAgain !== '') {
            if (debouncedNewPassword !== debouncedNewPasswordAgain) {
                setMatchPasswords(false)
                return <ErrorBox text={t('profile.notMatchPassword')} size={'sm'} />
            } else {
                setMatchPasswords(true)
                return undefined
            }
        }
    }, [debouncedNewPassword, debouncedNewPasswordAgain, t])

    const fullNameReverseHandler = () => {
        setFullName(initialFullName)
    }
    const fullNameSubmitHandler = async () => {
        setFullNameLoading(true)

        try {
            await api.Student.changeMyName(fullName)
            setInitialFullName(fullName)
            toast.success(t('toast.success'))
        } catch (e) {}

        setFullNameLoading(false)
    }
    const emailReverseHandler = () => {
        setEmail(initialEmail)
    }

    const emailSubmitHandler = async () => {
        setEmailLoading(true)

        try {
            await api.Student.changeMyEmailAddress(email)
            setInitialEmail(email)
            toast.success(t('toast.success'))
        } catch {}

        setEmailLoading(false)
    }

    const phoneNumberReverseHandler = () => {
        setPhoneNumber(initialPhoneNumber)
    }

    const phoneNumberSubmitHandler = async () => {
        setPhoneNumberLoading(true)

        try {
            await api.Student.changeMyPhoneNumber(phoneNumber)
            setInitialPhoneNumber(phoneNumber)
            toast.success(t('toast.success'))
        } catch {}

        setPhoneNumberLoading(false)
    }

    const photoSubmitHandler = async (imagePath: string) => {
        setPhotoLoading(true)

        const pathToBlob: any = await fetch(imagePath).then(r => r.blob())

        try {
            const { data } = await api.Student.updateProfilePhotoAsStudent(pathToBlob)

            if (auth.user) {
                auth.setUser?.({
                    ...auth.user,
                    avatar1X: data.createdPhotoInfo.x1_64x64,
                    avatar2X: data.createdPhotoInfo.x2_128x128,
                    avatar3X: data.createdPhotoInfo.x3_192x192,
                    avatar4X: data.createdPhotoInfo.x4_256x256,
                })
            }

            toast.success(t('toast.success'))
        } catch (e) {}

        setPhotoLoading(false)
    }

    const passwordChangeHandler = async () => {
        setPasswordLoading(true)

        try {
            await api.AllAccounts.changePassword(newPassword)
            setNewPassword('')
            setNewPasswordAgain('')
            toast.success(t('toast.success'))
        } catch (e) {}

        setPasswordLoading(false)
    }

    const specialitiesConfirmHandler = async (options: StudentSpecialities) => {
        setStudentSpecialitiesLoading(true)

        const mappedData: StudentCommandsUpdateMySpecalitiesParemeters = {
            languageLevel: options.languageLevels ?? [],
            accentType: options.accents?.[0] ?? '',
        }

        try {
            await api.Student.updateStudentSpecalities(mappedData)
            setStudentSpecialities(options)
            setSpecialitiesShow(false)
            toast.success(t('common:toast.success'))
        } catch (e) {}

        setStudentSpecialitiesLoading(false)
    }
    React.useEffect(() => {
        api.Student.getMyDetailsAsStudent().then((res: any) => {
            setRemainingCredits(res.data.remainingCredits)
            setSubscriptionMessage(res.data.subscriptionMessage)
            if (res.data.remainingExpired !== '') {
                const expiryDate = res.data.remainingExpired
                setCreditsExpiryDate(moment(expiryDate).format('MMMM Do YYYY'))
            } else {
                setCreditsExpiryDate('')
            }
        })
    }, [api.Student, student.remainingCredits, student.subscriptionMessage])
    return (
        <div>
            <div className={styles.list}>
                {subscriptionMessage === 'UserHasExpiredSubscription' && <ErrorBox text={t('profile.userHasExpiredSubscription')} align={'center'} size={'lg'}></ErrorBox>}
                <PageHeading title={t('profile.title')} />

                <div className={classNames(styles['result-grid'])}>
                    <div>
                        <PageInfo>{t('profile.personalInfo')}</PageInfo>

                        <div className={styles['margin-grid']}>
                            <ProfilePhotoChanger avatar={student.avatar1X} onChange={photoSubmitHandler} loading={isPhotoLoading} />

                            <InputWithActions
                                value={fullName}
                                initialValue={initialFullName}
                                onSubmit={fullNameSubmitHandler}
                                onReverse={fullNameReverseHandler}
                                onChangeText={setFullName}
                                label={t('profile.fullName')}
                                loading={isFullNameLoading}
                            />
                            <Input value={remainingCredits} label={t('profile.remainingCredits')} disabled />
                            <Input value={creditsExpiryDate} label={t('profile.creditsExpiry')} disabled />
                            <Button
                                type="danger-3"
                                alignment="left"
                                rightIcon={<ArrowRightSLine />}
                                rightIconPlacement="absolute"
                                onClick={() => setSpecialitiesShow(r => !r)}
                                style={{ maxWidth: '100%' }}
                            >
                                <div className="truncated" style={{ paddingRight: '1rem' }}>
                                    {studentSpecialities
                                        ? Object.values(studentSpecialities)
                                              .filter(x => x.length)
                                              .map(e => e.map(x => t(`organisms:filters.${x}`)))
                                              .join(',')
                                        : null ?? t('common:profile.specialities')}
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div>
                        <PageInfo>{t('profile.contactInfo')}</PageInfo>

                        <div className={styles['margin-grid']}>
                            <InputWithActions
                                value={email}
                                initialValue={initialEmail}
                                onSubmit={emailSubmitHandler}
                                onReverse={emailReverseHandler}
                                onChangeText={setEmail}
                                label={t('profile.email')}
                                loading={isEmailLoading}
                                regexType={'mail'}
                                onRegexFailed={setEmailCheck}
                                status={email === '' || email === undefined || emailCheck ? 'default' : 'danger'}
                            />
                            <InputPhoneNumber
                                value={phoneNumber ?? ''}
                                initialValue={initialPhoneNumber}
                                onSubmit={phoneNumberSubmitHandler}
                                onReverse={phoneNumberReverseHandler}
                                onChangeText={setPhoneNumber}
                                label={t('profile.phoneNumber')}
                                loading={isPhoneNumberLoading}
                            />
                        </div>
                    </div>

                    <div>
                        <PageInfo>{t('profile.securityInfo')}</PageInfo>

                        <div className={styles['margin-grid']}>
                            <PasswordInput value={newPassword} onChangeText={setNewPassword} label={t('profile.newPassword')} maxLength={64} autoComplete={'new-password'} />
                            <PasswordInput
                                value={newPasswordAgain}
                                onChangeText={setNewPasswordAgain}
                                label={t('profile.newPasswordAgain')}
                                maxLength={64}
                                autoComplete={'new-password'}
                            />
                            {debouncedErrorBox}
                            <Button type={'gradient'} loading={isPasswordLoading} onClick={passwordChangeHandler} disabled={!isMatchPasswords}>
                                {t('profile.passwordSave')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isVisible={isSpecialitiesShow} onModalClose={() => setSpecialitiesShow(false)} size={ModalSize.Small} type={ModalType.Centered}>
                <div className={componentStyles.container}>
                    <Card heading={{ title: t('common:profile.specialities'), size: 'lg' }}>
                        <StudentSpecialitiesSelectionCard specialities={studentSpecialities} loading={isStudentSpecialitiesLoading} onConfirm={specialitiesConfirmHandler} />
                    </Card>
                </div>
            </Modal>
        </div>
    )
}

import * as React from 'react'
import classnames from 'classnames'
import {use100vh} from 'react-div-100vh'

import styles from './style.module.css'

export { styles }

export type LiveTemplateProps = {
    children: React.ReactNode
}

export default function LiveTemplate({ children }: LiveTemplateProps) {
    const height = use100vh()
    const computedHeight = height ?? '100vh'
    return (
        <div style={{ height: computedHeight }} className={classnames(styles.container)}>
            {children}
        </div>
    )
}

import * as React from 'react'
import Text from '../../atoms/text'
import useQuery from 'hooks/useQuery'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {LogoBandColored} from 'components/icons'
import LegalDocumentModalCard from '../legal-document-modal-card'

import styles from './style.module.css'

export default function PageFooter() {
    const {t} = useTranslation()
    const params = useQuery()
    const router = useHistory()
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.paymentLogos}>
                    <LogoBandColored/>
                </div>

                <div>
                    <div
                        onClick={() => {
                            params.set('document', 'privacy-policy')
                            router.replace({search: params.toString()})
                        }}
                    >
                        <Text>{t('organisms:legalDocumentModalCard.privacyPolicy')}</Text>
                    </div>

                    <div
                        onClick={() => {
                            params.set('document', 'terms-of-use')
                            router.replace({search: params.toString()})
                        }}
                    >
                        <Text>{t('organisms:legalDocumentModalCard.termsOfUse')}</Text>
                    </div>

                    <div
                        onClick={() => {
                            params.set('document', 'about')
                            router.replace({search: params.toString()})
                        }}
                    >
                        <Text>{t('organisms:legalDocumentModalCard.about')}</Text>
                    </div>

                    <div
                        onClick={() => {
                            params.set('document', 'return-and-warranty')
                            router.replace({search: params.toString()})
                        }}
                    >
                        <Text>{t('organisms:legalDocumentModalCard.returnAndWarranty')}</Text>
                    </div>

                    <div
                        onClick={() => {
                            params.set('document', 'contact')
                            router.replace({search: params.toString()})
                        }}
                    >
                        <Text>{t('organisms:legalDocumentModalCard.contact')}</Text>
                    </div>
                </div>
            </div>

            <LegalDocumentModalCard/>
        </>
    )
}

import * as React from 'react'
import classnames from 'classnames'
import { AccentType, User } from 'types'
import Flag from 'components/atoms/flag'
import ImageWithFallback from 'components/atoms/image-with-fallback'

import styles from './style.module.css'

export type AvatarProps = {
    url?: string
    flag?: AccentType
    rounded?: boolean
    size?: number
    status?: 'online' | 'offline' | 'busy'
    user?: User | undefined
}

export default function Avatar({ url, flag, rounded, size, status, user }: AvatarProps) {
    const renderStatus = React.useMemo(() => {
        if (status) {
            return <div className={classnames(styles.status, styles[`status-${status}`])} />
        }
    }, [status])

    if (!url && !user) return null

    return (
        <div
            style={{
                // @ts-ignore
                '--size': size ?? 4,
            }}
            className={classnames(styles.avatar, { [styles.rounded]: rounded })}
        >
            <ImageWithFallback
                alt="avatar"
                src={user ? user.avatar1X : url}
                srcSet={user ? `${user.avatar1X}, ${user.avatar2X} 1.5x, ${user.avatar3X} 2x, ${user.avatar4X} 3x,` : undefined}
                fallbackSrc={'/img/avatar-fallback.jpg'}
            />
            {flag && (
                <div className={styles.flag}>
                    <Flag countryCode={flag} />
                </div>
            )}
            {renderStatus}
        </div>
    )
}

import * as React from 'react'
import classnames from 'classnames'
import useChat from 'hooks/useChat'
import useAuth from 'hooks/useAuth'
import {NavLink} from 'react-router-dom'
import Tippy from '@tippyjs/react/headless'
import ConversationDropdown from 'components/organisms/conversation-dropdown'

import styles from './style.module.css'
import {useTranslation} from "react-i18next";

export type NavigationItemProps = {
    url?: string
    label: string
    icon: React.ReactNode
    active?: boolean
    isLink?: boolean
    handler?: (event: any) => void
    showTippy?: boolean
}

const NavigationItem = ({url = '/', label, icon, active = false, isLink, handler, showTippy}: NavigationItemProps) => {
    const chat = useChat()
    const auth = useAuth()
    const {t} = useTranslation('common')
    const [visible, setVisible] = React.useState(false)
    const [hasNewMessage, setHasNewMessage] = React.useState(false)

    React.useEffect(() => {
        setHasNewMessage(chat.state?.conversations.find(x => x.unreadMessageCount > 0) !== undefined)
    }, [chat.state?.conversations])

    const _handler = (e: any) => {
        if (showTippy) {
            if (visible) {
                setVisible(false)
            } else {
                if (auth.isLoggedIn) {
                    setVisible(true)
                } else {
                    auth.setModalShown?.(true)
                }
            }
        }

        handler && handler(e)
    }

    const content: JSX.Element = (
        <div onClick={_handler} className={classnames(styles.navigationItem, {[styles.active]: active || visible, [styles.newMessage]: hasNewMessage && label === t('navigation.messages')})}>
            {icon}
            <span>{label}</span>
        </div>
    )

    if (showTippy) {
        return (
            <div id="parent">
                <Tippy
                    onClickOutside={() => setVisible(false)}
                    interactive={true}
                    placement={'bottom-start'}
                    visible={visible}
                    render={attrs => (
                        <div style={{width: 344}} {...attrs}>
                            {chat.state?.conversations && <ConversationDropdown conversations={chat.state.conversations}/>}
                        </div>
                    )}
                >
                    {content}
                </Tippy>
            </div>
        )
    }

    return isLink && url ? (
        <NavLink to={url} className={styles.link} activeClassName={styles.active}>
            {content}
        </NavLink>
    ) : (
        content
    )
}

export default NavigationItem

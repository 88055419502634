import * as React from 'react'
import classnames from 'classnames'
import useHover from 'hooks/useHover'
import {Link} from 'react-router-dom'
import Button from 'components/atoms/button'
import {ArrowRightSLine} from 'components/icons'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import {WordTutorialCategoryQueriesWordTutorialCategoryInList as Category} from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'

const MovieCategoryBox = ({title, coverImage1X, coverImage2X, coverImage3X, coverImage4X, description, slug}: Category) => {
    const hoverRef = React.useRef(null)
    const isHover = useHover(hoverRef)

    return (
        <Link to={`/word-tutorials/${slug}`} ref={hoverRef} className={classnames(styles['movie-category-box'])}>
            <ImageWithFallback
                src={coverImage1X}
                className={classnames(styles.image)}
                srcSet={coverImage1X ? `${coverImage1X}, ${coverImage2X} 1.5x, ${coverImage3X} 2x, ${coverImage4X} 3x,` : undefined}
                fallbackSrc={'/img/img-square-fallback.png'}
            />
            <div className={classnames(styles.body)}>
                <div className={classnames(styles.title)}>{title}</div>
                <div className={classnames(styles.description)}>{description}</div>
            </div>
            <Button className={styles.button} type={isHover ? 'gradient' : 'dimmed'} variation="circle">
                <ArrowRightSLine/>
            </Button>
        </Link>
    )
}

export default MovieCategoryBox

import Error from './error'
import { User } from '../types'
import * as React from 'react'
import { isString } from 'lodash'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useCall from 'hooks/useCall'
import classnames from 'classnames'
import useTheme from 'hooks/useTheme'
import Text from 'components/atoms/text'
import Modal from 'components/atoms/modal'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Avatar from 'components/atoms/avatar'
import AgoraRTC from 'custom-agora-rtc-react'
import Master from 'components/layouts/master'
import { LogoDark, LogoLight } from 'components/icons'
import LoadingBox from 'components/atoms/loading-box'
import ErrorTemplate from 'components/templates/error'
import { useHistory, useParams } from 'react-router-dom'
import LiveTemplate, { styles } from 'components/templates/live'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import { remainingSeconds } from 'utils/time-helpers'
import RateAppointmentModalCard from 'components/organisms/rate-appointment-modal-card'
import RemainingConversationDuration from 'components/atoms/remaining-conversation-duration'
import { LiveCommandsJoinToRoomAsStudentCommandResult, LiveCommandsJoinToRoomAsTeacherCommandResult, LiveQueriesGetRoomInfoQueryResult } from '@mohsininsignia/advancerapiclient'
import moment from 'moment'

export default function Live() {
    const { t } = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const call = useCall()
    const { theme } = useTheme()
    const router = useHistory()
    const { id } = useParams<{ id: string }>()

    // Görüşmeye giriş ve katılma tokenleri
    const [room, setRoom] = React.useState<LiveCommandsJoinToRoomAsStudentCommandResult | LiveCommandsJoinToRoomAsTeacherCommandResult | undefined>(undefined)
    const [shareScreenRoom, setShareScreenRoom] = React.useState<LiveCommandsJoinToRoomAsStudentCommandResult | LiveCommandsJoinToRoomAsTeacherCommandResult | undefined>(undefined)

    // Oda bilgilerini barındırır
    const [roomInfo, setRoomInfo] = React.useState<LiveQueriesGetRoomInfoQueryResult | undefined>(undefined)
    const [meetingUrl, setMeetingUrl] = React.useState<string>('')
    // Görüşme Başladı mı ?
    const [roomStart, setRoomStart] = React.useState(false)

    // Görüşme daha önceden sonlandı mı ?
    const [roomEnded, setRoomEnded] = React.useState(false)

    // Görüşme tamamlanıp sonlandı mı ?
    const [roomCompleted, setRoomCompleted] = React.useState(false)

    // Odanın başlamasına kalan süre
    const [remainingTime, setRemainingTime] = React.useState(0)

    // Sistem yeterlilikleri karşılanıyor mu ?
    // const [isSystemEnough] = React.useState(AgoraRTC.checkSystemRequirements())
    const [isSystemEnough] = React.useState(true)

    // Görüşme sonlandığında gözükecek oylama kartı
    const [showRateCard, setShowRateCard] = React.useState(true)

    const closePage = React.useCallback(async () => {
        await router.replace({ pathname: '/' })
    }, [router])

    const joinRoom = React.useCallback(async () => {
        const appointmentId = parseInt(id)

        try {
            switch (auth.user?.userRole) {
                case 'Teacher':
                    const { data: teacherRoom } = await api.Live.joinToRoomAsTeacher(appointmentId)
                    setRoom(teacherRoom)
                    let URL: any = teacherRoom.startUrl
                    setMeetingUrl(URL)
                    const { data: screenShareTeacherData } = await api.Live.joinToRoomAsTeacher(appointmentId, true)
                    setShareScreenRoom(screenShareTeacherData)

                    break
                case 'Student':
                    const { data: studentRoom } = await api.Live.joinToRoomAsStudent(appointmentId)
                    setRoom(studentRoom)
                    let URLStudent: any = studentRoom.joinUrl
                    setMeetingUrl(URLStudent)
                    const { data: screenShareStudentData } = await api.Live.joinToRoomAsStudent(appointmentId, true)
                    setShareScreenRoom(screenShareStudentData)

                    break
            }
        } catch {
            await closePage()
        }
    }, [api.Live, auth.user?.userRole, closePage, id])

    const getRoomInfo = React.useCallback(async () => {
        if (!room || roomInfo || !isString(room.channelName)) return undefined
        try {
            let { data } = await api.Live.getRoomInfo(room.channelName)
            setRemainingTime(remainingSeconds(data.startDateTime!))
            setRoomEnded(remainingSeconds(data.endDateTime!) <= 0)
            setRoomInfo(data)
        } catch {
            await closePage()
        }
    }, [api.Live, room, roomInfo, closePage])

    React.useEffect(() => {
        if (!room) {
            joinRoom()
        } else {
            getRoomInfo()
        }
    }, [room, joinRoom, getRoomInfo])

    React.useEffect(
        () => {
            if (auth.isLoggedIn) {
                auth.setModalShown?.(false)
            } else {
                auth.setModalShown?.(true)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn]
    )

    React.useEffect(
        () => {
            if (call.latestFinishedAppointmentId === parseInt(id)) {
                roomCompleteHandler()
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [call.latestFinishedAppointmentId, id]
    )
    React.useEffect(() => {
        if (room && roomInfo && shareScreenRoom && !roomCompleted) {
            // const newWindow = window.open(meetingUrl, '_blank', "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212");
            let userAgent = navigator.userAgent
            let browserName

            if (userAgent.match(/chrome|chromium|crios/i)) {
                browserName = 'chrome'
            } else if (userAgent.match(/firefox|fxios/i)) {
                browserName = 'firefox'
            } else if (userAgent.match(/safari/i)) {
                browserName = 'safari'
            } else if (userAgent.match(/opr\//i)) {
                browserName = 'opera'
            } else if (userAgent.match(/edg/i)) {
                browserName = 'edge'
            } else {
                browserName = 'No browser detection'
            }
            console.log('my browser: ', localStorage.getItem('platform'))
            if (browserName === 'safari' || browserName === 'firefox') {
                // console.log('from if')
                window.location.href = meetingUrl
                // if ((localStorage.getItem('platform')=== 'ios')) {
                //     console.log('from if 2')

                //     window.history.back();
                // // closePage()
                // }
                // if (browserName === "safari" || browserName === "firefox" ) {
                //     console.log('from if')
                //     window.location.href = meetingUrl
            } else if (localStorage.getItem('platform') === 'ios') {
                // console.log('from else if')
                // closePage()
                router.replace({ pathname: '/' })
                window.location.href = meetingUrl
            } else {
                console.log('from else')

                window.open(meetingUrl, '_blank', 'toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212')
                if (window) {
                    window.focus()
                }
                window.history.back()
                // Close the new window/tab after it opens
                if (window) {
                    window.addEventListener('load', () => {
                        window.close()
                    })
                }
            }
        }
    }, [room, roomInfo, shareScreenRoom, roomCompleted, meetingUrl, router, id])
    const roomCompleteHandler = () => {
        if (auth.user?.userRole === 'Teacher') {
            setRoomEnded(true)
            setRoomCompleted(false)
        } else if (auth.user?.userRole === 'Student') {
            setRoomCompleted(true)
            setRoomEnded(false)
        }
    }

    const roomStartHandler = () => {
        setRoomStart(true)
    }

    const rateAppointment = async (rate: number, text?: string) => {
        try {
            await api.Teacher.rateTeacher(parseInt(id), rate, text)
            await closePage()
        } catch {}
    }

    if (!auth.isLoggedIn) {
        return <Error statusCode={401} />
    }

    if (!isSystemEnough) {
        return (
            <Master title={t('live.systemNotEnough') + t('title')}>
                <ErrorTemplate>
                    <ConfirmModalCard
                        title={t('live.deviceNotEnough')}
                        description={t('live.deviceNotEnoughDescription')}
                        confirmButtonText={t('live.backToHome')}
                        isVisible={true}
                        onModalClose={() => true}
                        onCancelButtonClick={() => true}
                        onConfirmButtonClick={closePage}
                        loading={false}
                    />
                </ErrorTemplate>
            </Master>
        )
    }

    if (roomEnded) {
        return (
            <Master title={t('live.roomEnd') + t('title')}>
                <ErrorTemplate>
                    <ConfirmModalCard
                        title={t('live.roomEnd')}
                        description={t('live.roomEndDescription')}
                        confirmButtonText={t('live.backToHome')}
                        onModalClose={closePage}
                        onConfirmButtonClick={closePage}
                        hideCancelButton
                        isVisible
                    />
                </ErrorTemplate>
            </Master>
        )
    }

    if (roomCompleted) {
        return (
            <Master title={t('live.roomEnd') + t('title')}>
                <ErrorTemplate>
                    <RateAppointmentModalCard isVisible={showRateCard} onModalClose={() => setShowRateCard(false)} onSubmitButtonClick={rateAppointment} />
                </ErrorTemplate>
            </Master>
        )
    }

    if (!roomStart) {
        return (
            <Master title={t('live.roomEnd') + t('title')}>
                <ErrorTemplate>
                    <Modal isVisible={true} onModalClose={() => true}>
                        <Card heading={{ title: t('live.roomNotYetOpen'), divider: true }}>
                            <div className={styles['card-content']}>
                                <RemainingConversationDuration duration={remainingTime} onEnd={roomStartHandler} />
                            </div>
                        </Card>
                    </Modal>
                </ErrorTemplate>
            </Master>
        )
    }
    // console.log(room?.rtmToken)
    function minuteDiffToDates(startDateTime?: string, endDateTime?: string): number {
        const start = startDateTime ? moment(startDateTime) : null
        const end = endDateTime ? moment(endDateTime) : null
        return start && end ? end.diff(start, 'minutes') : 0
    }

    return (
        <Master title={t('live.title') + t('title')}>
            <LiveTemplate>
                <div className={styles.wrapper}>
                    <div className={classnames(styles.navbar)}>
                        <div>
                            <div className={styles.logo}>{theme === 'dark' ? <LogoLight /> : <LogoDark />}</div>
                        </div>

                        <div>
                            <Avatar user={auth.user?.userRole === 'Teacher' ? (roomInfo?.student as User) : (roomInfo?.teacher as User)} size={3} rounded />
                            <div>
                                <Text size="xl" weight="medium">
                                    {auth.user?.userRole === 'Teacher' ? roomInfo?.student?.name : roomInfo?.teacher?.name}
                                </Text>
                                {roomInfo && (
                                    <Text weight="medium" type={'dimmed'}>
                                        {/*Appointment type 0 = free, 1 = paid*/}
                                        {roomInfo.appointmentType === 0 ? t('live.free') : t('live.paid')}
                                        {roomInfo.startDateTime &&
                                            roomInfo.endDateTime &&
                                            t('live.appointmentType', { count: minuteDiffToDates(roomInfo.startDateTime, roomInfo.endDateTime) })}
                                    </Text>
                                )}
                            </div>
                        </div>
                    </div>

                    {room && roomInfo && shareScreenRoom && !roomCompleted ? <LoadingBox /> : <LoadingBox />}
                </div>
            </LiveTemplate>
        </Master>
    )
}

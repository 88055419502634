import * as React from 'react'
import Cropper from 'react-easy-crop'
import { useTranslation } from 'react-i18next'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { CloseLine, Upload2Line } from 'components/icons'
import { CropType, getCroppedImg, readFile } from 'utils/image'

import Avatar from '../avatar'
import Button from '../button'
import styles from './style.module.css'
import { Point } from 'react-easy-crop/types'

type SimpleImageSelectorProps = {
    initialImage?: string
    onImageCropped: (image: string | undefined) => void
}

const SimpleImageSelector = ({ initialImage, onImageCropped }: SimpleImageSelectorProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const { t } = useTranslation('atoms')

    const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined)
    const [croppedImageSrc, setCroppedImageSrc] = React.useState<string | undefined>(initialImage)
    const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<CropType | undefined>(undefined)

    const selectFile = () => {
        inputRef.current?.click()
    }

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let imageDataUrl = await readFile(e.target.files[0])
        setImageSrc(imageDataUrl)
    }

    // when user changes cropped area
    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    // when user click to "kaydet" button
    const onCropFinished = React.useCallback(
        async () => {
            if (!imageSrc || !croppedAreaPixels) {
                return undefined
            }
            try {
                const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
                onImageCropped(croppedImage)
                setCroppedImageSrc(croppedImage)
                setImageSrc(undefined)
            } catch (error) {
                console.log(error)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [imageSrc, croppedAreaPixels]
    )

    const onClose = React.useCallback(() => {
        setImageSrc(undefined)
    }, [])

    const removeImage = React.useCallback(() => {
        setCroppedImageSrc(undefined)
        onImageCropped(undefined)
    }, [])

    return (
        <>
            {croppedImageSrc ? (
                <div className={styles['selected-image']}>
                    <Avatar size={3} url={croppedImageSrc} rounded />
                    <div className={styles['selected-image-label']}>{t('profile-photo-selected')}</div>
                    <button className={styles['selected-image-remove-button']} onClick={removeImage}>
                        <CloseLine />
                    </button>
                </div>
            ) : (
                <>
                    <button onClick={selectFile} className={styles['select-button']}>
                        <Upload2Line />
                        <span className={styles['select-button-label']}>{t('profile-photo')}</span>
                    </button>
                    <div className={styles.hidden}>
                        <input type="file" ref={inputRef} onChange={onFileChange} accept="image/*" />
                    </div>
                </>
            )}
            <Modal isVisible={!!imageSrc} onModalClose={onClose} size={ModalSize.Small} type={ModalType.Centered}>
                <div className={styles.modal}>
                    <div className={styles['crop-container']}>
                        <Cropper image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                    </div>
                    <div className={styles['modal-actions']}>
                        <Button variation="block" type="gradient" onClick={onCropFinished}>
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SimpleImageSelector

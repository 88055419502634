import i18n from 'i18next'
import * as React from 'react'
import Moment from 'react-moment'
import classNames from 'classnames'
import useChat from 'hooks/useChat'
import moment from 'moment-timezone'
import Text from 'components/atoms/text'
import {CloseLine} from 'components/icons'
import {ChatUser} from 'types/chatProvider'
import Avatar from 'components/atoms/avatar'
import {getUtcToTimezone} from 'utils/time-helpers'
import {getOtherUserId} from 'utils/chat-helpers'
import {ConversationWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types'

import 'moment/locale/tr'
import styles from './style.module.css'
import {useTranslation} from 'react-i18next';

export type ConversationListItemProps = {
    index: number
    topBorder?: boolean
    deletable?: boolean
    onDelete?: (index: number) => void
    conversation: ConversationWithUserMetadata<ChatUser | null>
    disableClick?: boolean
}

moment.updateLocale('tr', {relativeTime: {s: 'şimdi'}})
moment.updateLocale('en', {relativeTime: {s: 'now'}})

const ConversationListItem = ({index, conversation, topBorder, deletable, onDelete, disableClick}: ConversationListItemProps) => {
    const chat = useChat()
    const {t} = useTranslation('molecules');

    const otherUser = React.useMemo(() => {
        const otherUserId = getOtherUserId(chat.state?.decodedJwt?.nameid ?? '', conversation.userIds)
        return conversation.userIds.find(f => f.userId === otherUserId) ?? null
    }, [chat.state?.decodedJwt?.nameid, conversation.userIds])

    const avatarUrl = (() => {
        if (otherUser === null || otherUser.user === null || otherUser.user.avatar4X === null) {
            return ''
        }

        return otherUser.user.avatar4X
    })()

    const messageClickHandler = () => {
        if (!disableClick) {
            chat.methods?.openConversation?.(conversation)
        }
    }

    return (
        <div
            onClick={messageClickHandler}
            className={classNames(styles.wrapper, {
                [styles.topBorder]: topBorder,
            })}
        >
            <div className={styles.avatar}>
                {deletable && (
                    <button onClick={() => onDelete && onDelete(index)} className={styles.delete}>
                        <CloseLine/>
                    </button>
                )}
                <Avatar url={avatarUrl} rounded size={2.375}/>
            </div>
            <div className={styles.body}>
                <Text size="sm" weight="bold">
                    {otherUser?.user?.visibleName}
                </Text>

                {conversation.lastMessage ? (
                    <Text size="sm" type="dimmed">
                        {conversation.lastMessage.message}
                    </Text>
                ) : (
                  <Text size="sm" type="dimmed">
                      {t('conversations.noAnyMessage')}
                  </Text>
                )}
            </div>
            <div className={styles.trailing}>
                <Text size="xs" type="dimmed">
                    <Moment locale={i18n.language} interval={5000} ago fromNow>{getUtcToTimezone(conversation.updatedAt).toDate()}</Moment>
                </Text>

                {conversation.unreadMessageCount > 0 && (
                    <div className={styles['new-message-indicator']}/>
                )}
            </div>
        </div>
    )
}

export default ConversationListItem

import * as React from 'react'
import classnames from 'classnames'
import {Link} from 'react-router-dom'
import {AccentType, User} from 'types'
import Avatar from 'components/atoms/avatar'
import Rating from 'components/atoms/rating'
import StatusBadge from 'components/atoms/status-badge'
import {
    TeacherQueriesGetDetailsQueryResult as TeacherDetail,
    TeacherQueriesTeacherListItem as Teacher,
    VideoRecordQueriesTeacherInformationForVideoRecord as VideoTeacher,
} from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'

type TeacherInfoProps = {
    teacher: Teacher | TeacherDetail | VideoTeacher
    vertical?: boolean
}

const TeacherInfo = ({teacher, vertical}: TeacherInfoProps) => {
    return (
        <Link to={`/teachers/${teacher.teacherId}`} className={classnames(styles['teacher-info'], {[styles['vertical']]: vertical})}>
            <div className={classnames(styles.avatar)}>
                <Avatar user={teacher as User} flag={teacher.accentType as AccentType} rounded={!!vertical}/>
            </div>
            <div className={styles.right}>
                <span className={classnames(styles.name)}>{teacher.name}</span>
                <div className={styles.info}>
                    <StatusBadge status={teacher.isOnline ? 'online' : 'offline'}/>
                    <Rating rate={teacher.rate}/>
                </div>
            </div>
        </Link>
    )
}

export default TeacherInfo

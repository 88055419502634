import * as React from 'react'

function SvgRabbitFast(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191.98 191.98" {...props}>
            <path d="M136.771 31.518s-9.613-9.806-26.777-17.912C92.828 5.498 70.541-1.417 68.629 7.88c-1.908 9.307 17.193 16.561 28.158 21.444 10.967 4.894 20.555 10.891 20.795 12.442.234 1.544-.014 1.034-3.828 7.951-3.814 6.906-.777 12.628-2.924 14.549-2.148 1.903-8.705 7.022-30.996 6.906-22.285-.123-64.328 10.428-64.617 60.031 0 4.275 1.141 5.982-2.275 6.555C9.519 138.325 0 140.048 0 151.728c0 11.7 8.945 14.834 14.365 14.536 5.418-.277 15.395-7.406 17.391-5.983 1.996 1.433 3.137 5.983 1.715 10.26-1.43 4.281-4.564 9.423-2.859 12.558 1.717 3.131 2.287 5.401 9.982 5.401h81.826s4.844-1.134 4.844-7.972c0-6.842-3.135-13.966-45.043-12.83 26.226-2.85 36.209-21.385 34.496-34.784-.07-3.301 1.994-3.992 4.277 2.283 2.281 6.263 27.937 43.902 27.937 43.902s6.273 9.4 12.83 9.4 22.521 1.146 22.521-7.398c0-8.555-8.561-11.607-18.527-11.124-5.992.291-6.273-8.551-6.273-8.551s-5.133-13.692-2.279-61.024c.248-2.281 1.664-2.547 5.414-2.563 4.598-.029 24.238-4.281 28.223-12.834 3.994-8.555-2.848-24.246-15.111-37.364-12.252-13.102-21.174-19.121-38.958-16.123z" />
        </svg>
    )
}

export default SvgRabbitFast

import * as React from 'react'

function SvgPage404(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={343} height={213.062} viewBox="0 0 343 213.062" {...props}>
            <g data-name="404 Error-pana">
                <path data-name="Path 6157" d="M219.553 206.572a8.435 8.435 0 0112.516 6.491c-5.114 0-10.126-1.19-12.516-6.491" fill="#ebebeb" />
                <path data-name="Path 6158" d="M225.504 201.331a10.911 10.911 0 0014.268-11.372c-6.5 1.276-12.592 4.051-14.268 11.372" fill="#ebebeb" />
                <path
                    data-name="Path 6159"
                    d="M203.244 209.984a1.98 1.98 0 01.319-.127l.937-.314c.81-.263 1.985-.648 3.428-1.149 2.891-1.013 6.855-2.451 11.139-4.309 2.132-.937 4.137-1.889 5.939-2.8s3.377-1.813 4.683-2.608a31.579 31.579 0 002.972-2.025c.668-.506 1.013-.866 1.013-.846a1.518 1.518 0 01-.228.253 8.924 8.924 0 01-.724.673 26.977 26.977 0 01-2.942 2.121c-1.291.82-2.876 1.721-4.668 2.663s-3.807 1.889-5.949 2.83a129.328 129.328 0 01-11.169 4.243 85.334 85.334 0 01-3.458 1.068l-.947.268a1.372 1.372 0 01-.345.059z"
                    fill="#e0e0e0"
                />
                <path
                    data-name="Path 6160"
                    d="M226.135 209.549c0 .04-.618-.375-1.635-.927a20.43 20.43 0 00-4.187-1.681 20.01 20.01 0 00-4.466-.683c-1.154-.046-1.878 0-1.878-.04a2.026 2.026 0 01.506-.081 11.877 11.877 0 011.382-.066 17.453 17.453 0 018.739 2.39 11.651 11.651 0 011.154.765 1.727 1.727 0 01.385.324z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6161" d="M105.495 186.785a10.633 10.633 0 0010.481 14.354c-.972-6.369-3.423-12.425-10.481-14.354" fill="#ebebeb" />
                <path
                    data-name="Path 6162"
                    d="M104.498 183.656c.041 0 .213.7.582 1.808a27.088 27.088 0 001.8 4.192 27.651 27.651 0 002.5 3.818c.724.921 1.215 1.458 1.185 1.489a1.989 1.989 0 01-.385-.339 20.329 20.329 0 01-.942-1.013 22.07 22.07 0 01-4.334-8.072 12.814 12.814 0 01-.334-1.357 1.646 1.646 0 01-.071-.527z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6163" d="M4.314 59.022a16.47 16.47 0 0016.257 22.242c-1.519-9.873-5.3-19.24-16.237-22.242" fill="#ebebeb" />
                <path
                    data-name="Path 6164"
                    d="M2.764 54.181a4.2 4.2 0 01.238.754c.132.506.339 1.195.618 2.061a40.819 40.819 0 002.775 6.511 40.4 40.4 0 003.889 5.924c.567.709 1.013 1.276 1.372 1.656a5.505 5.505 0 01.506.613 4.289 4.289 0 01-.577-.542 27.135 27.135 0 01-1.438-1.61 35.164 35.164 0 01-6.7-12.491c-.258-.871-.44-1.59-.542-2.086a5.67 5.67 0 01-.142-.79z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6165" d="M21.422 159.798a10.633 10.633 0 001.716 17.7c2.4-5.98 3.377-12.435-1.716-17.7" fill="#ebebeb" />
                <path
                    data-name="Path 6166"
                    d="M22.151 156.598c.041 0-.167.714-.415 1.853a26.171 26.171 0 00-.38 9.083c.152 1.159.3 1.873.258 1.883a2.2 2.2 0 01-.157-.506 16.733 16.733 0 01-.289-1.362 22.278 22.278 0 01.385-9.154c.147-.572.289-1.013.4-1.337a2.187 2.187 0 01.2-.461z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6167" d="M115.384 31.772a10.633 10.633 0 0015.357-8.952c-6.44.309-12.709 2.127-15.357 8.952" fill="#ebebeb" />
                <path
                    data-name="Path 6168"
                    d="M112.169 32.47c0-.041.719-.142 1.858-.395a26.258 26.258 0 008.41-3.448c1.013-.628 1.575-1.058 1.6-1.013a1.757 1.757 0 01-.375.349 13.106 13.106 0 01-1.124.83 22.161 22.161 0 01-8.471 3.478c-.582.106-1.053.167-1.382.2a1.929 1.929 0 01-.516 0z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6169" d="M102.26 30.855a14.02 14.02 0 008.466-21.858c-6.38 5.615-11.352 12.658-8.466 21.858" fill="#ebebeb" />
                <path
                    data-name="Path 6170"
                    d="M99.504 34.187a3.512 3.512 0 01.425-.506c.289-.319.7-.795 1.185-1.392a33.923 33.923 0 005.838-10.481c.253-.729.44-1.327.562-1.742a3.326 3.326 0 01.218-.638 3.008 3.008 0 01-.122.663 16.089 16.089 0 01-.506 1.772 29.706 29.706 0 01-5.868 10.541 19.817 19.817 0 01-1.256 1.347 3.1 3.1 0 01-.476.435z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6171" d="M143.793 204.298a6.4 6.4 0 0010.633 1.2c-3.22-2.167-6.906-3.544-10.633-1.2" fill="#ebebeb" />
                <path
                    data-name="Path 6172"
                    d="M142.004 203.468a29.586 29.586 0 003.645 1.337 28.475 28.475 0 003.838.577 3.732 3.732 0 01-1.149.071 12.855 12.855 0 01-5.357-1.372 3.858 3.858 0 01-.977-.613z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6173" d="M190.728 22.511a6.4 6.4 0 0010.668-.881c-3.58-1.519-7.458-2.142-10.668.881" fill="#ebebeb" />
                <path
                    data-name="Path 6174"
                    d="M188.809 22.045a19.412 19.412 0 007.711.43 3.969 3.969 0 01-1.114.289 12.83 12.83 0 01-5.524-.309 3.812 3.812 0 01-1.073-.41z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6175" d="M312.365 38.085a10.633 10.633 0 0013.352-11.721c-6.258 1.519-12.06 4.521-13.352 11.721" fill="#ebebeb" />
                <path
                    data-name="Path 6176"
                    d="M309.338 39.361c0-.041.673-.278 1.747-.744a26.247 26.247 0 007.595-4.992c.856-.805 1.342-1.342 1.377-1.311a1.95 1.95 0 01-.3.41 8.9 8.9 0 01-.942 1.013 22.192 22.192 0 01-7.65 5.033c-.547.213-1.013.37-1.316.456a2.1 2.1 0 01-.506.137z"
                    fill="#e0e0e0"
                />
                <path data-name="Path 6177" d="M324.36 192.405a18.506 18.506 0 0010.724-29.007c-8.319 7.539-14.729 16.916-10.724 29.007" fill="#ebebeb" />
                <path
                    data-name="Path 6178"
                    d="M320.77 196.861a6.219 6.219 0 01.567-.689c.38-.425.911-1.058 1.544-1.853s1.362-1.772 2.116-2.886 1.519-2.385 2.263-3.752a42.8 42.8 0 001.873-3.959c.522-1.246.932-2.385 1.24-3.357s.542-1.762.689-2.314a5.232 5.232 0 01.263-.851 6.144 6.144 0 01-.162.876c-.116.562-.324 1.362-.613 2.344s-.689 2.137-1.19 3.4a40.567 40.567 0 01-1.868 3.99 43.856 43.856 0 01-2.294 3.762c-.775 1.114-1.519 2.081-2.172 2.866s-1.215 1.4-1.615 1.808a5.614 5.614 0 01-.641.615z"
                    fill="#e0e0e0"
                />
                <path
                    data-name="Path 6181"
                    d="M203.432 123.839q.248 26.156-11.4 40t-31.9 14.025q-20.354.2-32.359-13.407t-12.263-39.756l-.294-30.662q-.253-26.055 11.4-40t32-14.116q20.253-.2 32.267 13.5t12.258 39.751zm-22.7-33.883q-.167-16.47-5.737-24.323t-16.2-7.757q-10.6.1-15.994 8.066t-5.271 24.43l.354 37.26q.157 16.557 5.807 24.46t16.238 7.8q10.587-.1 15.944-8.066t5.2-24.612z"
                    fill="#ff435b"
                />
                <g data-name="Group 6510">
                    <path
                        data-name="Path 6182"
                        d="M203.432 123.839q.248 26.156-11.4 40t-31.9 14.025q-20.354.2-32.359-13.407t-12.263-39.756l-.294-30.662q-.253-26.055 11.4-40t32-14.116q20.253-.2 32.267 13.5t12.258 39.751zm-22.7-33.883q-.167-16.47-5.737-24.323t-16.2-7.757q-10.6.1-15.994 8.066t-5.271 24.43l.354 37.26q.157 16.557 5.807 24.46t16.238 7.8q10.587-.1 15.944-8.066t5.2-24.612z"
                        opacity={0.4}
                    />
                </g>
                <path
                    data-name="Path 6183"
                    d="M216.323 123.713q.253 26.151-11.4 40t-31.9 14.025q-20.354.2-32.359-13.412t-12.248-39.767l-.294-30.662q-.253-26.055 11.4-40t32-14.121q20.253-.187 32.267 13.5t12.215 39.775zm-22.7-33.883q-.162-16.465-5.762-24.3t-16.2-7.757q-10.6.106-15.994 8.066t-5.245 24.435l.359 37.255q.162 16.562 5.807 24.465t16.238 7.8q10.587-.106 15.944-8.066t5.195-24.617z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6184"
                    d="M128.188 95.719c.025.066-2.967 1.256-6.683 2.653s-6.759 2.451-6.759 2.4 2.967-1.256 6.683-2.653 6.734-2.471 6.759-2.4z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6185"
                    d="M128.923 107.839c.025.066-2.967 1.256-6.688 2.653s-6.749 2.471-6.774 2.4 2.967-1.256 6.683-2.653 6.779-2.475 6.779-2.4z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6186"
                    d="M129.677 119.951c.025.066-2.967 1.256-6.688 2.653s-6.724 2.471-6.774 2.4 2.967-1.256 6.683-2.653 6.754-2.47 6.779-2.4z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6187"
                    d="M130.441 132.068c.025.066-2.967 1.256-6.688 2.653s-6.754 2.471-6.78 2.4 2.972-1.256 6.688-2.653 6.735-2.471 6.78-2.4z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6188"
                    d="M131.166 144.184a2.244 2.244 0 01-.466.228c-.3.132-.749.314-1.3.532-1.1.44-2.628 1.013-4.319 1.666s-3.23 1.195-4.349 1.59c-.557.2-1.013.354-1.327.456a1.828 1.828 0 01-.506.132 2.076 2.076 0 01.466-.228c.3-.132.744-.314 1.3-.506 1.1-.44 2.628-1.013 4.319-1.666s3.23-1.2 4.349-1.59c.557-.2 1.013-.354 1.327-.456a1.742 1.742 0 01.506-.158z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6189"
                    d="M134.624 155.267a10.847 10.847 0 01-1.52.672c-.962.385-2.294.9-3.767 1.458s-2.82 1.043-3.792 1.382a10.573 10.573 0 01-1.6.506 10.419 10.419 0 011.519-.673c.957-.39 2.289-.906 3.767-1.458s2.815-1.043 3.792-1.382a9.939 9.939 0 011.601-.505z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6190"
                    d="M142.031 165.434a8.8 8.8 0 01-1.362.613c-.856.344-2.025.805-3.352 1.3s-2.532.927-3.382 1.225a8.766 8.766 0 01-1.428.44 8.248 8.248 0 011.362-.613c.851-.344 2.025-.805 3.352-1.3s2.532-.927 3.382-1.23a8.38 8.38 0 011.428-.435z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6191"
                    d="M154.34 172.102a9.159 9.159 0 01-1.362.608c-.856.349-2.025.81-3.352 1.3s-2.532.927-3.382 1.23a8.38 8.38 0 01-1.428.435 8.806 8.806 0 011.362-.613c.856-.344 2.025-.805 3.352-1.3s2.532-.927 3.382-1.225a7.834 7.834 0 011.428-.435z"
                    fill="#ff435b"
                />
                <path data-name="Path 6192" d="M191.462 146.244c.03.066-3.813 1.858-8.582 4s-8.663 3.828-8.693 3.762 3.813-1.858 8.607-4 8.638-3.827 8.668-3.762z" fill="#ff435b" />
                <path
                    data-name="Path 6193"
                    d="M193.984 135.865c.03.066-3.144 1.554-7.088 3.326s-7.164 3.159-7.195 3.094 3.139-1.559 7.088-3.332 7.165-3.154 7.195-3.088z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6194"
                    d="M195.143 124.204c.03.066-3.144 1.554-7.088 3.326s-7.164 3.159-7.195 3.094 3.139-1.559 7.088-3.332 7.165-3.179 7.195-3.088z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6195"
                    d="M196.304 112.539c.03.066-3.139 1.559-7.088 3.332s-7.164 3.154-7.195 3.089 3.144-1.554 7.088-3.326 7.153-3.166 7.195-3.095z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6196"
                    d="M197.467 100.879c.025.066-3.144 1.559-7.088 3.332s-7.164 3.154-7.195 3.089 3.144-1.554 7.088-3.332 7.165-3.145 7.195-3.089z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6197"
                    d="M195.143 90.494c.03.066-3.144 1.559-7.088 3.332s-7.164 3.154-7.195 3.089 3.139-1.559 7.088-3.332 7.165-3.155 7.195-3.089z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6198"
                    d="M195.143 79.315c.03.066-3.144 1.559-7.088 3.332s-7.164 3.154-7.195 3.089 3.139-1.559 7.088-3.332 7.165-3.155 7.195-3.089z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6199"
                    d="M193.984 68.788c.03.066-3.144 1.554-7.088 3.326s-7.164 3.159-7.195 3.094 3.139-1.559 7.088-3.332 7.165-3.153 7.195-3.088z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6200"
                    d="M183.984 61.452a43.255 43.255 0 01-4.288 2.076 43.311 43.311 0 01-4.4 1.833 41.317 41.317 0 014.288-2.076 43.312 43.312 0 014.4-1.833z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6201"
                    d="M178.638 56.961a44.68 44.68 0 01-4.288 2.071 43.311 43.311 0 01-4.4 1.833 43.263 43.263 0 014.288-2.066 43.311 43.311 0 014.4-1.838z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6202"
                    d="M128.761 81.031c.025.066-2.967 1.256-6.683 2.653s-6.754 2.476-6.78 2.4 2.967-1.256 6.688-2.653 6.749-2.466 6.775-2.4z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6203"
                    d="M132.467 65.923c.03.066-3.22 1.347-7.245 2.866s-7.321 2.683-7.347 2.618 3.22-1.352 7.25-2.866 7.342-2.689 7.342-2.618z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6204"
                    d="M142.223 50.966c.025.071-4.091 1.676-9.195 3.595s-9.26 3.418-9.286 3.352 4.091-1.681 9.195-3.6 9.261-3.413 9.286-3.347z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6205"
                    d="M214.14 146.391l-.213-.071-.608-.238c-.268-.1-.618-.172-1.013-.284a10.433 10.433 0 00-1.433-.177 13.6 13.6 0 00-8.536 3 24.258 24.258 0 00-4.521 4.471c-1.453 1.777-2.876 3.787-4.491 5.813a26.626 26.626 0 01-5.833 5.656 21.052 21.052 0 01-8.071 3.271 20.759 20.759 0 01-8.7-.289 41.168 41.168 0 01-7.625-2.83c-2.354-1.089-4.557-2.2-6.653-3.114a35.335 35.335 0 00-6.045-2.04 22.3 22.3 0 00-5.215-.6 15.63 15.63 0 00-3.924.532 14.293 14.293 0 00-2.339.916l-.577.3a1.01 1.01 0 01-.2.1l.187-.116.567-.329a12.965 12.965 0 012.334-.967 15.563 15.563 0 013.949-.582 22.222 22.222 0 015.261.562 35.443 35.443 0 016.076 2.025c2.116.911 4.319 2.025 6.668 3.1a40.881 40.881 0 007.595 2.795 20.481 20.481 0 008.607.284 20.9 20.9 0 007.974-3.225 26.393 26.393 0 005.792-5.595c1.615-2.025 3.038-4.02 4.511-5.8a24.4 24.4 0 014.557-4.481 13.6 13.6 0 018.653-2.947 10.449 10.449 0 011.448.2c.405.127.754.208 1.013.314l.6.263z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6206"
                    d="M217.335 112.62c0 .025-.359.071-.987.278a9.843 9.843 0 00-2.445 1.311c-.962.673-2.025 1.615-3.245 2.587a23.16 23.16 0 01-4.349 2.678 23.913 23.913 0 01-8.942 2.309c-.592.051-1.134.035-1.605.046a11.491 11.491 0 01-1.205-.056l-.765-.076a.826.826 0 01-.258-.046.957.957 0 01.263 0l.765.025h1.2c.471-.025 1.013 0 1.59-.081a25 25 0 008.845-2.365 24.24 24.24 0 004.314-2.633c1.23-.952 2.3-1.889 3.291-2.532a9.114 9.114 0 012.532-1.256 5.929 5.929 0 01.749-.162.83.83 0 01.252-.027z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6207"
                    d="M151.204 124.351a1.372 1.372 0 01-.273-.04c-.182-.04-.451-.081-.79-.177a11.839 11.839 0 01-2.714-1.19 27.453 27.453 0 01-3.544-2.587c-1.271-1.053-2.628-2.253-4.182-3.326a14.334 14.334 0 00-4.8-2.2 14.83 14.83 0 00-7.21.03c-.673.172-1.038.3-1.043.278a.937.937 0 01.253-.116c.172-.066.425-.167.765-.263a13.164 13.164 0 012.911-.506 14.177 14.177 0 014.38.359 14.374 14.374 0 014.9 2.223c1.575 1.089 2.932 2.3 4.192 3.357a29.019 29.019 0 003.483 2.618 13.164 13.164 0 002.648 1.251 10.131 10.131 0 011.024.289z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6208"
                    d="M151.286 76.489a5.341 5.341 0 01-.846.309 10.164 10.164 0 01-1.013.3c-.4.1-.851.238-1.362.329s-1.063.218-1.671.294-1.251.147-1.934.208a29.4 29.4 0 01-4.456-.056 29.757 29.757 0 01-4.39-.775c-.658-.187-1.291-.359-1.863-.567s-1.109-.4-1.585-.6-.9-.405-1.276-.577-.684-.339-.937-.481a5.858 5.858 0 01-.77-.461 6.173 6.173 0 01.815.37c.258.127.577.284.952.44s.8.359 1.286.532 1.013.39 1.585.567 1.195.359 1.853.537a28.859 28.859 0 008.769.825c.678-.056 1.321-.091 1.924-.182s1.154-.167 1.661-.258.957-.2 1.357-.289.744-.177 1.013-.253a6.354 6.354 0 01.886-.213z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6209"
                    d="M214.459 76.363a1.063 1.063 0 01-.213-.106l-.587-.339a9.966 9.966 0 00-.942-.506 13.449 13.449 0 00-1.281-.6l-.759-.319c-.268-.106-.552-.187-.846-.289-.577-.213-1.22-.359-1.894-.537a20.956 20.956 0 00-8.952-.076 19.2 19.2 0 00-1.9.506c-.294.1-.582.172-.851.273l-.765.3a13.936 13.936 0 00-1.291.582 8.36 8.36 0 00-.947.506l-.6.329c-.137.071-.213.106-.218.1a.911.911 0 01.192-.142l.567-.375a8.064 8.064 0 01.937-.537 11.807 11.807 0 011.286-.623l.765-.324c.268-.106.562-.187.856-.289a18.079 18.079 0 011.919-.532 20.313 20.313 0 019.058.081c.678.182 1.327.339 1.909.562.294.106.582.192.846.309l.765.334a14.176 14.176 0 011.276.643 8.267 8.267 0 01.921.557l.567.385c.127.066.213.117.182.127z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6210"
                    d="M183.504 41.969a2.178 2.178 0 01-.365.223l-1.053.582a151.23 151.23 0 01-3.995 2.061 69.162 69.162 0 01-13.8 5.4 37.8 37.8 0 01-8.1 1.124 29.057 29.057 0 01-6.648-.572 23.078 23.078 0 01-4.3-1.311c-.506-.192-.856-.395-1.109-.506a2.919 2.919 0 01-.38-.2 1.959 1.959 0 01.4.152c.263.1.628.284 1.129.461a24.876 24.876 0 004.3 1.225 29.574 29.574 0 006.607.506 38.309 38.309 0 008.035-1.139 72.688 72.688 0 0013.779-5.307l4.051-1.99 1.043-.537a3.372 3.372 0 01.405-.172z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6211"
                    d="M81.866 130.189l16.926.739-.785 17.939-16.926-.739-1.3 29.807-22.277-.973 1.3-29.807-57.472-2.511.046-13.67 61.264-88 23 1.013zm-58.226-2.532l35.948 1.57 2.37-54.368-.542-.208-2.025 3.873z"
                    fill="#ff435b"
                />
                <g data-name="Group 6511">
                    <path
                        data-name="Path 6212"
                        d="M81.866 130.189l16.926.739-.785 17.939-16.926-.739-1.3 29.807-22.277-.973 1.3-29.807-57.472-2.511.046-13.67 61.264-88 23 1.013zm-58.226-2.532l35.948 1.57 2.37-54.368-.542-.208-2.025 3.873z"
                        opacity={0.4}
                    />
                </g>
                <path
                    data-name="Path 6213"
                    d="M96.012 130.807l16.926.739-.785 17.939-16.926-.739-1.3 29.807-22.278-.972 1.3-29.807-57.5-2.532.046-13.67 61.277-87.963 23 1.013zm-58.226-2.532l35.974 1.57 2.37-54.368-.542-.208-2.026 3.87z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6214"
                    d="M15.804 134.199a2.789 2.789 0 01-.547.309c-.359.182-.886.435-1.519.739-1.3.613-3.119 1.428-5.149 2.289s-3.878 1.59-5.225 2.1c-.673.253-1.225.451-1.605.582a3.188 3.188 0 01-.6.182 2.734 2.734 0 01.567-.278l1.57-.663 5.175-2.182 5.175-2.213 1.575-.668a3.039 3.039 0 01.587-.2z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6215"
                    d="M24.05 119.05a6.29 6.29 0 01-.765.4 359 359 0 01-2.127 1.013c-1.808.82-4.314 1.919-7.088 3.1s-5.342 2.187-7.169 2.906c-.921.365-1.671.648-2.2.835a4.618 4.618 0 01-.82.263 6.174 6.174 0 01.785-.359l2.162-.911 7.144-3 7.132-3.042 2.162-.916a4.482 4.482 0 01.784-.289z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6216"
                    d="M34.11 105.516a5.306 5.306 0 01-.8.405c-.506.243-1.281.587-2.223 1.013-1.889.825-4.506 1.924-7.412 3.1s-5.571 2.211-7.488 2.935c-.962.359-1.747.643-2.289.83a4.919 4.919 0 01-.856.263 6.885 6.885 0 01.82-.359l2.258-.911 7.453-3 7.438-3.038 2.259-.915a5.817 5.817 0 01.84-.319z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6217"
                    d="M74.404 121.131a2.881 2.881 0 01-.552.3c-.359.177-.886.42-1.544.714-1.306.592-3.129 1.377-5.159 2.2s-3.888 1.519-5.235 2.025c-.678.243-1.225.435-1.605.562a3.311 3.311 0 01-.6.167 2.644 2.644 0 01.562-.263l1.58-.638 5.2-2.086 5.185-2.127 1.575-.643a2.866 2.866 0 01.593-.211z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6218"
                    d="M72.767 148.948a2.739 2.739 0 01-.532.3c-.349.172-.861.42-1.494.714-1.266.592-3.038 1.377-5 2.2s-3.772 1.519-5.063 2.025c-.658.243-1.19.435-1.559.557a2.789 2.789 0 01-.587.172 2.709 2.709 0 01.547-.268l1.519-.638 5.063-2.081 5.023-2.127 1.519-.638a2.87 2.87 0 01.562-.213z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6219"
                    d="M72.534 158.7a2.6 2.6 0 01-.506.294c-.349.177-.861.42-1.519.719-1.266.587-3.038 1.372-5 2.2s-3.767 1.519-5.063 2.025c-.653.243-1.185.435-1.559.562a2.319 2.319 0 01-.582.167 3.11 3.11 0 01.547-.263l1.519-.638 5.063-2.081 5.028-2.127 1.519-.638a2.416 2.416 0 01.553-.22z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6220"
                    d="M72.304 168.446a2.97 2.97 0 01-.506.3c-.349.177-.861.42-1.519.714-1.271.592-3.038 1.377-5 2.2s-3.767 1.519-5.063 2.025c-.653.243-1.19.435-1.559.557a2.906 2.906 0 01-.582.172 2.669 2.669 0 01.547-.268l1.519-.633 5.063-2.086 5.028-2.127 1.519-.638a2.724 2.724 0 01.557-.213z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6221"
                    d="M74.671 110.833a3.281 3.281 0 01-.552.3c-.359.177-.886.42-1.519.714-1.306.592-3.129 1.377-5.159 2.2s-3.888 1.519-5.235 2.025a56.76 56.76 0 01-1.605.557 3.144 3.144 0 01-.6.172 2.471 2.471 0 01.562-.268l1.58-.638 5.2-2.081 5.185-2.127 1.576-.637a2.866 2.866 0 01.567-.218z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6222"
                    d="M74.934 100.539a3.28 3.28 0 01-.552.3c-.359.172-.886.42-1.544.714-1.306.592-3.129 1.377-5.159 2.2s-3.883 1.519-5.235 2.025c-.678.243-1.225.43-1.605.557a3.145 3.145 0 01-.6.172 2.455 2.455 0 01.567-.268l1.575-.638 5.2-2.081 5.185-2.127 1.575-.638a2.867 2.867 0 01.592-.213z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6223"
                    d="M75.198 90.239a2.9 2.9 0 01-.506.284c-.339.167-.84.4-1.463.684-1.251.567-2.982 1.321-4.916 2.1s-3.706 1.453-4.992 1.914c-.643.228-1.165.41-1.519.506a2.689 2.689 0 01-.577.162 2.6 2.6 0 01.542-.258l1.519-.608 4.957-1.98 4.942-2.025 1.519-.608a2.333 2.333 0 01.494-.171z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6224"
                    d="M76.362 80.611a14.745 14.745 0 01-2.532 1.078 14.592 14.592 0 01-2.628.835 14.8 14.8 0 012.532-1.085 14.293 14.293 0 012.628-.83z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6225"
                    d="M44.15 91.502a6.455 6.455 0 01-.795.405c-.506.243-1.281.582-2.228 1.013-1.883.825-4.5 1.924-7.412 3.1s-5.569 2.213-7.483 2.932c-.962.359-1.747.643-2.289.83a6.612 6.612 0 01-.856.268 5.23 5.23 0 01.82-.365l2.258-.906 7.453-3.023 7.438-3.038 2.253-.916a6.725 6.725 0 01.84-.3z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6226"
                    d="M54.196 77.482a5.306 5.306 0 01-.8.405c-.506.243-1.281.587-2.223 1.013-1.883.82-4.506 1.924-7.412 3.1s-5.569 2.213-7.483 2.932c-.967.365-1.747.648-2.294.835a5.124 5.124 0 01-.851.263 6.076 6.076 0 01.815-.359l2.258-.911 7.453-3 7.445-3.043 2.253-.922a6.077 6.077 0 01.839-.313z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6227"
                    d="M63.476 63.467a6.343 6.343 0 01-.8.405c-.506.243-1.281.582-2.223 1.013-1.883.825-4.506 1.924-7.412 3.1s-5.569 2.213-7.483 2.932c-.962.359-1.747.643-2.289.83a6.613 6.613 0 01-.856.268 5.23 5.23 0 01.82-.365l2.258-.906 7.453-3 7.438-3.038 2.253-.916a5.113 5.113 0 01.841-.323z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6228"
                    d="M73.137 49.989a6.859 6.859 0 01-.8.405c-.506.243-1.281.582-2.223 1.013-1.889.825-4.506 1.924-7.412 3.1s-5.569 2.213-7.488 2.932c-.962.359-1.742.643-2.289.83a6.075 6.075 0 01-.856.268 5.54 5.54 0 01.82-.365l2.258-.906 7.448-3.027 7.438-3.038 2.258-.916a6.077 6.077 0 01.846-.3z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6229"
                    d="M98.766 70.581a1.924 1.924 0 01-.294.334 10.017 10.017 0 01-.916.886 16.086 16.086 0 01-3.934 2.532 17.326 17.326 0 01-6.764 1.519 15.843 15.843 0 01-4.1-.461 20.759 20.759 0 01-4.137-1.544c-2.724-1.327-5.144-2.891-7.509-3.98a17.215 17.215 0 00-6.628-1.728 9.833 9.833 0 00-4.521.846 7.843 7.843 0 00-1.1.628 3.114 3.114 0 01-.375.238 3.12 3.12 0 01.344-.278 7.089 7.089 0 011.084-.678 9.716 9.716 0 014.557-.942 17.164 17.164 0 016.731 1.721c2.39 1.089 4.82 2.648 7.519 3.964a20.982 20.982 0 004.091 1.519 15.741 15.741 0 004.025.466 17.037 17.037 0 0010.633-3.914c.43-.344.734-.648.947-.846a2.856 2.856 0 01.347-.282z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6230"
                    d="M91.719 45.549a4.693 4.693 0 01-.643.375c-.42.228-1.048.506-1.838.861a27.34 27.34 0 01-2.835 1.013 28.738 28.738 0 01-7.261 1.185 26.933 26.933 0 01-3.038-.062 22.768 22.768 0 01-2.025-.233 4.274 4.274 0 01-.729-.147 3.889 3.889 0 01.744.046c.471.056 1.159.106 2.025.147s1.868.051 2.992 0a34.373 34.373 0 003.635-.375 35.054 35.054 0 003.544-.8c1.078-.314 2.025-.638 2.83-.952s1.428-.582 1.863-.78a4.122 4.122 0 01.736-.278z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6231"
                    d="M55.77 101.78a3.757 3.757 0 01-.456-.506l-1.195-1.428a56.947 56.947 0 00-4.243-4.43 15.519 15.519 0 00-2.658-2.025 8.071 8.071 0 00-2.532-.982 7.124 7.124 0 00-2.506 0 1.048 1.048 0 01.167-.066 2.784 2.784 0 01.506-.116 5.671 5.671 0 011.878 0 7.858 7.858 0 012.618.957 14.755 14.755 0 012.719 2.061 44.3 44.3 0 014.2 4.506c.506.613.881 1.124 1.134 1.478a3.108 3.108 0 01.365.552z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6232"
                    d="M96.159 117.076a1.983 1.983 0 01-.2.152 6.44 6.44 0 01-.6.39 14.989 14.989 0 01-2.385 1.129 15.493 15.493 0 01-16.2-3.316 14.208 14.208 0 01-1.742-1.97c-.187-.248-.309-.456-.4-.592s-.132-.213-.122-.218a8.853 8.853 0 01.6.749 17.26 17.26 0 001.787 1.894 15.772 15.772 0 0015.994 3.276 16.566 16.566 0 002.385-1.043 9.059 9.059 0 01.883-.451z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6233"
                    d="M110.904 149.139s-.051-.076-.142-.238-.233-.395-.405-.7a19.426 19.426 0 00-1.732-2.567l-.689-.81-.37-.44-.43-.42-.911-.9-1.063-.881a23.939 23.939 0 00-5.569-3.306 22.845 22.845 0 00-7.595-1.767 21.153 21.153 0 00-8.825 1.418 74.682 74.682 0 00-8.85 4.623 41.961 41.961 0 01-4.754 2.359 19.407 19.407 0 01-5.3 1.392 17.1 17.1 0 01-5.458-.329 24.421 24.421 0 01-5.028-1.7 52.656 52.656 0 01-8.567-5.2c-2.6-1.848-5.063-3.635-7.5-5.063a26.982 26.982 0 00-7.2-3.078 18.476 18.476 0 00-6.435-.425 13.726 13.726 0 00-4.673 1.352 11.808 11.808 0 00-2.532 1.747l-.587.547c-.127.127-.2.187-.2.187s.056-.071.182-.208l.552-.592a11.584 11.584 0 012.532-1.8 13.706 13.706 0 014.709-1.408 18.505 18.505 0 016.5.39 27.4 27.4 0 017.271 3.068c2.466 1.428 4.926 3.21 7.529 5.063a52.53 52.53 0 008.531 5.164 23.989 23.989 0 004.972 1.7 16.942 16.942 0 005.382.324 19.239 19.239 0 005.22-1.367 43.214 43.214 0 004.729-2.339 75.412 75.412 0 018.881-4.628 19.183 19.183 0 012.258-.729c.38-.1.754-.213 1.129-.289l1.134-.187a21.661 21.661 0 014.4-.2 23.027 23.027 0 017.681 1.813 24.233 24.233 0 015.569 3.362c.365.309.719.613 1.063.9l.906.916.425.425c.127.152.253.3.37.446.243.289.471.562.684.825a17.96 17.96 0 011.7 2.6l.38.714a1.948 1.948 0 01.126.236z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6234"
                    d="M95.604 170.284a1.013 1.013 0 01-.258.066l-.749.142a11.71 11.71 0 01-1.19.177c-.466.046-1.013.122-1.59.137l-.932.04h-1.013c-.694 0-1.438-.066-2.218-.127a30.615 30.615 0 01-5-.972 31.024 31.024 0 01-4.739-1.863c-.689-.37-1.352-.709-1.939-1.089-.294-.182-.587-.349-.856-.506l-.759-.532a15.344 15.344 0 01-1.257-.971 11.137 11.137 0 01-.916-.785 9.3 9.3 0 00-.552-.506 1.083 1.083 0 01-.182-.2 1.15 1.15 0 01.218.157l.587.506c.248.213.557.471.937.744s.785.618 1.281.937l.765.506c.268.177.562.339.856.506.587.37 1.251.7 1.939 1.058a28.926 28.926 0 009.65 2.81c.775.066 1.519.147 2.2.152.344 0 .678.03 1.013 0h.921c.587 0 1.119-.066 1.585-.1s.866-.081 1.19-.132l.754-.091a.907.907 0 01.258-.071z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6235"
                    d="M301.738 130.397l16.86-1.646 1.747 17.868-16.86 1.651 2.9 29.69-22.177 2.172-2.886-29.69-57.295 5.6-1.878-13.534 48.278-95.739 22.911-2.243zm-58.014 5.676l35.832-3.5-5.3-54.176-.567-.127-1.448 4.121z"
                    fill="#ff435b"
                />
                <g data-name="Group 6512">
                    <path
                        data-name="Path 6236"
                        d="M301.738 130.397l16.86-1.646 1.747 17.868-16.86 1.651 2.9 29.69-22.177 2.172-2.886-29.69-57.295 5.6-1.878-13.534 48.278-95.739 22.911-2.243zm-58.014 5.676l35.832-3.5-5.3-54.176-.567-.127-1.448 4.121z"
                        opacity={0.4}
                    />
                </g>
                <path
                    data-name="Path 6237"
                    d="M315.839 129.02l16.86-1.651 1.752 17.873-16.865 1.656 2.906 29.7-22.182 2.167-2.9-29.69-57.279 5.59-1.868-13.529 48.277-95.739 22.911-2.243zm-58.008 5.671l35.832-3.489-5.3-54.176-.572-.132-1.443 4.121z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6238"
                    d="M236.904 143.662a3.036 3.036 0 01-.506.38c-.329.233-.815.557-1.418.952-1.21.79-2.891 1.853-4.78 2.987s-3.615 2.121-4.881 2.815c-.628.344-1.144.623-1.519.805a3.078 3.078 0 01-.572.263 2.568 2.568 0 01.506-.354l1.463-.876 4.84-2.881 4.807-2.914 1.463-.881a2.846 2.846 0 01.592-.294z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6239"
                    d="M242.924 127.49a5.534 5.534 0 01-.7.506c-.456.309-1.129.749-1.964 1.286-1.671 1.068-4 2.532-6.582 4.051s-4.962 2.942-6.688 3.914c-.861.506-1.565.876-2.056 1.134a6.028 6.028 0 01-.775.38 5.339 5.339 0 01.724-.471l2.025-1.205 6.648-3.975 6.628-4 2.025-1.21a5.4 5.4 0 01.715-.41z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6240"
                    d="M250.985 112.691a5.773 5.773 0 01-.734.506c-.506.314-1.185.759-2.061 1.3-1.752 1.078-4.192 2.532-6.906 4.111s-5.19 2.972-7 3.985c-.9.506-1.635.886-2.147 1.149a5.926 5.926 0 01-.81.38 5.942 5.942 0 01.759-.471l2.106-1.22 6.957-4.02 6.942-4.051 2.1-1.225a5.954 5.954 0 01.794-.444z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6241"
                    d="M293.08 122.483a2.867 2.867 0 01-.506.37c-.329.228-.82.542-1.423.927-1.215.77-2.906 1.8-4.8 2.906s-3.635 2.056-4.906 2.724c-.633.339-1.149.6-1.519.78a2.926 2.926 0 01-.572.253 2.894 2.894 0 01.506-.344l1.473-.851 4.856-2.795 4.835-2.83 1.473-.856a2.83 2.83 0 01.583-.284z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6242"
                    d="M295.368 150.249a2.593 2.593 0 01-.506.37 59.1 59.1 0 01-1.377.921c-1.17.759-2.8 1.787-4.638 2.881s-3.519 2.025-4.749 2.7c-.613.334-1.114.6-1.463.775a2.532 2.532 0 01-.557.248 2.9 2.9 0 01.506-.339l1.423-.846 4.7-2.77 4.678-2.815 1.423-.846a2.6 2.6 0 01.557-.284z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6243"
                    d="M296.513 159.939a2.592 2.592 0 01-.506.37c-.319.223-.79.537-1.377.922-1.17.759-2.8 1.787-4.638 2.876s-3.519 2.025-4.749 2.709c-.613.334-1.114.6-1.463.775a2.532 2.532 0 01-.557.248 2.893 2.893 0 01.506-.339l1.423-.846 4.7-2.77 4.678-2.815 1.423-.846a2.6 2.6 0 01.557-.284z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6244"
                    d="M297.647 169.621a2.594 2.594 0 01-.506.37c-.319.223-.79.537-1.377.916-1.17.765-2.8 1.792-4.638 2.881s-3.519 2.025-4.749 2.709c-.613.334-1.114.6-1.463.775a2.909 2.909 0 01-.557.248 2.9 2.9 0 01.506-.339l1.423-.846 4.7-2.77 4.678-2.815 1.423-.846a2.6 2.6 0 01.56-.283z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6245"
                    d="M291.89 112.245a3.082 3.082 0 01-.506.375c-.334.223-.82.542-1.428.927-1.215.765-2.906 1.8-4.8 2.9s-3.635 2.056-4.9 2.729c-.638.334-1.154.6-1.519.78a2.927 2.927 0 01-.572.253 3.1 3.1 0 01.506-.344l1.468-.851 4.861-2.795 4.835-2.835 1.468-.851a3.1 3.1 0 01.587-.289z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6246"
                    d="M290.704 102.013a2.8 2.8 0 01-.506.375 135.318 135.318 0 01-6.228 3.827c-1.9 1.098-3.63 2.056-4.9 2.729-.633.334-1.149.6-1.519.78a2.481 2.481 0 01-.572.248 2.748 2.748 0 01.506-.339l1.468-.856 4.861-2.79 4.835-2.835 1.468-.856a3.2 3.2 0 01.587-.284z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6247"
                    d="M289.515 91.78a2.586 2.586 0 01-.476.354c-.319.218-.785.506-1.362.886a138.135 138.135 0 01-9.23 5.37l-1.443.739a2.72 2.72 0 01-.547.238 3 3 0 01.506-.329l1.4-.815 4.633-2.658 4.6-2.7 1.4-.81a2.628 2.628 0 01.519-.275z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6248"
                    d="M289.313 82.089a14.334 14.334 0 01-2.359 1.428 15.187 15.187 0 01-2.486 1.2 14.335 14.335 0 012.359-1.428 15 15 0 012.486-1.2z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6249"
                    d="M258.954 97.4a5.423 5.423 0 01-.729.506c-.506.314-1.19.759-2.066 1.3-1.747 1.078-4.187 2.532-6.9 4.111s-5.19 2.972-7 3.954c-.9.506-1.64.886-2.152 1.149a6.478 6.478 0 01-.805.38 5.491 5.491 0 01.759-.471l2.106-1.22 6.957-4.02 6.936-4.051 2.106-1.225a6.15 6.15 0 01.785-.415z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6250"
                    d="M266.929 82.089a5.773 5.773 0 01-.734.506c-.506.314-1.185.759-2.061 1.3-1.752 1.078-4.192 2.532-6.9 4.111s-5.195 2.972-7 3.954c-.9.506-1.635.886-2.147 1.149a5.924 5.924 0 01-.81.38 5.947 5.947 0 01.76-.471l2.106-1.22 6.957-4.02 6.942-4.051 2.106-1.225a5.76 5.76 0 01.785-.415z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6251"
                    d="M274.149 66.925a5.769 5.769 0 01-.734.506c-.506.314-1.185.759-2.061 1.3-1.752 1.078-4.192 2.532-6.906 4.111s-5.19 2.972-7 3.954c-.9.506-1.635.886-2.147 1.149a6.29 6.29 0 01-.81.38 5.943 5.943 0 01.759-.471l2.106-1.22 6.957-4.02 6.942-4.051 2.1-1.225a5.6 5.6 0 01.79-.415z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6252"
                    d="M281.829 52.217a5.775 5.775 0 01-.734.506c-.481.314-1.185.759-2.061 1.3-1.752 1.078-4.192 2.532-6.906 4.116s-5.19 2.967-7 3.954c-.9.506-1.635.881-2.147 1.144a6.01 6.01 0 01-.81.385 4.992 4.992 0 01.759-.476l2.106-1.215 6.957-4.051 6.942-4.051 2.1-1.225a5.954 5.954 0 01.794-.387z"
                    fill="#ff435b"
                />
                <path
                    data-name="Path 6253"
                    d="M310.087 69.006a2.461 2.461 0 01-.243.37 9.843 9.843 0 01-.785 1.013 15.99 15.99 0 01-3.544 3.068 17.255 17.255 0 01-6.481 2.482 15.847 15.847 0 01-4.116.116 20.809 20.809 0 01-4.314-.947c-2.886-.927-5.5-2.137-7.995-2.886a17.291 17.291 0 00-6.8-.765 9.883 9.883 0 00-4.359 1.473 8.507 8.507 0 00-1.013.775 2.289 2.289 0 01-.334.289 2.532 2.532 0 01.3-.324 7.12 7.12 0 01.971-.831 9.706 9.706 0 014.395-1.57 17.164 17.164 0 016.916.689c2.532.744 5.144 1.944 8 2.871a21.231 21.231 0 004.263.942 15.852 15.852 0 004.051-.106 17.27 17.27 0 006.415-2.395 16.8 16.8 0 003.544-2.977 11.753 11.753 0 00.815-.972 3.151 3.151 0 01.314-.315z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6254"
                    d="M299.586 45.214a4.09 4.09 0 01-.582.461c-.385.289-.962.668-1.7 1.109a27.342 27.342 0 01-2.663 1.4 31.824 31.824 0 01-3.443 1.322 31.393 31.393 0 01-3.585.876 28.96 28.96 0 01-2.987.365 21.84 21.84 0 01-2.025.051 3.615 3.615 0 01-.744-.046 3.954 3.954 0 01.739-.056c.476 0 1.165-.061 2.025-.137s1.853-.213 2.957-.415a35.95 35.95 0 003.544-.886 34.24 34.24 0 003.423-1.3c1.013-.461 1.929-.916 2.668-1.342s1.332-.775 1.732-1.013a4.307 4.307 0 01.638-.395z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6255"
                    d="M271.904 105.939a3.805 3.805 0 01-.506-.446l-1.387-1.24a56.8 56.8 0 00-4.82-3.792 15.451 15.451 0 00-2.921-1.651 8.03 8.03 0 00-2.643-.613 7.19 7.19 0 00-2.486.334.876.876 0 01.157-.091 3.29 3.29 0 01.476-.182 5.65 5.65 0 011.863-.248 7.888 7.888 0 012.724.577 14.738 14.738 0 012.977 1.656 44.55 44.55 0 014.8 3.873c.577.537 1.013 1.013 1.332 1.306a2.977 2.977 0 01.435.516z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6256"
                    d="M314.041 115.41a1.6 1.6 0 01-.172.177 6.675 6.675 0 01-.542.466 14.263 14.263 0 01-2.192 1.453 15.518 15.518 0 01-16.491-1.013 14.178 14.178 0 01-1.995-1.706c-.223-.218-.375-.405-.506-.506a.865.865 0 01-.152-.2s.253.233.7.658a16.766 16.766 0 002.025 1.625 15.762 15.762 0 0016.293 1.013 16.865 16.865 0 002.218-1.367 8.967 8.967 0 01.81-.6z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6257"
                    d="M333.16 145.07a2.28 2.28 0 01-.192-.2c-.122-.147-.284-.359-.506-.633a19.885 19.885 0 00-2.076-2.3l-.8-.709-.425-.38-.506-.359-1.013-.759-1.18-.724a23.887 23.887 0 00-5.964-2.491 22.911 22.911 0 00-7.782-.7 21.174 21.174 0 00-8.536 2.648 73.493 73.493 0 00-8.1 5.823 42.993 42.993 0 01-4.375 3 19.422 19.422 0 01-5.063 2.121 17.312 17.312 0 01-5.453.44 24.354 24.354 0 01-5.22-1.013 52.151 52.151 0 01-9.21-3.944c-2.835-1.468-5.514-2.886-8.136-3.954a27.264 27.264 0 00-7.595-2.025 18.485 18.485 0 00-6.43.506 13.711 13.711 0 00-4.435 1.995 12.227 12.227 0 00-2.278 2.086l-.481.648c-.111.147-.172.218-.172.218s.046-.081.147-.233l.466-.663a11.448 11.448 0 012.259-2.129 13.508 13.508 0 014.461-2.056 18.485 18.485 0 016.486-.532 27.341 27.341 0 017.63 2.025c2.643 1.063 5.332 2.481 8.167 3.939a52.655 52.655 0 009.169 3.919 24.3 24.3 0 005.164.977 16.917 16.917 0 005.372-.435 19.206 19.206 0 004.977-2.091 41.975 41.975 0 004.349-2.982 74.75 74.75 0 018.147-5.828 19.294 19.294 0 012.137-1.038c.359-.152.709-.314 1.073-.446l1.094-.344a21.912 21.912 0 014.334-.825 23.109 23.109 0 017.858.719 23.888 23.888 0 015.995 2.532l1.18.734 1.013.78.506.359.425.39.795.719a19.072 19.072 0 012.025 2.339c.2.283.365.506.476.653z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6258"
                    d="M320.978 168.163a1.351 1.351 0 01-.243.1l-.719.248a11.989 11.989 0 01-1.154.339c-.461.111-.972.263-1.559.359l-.911.172c-.319.056-.653.086-1.013.132-.689.1-1.433.137-2.218.187a31.227 31.227 0 01-5.063-.258 30.212 30.212 0 01-4.952-1.185c-.739-.263-1.443-.506-2.076-.8-.319-.142-.633-.263-.922-.405l-.825-.42c-.537-.258-.987-.547-1.392-.785a11.31 11.31 0 01-1.013-.648l-.623-.44a1.053 1.053 0 01-.208-.167.962.962 0 01.238.122l.648.4c.273.177.618.39 1.013.608s.861.506 1.4.744l.83.4c.289.142.6.258.921.395.633.278 1.332.506 2.066.77a31.464 31.464 0 004.957 1.144 31.9 31.9 0 005.038.279c.775-.046 1.519-.066 2.2-.162.339-.04.673-.066 1.013-.116l.911-.152c.582-.081 1.094-.223 1.554-.319s.846-.2 1.159-.3l.734-.2a1.013 1.013 0 01.209-.042z"
                    fill="#fff"
                />
                <path
                    data-name="Path 6259"
                    d="M215.543 94.008l-3.544-.557-21.959-7.088-12.84-3.711a13.832 13.832 0 00-11.114.759c-6.076 3.089-22.815 18.673-22.815 18.673l12.055 12.43-5.676 25.154c6.354 18.065 18.794 20.136 18.794 20.136a44.049 44.049 0 0017.822-2.947c11.549-4.39 23.665-14.724 25.184-19.164.911-2.663 1.666-8.263 2.263-14.587l18.288-7.453 4.957-25.316z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6260"
                    d="M188.83 138.796a1.66 1.66 0 01-.177.157 6.722 6.722 0 01-.547.405 12.507 12.507 0 01-2.2 1.18 13.428 13.428 0 01-15.189-3.311 12.658 12.658 0 01-1.519-1.995c-.157-.248-.253-.456-.329-.592a1.064 1.064 0 01-.1-.213 7.348 7.348 0 01.506.749 14.178 14.178 0 001.559 1.919 13.564 13.564 0 0014.962 3.271 14.142 14.142 0 002.213-1.1 5.339 5.339 0 01.821-.47z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6261"
                    d="M181.883 87.411a.38.38 0 01.041.122c.025.1.056.213.091.359.076.314.182.785.309 1.387a33.8 33.8 0 01.724 5.18 16.359 16.359 0 01-.208 3.615 11.762 11.762 0 01-1.443 3.9 15.443 15.443 0 01-3 3.478c-1.19 1.063-2.506 2.025-3.848 3.068-2.856 2.142-5.63 4.081-8.1 5.843s-4.673 3.337-6.425 4.663l-.056.046-.056-.046a37.879 37.879 0 01-3.514-3.164 8.667 8.667 0 01-.825-.972c-.086-.116-.137-.213-.177-.273a.884.884 0 01-.056-.1l.273.344a11.643 11.643 0 00.851.937 44.643 44.643 0 003.544 3.094h-.111c1.742-1.347 3.919-2.952 6.4-4.719s5.245-3.716 8.1-5.858c1.337-1.013 2.653-2 3.833-3.038a15.3 15.3 0 002.967-3.413 11.422 11.422 0 001.433-3.813 16.37 16.37 0 00.238-3.575 36.782 36.782 0 00-.638-5.164c-.111-.592-.2-1.058-.263-1.4q-.04-.218-.061-.365a.389.389 0 01-.023-.136z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6262"
                    d="M277.237 98.798a16.178 16.178 0 004.051-18.5l-7.24-.744a10.324 10.324 0 005.25-10.41 13.827 13.827 0 01-15.812 2.911c-3.382-1.671-6.126-4.785-9.843-5.438-3.372-.592-6.688 1.013-9.919 2.137s-7.139 1.777-9.812-.365c-2.116-1.7-2.775-4.668-2.638-7.382s.921-5.357 1.089-8.066a18.324 18.324 0 00-5.868-14.288 13.9 13.9 0 00-21.827-6.942c-1.585 1.225-2.921 2.81-4.719 3.7-2.025 1.013-4.39 1.013-6.582 1.519s-4.506 1.995-4.739 4.248l-.886 4.613a7.417 7.417 0 01-.506.972c-1.863 3.271-5.681 5.423-6.516 9.114-.466 2.061.111 4.294-.582 6.288-.916 2.628-3.752 4.106-5.063 6.552-1.813 3.362-.582 9.25 2.319 11.731s8.881 5.114 12.617 5.909c1.013-3.883 1.909-8.035 2.775-12.283 1.519 1.463 3.124 2.856 4.714 4.238 4.157 3.615 8.4 7.286 11.291 11.979 5.483 8.926 5.569 20.759 12.481 28.627a11.147 11.147 0 004.962 3.514 5.837 5.837 0 005.747-1.17c1.716-1.711 1.939-4.6 3.838-6.1 2.532-2 6.207-.405 8.916 1.332a26.132 26.132 0 006.152 3.271 7.72 7.72 0 006.709-.714c1.894-1.357 2.724-4.253 1.357-6.142-.547-.754-1.362-1.281-1.9-2.025a1.9 1.9 0 01.046-2.532c.744-.572 1.965 0 2.851.506a16.977 16.977 0 0024.678-9.179 14.071 14.071 0 01-7.382-.881z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6263"
                    d="M272.716 59.077a5.526 5.526 0 01.653.557 7.6 7.6 0 011.519 1.97 5.726 5.726 0 01.557 1.706 4.05 4.05 0 01-1.8 4 3 3 0 01-1.276.461 2.922 2.922 0 01-1.418-.2c-1.878-.77-2.825-2.942-4.856-3.909a10.177 10.177 0 00-6.942-.562 28.331 28.331 0 00-7.2 3.225 24.769 24.769 0 01-7.873 3.291 11.053 11.053 0 01-4.354-.03 8.314 8.314 0 01-2.022-.747 7.868 7.868 0 01-1.7-1.256 9.589 9.589 0 01-2.177-3.453 15.573 15.573 0 01-.9-3.813 59.172 59.172 0 01-.03-7.2 21.189 21.189 0 00-.613-6.187 14.273 14.273 0 00-.967-2.532 14.682 14.682 0 00-1.23-2.091 15.772 15.772 0 00-2.587-2.886 16.449 16.449 0 00-1.98-1.458l-.552-.329-.182-.116a1.115 1.115 0 01.2.1l.567.3a15.5 15.5 0 016.911 8.967 21.038 21.038 0 01.64 6.233 63.217 63.217 0 00.056 7.174 15.442 15.442 0 00.886 3.747 9.347 9.347 0 002.127 3.357 7.453 7.453 0 001.641 1.215 8.42 8.42 0 001.949.724 10.926 10.926 0 004.253.025 25.046 25.046 0 007.792-3.261 28.26 28.26 0 017.271-3.235 12.709 12.709 0 013.711-.314 9.751 9.751 0 011.767.289 8.035 8.035 0 011.62.633c2.1 1.013 3.038 3.21 4.825 3.924a2.7 2.7 0 002.532-.223 3.889 3.889 0 001.519-1.8 3.818 3.818 0 00.253-2.025 5.473 5.473 0 00-.506-1.676 7.955 7.955 0 00-1.438-1.98c-.2-.2-.354-.349-.461-.446l-.185-.169z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6264"
                    d="M189.959 88.302a1 1 0 00-.04-.228 3.942 3.942 0 00-.137-.663 5.62 5.62 0 00-1.24-2.258 9.7 9.7 0 00-3.357-2.334 23.042 23.042 0 00-5.286-1.377 16.086 16.086 0 01-6.293-2.157 9.342 9.342 0 01-1.489-14.233c2.1-2.152 5.063-3.22 7.483-5.109a9.464 9.464 0 001.59-1.625 6.719 6.719 0 00.972-2.025 24.368 24.368 0 00.506-4.4 10 10 0 01.405-2.162 5.428 5.428 0 011.068-1.894 7.6 7.6 0 013.62-2.025c1.3-.38 2.6-.618 3.818-.952a10.4 10.4 0 003.337-1.519 12.526 12.526 0 002.5-2.339 27.871 27.871 0 001.889-2.5c1.119-1.691 2.076-3.276 3.094-4.557a14.83 14.83 0 012.952-2.916 8.238 8.238 0 012.334-1.195 5.009 5.009 0 01.886-.223 9.308 9.308 0 00-3.159 1.5 14.764 14.764 0 00-2.9 2.921c-1.013 1.266-1.944 2.845-3.038 4.557a26.672 26.672 0 01-1.883 2.587 12.834 12.834 0 01-2.532 2.39 10.632 10.632 0 01-3.392 1.519c-1.235.344-2.532.582-3.813.962a7.311 7.311 0 00-3.5 1.949 5.184 5.184 0 00-1.013 1.8 9.677 9.677 0 00-.39 2.106 25.2 25.2 0 01-.506 4.456 6.865 6.865 0 01-1.013 2.086 9.661 9.661 0 01-1.64 1.671 33.752 33.752 0 01-3.894 2.476 17 17 0 00-3.544 2.608 9.114 9.114 0 001.428 13.873 16.45 16.45 0 006.2 2.157 24.334 24.334 0 015.306 1.423 9.681 9.681 0 013.377 2.4 5.569 5.569 0 011.215 2.314 3.621 3.621 0 01.111.673 1.918 1.918 0 00-.032.263z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6265"
                    d="M230.383 42.85a1.261 1.261 0 01-.385-.1 8.454 8.454 0 01-1.013-.405 13.382 13.382 0 01-5.413-4.653 9.067 9.067 0 01-.552-.942 1.226 1.226 0 01-.157-.37c.035 0 .324.466.861 1.2a15.564 15.564 0 005.342 4.6c.806.417 1.338.629 1.317.67z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6266"
                    d="M247.218 77.386s-.319-.081-.891-.2a14.511 14.511 0 00-2.461-.3 32.778 32.778 0 00-3.676.106c-1.387.1-2.916.238-4.532.2a12.309 12.309 0 01-4.471-.841 7.974 7.974 0 01-3-2.187 5.605 5.605 0 01-1.149-2.223 3.839 3.839 0 01-.106-.673.845.845 0 010-.238c.03 0 .046.324.208.886a5.873 5.873 0 001.2 2.127 7.883 7.883 0 002.947 2.086 12.42 12.42 0 004.38.8c1.595.035 3.119-.1 4.511-.182a28.905 28.905 0 013.7-.051 12.814 12.814 0 012.476.39 6.736 6.736 0 01.648.208.74.74 0 01.213.1z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6267"
                    d="M277.055 100.408a.326.326 0 00-.147.066 2.117 2.117 0 01-.451.147 3.6 3.6 0 01-1.813-.081 5.843 5.843 0 01-2.43-1.519 10.157 10.157 0 01-1.954-3.276 24.339 24.339 0 00-1.919-4.162 10.931 10.931 0 00-1.605-1.959 12.851 12.851 0 00-2.147-1.625 20.465 20.465 0 00-11.645-2.451c-4.288.253-8.324 1.063-12.055 1.321a23.746 23.746 0 01-9.822-1.013 13.6 13.6 0 01-5.635-3.544 8.334 8.334 0 01-.673-.805 4.8 4.8 0 01-.435-.633c-.1-.167-.182-.3-.248-.405a.613.613 0 01-.076-.142 1.061 1.061 0 01.1.127l.263.39a6.074 6.074 0 00.456.613 8.025 8.025 0 00.689.785 13.726 13.726 0 005.615 3.423 23.9 23.9 0 009.747.952c3.711-.273 7.742-1.124 12.06-1.347a20.789 20.789 0 0111.762 2.506 13.069 13.069 0 012.182 1.666 11.215 11.215 0 011.63 2.025 24.46 24.46 0 011.909 4.207 9.974 9.974 0 001.883 3.245 5.767 5.767 0 002.354 1.519 3.544 3.544 0 001.772.127c.43-.071.628-.178.633-.157z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6268"
                    d="M197.422 42.845a3.787 3.787 0 00-3.858 3.367l-4.1 42.637c-.709 5.995 7.134 18.85 13.559 19.559 6.486.714 7.124-2.43 7.964-8.1 1.408-9.488 1.878-13.063 1.873-13.007s8.354-.278 11.453-10.724c1.519-5.195 2.42-13.929 2.911-21.1.44-6.456-2.764-14.865-9.235-15.032z"
                    fill="#ffbe9d"
                />
                <path
                    data-name="Path 6269"
                    d="M224.404 61.123a1.372 1.372 0 01-1.519 1.205 1.321 1.321 0 01-1.251-1.418 1.387 1.387 0 011.519-1.21 1.321 1.321 0 011.251 1.423z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6270"
                    d="M224.986 58.363c-.162.192-1.256-.506-2.739-.4s-2.532.921-2.689.754c-.081-.076.066-.41.506-.79a3.8 3.8 0 012.137-.825 3.625 3.625 0 012.192.542c.491.319.669.648.593.719z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6271"
                    d="M211.376 61.892c-.177.177-1.2-.628-2.683-.663s-2.592.673-2.749.506c-.076-.086.106-.405.592-.734a3.752 3.752 0 012.208-.613 3.635 3.635 0 012.127.754c.444.345.581.697.505.75z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6272"
                    d="M214.14 69.739a9.778 9.778 0 012.451-.116c.38 0 .744 0 .846-.268a1.929 1.929 0 00-.106-1.144q-.349-1.438-.734-3.038c-1.013-4.278-1.686-7.777-1.519-7.817s1.129 3.4 2.137 7.681c.243 1.053.471 2.056.689 3.038a2.187 2.187 0 010 1.519.947.947 0 01-.689.466 2.572 2.572 0 01-.653 0 9.759 9.759 0 01-2.422-.321z"
                    fill="#263238"
                />
                <path data-name="Path 6273" d="M212.864 87.289a27.341 27.341 0 01-13.787-5.706s2.638 7.807 13.448 8.217z" fill="#eb996e" />
                <path
                    data-name="Path 6274"
                    d="M211.583 59.356c-.233.359-1.635-.167-3.377-.359s-3.225-.051-3.367-.456c-.061-.192.263-.506.906-.754a5.6 5.6 0 012.638-.284 5.665 5.665 0 012.5.9c.569.396.807.781.7.953z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6275"
                    d="M224.763 56.374c-.243.349-1.281.056-2.532.1s-2.258.339-2.532 0c-.111-.167.035-.506.471-.851a3.453 3.453 0 012.025-.658 3.5 3.5 0 012.025.572c.492.325.649.669.543.837z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6276"
                    d="M210.556 72.424a3.169 3.169 0 012.324-.506 1.808 1.808 0 011.078.562 2.294 2.294 0 01.38 1.544 3.109 3.109 0 01-.147.967 1.266 1.266 0 01-.618.724 1.737 1.737 0 01-1.013.051 3.807 3.807 0 01-1.564-.567 1.929 1.929 0 01-.871-1.367 1.306 1.306 0 01.785-1.347"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6277"
                    d="M214.55 75.674c-.106.041-.3-.284-.658-.724a5.413 5.413 0 00-3.676-2.1c-.562-.081-.937-.086-.962-.2s.329-.3.972-.365a4.238 4.238 0 012.435.547 4.177 4.177 0 011.706 1.818c.275.589.28.989.183 1.024z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6278"
                    d="M188.895 85.917c3.519-.7 6.233-3.726 7.443-7.088s1.144-7.063.825-10.633a1.706 1.706 0 00-.481-1.256c-.532-.41-1.3-.076-1.873.273a58.47 58.47 0 00-6.364 4.476c-1.883 1.519-3.747 3.266-4.471 5.569a7.148 7.148 0 004.825 8.7"
                    fill="#263238"
                />
                <path
                    data-name="Path 6279"
                    d="M269.779 85.092l-16.592-26.673-4.583-9.387s-1.463-1.939-2.982-3.595a3.467 3.467 0 00-4.957-.608 1.681 1.681 0 00-1.666-1.013 2.83 2.83 0 00-2.294 1.17 3.823 3.823 0 00-2.562-.871 1.959 1.959 0 00-1.19.385c-.618-.542-1.752-1.321-2.84-.927-.851.309-6.01 4.233-7.281 6.207-.628.977.537 2.243 2.106 1.651a13.937 13.937 0 001.883-.765 5.148 5.148 0 001.276-1.013s-1.3 2.278 0 2.678a2.982 2.982 0 002.445-.592c.506-.415 1.063-.957 1.605-1.489l-.061.091c-.719 1.063 1.154 2.638 2.061 2a13.477 13.477 0 001.21-.962c2.106 1.519-.815 3.489-2.051 3.387a37.773 37.773 0 00-5.711 0 4.105 4.105 0 00-2.613.937c-.7.719.035 2.228 1.013 2.38 1.818.284 4.137.684 6.547 1.306 2.835.729 10.263 3.144 10.263 3.144l.724.587 10.089 24.619-17.275 3.661-3.858 21.893 31.189-8.466c7.017-3.7 10.363-13.052 6.105-19.735z"
                    fill="#ffbe9d"
                />
                <path
                    data-name="Path 6280"
                    d="M242.804 62.49a2.125 2.125 0 01-.395-.1l-1.119-.319-4.106-1.22-2.83-.856a65.223 65.223 0 00-3.256-.932 31.072 31.072 0 00-3.656-.754 6.258 6.258 0 01-1.985-.359 1.327 1.327 0 01-.446-.329.836.836 0 01-.152-.273l-.035-.268a2.582 2.582 0 01.066-1.073 1.478 1.478 0 01.719-.83 5.3 5.3 0 01.962-.385 6.522 6.522 0 012.025-.248c1.316 0 2.557.2 3.742.152a11.736 11.736 0 001.716-.167c.552-.1 1.078-.243 1.595-.375a8.537 8.537 0 012.952-.38 7.98 7.98 0 012.38.608 15.03 15.03 0 011.661.815c.44.248.775.456 1.013.592a2.692 2.692 0 01.334.228 2.075 2.075 0 01-.365-.182l-1.013-.547a16.322 16.322 0 00-1.666-.77 7.894 7.894 0 00-2.339-.557 8.39 8.39 0 00-2.881.4c-.506.137-1.043.289-1.605.395a11.594 11.594 0 01-1.757.182c-1.205.056-2.461-.122-3.747-.137a6.349 6.349 0 00-1.924.238c-.618.2-1.321.42-1.519 1.048a2.445 2.445 0 00-.056.962l.035.233a.4.4 0 00.1.167 1.2 1.2 0 00.359.268 6.3 6.3 0 001.9.334 30.883 30.883 0 013.686.78c1.159.3 2.243.633 3.256.957l2.825.886 4.081 1.3 1.1.365a2.07 2.07 0 01.344.147z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6281"
                    d="M230.013 43.609c.03.041-.78.506-1.975 1.413a20.822 20.822 0 00-1.984 1.717c-.709.689-1.453 1.519-2.334 2.253a2.4 2.4 0 00-.932 1.251.881.881 0 00.608 1.084 2.663 2.663 0 001.337.025 6.334 6.334 0 001.165-.334 8.343 8.343 0 002.051-1.246 1.98 1.98 0 01-.456.476 6.207 6.207 0 01-1.519.947 6.445 6.445 0 01-1.19.375 2.906 2.906 0 01-1.458 0 1.124 1.124 0 01-.785-1.372 2.658 2.658 0 011.013-1.4c.866-.739 1.61-1.519 2.339-2.228a17.909 17.909 0 012.025-1.691 16.494 16.494 0 011.519-.967 3.429 3.429 0 01.577-.309z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6282"
                    d="M236.347 45.695a6.966 6.966 0 01-.587.694l-1.666 1.833-2.5 2.7a6.157 6.157 0 01-1.752 1.337 2.709 2.709 0 01-1.165.289 1.62 1.62 0 01-1.165-.466 1.084 1.084 0 01-.273-.633 1.823 1.823 0 01.066-.648 3.692 3.692 0 01.537-1.109c.9-.947 1.727-1.838 2.471-2.658s1.489-1.463 2.086-2.025a4.2 4.2 0 01.866-.694 2.137 2.137 0 01.815-.182c.451-.03.714 0 .689.061a2.076 2.076 0 00-.678.03 2.294 2.294 0 00-.765.2 4.642 4.642 0 00-.815.7c-.577.577-1.291 1.246-2.025 2.056s-1.559 1.732-2.451 2.689a3.544 3.544 0 00-.506 1.013 1.625 1.625 0 00-.061.557.886.886 0 00.213.481 1.372 1.372 0 00.972.38 2.446 2.446 0 001.048-.258 5.985 5.985 0 001.686-1.271l2.532-2.658 1.727-1.772.481-.476q.205-.18.22-.17z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6283"
                    d="M244.104 48.139a.405.405 0 01-.2.3 1.073 1.073 0 01-1.564-.268c-.116-.192-.116-.339-.086-.344s.1.1.233.228a1.185 1.185 0 00.618.334 1.205 1.205 0 00.7-.106c.167-.086.269-.173.299-.144z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6284"
                    d="M244.712 50.46c-.056.046-.435-.334-.77-.906a2.106 2.106 0 01-.42-1.109c.066-.025.319.43.648.977a3.953 3.953 0 01.542 1.038z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6285"
                    d="M245.831 59.513a3.271 3.271 0 01-.481-1.053 8.51 8.51 0 01.091-5.524 3.246 3.246 0 01.506-1.043 13.475 13.475 0 00-.734 3.8 13.336 13.336 0 00.618 3.818z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6286"
                    d="M259.461 94.595c-.051 0-.1-.537-.365-1.362a8.249 8.249 0 00-3.028-4.152 8.249 8.249 0 00-1.3-.775c-.78-.37-1.311-.506-1.3-.537a1.119 1.119 0 01.38.051 5.879 5.879 0 011.013.314 7.49 7.49 0 011.367.749 7.8 7.8 0 012.5 2.845 7.23 7.23 0 01.562 1.453 5.687 5.687 0 01.187 1.013 1.255 1.255 0 01-.016.401z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6287"
                    d="M201.548 143.353a7.2 7.2 0 00-4.218.537c-2.253-1.013-5.033.724-5.033.724-.815-.39-5.726 2.82-5.726 2.82l-6.116 2.886-32.672-24.281 9.26-9.62-12.977-13.5-15.189 15.645a11.468 11.468 0 00-3.038 7.367 14.982 14.982 0 008.319 14.177l38.48 19.746a44.935 44.935 0 008.754-1.362 79.591 79.591 0 009-3.387 12.981 12.981 0 001.013 2.142c1.828 3.544 4.506-.284 4.506-.284a7.347 7.347 0 001.043 1.732c2.314 2.243 3.919-.77 3.919-.77s.694 1.21 2.759.309-.3-7.119-.3-7.119.694 1.352 2.025-.359-1.596-6.937-3.809-7.403z"
                    fill="#ffbe9d"
                />
                <path
                    data-name="Path 6288"
                    d="M196.926 144.209a.978.978 0 01.425-.167 2.532 2.532 0 011.241.041 4.734 4.734 0 011.635.835 6.291 6.291 0 01.815.724 5.032 5.032 0 01.638 1.013 24.607 24.607 0 011.494 3.752c.152.506.253.9.319 1.18a1.332 1.332 0 01.066.446c-.035 0-.218-.6-.562-1.57a34.628 34.628 0 00-1.554-3.691 4.486 4.486 0 00-.6-.937 6.628 6.628 0 00-.765-.7 5.018 5.018 0 00-1.519-.851 3.387 3.387 0 00-1.635-.071z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6289"
                    d="M200.86 157.939a2.86 2.86 0 01-.289-.562l-.714-1.549c-.6-1.311-1.483-3.1-2.324-5.144a13.672 13.672 0 00-1.332-2.77 4.557 4.557 0 00-1.878-1.564 3.038 3.038 0 00-1.62-.289c-.395.051-.6.157-.608.132s.046-.035.142-.086a1.615 1.615 0 01.446-.147 2.972 2.972 0 011.716.218 4.643 4.643 0 012 1.6 13.163 13.163 0 011.377 2.8c.835 2.025 1.691 3.838 2.253 5.169.278.663.506 1.21.638 1.585a2.9 2.9 0 01.192.6z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6290"
                    d="M185.863 150.128a2.87 2.87 0 01.506-.289l1.458-.7c.314-.147.643-.309 1.013-.471a2.865 2.865 0 011.311-.192 2.729 2.729 0 011.377.506 4.319 4.319 0 011.013 1.195 13.547 13.547 0 01.709 1.362c.2.435.44.856.663 1.251.44.785.856 1.489 1.159 2.106a11.868 11.868 0 01.648 1.494 2.092 2.092 0 01.162.572c-.04 0-.314-.785-.972-1.98-.329-.6-.754-1.286-1.21-2.071-.228-.395-.456-.815-.678-1.256s-.435-.9-.7-1.342a4.3 4.3 0 00-.937-1.129 2.557 2.557 0 00-1.241-.506 2.648 2.648 0 00-1.21.152c-.365.147-.714.309-1.013.435l-1.494.673a2.46 2.46 0 01-.562.192z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6291"
                    d="M186.571 147.439a1.955 1.955 0 01-.446.278c-.294.167-.724.4-1.261.684-1.068.567-2.557 1.337-4.207 2.167s-3.149 1.57-4.243 2.1l-1.3.608a2.025 2.025 0 01-.506.187 2.208 2.208 0 01.451-.284 78.74 78.74 0 011.261-.678c1.068-.567 2.557-1.332 4.207-2.167s3.149-1.57 4.238-2.091c.547-.263 1.013-.471 1.3-.613a1.848 1.848 0 01.5-.187z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6292"
                    d="M148.218 126.103a16.521 16.521 0 00-3.858-.127 16.709 16.709 0 00-3.726 1.013 3.594 3.594 0 011.013-.567 9.68 9.68 0 012.683-.719 9.787 9.787 0 012.785.071 3.57 3.57 0 011.1.329z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6293"
                    d="M220.373 38.399a101.77 101.77 0 00-24.06 2.532c-1.828.42-3.762.957-5 2.364-1.311 1.489-1.519 3.61-1.711 5.569l-.628 7.215a16.62 16.62 0 01-1.149 1.716c-1.9 2.486-4.329 4.557-6.076 7.169s-2.686 6.075-1.279 8.875c.856 1.691 2.435 2.886 3.59 4.385 1.959 2.532 2.6 5.828 3.742 8.83s3.21 6.076 6.364 6.663c1.519.289 3.068-.03 4.582.192 2.263.329 4.167 1.813 5.964 3.225s3.752 2.851 6.03 3.078 4.886-1.316 4.931-3.6a5.347 5.347 0 01-5.5-.152c-1.327-.876-2.182-2.294-3.321-3.4-2.8-2.714-7.306-3.575-9.255-6.952-1.519-2.668-.927-6.015-.228-9.017.6-2.6 1.22-5.423.466-7.924a8.871 8.871 0 00.1-1.215c0-2.663-1.038-5.519.2-7.873 1.64-3.119 6.369-3.514 8.025-6.618.967-1.808.628-4.116 1.681-5.873.987-1.651 2.967-2.41 4.85-2.82s3.863-.6 5.524-1.57 2.912-3.032 2.158-4.799z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6294"
                    d="M226.51 92.722a.506.506 0 01-.076.127l-.248.349a7.038 7.038 0 01-1.165 1.18 7.863 7.863 0 01-2.314 1.24 8 8 0 01-3.443.4 7.276 7.276 0 01-3.878-1.59c-1.205-.927-2.278-2.157-3.64-3.134a5.256 5.256 0 00-2.319-.977 5.063 5.063 0 00-2.582.471 12.945 12.945 0 01-2.678.922 4.7 4.7 0 01-2.886-.506 14.521 14.521 0 01-2.435-1.615 19.789 19.789 0 01-2.137-1.858 6.678 6.678 0 01-1.519-2.289 4.739 4.739 0 01-.172-2.633 14.314 14.314 0 01.765-2.375c.294-.744.547-1.478.77-2.2a29.5 29.5 0 00.937-4.051 29.957 29.957 0 00.329-6.04 26.221 26.221 0 00-.116-1.646 1.478 1.478 0 00-.035-.425.073.073 0 11.03-.005l.061.425c.056.375.122.927.167 1.646a28.8 28.8 0 01-.243 6.076 29.809 29.809 0 01-.911 4.106 36.222 36.222 0 01-.765 2.218 14.256 14.256 0 00-.754 2.309 4.451 4.451 0 00.172 2.5 6.446 6.446 0 001.468 2.2 19.472 19.472 0 002.111 1.828 14.284 14.284 0 002.39 1.585 4.425 4.425 0 002.729.506 12.87 12.87 0 002.623-.906 5.352 5.352 0 012.709-.476 5.494 5.494 0 012.43 1.013c1.387 1.013 2.451 2.248 3.635 3.164a7.048 7.048 0 003.759 1.578 8.1 8.1 0 005.671-1.519 9.361 9.361 0 001.559-1.595z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6295"
                    d="M175.924 76.621a5.862 5.862 0 01-.405-.562 5.727 5.727 0 01-.719-1.843 7.624 7.624 0 01.051-3.129 9.62 9.62 0 011.752-3.757 17.514 17.514 0 013.656-3.453c1.453-1.084 3.1-2.091 4.749-3.261a17.428 17.428 0 004.506-4.38 11.285 11.285 0 001.367-3.038c.324-1.084.547-2.218.81-3.362a17.871 17.871 0 011.013-3.332 7.524 7.524 0 011.99-2.749 8.445 8.445 0 012.871-1.585 13.311 13.311 0 013.078-.608 35.143 35.143 0 015.772.182 26.789 26.789 0 004.992.066 10.633 10.633 0 003.914-1.165 7.757 7.757 0 002.395-1.934 5.817 5.817 0 00.972-1.7c.142-.425.2-.658.208-.653a4.96 4.96 0 01-.157.673 5.722 5.722 0 01-.942 1.742 7.863 7.863 0 01-2.4 2.025 10.744 10.744 0 01-3.964 1.22 26.642 26.642 0 01-5.033-.035 35.443 35.443 0 00-5.731-.157 12.976 12.976 0 00-3.038.608 8.232 8.232 0 00-2.785 1.519 7.311 7.311 0 00-1.919 2.663 17.429 17.429 0 00-1.013 3.286c-.263 1.139-.506 2.278-.82 3.377a11.24 11.24 0 01-1.4 3.089 17.58 17.58 0 01-4.557 4.435c-1.661 1.175-3.311 2.172-4.759 3.24a17.321 17.321 0 00-3.644 3.396 9.337 9.337 0 00-1.757 3.671 7.474 7.474 0 00-.1 3.078 6.446 6.446 0 001.043 2.43z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6296"
                    d="M177.404 79.279a11.193 11.193 0 01-1.554-1.494 6.962 6.962 0 01-1.346-2.146 3.509 3.509 0 01-.233-1.585 3.144 3.144 0 01.684-1.656 6.158 6.158 0 013.6-1.848c.714-.147 1.463-.263 2.208-.451a6.279 6.279 0 002.157-.9 5.2 5.2 0 001.57-1.858 17.051 17.051 0 00.972-2.415 17.1 17.1 0 011.013-2.532 5.367 5.367 0 011.9-2.1 9.327 9.327 0 012.638-1.013c.9-.233 1.782-.42 2.618-.7a8.466 8.466 0 004.02-2.962 30.682 30.682 0 002.516-3.914 21.671 21.671 0 012.267-3.366 8.648 8.648 0 012.6-2.121 6.552 6.552 0 012.425-.734 4.557 4.557 0 011.6.132 3.139 3.139 0 01.506.182s-.187-.051-.542-.132a4.967 4.967 0 00-1.575-.081 6.628 6.628 0 00-2.365.759 8.607 8.607 0 00-2.532 2.111 22.384 22.384 0 00-2.228 3.362 30.5 30.5 0 01-2.491 3.944 10.577 10.577 0 01-1.787 1.808 7.777 7.777 0 01-2.314 1.225c-.851.294-1.747.481-2.633.714a8.987 8.987 0 00-2.562 1.013 5.119 5.119 0 00-1.813 2.025 16.871 16.871 0 00-1.013 2.5 16.934 16.934 0 01-1.013 2.446 5.57 5.57 0 01-1.651 1.944 6.7 6.7 0 01-2.243.921c-.759.182-1.519.294-2.218.44a6.012 6.012 0 00-3.5 1.747 2.977 2.977 0 00-.663 1.559 3.388 3.388 0 00.2 1.519 7.209 7.209 0 001.291 2.127c.446.506.825.906 1.084 1.165l.3.289c.08.036.115.071.107.076z"
                    fill="#455a64"
                />
                <path
                    data-name="Path 6297"
                    d="M239.734 46.571a1.8 1.8 0 01-.263.39c-.182.243-.451.587-.8 1.013a48.388 48.388 0 01-2.845 3.114 5.1 5.1 0 01-1.863 1.438 1.64 1.64 0 01-1.068 0 1.367 1.367 0 01-.689-.633 2.061 2.061 0 01-.187-1.286 1.205 1.205 0 01.116-.456 2.173 2.173 0 010 .466 2.076 2.076 0 00.248 1.185 1.17 1.17 0 00.6.506 1.392 1.392 0 00.916 0 5.1 5.1 0 001.752-1.392c1.159-1.159 2.167-2.253 2.9-3.038a13.123 13.123 0 011.185-1.306z"
                    fill="#eb996e"
                />
                <path
                    data-name="Path 6298"
                    d="M243.604 46.571a1.721 1.721 0 01-.309.395c-.208.243-.506.587-.9 1.013l-1.362 1.473-.81.861a7.213 7.213 0 01-.922.891 2.385 2.385 0 01-1.235.506 1.266 1.266 0 01-1.149-.506 1.013 1.013 0 01-.187-.587 1.16 1.16 0 01.172-.542c.187-.3.38-.537.547-.77.334-.461.613-.83.81-1.084a1.746 1.746 0 01.329-.375 2.08 2.08 0 01-.243.435c-.172.268-.425.653-.744 1.129-.157.238-.349.506-.506.77a.754.754 0 000 .881 1.013 1.013 0 00.937.385 2.137 2.137 0 001.094-.446 6.582 6.582 0 00.916-.84l.825-.851 1.4-1.438c.4-.405.729-.729.962-.952a1.852 1.852 0 01.375-.349z"
                    fill="#eb996e"
                />
                <path data-name="Path 6299" d="M296.467 201.579a6.42 6.42 0 0110.425-1.808 8.334 8.334 0 01-4.663 3.585 5.063 5.063 0 01-5.762-1.777" fill="#ff435b" />
                <path
                    data-name="Path 6300"
                    d="M292.872 202.41a6.507 6.507 0 011.453-.456c.916-.223 2.192-.471 3.6-.7s2.7-.41 3.635-.506a8.648 8.648 0 011.519-.116 9.01 9.01 0 01-1.489.3l-3.62.592c-1.408.233-2.683.456-3.6.628a13.43 13.43 0 01-1.498.258z"
                    fill="#263238"
                />
                <path data-name="Path 6301" d="M115.495 169.525a8.435 8.435 0 01-1.519 14.02c-1.858-4.764-2.572-9.893 1.519-14.02" fill="#ff435b" />
                <path data-name="Path 6302" d="M122.538 173.18a10.911 10.911 0 0015.772 9.159c-3.544-5.569-8.339-10.263-15.772-9.169" fill="#ff435b" />
                <path
                    data-name="Path 6303"
                    d="M106.404 155.571a1.428 1.428 0 01.238.253l.633.754c.537.663 1.321 1.62 2.314 2.78 1.975 2.329 4.764 5.5 8.05 8.8 1.646 1.651 3.261 3.175 4.759 4.521s2.916 2.5 4.132 3.418a31.468 31.468 0 002.982 2.025c.734.435 1.17.618 1.159.643a1.292 1.292 0 01-.324-.122 7.729 7.729 0 01-.886-.43 26.964 26.964 0 01-3.038-1.97c-1.235-.911-2.653-2.061-4.177-3.387s-3.139-2.866-4.79-4.516a123.426 123.426 0 01-8-8.871 88.198 88.198 0 01-2.253-2.835l-.6-.79a1.832 1.832 0 01-.2-.278z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6304"
                    d="M115.104 176.724c-.046 0 .122-.709.268-1.858a19.807 19.807 0 00-.932-8.921c-.38-1.094-.689-1.747-.648-1.767a1.889 1.889 0 01.258.435 13.1 13.1 0 01.567 1.266 17.62 17.62 0 011.063 4.445 17.422 17.422 0 01-.127 4.557 12.491 12.491 0 01-.3 1.382 2.085 2.085 0 01-.147.461z"
                    fill="#263238"
                />
                <path data-name="Path 6305" d="M338.537 74.262a6.415 6.415 0 013.352-10.03 8.314 8.314 0 01.947 5.8 5.063 5.063 0 01-4.3 4.233" fill="#ff435b" />
                <path
                    data-name="Path 6306"
                    d="M337.575 77.821a6.3 6.3 0 01.289-1.494c.238-.911.623-2.152 1.089-3.509s.921-2.572 1.266-3.448a8.175 8.175 0 01.618-1.392 9.168 9.168 0 01-.441 1.458c-.334.967-.744 2.152-1.195 3.468s-.876 2.582-1.159 3.473a14.8 14.8 0 01-.467 1.444z"
                    fill="#263238"
                />
                <path data-name="Path 6307" d="M316.259 111.931a12.4 12.4 0 0113.27-15.564c.7 3.828-.506 7.757-2.506 11.083a9.809 9.809 0 01-10.764 4.481" fill="#ff435b" />
                <path
                    data-name="Path 6308"
                    d="M301.277 126.918a1.767 1.767 0 01.218-.273l.653-.744c.582-.638 1.433-1.549 2.491-2.663 2.127-2.228 5.094-5.271 8.395-8.607s6.3-6.349 8.491-8.511l2.6-2.557.719-.684c.162-.152.253-.233.263-.223a1.864 1.864 0 01-.228.263l-.684.719-2.532 2.608-8.445 8.567-8.445 8.557-2.532 2.6-.689.709a1.592 1.592 0 01-.279.238z"
                    fill="#263238"
                />
                <g data-name="Group 6513">
                    <path data-name="Path 6309" d="M316.259 111.931a12.4 12.4 0 0113.27-15.564c.7 3.828-.506 7.757-2.506 11.083a9.809 9.809 0 01-10.764 4.481" opacity={0.3} />
                </g>
                <path data-name="Path 6310" d="M309.317 113.439a7.595 7.595 0 01-1.585-12.455 9.888 9.888 0 013.995 5.716 5.987 5.987 0 01-2.41 6.739" fill="#ff435b" />
                <g data-name="Group 6514">
                    <path data-name="Path 6311" d="M309.317 113.439a7.595 7.595 0 01-1.585-12.455 9.888 9.888 0 013.995 5.716 5.987 5.987 0 01-2.41 6.739" opacity={0.3} />
                </g>
                <path
                    data-name="Path 6312"
                    d="M310.104 117.749a1.972 1.972 0 01-.152-.466c-.086-.3-.182-.739-.289-1.291-.213-1.094-.435-2.623-.638-4.309s-.344-3.225-.43-4.339a10.973 10.973 0 01-.071-1.8 13.344 13.344 0 01.258 1.787c.137 1.175.314 2.668.506 4.324s.395 3.21.557 4.309.303 1.775.259 1.785z"
                    fill="#263238"
                />
                <path data-name="Path 6313" d="M214.49 172.117a11.984 11.984 0 0119.746-3.585c-5.742 4.45-12.44 7.488-19.706 3.585" fill="#ff435b" />
                <g data-name="Group 6515">
                    <path data-name="Path 6314" d="M214.49 172.117a11.984 11.984 0 0119.746-3.585c-5.742 4.45-12.44 7.488-19.706 3.585" opacity={0.3} />
                </g>
                <path
                    data-name="Path 6315"
                    d="M211.264 173.899a2.334 2.334 0 01.456-.354c.3-.218.754-.506 1.332-.84a25.315 25.315 0 019.8-3.235 17.707 17.707 0 011.565-.111 2.123 2.123 0 01.577 0c0 .041-.815.091-2.121.284a30.258 30.258 0 00-5.007 1.175 29.873 29.873 0 00-4.723 2.021c-1.185.65-1.853 1.1-1.879 1.06z"
                    fill="#263238"
                />
                <path data-name="Path 6316" d="M81.04 106.144a9.255 9.255 0 0113.164 8.126c-5.595-.415-11.007-2.132-13.164-8.126" fill="#ff435b" />
                <g data-name="Group 6516">
                    <path data-name="Path 6317" d="M81.04 106.144a9.255 9.255 0 0113.164 8.126c-5.595-.415-11.007-2.132-13.164-8.126" opacity={0.3} />
                </g>
                <path
                    data-name="Path 6318"
                    d="M78.261 105.491a1.372 1.372 0 01.446 0 11.7 11.7 0 011.2.192 19.387 19.387 0 013.818 1.22 19.67 19.67 0 013.483 1.99c.415.3.739.562.952.754a1.712 1.712 0 01.319.314c-.03.035-.506-.354-1.377-.911a23.209 23.209 0 00-7.235-3.164c-.989-.247-1.611-.354-1.606-.395z"
                    fill="#263238"
                />
                <path data-name="Path 6319" d="M262.468 165.915a8.278 8.278 0 0110.572-8.931c-1.909 4.643-4.876 8.769-10.572 8.931" fill="#ff435b" />
                <path
                    data-name="Path 6320"
                    d="M261.136 168.097a6.03 6.03 0 01.633-1.347 17.032 17.032 0 014.764-5.316 6.075 6.075 0 011.271-.775 13.844 13.844 0 01-1.165.921 21 21 0 00-4.718 5.259 12.9 12.9 0 01-.785 1.256z"
                    fill="#263238"
                />
                <path data-name="Path 6321" d="M302.31 139.612a8.278 8.278 0 018.7-10.759c-1.013 4.921-3.139 9.529-8.7 10.759" fill="#ff435b" />
                <g data-name="Group 6517">
                    <path data-name="Path 6322" d="M302.31 139.612a8.278 8.278 0 018.7-10.759c-1.013 4.921-3.139 9.529-8.7 10.759" opacity={0.3} />
                </g>
                <path
                    data-name="Path 6323"
                    d="M301.414 142.006a5.833 5.833 0 01.37-1.438 16.656 16.656 0 011.519-3.256 16.489 16.489 0 012.167-2.861 6.076 6.076 0 011.1-1.013 13.981 13.981 0 01-.962 1.124 22.117 22.117 0 00-2.081 2.876 22.532 22.532 0 00-1.565 3.18 12.93 12.93 0 01-.547 1.387z"
                    fill="#263238"
                />
                <path data-name="Path 6324" d="M1.979 103.405a8.278 8.278 0 0112.941-4.906c-3.342 3.742-7.514 6.648-12.941 4.906" fill="#ff435b" />
                <path
                    data-name="Path 6325"
                    d="M.004 105.02a6.279 6.279 0 011.043-1.058 16.9 16.9 0 012.947-2.025 16.547 16.547 0 013.316-1.382 5.757 5.757 0 011.453-.309 13.67 13.67 0 01-1.4.506 20.85 20.85 0 00-6.2 3.392 12.367 12.367 0 01-1.165.876z"
                    fill="#263238"
                />
                <path data-name="Path 6326" d="M233.411 37.331l4.051-7.336a19.549 19.549 0 114.734 5.164z" fill="#fff" />
                <path
                    data-name="Path 6327"
                    d="M233.411 37.331l8.795-2.228-.035.106-.309-.39.405.3a20.066 20.066 0 004.02 2.339 19.544 19.544 0 005.326 1.443 17.462 17.462 0 003.063.111c.506-.035 1.063-.056 1.605-.106l1.623-.267a19.485 19.485 0 0015.154-15.372 19.681 19.681 0 00-.344-8.582 19.073 19.073 0 00-4.207-7.883l-.795-.856c-.263-.289-.572-.506-.856-.79a10.341 10.341 0 00-.881-.754l-.932-.684a8 8 0 00-.937-.613l-1.013-.6-1.013-.506a8.923 8.923 0 00-1.013-.451 19.63 19.63 0 00-8.653-1.251 18.961 18.961 0 00-7.914 2.41 19.635 19.635 0 00-9.083 11.341 21.265 21.265 0 00-.8 6.3 19.412 19.412 0 002.937 9.62v.071l-4.126 7.3 4.015-7.367v.067a19.412 19.412 0 01-3.013-9.655 21.265 21.265 0 01.785-6.359 19.868 19.868 0 019.144-11.5 19.28 19.28 0 018.015-2.451 19.934 19.934 0 018.769 1.261 9.117 9.117 0 011.048.456l1.043.506 1.013.592a8.7 8.7 0 01.977.633l.906.678a11.544 11.544 0 01.9.765 9.259 9.259 0 01.866.8l.825.891a19.194 19.194 0 014.263 7.99 19.969 19.969 0 01.314 8.688 19.427 19.427 0 01-3.23 7.595 19.64 19.64 0 01-12.126 7.949l-1.651.268c-.542.051-1.083.066-1.615.1a17.28 17.28 0 01-3.094-.127 19.468 19.468 0 01-5.372-1.478 19.747 19.747 0 01-4.05-2.385l.091-.091.066.081h-.1z"
                    fill="#263238"
                />
                <path
                    data-name="Path 6328"
                    d="M248.595 16.268l2.532-.025a2.152 2.152 0 012.192-2.187 2.025 2.025 0 012 1.291 2.273 2.273 0 01-.157 2.056 2.762 2.762 0 01-3.078 1.084l.056 5.726h2.532l-.046-3.387A4.911 4.911 0 00258.104 16a4.549 4.549 0 00-4.8-4.526c-1.089.025-4.8.324-4.709 4.794z"
                    fill="#ff435b"
                />
                <path data-name="Rectangle 2528" fill="#ff435b" d="M252.154 25.394l2.547-.025.024 2.38-2.547.025z" />
            </g>
        </svg>
    )
}

export default SvgPage404

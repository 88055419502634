import { getCoverPhoto } from 'utils/youtube-helpers';
import i18n from 'i18next'
import Config from '../config'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import * as Services from '@mohsininsignia/advancerapiclient'
import axios, { AxiosResponse } from 'axios'
import { Device } from '@capacitor/device';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

const onRequestSucceed = async (response: AxiosResponse): Promise<AxiosResponse> => {
    const { platform } = await Device.getInfo()

    if (typeof response.data.jwt !== 'undefined' && response.data.jwt.length > 0) {
        const cookies = new Cookies()
        cookies.set('token', response.data.jwt, { path: '/' })
        if (platform ==='ios') {
            localStorage.setItem('token',response.data.jwt)
        }
    }
    return response
}

const onRequestFailed = (error: any) => {
    if (error.response?.data?.userMessageCode) {
        toast.error(i18n.t(`common:userMessageCode.${error.response?.data?.userMessageCode}`))
    } else if (error.response?.status === 400) {
        if (error.response.data.fields) {
            for (const [key, value] of Object.entries(error.response.data.fields)) {
                toast.error(`${key}: ${value}`);
            }
        }
        toast.warning(error.response.data.title)
    } else {
        toast.error(i18n.t(`common:userMessageCode.Unhandled`))
    }

    throw error
}

const onRequest = async (req: any) => {
    const { platform } = await Device.getInfo()

    const cookies = new Cookies(req.headers?.cookie)
    let token = cookies.get('token')
if (platform==='ios') {
    token = localStorage.getItem('token')
}
    if (token) {
        req.headers!.Authorization = `Bearer ${token}`
    }

    // req.data = req.data && typeof req.data === 'string' && JSON.parse(req.data)
    return req
}

axios.interceptors.request.use(onRequest)
axios.interceptors.response.use(onRequestSucceed, onRequestFailed)

export const AllAccounts = Services.AllAccountsApiFactory(undefined, Config.api.baseUrl, axios)
export const AppInfo = Services.AppInfoApiFactory(undefined, Config.api.baseUrl, axios)
export const Appointment = Services.AppointmentApiFactory(undefined, Config.api.baseUrl, axios)
export const Chat = Services.ChatApiFactory(undefined, Config.api.baseUrl, axios)
export const CustomerService = Services.CustomerServiceApiFactory(undefined, Config.api.baseUrl, axios)
export const LegalDocument = Services.LegalDocumentApiFactory(undefined, Config.api.baseUrl, axios)
export const Live = Services.LiveApiFactory(undefined, Config.api.baseUrl, axios)
export const OneSignal = Services.OneSignalApiFactory(undefined, Config.api.baseUrl, axios)
export const Payments = Services.PaymentApiFactory(undefined, Config.api.baseUrl, axios)
export const Scheduler = Services.SchedulerApiFactory(undefined, Config.api.baseUrl, axios)
export const Student = Services.StudentApiFactory(undefined, Config.api.baseUrl, axios)
export const Teacher = Services.TeacherApiFactory(undefined, Config.api.baseUrl, axios)
export const VideoRecord = Services.VideoRecordApiFactory(undefined, Config.api.baseUrl, axios)
export const WordTutorial = Services.WordTutorialApiFactory(undefined, Config.api.baseUrl, axios)
export const WordTutorialCategory = Services.WordTutorialCategoryApiFactory(undefined, Config.api.baseUrl, axios)

export const Referrals = Services.ReferralsApiFactory(undefined, Config.api.baseUrl, axios)
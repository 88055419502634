import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type HomeTemplateProps = {
    isTeacher?: boolean
    children: React.ReactNode
}

const HomeTemplate: React.FC<HomeTemplateProps> = ({ children, isTeacher }) => {
    return (
        <div
            className={classnames(styles.home, {
                [styles.wide]: isTeacher,
            })}
        >
            {children}
        </div>
    )
}

export default HomeTemplate

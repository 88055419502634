import * as React from 'react'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

export type TeacherAuthServiceSelectorCardProps = {
    onDirectLoginClick: () => void
    onDirectRegisterClick: () => void
}

const TeacherAuthServiceSelectorCard = ({ onDirectLoginClick, onDirectRegisterClick }: TeacherAuthServiceSelectorCardProps) => {
    const { t } = useTranslation('organisms')

    const onDirectLoginClickHandler = () => {
        if (typeof onDirectLoginClick === 'function') {
            onDirectLoginClick()
        }
    }

    const onDirectRegisterClickHandler = () => {
        if (typeof onDirectRegisterClick === 'function') {
            onDirectRegisterClick()
        }
    }

    return (
        <div className={styles.container}>
            <Button size={'default'} type={'default'} onClick={onDirectRegisterClickHandler}>
                {t('teacherAuth.directRegister')}
            </Button>

            <Button size={'default'} type={'default'} onClick={onDirectLoginClickHandler}>
                {t('teacherAuth.directLogin')}
            </Button>
        </div>
    )
}

export default TeacherAuthServiceSelectorCard

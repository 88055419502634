import * as React from 'react'
import useApi from 'hooks/useApi'
import {isUndefined} from 'lodash'
import useAuth from 'hooks/useAuth'
import usePrevious from 'hooks/usePrevious'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import TeacherBox from 'components/organisms/teacher-box'
import InfiniteScroll from 'react-infinite-scroll-component'
import TeachersTemplate, {styles} from 'components/templates/teachers'
import TeacherFilters, {EmittedFilters} from 'components/organisms/teacher-filters'
import {TeacherQueriesListTeachersPagination as Pagination, TeacherQueriesTeacherListItem as Teacher} from '@mohsininsignia/advancerapiclient'

export default function Teachers() {
    const {t} = useTranslation('common')
    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [loading, setLoading] = React.useState(true)
    const [teachers, setTeachers] = React.useState<Teacher[]>([])
    const [pagination, setPagination] = React.useState<Pagination>({isNextPageAvailable: false})

    const [filters, setFilters] = React.useState<EmittedFilters>({
        query: '',
        accents: [],
        lessonTypes: [],
        teachingStyles: [],
        languageLevels: [],
    })
    const prevFilters = usePrevious(filters)

    const fetchTeachers = async () => {
        setLoading(true)
        setTeachers([])
        try {
            const {data} = await api.Teacher.listTeachers(
                undefined,
                filters?.query && filters.query !== '' ? filters.query : undefined,
                filters?.teachingStyles,
                filters?.lessonTypes,
                filters?.languageLevels,
                filters?.accents
            )
            setTeachers(data.teachers)
            setPagination(data.pagination)
        } catch {
        }
        setLoading(false)
    }

    const paginate = async () => {
        if (loading || !pagination.isNextPageAvailable || !pagination.lastId || teachers.length === 0) return

        try {
            const {data} = await api.Teacher.listTeachers(
                pagination.lastId,
                filters?.query,
                filters?.teachingStyles,
                filters?.lessonTypes,
                filters?.languageLevels,
                filters?.accents
            )
            setTeachers([...teachers, ...data.teachers])
            setPagination(data.pagination)
        } catch {
        }
    }

    React.useEffect(
        () => {
            ;(async () => {
                JSON.stringify(filters) !== JSON.stringify(prevFilters) && !isUndefined(prevFilters) && (await fetchTeachers())
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [filters, prevFilters]
    )

    React.useEffect(
        () => {
            fetchTeachers()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    React.useEffect(() => {
        (async () => {
            if (auth.user?.userRole === 'Teacher') {
                await router.replace({pathname: '/'})
            }
        })()
    }, [auth.user?.userRole, router])

    if (auth.user?.userRole === 'Teacher') {
        return <></>
    }

    return (
        <Master title={`${t('teachers.title')}${t('title')}`}>
            <TeachersTemplate>
                <div className={styles['teacher-list']}>
                    <TeacherFilters onChange={setFilters}/>

                    <InfiniteScroll next={paginate} hasMore={pagination.isNextPageAvailable} loader={<LoadingBox/>} dataLength={teachers.length}>
                        {!loading && !teachers.length && <ErrorBox text={t('teachers.notFound')}/>}
                        <div className={styles.list}>
                            {teachers.map(teacher => (
                                <TeacherBox key={teacher.teacherId} teacher={teacher}/>
                            ))}
                            {loading && <LoadingBox/>}
                        </div>
                    </InfiniteScroll>
                </div>
            </TeachersTemplate>
        </Master>
    )
}

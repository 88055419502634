import Card from '../card'
import * as React from 'react'
import {format} from 'date-fns'
import Text from 'components/atoms/text'
import TeacherInfo from '../teacher-info'
import {Dictionary, groupBy} from 'lodash'
import Button from 'components/atoms/button'
import {isoToHuman} from 'utils/time-helpers'
import {TeacherQueriesFreeAppointmentRange, TeacherQueriesGetDetailsQueryResult as Teacher} from '@mohsininsignia/advancerapiclient'
import {useTranslation} from 'react-i18next'

import styles from './style.module.css'

type TeacherAppointmentCardProps = {
    teacher: Teacher
    selectedDates: TeacherQueriesFreeAppointmentRange[]
    loading?: boolean
    canCreateAppointment?: boolean
    onConfirm?: () => void
}

const TeacherAppointmentCard: React.FC<TeacherAppointmentCardProps> = ({teacher, loading, selectedDates, canCreateAppointment, onConfirm}) => {
    const {t} = useTranslation('molecules')

    const [grouped, setGrouped] = React.useState<Dictionary<TeacherQueriesFreeAppointmentRange[]>>({})

    React.useEffect(() => {
        if (!selectedDates.length) {
            setGrouped({})
            return
        }

        const groupedDays = groupBy(selectedDates, r => {
            return format(new Date(r.start!), 'yyyyy-MM-dd')
        })
        setGrouped(groupedDays)
    }, [selectedDates])

    return (
        <Card>
            <div className={styles.wrapper}>
                <TeacherInfo teacher={teacher} vertical/>

                <div className={styles.divider}/>

                {grouped &&
                Object.keys(grouped).map((r, i) => (
                    <div key={i.toString()} className={styles.texts}>
                        <Text size="xl" weight="medium">
                            {isoToHuman(grouped[r][0].start!)}
                        </Text>
                        {grouped[r].map((s, x) => (
                            <Text key={x.toString()} size="xl" weight="medium" type="dimmed-light">
                                {t('appointments.betweenHour', {hour: `${format(new Date(s.start!), 'kk:mm')} - ${format(new Date(s.end!), 'kk:mm')}`})}
                            </Text>
                        ))}
                    </div>
                ))}

                <Button disabled={!canCreateAppointment} loading={loading} onClick={onConfirm} type="gradient" variation="block">
                    {t('appointments.create-appointment')}
                </Button>
            </div>
        </Card>
    )
}

export default TeacherAppointmentCard

import { TeacherQueriesGetDetailsQueryResult, TeacherQueriesMyRatingResultList } from '@mohsininsignia/advancerapiclient'
import Avatar from 'components/atoms/avatar'
import Button from 'components/atoms/button'
import useApi from 'hooks/useApi'
import React from 'react'
import styles from './style.module.css'
import { useParams } from 'react-router-dom'
import Rateable from 'components/atoms/rateable'
import { useTranslation } from 'react-i18next'

function RatingDisplay({ writeReview, allowReview }: any) {
    const { t } = useTranslation('organisms')
    const api = useApi()
    const { id } = useParams<{ id: string }>()
    const [teacher, setTeacher] = React.useState<TeacherQueriesGetDetailsQueryResult | undefined>()
    const [reviews, setReviews] = React.useState<TeacherQueriesMyRatingResultList[]>([])

    React.useEffect(() => {
        ; (async () => {
            const { data } = await api.Teacher.getTeacherDetails(parseInt(id))
            setTeacher(data)

        })()
    }, [api.Teacher, id])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = async () => {
        try {
            const { data } = await api.Teacher.ratingList(teacher?.teacherId)
            console.log(data);
            setReviews(data)
        } catch (error) {
            // Handle error
        }
    }

    React.useEffect(() => {
        fetchData()
    }, [allowReview, api.Teacher, teacher?.teacherId])

    return (
        <div className={styles['teacher-detail']}>
            <div>
                <div style={{ display: 'flex', color: 'var(--dark)', justifyContent: 'center' }} className={styles['vertically-align']}>
                    <div>
                        <h1 style={{ display: 'inline-block' }}>
                            {t('rateAppointment.studentReviews')}
                        </h1>
                    </div>
                    <div style={{ width: "0.15rem", backgroundColor: 'var(--dark)', marginLeft: "2rem", marginRight: "2rem", color: 'var(--gray-4)' }}></div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Button onClick={writeReview} style={{ padding: '0 2.75rem' }}
                            type="gradient"
                            size='large'
                            alignment="center"
                            disabled={allowReview}
                        >
                            {t('rateAppointment.writeReviews')}
                        </Button>
                    </div>
                </div>
                {reviews.length === 0 ? <h3 style={{ color: 'var(--gray-4)' }}>{t('rateAppointment.noReviews')}</h3> :
                    reviews.map((eachReview, index) => {
                        return (
                            <div key={index}>
                                <div style={{ display: 'flex', margin: '2rem 2rem' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                                        <Avatar url={eachReview?.avatar1X ?? ''} rounded={true} size={4.5} />
                                    </div>
                                    <div style={{ margin: '0 0.5rem' }}>
                                        <h3 style={{ color: 'var(--dark)', padding: '0', margin: '0' }}>{eachReview.studentName}</h3>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Rateable rate={eachReview.rate} displayText={false} />
                                            <h5 style={{ margin: '0', color: 'var(--gray-4)' }}>{eachReview.rate}/5.0</h5>
                                        </div>
                                        <h5 style={{ color: 'var(--gray-4)', margin: '0', fontWeight: '500', wordWrap: 'break-word', maxWidth: '45vw' }}>{eachReview.comments}</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RatingDisplay
import moment from 'moment'
import * as React from 'react'
import { format } from 'date-fns'
import useApi from 'hooks/useApi'
import { getUserRole } from 'utils/auth'
import { pad } from 'utils/time-helpers'
import Text from 'components/atoms/text'
import { Dictionary, groupBy } from 'lodash'
import Badge from 'components/atoms/badge'
import { useHistory } from 'react-router-dom'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import Calendar from 'components/atoms/calendar'
import { FormatLocale } from 'helpers/formatLocale'
import PageHeading from 'components/molecules/page-heading'
import { DeleteBinLine, FileCopyLine } from 'components/icons'
import SectionHeading from 'components/molecules/section-heading'
import TimeRangePicker from 'components/molecules/time-range-picker'
import CalendarModalCard from 'components/organisms/calendar-modal-card'
import { SchedulerCommandsSchedulerTimeRange, SchedulerQueriesAvailableDate } from '@mohsininsignia/advancerapiclient'

import CalendarTemplate, { styles } from 'components/templates/calendar-template'
import { toast } from 'react-toastify'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'

export default function CalendarScreen() {
    const { t } = useTranslation('common')

    const api = useApi()
    const router = useHistory()
    const role = getUserRole()
    var [tmp, setTemp] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [isDuplicating, setIsDuplicating] = React.useState<string | undefined>(undefined)
    const [selectedDate, setSelectedDate] = React.useState<Date>(moment().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate())
    const [availableTimes, setAvailableTimes] = React.useState<Dictionary<SchedulerQueriesAvailableDate[]>>({})
    const [availableDates, setAvailableDates] = React.useState<SchedulerQueriesAvailableDate[]>([])
    const [selectedTimeRanges, setSelectedTimeRanges] = React.useState<string[]>([])
    const [timeSlots, setTimeSlots] = React.useState<any[]>([])
    const [changedTimeRanges, setChangedTimeRanges] = React.useState<string[]>([])
    const [saveDate, setSaveDate] = React.useState<boolean>(false)
    const [timeSlotExist, setTimeSlotExist] = React.useState<boolean>(false)
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false)
    const [dayAvailabilityKey, setDayAvailabilityKey] = React.useState<string>('')


    const getAvailableTimes = React.useCallback(async () => {
        try {
            const { data } = await api.Scheduler.getMyAvailableTimes()
            console.log(data.availableDates);
            setAvailableDates(data.availableDates)
            data.availableDates.map((availableDate: any) => {
                availableDate.startTimeUTC = availableDate?.startTime
                availableDate.startTime = moment(availableDate?.startTime).toDate().toISOString()
                availableDate.endTimeUTC = availableDate?.endTime
                availableDate.endTime = moment(availableDate?.endTime).toDate().toISOString()
            })
            const groupedDays = groupBy(data.availableDates, r => format(new Date(r.startTime), 'yyyy-MM-dd'))
            setAvailableTimes(groupedDays)
        } catch (error) { }
    }, [api.Scheduler, api.AppInfo])

    React.useEffect(() => {
        setTimeSlotExist(false)
        Object.keys(availableTimes).map((r: string, i: Number) => {
            const date = new Date(r)
            console.log(r);
            availableDates.map((availableDate: any) => {
                const inputDate = new Date(availableDate.startTimeUTC);
                if (inputDate.getDate() === selectedDate.getDate()) {

                    const hours = inputDate.getHours();
                    const minutes = inputDate.getMinutes();
                    // const hours = date.getHours();
                    // const minutes = date.getUTCMinutes();

                    if (date.getDate() === selectedDate.getDate() && hours === 23 && minutes >= 20) {
                        setTimeSlotExist(true)
                    }
                }
            })
        })
    }, [availableDates, availableTimes, selectedDate, timeSlotExist])

    const getTimeSlots = React.useCallback(async () => {
        try {
            const { data } = await api.AppInfo.getTimeSlots('11-29-2022')
            const currentDate = moment.utc().format('yyyy-MM-DD');
            data.timeList?.map((timeslot: any) => {
                timeslot.startTimeUTC = timeslot?.startTime
                timeslot.startTime = moment.utc(`${currentDate} ${timeslot?.startTime}`).local().format('HH:mm')
                // timeslot.startTime = format(new Date(`2023-07-10 ${timeslot?.startTime}:00+00:00`), 'HH:mm')
                const [hour, min] = timeslot.startTime.split(':')
                timeslot.startTimeInMinutes = Number(hour) * 60 + Number(min)
                timeslot.endTimeUTC = timeslot?.endTime
                timeslot.endTime = moment.utc(`${currentDate} ${timeslot?.endTime}`).local().format('HH:mm')
                // timeslot.endTime = format(new Date(`2023-07-10 ${timeslot?.endTime}:00+00:00`), 'HH:mm')
            })

            data.timeList?.sort(function (a: any, b: any) {
                return a?.startTimeInMinutes - b?.startTimeInMinutes
            })


            const groupedList = Array(data.timeList)

            setTimeSlots(groupedList)
        } catch (error) { }
    }, [api.AppInfo])

    React.useEffect(() => {
        if (role !== 'Teacher') {
            router.push({ pathname: '/' })
            return
        }

        getAvailableTimes()
        getTimeSlots()
    }, [getAvailableTimes, getTimeSlots, role, router])

    React.useEffect(() => {
        const selectedTimes =
            availableTimes[format(selectedDate, 'yyyy-MM-dd')]?.map(p => `${format(new Date(p.startTime), 'HH:mm')} - ${format(new Date(p.endTime), 'HH:mm')}`) ?? []

        setSelectedTimeRanges(selectedTimes);
    }, [availableTimes, selectedDate])

    const save = async () => {
        setSaveDate(true)
        if (changedTimeRanges.length === 0) return

        const timeRanges = changedTimeRanges.map(r => {
            const [_start, _end] = r.split('-')
            const [_startHour, _startMinute] = _start.split(':')
            const [_endHour, _endMinute] = _end.split(':')

            const startTime = moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(_startHour, 'hours').add(_startMinute, 'minutes').toISOString()
            const endTime = moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(_endHour, 'hours').add(_endMinute, 'minutes').toISOString()

            return { startTime, endTime, isAdded: !selectedTimeRanges.includes(r) }
        })

        try {
            await api.Scheduler.changeMyAvailableTimes([
                {
                    date: moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                    timeRanges: timeRanges,
                },
            ])
            toast.success(t('calendar.success'))
            setChangedTimeRanges([])
            getAvailableTimes()
            getTimeSlots()
        } catch (error) { }
    }

    const toggleInTimeRange = (date: string) => {
        if (selectedTimeRanges.includes(date)) {
            setSelectedTimeRanges(x => x.filter(r => r !== date))
        } else {
            setSelectedTimeRanges(x => [...x, date])
        }

        if (changedTimeRanges.includes(date)) {
            setChangedTimeRanges(x => x.filter(r => r !== date))
        } else {
            setChangedTimeRanges(x => [...x, date])
        }
        setSaveDate(false)
    }

    const setConfirmationModalStatus = (key: string) => {
        setOpenConfirmationModal(true)
        setDayAvailabilityKey(key)
    }

    const deleteDay = async () => {
        try {
            let timeRanges: SchedulerCommandsSchedulerTimeRange[] = []

            availableTimes[dayAvailabilityKey].map(x => timeRanges.push({ startTime: x.startTime, endTime: x.endTime, isAdded: true }))
            setOpenConfirmationModal(false)

            await api.Scheduler.changeMyAvailableTimes([
                {
                    date: moment(dayAvailabilityKey).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                    timeRanges,
                },
            ])
            toast.success(t('calendar.deleteAvailability'))
            await getAvailableTimes()
        } catch { }
    }

    const duplicate = async (selectedDate: any) => {
        if (!isDuplicating) return

        setLoading(true)

        let timeRanges: SchedulerCommandsSchedulerTimeRange[] = []

        availableTimes[format(new Date(isDuplicating), 'yyyy-MM-dd')].map(x => {
            const startDate = new Date(x.startTime)
            const endDate = new Date(x.endTime)
            timeRanges.push({
                startTime: moment(selectedDate)
                    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                    .add(startDate.getHours(), 'hours')
                    .add(startDate.getMinutes(), 'minutes')
                    .toISOString(),
                endTime: moment(selectedDate)
                    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                    .add(endDate.getHours(), 'hours')
                    .add(endDate.getMinutes(), 'minutes')
                    .toISOString(),
                isAdded: false,
            })
        })

        // const startTime = moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(_startHour, 'hours').add(_startMinute, 'minutes').toISOString()
        //     const endTime = moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(_endHour, 'hours').add(_endMinute, 'minutes').toISOString()

        try {
            await api.Scheduler.changeMyAvailableTimes([
                {
                    date: moment(selectedDate).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                    timeRanges: timeRanges,
                },
            ])

            setIsDuplicating(undefined)
        } catch { }

        setLoading(false)
    }

    if (role !== 'Teacher') {
        router.replace({ pathname: '/' })
        return <></>
    }

    return (
        <Master title={t('navigation.calendar')}>
            {openConfirmationModal ? (
                <ConfirmModalCard
                    title={t('calendar.title')}
                    description={t('calendar.description')}
                    confirmButtonText={t('calendar.confirm')}
                    cancelButtonText={t('calendar.cancel')}
                    isVisible={true}
                    onModalClose={() => setOpenConfirmationModal(false)}
                    onConfirmButtonClick={() => { deleteDay() }}
                    onCancelButtonClick={() => setOpenConfirmationModal(false)}
                    loading={loading}
                />
            ) : null}
            <CalendarTemplate>
                <PageHeading title={t('calendar.update')} />

                <div>
                    <Card paddingles>
                        {Object.keys(availableTimes).length === 0 && (
                            <div className={styles.infoCard}>
                                <Badge>{t('calendar.informationHeader')}</Badge>
                                <Text type="dimmed-light">{t('calendar.informationText')}</Text>
                            </div>
                        )}

                        {Object.keys(availableTimes).length > 0 && (
                            <div className={styles.infoCard}>
                                <Badge>{t('calendar.availability')}</Badge>

                                <div className={styles.currentTimes}>
                                    {Object.keys(availableTimes).map((r, i) => {
                                        const date = new Date(r).toISOString().slice(0, 10)
                                        return (
                                            <div className={styles.currentTime} key={i.toString() + '_availableTime'}>
                                                <div className={styles.currentTimeTop}>
                                                    <Text size="lg" type="default" weight="medium">
                                                        {/* {format(new Date(r), 'dd LLLL yyyy')} */}
                                                        {date}
                                                    </Text>
                                                    <div className={styles.currentTimeActions}>
                                                        <button onClick={() => setIsDuplicating(r)}>
                                                            <FileCopyLine />
                                                        </button>
                                                        <button onClick={() => {
                                                            setConfirmationModalStatus(r)
                                                        }}>
                                                            <DeleteBinLine />
                                                        </button>
                                                    </div>
                                                </div>
                                                <Text size="sm" type="dimmed-light" weight="medium">
                                                    {availableTimes[r]
                                                        .map(p => `${format(new Date(p.startTime), 'HH:mm')} - ${format(new Date(p.endTime), 'HH:mm')}`)
                                                        .join(' • ')
                                                        .substring(0, 32)}
                                                    {availableTimes[r].length > 2 && <>...</>}
                                                </Text>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </Card>
                </div>

                <div>
                    <Calendar
                        label={t('calendar.selectTime')}
                        fromDate={moment().toDate()}
                        toDate={moment().add('14', 'days').toDate()}
                        selectedDate={selectedDate}
                        required
                        onDateSelected={r => {
                            if (r) setSelectedDate(r)
                            console.log(selectedDate.getDate());
                        }}
                    />
                </div>

                <div>
                    <SectionHeading>{format(selectedDate, 'PPPP', FormatLocale())}</SectionHeading>

                    {/* 15 min difference time slots */}

                    {/* <div className={styles.timetable}>
                        {[...new Array(24).keys()].map(r => {
                            return [...new Array(4).keys()].map(p => {
                                const selectedFullDate = moment(selectedDate).add(`${pad(r)}:${pad(p * 15)}`, 'hours')

                                if (selectedFullDate.isSameOrBefore(moment().add(15, 'minutes'))) {
                                    return undefined
                                }

                                const start = `${pad(r)}:${pad(p * 15)}`
                                const end = `${pad(r + (p * 15 + 15 === 60 ? 1 : 0))}:${pad(p * 15 + 15 === 60 ? 0 : p * 15 + 15)}`
                                const full = `${start} - ${end}`
                                return <TimeRangePicker key={`${r}-${p}`} isSelected={selectedTimeRanges.includes(full)} onSelect={() => toggleInTimeRange(full)} label={full}/>
                            })
                        })}
                    </div> */}

                    {/* 40 min difference time slots */}
                    {/* 
                    <div className={styles.timetable}>
                        {[...new Array(24).keys()].map(r => {
                            return [...new Array(3).keys()].map(p => {
                                const selectedFullDate = moment(selectedDate).add(`${pad(r)}:${pad(p * 20)}`, 'hours')

                                if (selectedFullDate.isSameOrBefore(moment().add(40, 'minutes'))) {
                                    return undefined
                                }

                                const start = `${pad(r)}:${pad(p * 20)}`
                                const end = `${pad(r + (p === 0 ? 0 : 1))}:${pad(p * 20 + 40 === 40 ? 40 : p * 20 + 40 === 60 ? 0 : 20)}`
                                if (tmp % 2) {
                                    tmp = tmp + 1
                                    return undefined
                                } else {
                                    tmp = tmp + 1
                                    const full = `${start} - ${end}`
                                    return (
                                        <TimeRangePicker key={`${r}-${p}`} isSelected={selectedTimeRanges.includes(full)} onSelect={() => toggleInTimeRange(full)} label={full} />
                                    )
                                }
                            })
                        })}
                    </div> */}
                    <div className={styles.timetable}>
                        {timeSlots.map(p => {
                            return p.map((r: any) => {

                                var newDate = new Date();
                                var AddCurrentTime = new Date(newDate.getTime() + 40 * 60000);
                                var currentTime = AddCurrentTime.getHours() * 60 + AddCurrentTime.getMinutes();
                                // var currentTime = today.setTime(today.getTime() + 40*60000);
                                // time = today.getHours() + ':' + today.getMinutes();
                                // var currentTime = moment(time).add(40, 'minutes');
                                var currentDate = moment().format('YYYY-MM-DD')
                                var selectedDateConverted = moment(selectedDate).format('YYYY-MM-DD')
                                if (currentDate == selectedDateConverted) {
                                    if (r.startTimeInMinutes < currentTime) {
                                        return undefined
                                    }
                                }
                                if (r.startTime > "23:20" && !timeSlotExist) {
                                    return null
                                }
                                const start = r.startTime
                                const end = r.endTime
                                tmp = tmp + 1
                                const full = `${start} - ${end}`
                                return (
                                    <TimeRangePicker
                                        key={`${r.startTime}-${r.endTime}`}
                                        isSelected={selectedTimeRanges.includes(full)}
                                        onSelect={() => toggleInTimeRange(full)}
                                        label={full}
                                    />
                                )
                            })
                        })}

                    </div>

                    <div className={styles.calendarActions}>
                        {/*<Button type="dimmed" variation="wide">*/}
                        {/*    {t('calendar.cancel')}*/}
                        {/*</Button>*/}
                        <Button onClick={save} disabled={changedTimeRanges.length === 0 || saveDate} type="gradient" variation="wide">
                            {t('calendar.save')}
                        </Button>
                    </div>
                </div>

                <CalendarModalCard
                    loading={loading}
                    selectedDate={isDuplicating ? new Date(isDuplicating) : undefined}
                    isVisible={!!isDuplicating}
                    onModalClose={() => setIsDuplicating(undefined)}
                    onDateSelected={duplicate}
                />
            </CalendarTemplate>
        </Master>
    )
}

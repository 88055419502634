import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import {useTranslation} from 'react-i18next'
import {ArrowLeftSLine} from 'components/icons'
import Input, {TextChange} from 'components/atoms/input'
import Modal, {ModalSize, ModalType} from 'components/atoms/modal'

import styles from './style.module.css'

export type ReportUserModalCardProps = {
    isVisible: boolean
    onModalClose: () => void
    onCancelButtonClick: () => void
    onReportButtonClick: () => void
    onChangeText: TextChange
    onBackButtonClick: () => void
}

const ReportUserModalCard = ({isVisible, onModalClose, onReportButtonClick, onCancelButtonClick, onBackButtonClick, onChangeText}: ReportUserModalCardProps) => {
    const {t} = useTranslation('organisms')

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onCancelButtonClickHandler = () => {
        if (typeof onCancelButtonClick === 'function') {
            onCancelButtonClick()
        }
    }

    const onReportButtonClickHandler = () => {
        if (typeof onReportButtonClick === 'function') {
            onReportButtonClick()
        }
    }

    const onChangeTextHandler = (text: string) => {
        if (typeof onChangeText === 'function') {
            onChangeText(text)
        }
    }

    const onBackButtonClickHandler = () => {
        if (typeof onBackButtonClick === 'function') {
            onBackButtonClick()
        }
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} size={ModalSize.Small} type={ModalType.ActionSheet}>
            <div className={styles.container}>
                <Card
                    actionSheet
                    heading={{
                        title: t('reportUserModalCard.title'),
                        size: 'md',
                        divider: true,
                        leading: <ArrowLeftSLine className={styles.arrowLeft} onClick={onBackButtonClickHandler}/>,
                    }}
                >
                    <div className={styles.headerWrapper}>
                        <Text size={'sm'} type={'default'} weight={'bold'}>
                            {t('reportUserModalCard.header')}
                        </Text>

                        <Text size={'sm'} type={'dimmed-light'} weight={'medium'}>
                            {t('reportUserModalCard.optional')}
                        </Text>
                    </div>

                    <Input multiline style={{height: 123}} onChangeText={onChangeTextHandler} max={1000} maxCharCount={1000} maxLength={1000} label={t('reportUserModalCard.placeholder')}/>

                    <div className={styles.descriptionWrapper}>
                        <Text size={'sm'} type={'dimmed-light'} weight={'medium'}>
                            {t('reportUserModalCard.description')}
                        </Text>
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'dimmed'} onClick={onCancelButtonClickHandler}>
                            {t('reportUserModalCard.cancel')}
                        </Button>
                        <Button size={'large'} type={'gradient'} onClick={onReportButtonClickHandler}>
                            {t('reportUserModalCard.report')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ReportUserModalCard

import * as React from 'react'
import {useTranslation} from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorTemplate from 'components/templates/error'
import ErrorCard, {ErrorCardProps} from 'components/organisms/error-card'
import {HelmetProvider} from 'react-helmet-async'
import {Theme} from 'providers/theme'

export default function Error({statusCode, initProviders}: { statusCode?: 401 | 404 | 403 | 426 | 503 | 500 , initProviders?: boolean }) {
    const {t} = useTranslation('organisms')

    const [type, setType] = React.useState<ErrorCardProps['type']>("404") //Initial 404 (Not Found) Page
    const [title, setTitle] = React.useState<string>(t('error.404')) //Initial 404 (Not Found) Page

    React.useEffect(() => {
        switch (statusCode) {
            case 401:
                // Not Found
                setType("unauthorized")
                setTitle(t('error.unauthorized'))
                break
            case 404:
                // Not Found
                setType("404")
                setTitle(t('error.404'))
                break
            case 403:
                // Banned User
                setType("banned")
                setTitle(t('error.banned'))
                break
            case 426:
                // Banned User
                setType("updateRequired")
                setTitle(t('update-needed'))
                break
            case 503:
                // Server In Maintenance
                setType("maintenance")
                setTitle(t('error.maintenance'))
                break
            case 500:
                // System Unavailable
                setType("500")
                setTitle(t('error.500'))
                break
        }
    }, [statusCode, t])

    const Providers = (props: any) => {
        if (initProviders) {
            return (
                <HelmetProvider>
                    <Theme>
                        {props.children}
                    </Theme>
                </HelmetProvider>
            )
        }
        return <>{props.children}</>
    }

    return (
        <Providers>
            <Master title={title}>
                <ErrorTemplate>
                    <ErrorCard type={type}/>
                </ErrorTemplate>
            </Master>
        </Providers>
    )
}

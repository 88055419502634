import * as React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ArrowDownSLine, ArrowUpSLine } from 'components/icons'

import styles from './style.module.css'

export interface StatusChanged {
    (isActive: boolean): void
}

type AccordionCardHeadingProps = {
    title: string
    subtitle?: string
    isActive: boolean
    isIndicatorShown?: boolean
    onChange: StatusChanged
}

const propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    isIndicatorShown: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

const defaultProps = {
    isActive: false,
    isIndicatorShown: false,
}

const AccordionCardHeading = ({ title, subtitle, isActive, isIndicatorShown, onChange }: AccordionCardHeadingProps) => {
    const onClickHandler = () => {
        onChange(!isActive)
    }

    return (
        <div
            onClick={onClickHandler}
            className={classNames(styles.wrapper, {
                [styles.inactive]: !isActive,
            })}
        >
            <div className={styles.body}>
                <div className={styles.title}>
                    {isIndicatorShown && <div className={styles.indicator} />}
                    {title}
                </div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
            <div className={styles.dropDownIcon}>{isActive ? <ArrowUpSLine /> : <ArrowDownSLine />}</div>
        </div>
    )
}

AccordionCardHeading.propTypes = propTypes
AccordionCardHeading.defaultProps = defaultProps

export default AccordionCardHeading

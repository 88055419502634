import * as React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Card from 'components/molecules/card'
import FavoriteButton from 'components/atoms/favorite-button'
import { getCoverPhoto, YouTubeGetID } from 'utils/youtube-helpers'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import { WordTutorialCategoryQueriesWordTutorial as Word } from '@mohsininsignia/advancerapiclient'
import { WordTutorialCategoryQueriesWordTutorialCategoryInList as Category } from '@mohsininsignia/advancerapiclient/models/word-tutorial-category-queries-word-tutorial-category-in-list'

import styles from './style.module.css'

export default function WordCard({
    word,
    category,
    onFavoriteChange,
    loading,
}: {
    word: any
    category: Category
    onFavoriteChange: (wordId: number, isFavorite: boolean) => void
    loading: boolean
}) {
    return (
        <Card>
            <Link to={`/word-tutorials/${category.slug}?selected=${word.slug}`}>
                <div>
                    <ImageWithFallback
                        fallbackSrc="/img/img-fallback.png"
                        className={styles.image}
                        // src={getCoverPhoto(YouTubeGetID(word.youtubeVideoUrl) ?? '')}
                        src={word?.youtubeVideoId ? getCoverPhoto(word?.youtubeVideoId) : getCoverPhoto(YouTubeGetID(word.youtubeVideoUrl) ?? '')}
                        alt={word.slug}
                    />
                </div>
            </Link>
            <div className={classnames(styles.body)}>
                <FavoriteButton favorited={word.isFavorited} onChange={fav => onFavoriteChange(word.id, fav)} loading={loading} />
                <Link to={`/word-tutorials/${category.slug}?selected=${word.slug}`}>
                    <div className={styles.translations}>
                        {word.translations.slice(0, 2).map((r: any, i: any) => (
                            <div
                                key={i.toString()}
                                className={classnames(styles.translation, {
                                    [styles.translation1]: i === 0,
                                    [styles.translation2]: i === 1,
                                })}
                            >
                                {r.content}
                            </div>
                        ))}
                    </div>
                </Link>
            </div>
        </Card>
    )
}

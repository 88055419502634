import atoms from './atoms.json'
import common from './common.json'
import molecules from './molecules.json'
import organisms from './organisms.json'

const en = {
    en: {
        atoms,
        common,
        molecules,
        organisms,
    },
}

export default en

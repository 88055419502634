import * as React from 'react'
import ReactDOM from 'react-dom'
import useApi from 'hooks/useApi'
import useChat from 'hooks/useChat'
import useAuth from 'hooks/useAuth'
import useMedia from 'hooks/useMedia'
import Input from 'components/atoms/input'
import ErrorBox from '../../atoms/error-box'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import { ChatUser } from 'types/chatProvider'
import { SendPlane2Line } from 'components/icons'
import useFlashTitle from 'hooks/useFlashTitle'
import LoadingBox from '../../atoms/loading-box'
import ReportUserModalCard from '../report-user-modal-card'
import InfiniteScroll from 'react-infinite-scroll-component'
import ConversationBubble from 'components/atoms/conversation-bubble'
import ConversationHeading from 'components/molecules/conversation-heading'
import { MessageReceivedDataWithUserMetadata } from '@fosh/chat-client/FoshChatClient.Types'

import styles from './style.module.css'

const ChatMessages: React.FC = () => {
    const { t } = useTranslation('common')

    const chat = useChat()
    const auth = useAuth()
    const api = useApi()
    const flashTitle = useFlashTitle()

    const inputRef = React.useRef<HTMLInputElement>(null)
    const scroller = React.useRef<HTMLDivElement | null>(null)
    const [messageBody, setMessageBody] = React.useState('')
    const [chatWindowVisible, setChatWindowVisible] = React.useState(true)
    const [otherUser, setOtherUser] = React.useState<ChatUser | null>(null)
    const [isUserBlocked, setUserBlocked] = React.useState(false)
    const [isUserBlockedMe, setUserBlockedMe] = React.useState(false)
    const isMobileOrTablet = useMedia('(max-width: 1024px)')

    const [reportModalCard, setReportModalCard] = React.useState(false)
    const [reportText, setReportText] = React.useState('')

    const [userGesture, setUserGesture] = React.useState(false)

    const audio = React.useRef(new Audio('/sound/message-tone.mp3'))

    const onMessageReceived = React.useCallback(
        (message: MessageReceivedDataWithUserMetadata<ChatUser | null>) => {
            scroller.current?.scrollTo({ top: 0 })

            if (message.senderId !== auth.user?.guid && !isUserBlocked) {
                flashTitle.on(`${message.user?.visibleName} ${t('message')}`, 1000, 5000)
                if (audio.current && userGesture) {
                    audio.current?.play()
                }
            }
        },
        [auth.user?.guid, flashTitle, t, userGesture, isUserBlocked]
    )

    const sendMessage = React.useCallback(() => {
        if (!chat.state?.openedConversation || !messageBody.trim().length) return
        chat.methods?.sendMessageToConversation?.(chat.state?.openedConversation.conversationId, messageBody)
        setMessageBody('')
        scroller.current?.scrollTo({ top: 0 })
    }, [chat.methods, chat.state?.openedConversation, messageBody])

    // User Gesture Handler
    React.useEffect(() => {
        const unMuted = () => {
            setUserGesture(true)
            if (audio.current) {
                audio.current.muted = false
            }
        }

        document.addEventListener('click', unMuted)
        return () => {
            document.removeEventListener('click', unMuted)
        }
    }, [])

    //Message Receiver
    React.useEffect(() => {
        chat.client?.Events.on('messageReceived', onMessageReceived)
        return () => {
            chat.client?.Events.off('messageReceived', onMessageReceived)
        }
    }, [chat, onMessageReceived])

    // Opened Conversation Change Handler
    React.useEffect(() => {
        scroller.current?.scrollTo({ top: 0 })
    }, [chat.state?.openedConversation?.conversationId])

    // Other User Handler
    React.useEffect(() => {
        const other = chat.state?.openedConversation?.userIds.find(x => x.userId !== auth.user?.guid)?.user ?? null
        setOtherUser(other)
        other && setUserBlocked(other.isBanned)
        other && setUserBlockedMe(other.didUserBannedMe)
    }, [auth.user?.guid, chat.state?.openedConversation?.userIds])

    const conversationDelete = () => {
        if (chat.state?.openedConversation?.conversationId) {
            chat.methods?.deleteConversation(chat.state?.openedConversation?.conversationId)
        }
    }

    const userBlocked = async () => {
        if (otherUser) {
            try {
                switch (auth.user?.userRole) {
                    case 'Student':
                        if (isUserBlocked) {
                            await api.Teacher.unblockTeacher(otherUser?.userId)
                        } else {
                            await api.Teacher.blockTeacher(otherUser?.userId)
                        }
                        break
                    case 'Teacher':
                        if (isUserBlocked) {
                            await api.Student.unblockStudent(otherUser?.userId)
                        } else {
                            await api.Student.blockStudent(otherUser?.userId)
                        }
                        break
                }
            } catch {}
        }
        setUserBlocked(x => !x)
    }

    const userReported = async () => {
        if (otherUser) {
            try {
                switch (auth.user?.userRole) {
                    case 'Student':
                        await api.Teacher.reportTeacher(otherUser?.userId, reportText)
                        break
                    case 'Teacher':
                        await api.Student.reportStudent(otherUser?.userId, reportText)
                        break
                }
            } catch {}
        }

        setReportText('')
        setReportModalCard(false)
    }

    const userClick = () => {
        setChatWindowVisible(true)
    }

    React.useEffect(() => {
        if (isMobileOrTablet) {
            setChatWindowVisible(true)
        }
    }, [isMobileOrTablet])

    const messages = React.useMemo(() => {
        if (isUserBlocked) {
            return (
                <div className={styles.blockBoxWrapper}>
                    <ErrorBox size={'sm'} type={'gray'} text={t('chatMessages.block')} />
                </div>
            )
        }

        if (isUserBlockedMe) {
            return (
                <div className={styles.blockBoxWrapper}>
                    <ErrorBox size={'sm'} type={'gray'} text={t('chatMessages.blockMe')} />
                </div>
            )
        }

        return (
            <InfiniteScroll
                scrollableTarget="scrollableDiv"
                className={styles.infiniteScroll}
                next={() => chat.methods?.loadPastMessages()}
                hasMore={chat.state?.isMoreMessagesAvailableInOpenedConversation ?? false}
                loader={<LoadingBox />}
                dataLength={chat.state?.messagesOfOpenedConversation?.length ?? 0}
                initialScrollY={0}
                inverse
            >
                {chat.state?.messagesOfOpenedConversation?.map(item => {
                    return (
                        <div key={item.messageId + '_message'}>
                            <ConversationBubble alignToRight={chat.state?.decodedJwt?.nameid === item.user?.id} message={item.message} time={item.sentAt} />
                        </div>
                    )
                })}
            </InfiniteScroll>
        )
    }, [
        isUserBlockedMe,
        isUserBlocked,
        chat.state?.isMoreMessagesAvailableInOpenedConversation,
        chat.state?.messagesOfOpenedConversation,
        chat.state?.decodedJwt?.nameid,
        chat.methods,
        t,
    ])

    const messagesArea = (
        <>
            {chat.state?.openedConversation && (
                <div className={styles.wrapper}>
                    <div className={styles.window}>
                        <ConversationHeading
                            onMinimizeClick={() => setChatWindowVisible(r => !r)}
                            onCloseClick={chat.methods?.closeConversation}
                            avatarUrl={otherUser?.avatar1X ?? ''}
                            userStatus={chat.state.isOtherUserOnline ? 'online' : 'offline'}
                            userName={otherUser?.visibleName ?? ''}
                            onConversationDeleted={conversationDelete}
                            onUserBlocked={userBlocked}
                            onUserReported={() => setReportModalCard(true)}
                            onUserClick={userClick}
                            isUserBlocked={isUserBlocked}
                        />
                        {chatWindowVisible && (
                            <div className={styles.messages}>
                                <div className={styles.scroller} id="scrollableDiv" ref={scroller}>
                                    {messages}
                                </div>

                                <div className={styles.input}>
                                    <Input
                                        value={messageBody}
                                        onChangeText={setMessageBody}
                                        placeholder={t('type-something')}
                                        enterKeyPress={sendMessage}
                                        ref={inputRef}
                                        disabled={isUserBlocked || isUserBlockedMe}
                                        trailingComponent={
                                            <div className={styles.button}>
                                                <Button onClick={sendMessage} type="gradient" variation="circle" disabled={isUserBlocked || isUserBlockedMe}>
                                                    <SendPlane2Line width={18} height={18} />
                                                </Button>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <ReportUserModalCard
                isVisible={reportModalCard}
                onModalClose={() => setReportModalCard(false)}
                onCancelButtonClick={() => setReportModalCard(false)}
                onReportButtonClick={userReported}
                onChangeText={setReportText}
                onBackButtonClick={() => setReportModalCard(false)}
            />
        </>
    )

    return ReactDOM.createPortal(messagesArea, document.getElementById('chat-root') as HTMLElement)
}

export default ChatMessages

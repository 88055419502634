import * as React from 'react'
import useApi from 'hooks/useApi'
import classNames from 'classnames'
import useAuth from 'hooks/useAuth'
import { isUndefined } from 'lodash'
import { toast } from 'react-toastify'
import Input from 'components/atoms/input'
import Button from 'components/atoms/button'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import PageInfo from 'components/atoms/page-info'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import PageHeading from 'components/molecules/page-heading'
import SegmentedCard from 'components/molecules/segmented-card'
import ProfileNavigation from 'components/organisms/profile-navigation'
import { TeacherQueriesMyEarningsQueryResult } from '@mohsininsignia/advancerapiclient'

import ProfileTemplate, { styles } from 'components/templates/profile'

export default function Earnings() {
    const { t } = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [earnings, setEarnings] = React.useState<TeacherQueriesMyEarningsQueryResult | undefined>(undefined)

    const [loading, setLoading] = React.useState<boolean>(false)
    const [pageLoading, setPageLoading] = React.useState<boolean>(true)

    const [bankName, setBankName] = React.useState<string | null | undefined>('')
    const [bankIban, setBankIban] = React.useState<string | null | undefined>('')
    const [bankAccountHolderName, setBankAccountHolderName] = React.useState<string | null | undefined>('')

    const [ibanCheck, setIbanCheck] = React.useState(false)

    const onChangeTextIban = (iban?: string) => {
        if (!isUndefined(iban)) {
            setBankIban(iban.replace(/ /g, ''))
        }
    }

    const saveHandler = async () => {
        setLoading(true)
        setBankIban(bankIban)
        try {
            await api.Teacher.changeMyIbanNumber(bankName ?? undefined, bankAccountHolderName ?? undefined, bankIban ?? undefined)
            toast.success(t('toast.success'))

            if (auth.user && auth.user.userRole === 'Teacher') {
                auth.setUser?.({ ...auth.user, bankName, bankIban, bankAccountHolderName })
            }
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    const cancelHandler = () => {
        if (auth.user?.userRole === 'Teacher') {
            setBankName(auth.user.bankName)
            setBankIban(auth.user.bankIban)
            setBankAccountHolderName(auth.user.bankAccountHolderName)
        }
    }

    const changeHandler = React.useMemo(() => {
        if (auth.user?.userRole === 'Teacher') {
            if (bankName !== '' && bankName !== auth.user.bankName) return true
            if (bankIban !== '' && bankIban !== auth.user.bankIban) return true
            if (bankAccountHolderName !== '' && bankAccountHolderName !== auth.user.bankAccountHolderName) return true
        }

        return false
    }, [auth.user, bankName, bankIban, bankAccountHolderName])

    React.useEffect(() => {
        ; (async () => {
            setPageLoading(true)

            if (auth.user?.userRole === 'Teacher') {
                setBankIban(auth.user.bankIban ?? undefined)
                setBankName(auth.user.bankName ?? undefined)
                setBankAccountHolderName(auth.user.bankAccountHolderName ?? undefined)
            }

            setPageLoading(false)
        })()
    }, [auth.user, router])

    React.useEffect(() => {
        ; (async () => {
            setPageLoading(true)

            try {
                const { data } = await api.Teacher.myEarnings()
                setEarnings(data)
            } catch (e) {
                console.log(e)
            }

            setPageLoading(false)
        })()
    }, [api.Teacher])

    if (!auth.isLoggedIn) {
        router.push({ pathname: '/profile' })
        return <></>
    }

    return (
        <Master title={`${t('earnings.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation />

                <div>
                    <div className={styles.list}>
                        <PageHeading title={t('earnings.title')} />
                        {earnings && (
                            <SegmentedCard totalLessonMinutes={earnings.totalMinutes} monthlyEarnings={earnings.last30DaysEarnings} totalEarnings={earnings.totalEarnings} totalHours={earnings.totalHours} />
                        )}

                        {!pageLoading && (
                            <div className={classNames(styles['result-grid'])}>
                                <div>
                                    <PageInfo>{t('earnings.editIBAN')}</PageInfo>

                                    <div className={styles['margin-grid']}>
                                        <Input label={t('earnings.bankName')} value={bankName ?? undefined} onChangeText={setBankName} />
                                        <Input label={t('earnings.bankAccountHolderName')} value={bankAccountHolderName ?? undefined} onChangeText={setBankAccountHolderName} />
                                        <ErrorBox text={t('earnings.mustBeSameName')} size={'sm'} align={'left'} />
                                        <Input
                                            label={t('earnings.bankIban')}
                                            value={bankIban ?? undefined}
                                            onChangeText={onChangeTextIban}
                                            regexType={'iban'}
                                            onRegexFailed={setIbanCheck}
                                            status={bankIban === '' || bankIban == null || ibanCheck ? 'default' : 'danger'}
                                        />
                                    </div>

                                    <div className={styles['buttons']}>
                                        <Button onClick={cancelHandler} loading={loading} disabled={!changeHandler}>
                                            {t('earnings.cancel')}
                                        </Button>
                                        <Button onClick={saveHandler} type={'gradient'} loading={loading} disabled={!changeHandler || !ibanCheck}>
                                            {t('earnings.save')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {pageLoading && <LoadingBox />}
                    </div>
                </div>
            </ProfileTemplate>
        </Master>
    )
}

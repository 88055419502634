import * as React from 'react'
import {isNumber} from 'lodash'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import {getUserRole} from 'utils/auth'
import Input from 'components/atoms/input'
import useDebounce from 'hooks/useDebounce'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Search2Line} from 'components/icons'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import PageHeading from 'components/molecules/page-heading'
import InfiniteScroll from 'react-infinite-scroll-component'
import TeacherInfoCard from 'components/organisms/teacher-info-card'
import ProfileTemplate, {styles} from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import {TeacherQueriesTeacherListItem as Teacher} from '@mohsininsignia/advancerapiclient'
import {TeacherQueriesListFavoriteTeachersPagination as Pagination} from '@mohsininsignia/advancerapiclient/models/teacher-queries-list-favorite-teachers-pagination'

export default function FavoriteTeachers() {
    const {t} = useTranslation('common')

    const api = useApi()
    const role = getUserRole()
    const router = useHistory()

    const [teachers, setTeachers] = React.useState<Teacher[]>([])
    const [pagination, setPagination] = React.useState<Pagination>({isNextPageAvailable: false})
    const [loading, setLoading] = React.useState<boolean>(true)

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    React.useEffect(() => {
            (async () => {
                setLoading(true)
                try {
                    const {data} = await api.Teacher.listMyFavoriteTeachers(pagination.lastTeacherId ?? undefined, debouncedQuery.trim() !== '' ? debouncedQuery : undefined)

                    setTeachers(data.teachers)
                    setPagination(data.pagination)
                } catch {
                }
                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [api.Teacher, debouncedQuery])

    const paginate = async () => {
        if (!pagination.isNextPageAvailable || !isNumber(pagination.lastTeacherId) || teachers.length === 0 || loading) return

        try {
            const {data} = await api.Teacher.listMyFavoriteTeachers(pagination.lastTeacherId, query)

            setTeachers([...teachers, ...data.teachers])
            setPagination(data.pagination)
        } catch {
        }
    }

    if (role !== 'Student') {
        router.push({pathname: '/profile'})
        return <></>
    }

    return (
        <Master title={`${t('favoriteTeachers.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                <div>
                    <div className={styles.list}>
                        <PageHeading
                            title={t('favoriteTeachers.title')}
                            trailing={<Input onChangeText={setQuery}
                                             leadingComponent={<Search2Line className={classnames(styles.searchIcon, {[styles.searched]: query.length > 0})}/>}
                                             placeholder={t('favoriteTeachers.search')}/>}
                        />

                        {loading && <LoadingBox/>}

                        {!loading && !teachers.length && <ErrorBox text={t('favoriteTeachers.notFound')}/>}

                        {!loading && (
                            <InfiniteScroll next={paginate} hasMore={pagination.isNextPageAvailable} loader={<LoadingBox/>} dataLength={teachers.length}>
                                <div className={classnames(styles['result-grid'])}>
                                    {teachers.map((r) => (
                                        <TeacherInfoCard arrangedButtons={true} teacher={r} key={r.teacherId + '_teacher'}/>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </ProfileTemplate>
        </Master>
    )
}

import * as React from 'react'
import classNames from 'classnames'
import useDebounce from 'hooks/useDebounce'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Input, { TextChange } from 'components/atoms/input'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { WordTutorialCategoryQueriesSearchWordTutorialsPagination as WordTutorialPagination, WordTutorialCategoryQueriesWordTutorial as Word } from '@mohsininsignia/advancerapiclient'
import { ArrowRightSLine, CheckboxCircleFill, CloseLine, Loader2Line, Search2Line } from 'components/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingBox from '../../atoms/loading-box'
import ErrorBox from '../../atoms/error-box'
import classnames from 'classnames'

import styles from './style.module.css'

export type WordListCardProps = {
    initialQuery?: string
    words: Word[]
    loading: boolean
    isVisible: boolean
    onClose: () => void
    onSelect: (word: Word) => void
    onChangeText: TextChange
    onPageEnd: () => void
    pagination: WordTutorialPagination
}

const WordListCard = ({ initialQuery, words, loading, isVisible, onClose, onSelect, onChangeText, onPageEnd, pagination }: WordListCardProps) => {
    const { t } = useTranslation('organisms')

    const [query, setQuery] = React.useState<string>(initialQuery ?? '')
    const debouncedQuery = useDebounce<string>(query, 500)

    const onChangeTextRef = React.useRef(onChangeText)

    React.useEffect(() => {
        if (debouncedQuery !== '' && typeof onChangeTextRef.current === 'function') {
            onChangeTextRef.current(debouncedQuery)
        }
    }, [debouncedQuery])

    const Row = ({ item }: { item: Word }) => {
        return (
            <div className={styles.item} onClick={() => onSelect(item)}>
                <CheckboxCircleFill className={classNames(styles.icon, { [styles.favorited]: item.isFavorited })} />
                <div className={styles.itemBody}>{item.slug}</div>
                <ArrowRightSLine />
            </div>
        )
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onClose} size={ModalSize.Small} type={ModalType.ActionSheet}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('wordListCard.title'),
                        size: 'lg',
                        trailing: <CloseLine className={styles.closeLine} onClick={onClose} />,
                    }}
                    type={'darken'}
                    paddingles
                >
                    <div className={styles.wrapper}>
                        <Input
                            value={query}
                            onChangeText={setQuery}
                            leadingComponent={<Search2Line className={classnames({ [styles.searched]: query.length > 0 })} />}
                            trailingComponent={
                                loading ? (
                                    <div className={styles.loader}>
                                        <Loader2Line />
                                    </div>
                                ) : null
                            }
                            placeholder={t('wordListCard.search')}
                        />

                        <div className={styles.listWrapper}>
                            <div className={styles.listBackground} />

                            <InfiniteScroll
                                next={onPageEnd}
                                hasMore={pagination.isNextPageAvailable}
                                loader={<LoadingBox />}
                                dataLength={words.length}
                                className={styles.list}
                                height={debouncedQuery !== '' ? '21rem' : 0}
                            >
                                {!loading && words.length === 0 && debouncedQuery !== '' && <ErrorBox text={t('wordListCard.notFound')} />}
                                <div>
                                    {words.map(r => (
                                        <Row key={r.id} item={r} />
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </div>

                        {words.length === 0 && debouncedQuery !== '' && !loading && <div className={styles.noResult}>{t('wordListCard.noResult')}</div>}

                        <div className={styles.actions}>
                            <Button onClick={onClose} variation="wide">
                                {t('wordListCard.close')}
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default WordListCard

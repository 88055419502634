import * as React from 'react'
import { CSSProperties } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import { StarSmileLine } from 'components/icons'

import styles from './style.module.css'

type ProgressBarProps = {
    total: number
    count: number
}

const propTypes = {
    total: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
}

const ProgressBar = ({ count, total }: ProgressBarProps) => {
    const { t } = useTranslation('atoms')
    const isMobile = useMedia('(max-width: 640px)')
    const isTablet = useMedia('(max-width: 1024px) and (min-width: 640px)')
    const percentage = (count / total) * 100

    let calculatedProgressStyles: CSSProperties = { height: '100%', width: percentage + '%' }
    if (!isMobile && !isTablet) {
        calculatedProgressStyles = { width: '100%', height: percentage + '%' }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.progressWrapper}>
                <div className={styles.progress} style={calculatedProgressStyles} />
            </div>
            <div className={styles.starAndCountWrapper}>
                <div className={styles.count}>
                    {total}
                    {!isMobile ? t('word') : undefined}
                </div>
                <div className={styles.starWrapper}>
                    <StarSmileLine className={styles.star} />
                </div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = propTypes

export default ProgressBar

import * as React from 'react'
import { isNumber } from 'lodash'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import PageHeading from 'components/molecules/page-heading'
import InfiniteScroll from 'react-infinite-scroll-component'
import AppointmentCard from 'components/molecules/appointment-card'
import ProfileTemplate, { styles } from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import { AppointmentQueriesAppointmentListItem as Appointment, AppointmentQueriesListAppointmentsPagination as Pagination } from '@mohsininsignia/advancerapiclient'
import PageInfo from 'components/atoms/page-info'






export default function Appointments() {
    const { t } = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const router = useHistory()
    const role = getUserRole()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [appointments, setAppointments] = React.useState<Appointment[]>([])
    const [previousAppointments, setPreviousAppointments] = React.useState<Appointment[]>([])
    const [pagination, setPagination] = React.useState<Pagination>({ isNextPageAvailable: false })




    
    React.useEffect(() => {
        const hasTimePassed=(targetTime:any)=> {
            // Parse the given time string to a Date object
            const targetDate = new Date(targetTime);
            
            // Get the current date and time
            const now = new Date();
            
            // Compare the target date with the current date
            return targetDate < now;
          }
        ;(async () => {
            setLoading(true)
            try {
                const { data } = await api.Appointment.listMyAppointments()
                const upcomingAppointments=await data.appointments.filter((ele:any)=>!hasTimePassed(ele.endDateTime))
                const oldAppointments=await data.appointments.filter((ele:any)=>hasTimePassed(ele.endDateTime))

                // setAppointments(data.appointments)
                setAppointments(upcomingAppointments)
                setPreviousAppointments(oldAppointments)
                setPagination(data.pagination)
            } catch {}
            setLoading(false)
        })()
    }, [api.Appointment])

    const paginate = async () => {
        if (!pagination.isNextPageAvailable || !isNumber(pagination.lastId) || appointments.length === 0) return true

        try {
            if (pagination.isNextPageAvailable && pagination.lastId) {
                const { data } = await api.Appointment.listMyAppointments(pagination.lastId)
                setPagination(data.pagination)
                setAppointments([...appointments, ...data.appointments])
            }
        } catch {}
    }

    const handleCancelAppointment = (appointmentId: number) => {
        setAppointments(x => x.filter(x => x.appointmentId !== appointmentId))
    }

    if (!auth.isLoggedIn) {
        router.push({ pathname: '/profile' })
        return <></>
    }

    return (
        <Master title={`${t('myAppointments.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation />

                <div>
                    {/* <div className={styles.list} style={{minHeight:'0',maxHeight:'82vh', overflowY:'scroll'}}> */}
                    <div className={styles.list}>
                        <PageHeading title={t('myAppointments.title')} />

                        {loading && <LoadingBox />}

                        {!loading && !appointments.length && <ErrorBox text={t('myAppointments.notFound')} />}

                        {!loading && (
                            <InfiniteScroll next={paginate} hasMore={pagination.isNextPageAvailable} loader={<LoadingBox />} dataLength={appointments.length}>
                                <div className={styles.list}>
                                    {appointments.map(r => (
                                        <AppointmentCard as={role} appointment={r} key={r.appointmentId + '_appointment'} onCancelled={handleCancelAppointment} />
                                    ))}
                                </div>
                            </InfiniteScroll>
                        )}
                        {!loading && <hr style={{width:'100%', color:'var("--red")'}}/>}
                        {!loading && <PageInfo>{`${t('myAppointments.completedAppointments')}`}</PageInfo>}
                                        {!loading && (
                        <InfiniteScroll
                            next={paginate}
                            hasMore={pagination.isNextPageAvailable}
                            loader={<LoadingBox />}
                            dataLength={appointments.length}
                        >
                            <div className={styles.list}>
                                {previousAppointments.map(appointment => (
                                    <AppointmentCard
                                        as="Teacher"
                                        appointment={appointment}
                                        key={appointment.appointmentId + '_appointment'}
                                        onCancelled={handleCancelAppointment}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                    </div>
                </div>
            </ProfileTemplate>
        </Master>
    )
}

import React, { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import { useHistory } from 'react-router-dom'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import LoadingBox from 'components/atoms/loading-box'
import PaymentModal from 'components/organisms/payment-modal'
import ProfileTemplate, { styles } from 'components/templates/profile'
import SubscriptionSelection, { SubscriptionOnChange } from 'components/organisms/subscription-selection'
import { PaymentQueriesListDropDownSubscriptionOptionsQueryResult, PaymentQueriesListSubscriptionOptionsQueryResult as Payments } from '@mohsininsignia/advancerapiclient'

const CourseFee = () => {
    const { t } = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [loading, setLoading] = useState(true)
    const [payments, setPayments] = useState<PaymentQueriesListDropDownSubscriptionOptionsQueryResult | any>(undefined)
    const [minutesPerDayPossibilities, setMinutesPerDayPossibilities] = useState<number | undefined>(undefined)
    const [dayPossibilities, setDayPossibilities] = useState<number | undefined>(undefined)
    const [checkedPackage, setCheckedPackage] = useState<number | null | undefined>(null)
    const [disabledSubscription, setDisabledSubscription] = React.useState<boolean>(false)

    useEffect(() => {
        ; (async () => {
            setLoading(true)
            try {
                const data = await api.Payments.listDropDownSubscriptionOptions()
                setPayments(data.data)
            } catch {
                router.replace({ pathname: '/profile' })
            }
            setLoading(false)
        })()
    }, [api.Payments, router])

    const subscriptionChangeHandler = ({ subscriptionId }: SubscriptionOnChange) => {
        setCheckedPackage(subscriptionId)
    }

    useEffect(() => {
        if (dayPossibilities && minutesPerDayPossibilities) {
            setDisabledSubscription(true)
        } else {
            setDisabledSubscription(false)
        }
    }, [dayPossibilities, minutesPerDayPossibilities])
    const loginPage = () => {
        if (auth.isLoggedIn) {
            router.replace({ pathname: '/profile/subscription/plans' })
        } else {
            auth.setModalShown?.(true)
        }
    }

    return (
        <div style={{ width: '100vw', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Master title={`${t('subscription.title')}${t('title')}`}>
                <ProfileTemplate>
                    <div style={{ width: '96vw', display: 'flex', justifyContent: 'center', zIndex: '0' }}>
                        {loading && <LoadingBox />}
                        {!loading && payments && (
                            <div className={classnames(styles.narrow, styles.list)} style={{ minWidth: '50%' }}>
                                <SubscriptionSelection
                                    options={payments}
                                    disabled={loading}
                                    onChange={subscriptionChangeHandler}
                                    initialDayPossibilities={dayPossibilities!!}
                                    initialMinutesPerDayPossibilities={minutesPerDayPossibilities!!}
                                    initialIndex={checkedPackage!!}
                                />

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className={styles.buttonWrapper}>
                                        <Button
                                            size={'large'}
                                            type={'gradient'}
                                            onClick={loginPage}
                                            variation={'block'}
                                            loading={loading}
                                            // disabled={!disabledSubscription ?? false}
                                            disabled={false}
                                        >
                                            {t('subscription.beSubscription')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <PaymentModal />
                    </div>
                </ProfileTemplate>
            </Master>
        </div>
    )
}

export default CourseFee

import * as React from 'react'
import Cropper from 'react-easy-crop'
import Text from 'components/atoms/text'
import Avatar from 'components/atoms/avatar'
import Button from 'components/atoms/button'
import { Point } from 'react-easy-crop/types'
import { useTranslation } from 'react-i18next'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { UserSmileLine } from 'components/icons'
import { CropType, getCroppedImg, readFile } from 'utils/image'
import LoadingBox from 'components/atoms/loading-box'

import styles from './style.module.css'

type ProfilePhotoChangerProps = {
    avatar?: string
    onChange?: (blob: string) => void
    loading?: boolean
}

export default function ProfilePhotoChanger({ avatar, onChange, loading }: ProfilePhotoChangerProps) {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const { t } = useTranslation('molecules')

    const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined)
    const [croppedImageSrc, setCroppedImageSrc] = React.useState<string | undefined>(avatar)
    const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<CropType | undefined>(undefined)

    const selectFile = () => {
        inputRef.current?.click()
    }

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        let imageDataUrl = await readFile(e.target.files[0])
        setImageSrc(imageDataUrl)
    }

    // when user changes cropped area
    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    // when user click to "kaydet" button
    const onCropFinished = React.useCallback(async () => {
        if (!imageSrc || !croppedAreaPixels) return undefined
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
            setCroppedImageSrc(croppedImage)
            setImageSrc(undefined)

            if (typeof onChange === 'function') {
                onChange(croppedImage)
            }
        } catch (error) {
            console.log(error)
        }
    }, [imageSrc, croppedAreaPixels, onChange])

    const onClose = React.useCallback(() => {
        setImageSrc(undefined)
        if(inputRef.current){
            inputRef.current.files = null
        }
    }, [])

    const removeImage = React.useCallback(() => {
        setCroppedImageSrc(undefined)
        if(inputRef.current){
            inputRef.current.files = null
        }
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <div className={styles.avatar}>{croppedImageSrc ? <Avatar rounded url={croppedImageSrc} size={3.5} /> : <UserSmileLine />}</div>
                <div className={styles['info-body']}>
                    <Text weight="medium">{t('profile-photo.change-title')}</Text>
                    <Text size="sm" type="dimmed">
                        {t('profile-photo.max-file-size')}
                    </Text>
                </div>
                {loading ? <LoadingBox type={'pure'} /> : undefined}
            </div>
            <div className={styles.actions}>
                {croppedImageSrc ? (
                    <>
                        <Button variation="block" type="danger" onClick={removeImage} disabled={loading}>
                            {t('profile-photo.remove')}
                        </Button>
                        <Button variation="block" type="danger-2" onClick={selectFile} disabled={loading}>
                            {t('profile-photo.change')}
                        </Button>
                    </>
                ) : (
                    <Button variation="block" type="danger-2" onClick={selectFile} disabled={loading}>
                        {t('profile-photo.upload')}
                    </Button>
                )}
            </div>
            <div className={styles.hidden}>
                <input type="file" ref={inputRef} onChange={onFileChange} accept="image/*" />
            </div>
            <Modal isVisible={!!imageSrc} onModalClose={onClose} size={ModalSize.Small} type={ModalType.Centered}>
                <div className={styles.modal}>
                    <div className={styles['crop-container']}>
                        <Cropper image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom}  />
                    </div>
                    <div className={styles['modal-actions']}>
                        <Button variation="block" type="gradient" onClick={onCropFinished}>
                            {t('profile-photo.save')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

import * as React from 'react'
import classnames from 'classnames'
import ErrorBox from '../error-box'
import {isString} from 'lodash'
import {useTranslation} from 'react-i18next'
import InputSaveButton from '../input-save-button'
import InputReverseButton from '../input-reverse-button'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'

import styles from './style.module.css'
import inputStyles from '../input/style.module.css'

export type InputPhoneNumberProps = {
    value: string
    onSubmit?: () => void
    onReverse?: () => void
    loading?: boolean
    initialValue?: string
    onChangeText: (text: string) => void
    label: string
}

export default function InputPhoneNumber({onSubmit, onReverse, loading, value, initialValue, onChangeText}: InputPhoneNumberProps) {
    const {t} = useTranslation('common')
    return (
        <>
            <div className={classnames(inputStyles['input-wrapper'])}>
                <PhoneInput
                    className={styles.phoneInput}
                    international
                    value={value}
                    onChange={onChangeText}
                    type={'phone'}
                    placeholder={t('profile.phoneNumber')}
                    limitMaxLength
                />
                {isString(value) && isValidPhoneNumber(value) && onSubmit && onReverse && (
                    <div className={styles.actions}>
                        {initialValue !== value ? <InputSaveButton loading={loading} onSubmit={onSubmit}/> : undefined}
                        {initialValue !== value ? <InputReverseButton onReverse={onReverse}/> : undefined}
                    </div>
                )}
            </div>
            {isString(value) && value !== '' && (!isValidPhoneNumber(value) && <ErrorBox text={t('inputPhoneNumber.error')} size={'sm'} align={'left'}/>)}
        </>
    )
}

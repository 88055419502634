import * as React from 'react'
import ReactHlsPlayer from 'react-hls-player/dist'
import Modal, {ModalSize, ModalType} from '../../atoms/modal'

import styles from './style.module.css'

export default function VideoPlayer({video, onClose}: { video: string | undefined, onClose: () => void }) {
    const playerRef = React.createRef<HTMLVideoElement>()

    return (
        <Modal isVisible={typeof video !== 'undefined'} onModalClose={onClose} type={ModalType.Centered} size={ModalSize.Large}>
            <div className={styles.video}>
                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={video as string}
                    autoPlay={false}
                    controls={true}
                    width='100%'
                    height='auto'
                />
            </div>
        </Modal>
    )
}

import * as React from 'react'
import { isFunction } from 'lodash'
import Text from 'components/atoms/text'
import Input from 'components/atoms/input'
import { CloseLine } from 'components/icons'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Rateable from 'components/atoms/rateable'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

export type RateAppointmentModalCardProps = {
    isVisible: boolean
    onModalClose?: () => void
    onSubmitButtonClick?: (rate: number, text?: string) => void
}

const RateAppointmentModalCard = ({ isVisible, onModalClose, onSubmitButtonClick }: RateAppointmentModalCardProps) => {
    const { t } = useTranslation('organisms')
    const router = useHistory()
    const [rate, setRate] = React.useState(0)
    const [text, setText] = React.useState<string | undefined>(undefined)

    const onModalCloseHandler = async () => {
        if (isFunction(onModalClose)) {
            onModalClose()
            // await router.push({pathname: '/'})
        }
    }

    const onSubmitButtonClickHandler = () => {
        isFunction(onSubmitButtonClick) && onSubmitButtonClick(rate, text)
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} size={ModalSize.Small} type={ModalType.Centered}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('rateAppointment.title'),
                        size: 'lg',
                        divider: true,
                        trailing: <CloseLine className={styles.closeLine} onClick={onModalCloseHandler} />,
                    }}
                >
                    <div className={styles.body}>
                        <Text size={'lg'} weight={'medium'}>
                            {t('rateAppointment.header')}
                        </Text>
                        <Rateable onRateChanged={setRate} displayText={true} />
                        <Input rows={6} label={t('rateAppointment.message')} onChangeText={setText} multiline maxlength="150" />
                    </div>

                    <div className={styles.buttons}>
                        <Button size={'large'} type={'dimmed'} onClick={onModalCloseHandler}>
                            {t('rateAppointment.cancel')}
                        </Button>
                        <Button size={'large'} type={'gradient'} onClick={onSubmitButtonClickHandler} disabled={rate === 0}>
                            {t('rateAppointment.send')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default RateAppointmentModalCard

import * as React from 'react'
import classNames from 'classnames'
import Checkbox from 'components/atoms/checkbox'

import styles from './style.module.css'

type TimeRangePickerProps = {
    label: string
    withCheckbox?: boolean
    isSelected?: boolean
    onSelect: (isSelected: boolean) => void
}

const TimeRangePicker = ({label, withCheckbox, isSelected = false,  onSelect}: TimeRangePickerProps) => {
    const toggle = () => {
        onSelect(!isSelected)
    }

    return (
        <button
            className={classNames(styles.button, {
                [styles.selected]: isSelected,
                [styles['with-checkbox']]: withCheckbox,
            })}
            onClick={toggle}
        >
            {withCheckbox && (
                <div className={styles.checkbox}>
                    <Checkbox checked={isSelected} onChange={toggle}/>
                </div>
            )}
            {label}
        </button>
    )
}

export default TimeRangePicker

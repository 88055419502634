import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

type ErrorBoxProps = {
    text: string | React.ReactNode
    align?: 'left' | 'center'
    size?: 'sm' | 'lg'
    type?: 'gray' | 'danger'
}
export default function ErrorBox({text, align, size, type}: ErrorBoxProps) {
    return <div className={classnames(styles.box, {[styles[`align-${align}`]]: align}, {[styles[`size-${size}`]]: size}, {[styles[`type-${type}`]]: type})}>{text}</div>
}

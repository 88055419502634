const env = process.env.NODE_ENV || 'development'

const Config = {
    nodeEnv: env,
    app: {
        name: 'Advancer',
    },
    api: {
        // baseUrl: 'https://localhost:5001',
        // baseUrl: 'https://advancer.ml',
        // baseUrl: 'http://192.168.1.40:9000',
        // baseUrl : 'http://192.168.100.145:51935'
        // baseUrl: 'https://stagingapi.advancerenglish.com',
        baseUrl: 'https://api.advancerenglish.com'
    },
    agora: {
        appId: '78864f6430294a5b9e9bd6cdaffb23e3',
    },
    chat: {
        appId: 'advancer-id',
    },
    linkLy: {
        url: 'https://l.linklyhq.com/l/b6TX',
    },
    oneSignal: {
        appId: '1443ea9b-d0a1-400c-b94e-971920234188',
    },
    sentry: {
        url: 'https://18ca8263e6674fc7b86a047ac74b8c1d@o396942.ingest.sentry.io/5858159',
    },
    googleApiKey: {
        // edev Advancer Production https://console.cloud.google.com/apis/credentials?project=advancer-english
        clientId: '909240027341-35nc1n5hoche8fk7j978s8lfsl3snhkm.apps.googleusercontent.com',
    },
}

export default Config

import * as React from 'react'
import classnames from 'classnames'
import Card from 'components/molecules/card'
import {useTranslation} from 'react-i18next'
import MovieCategoryBox from 'components/molecules/movie-category-box'
import {WordTutorialCategoryQueriesWordTutorialCategoryInList as Category} from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import LoadingBox from "../../atoms/loading-box";

export default function MovieCategories({categories, loading}: { categories: Category[], loading?: boolean }) {
    const {t} = useTranslation('common')

    return (
        <Card
            heading={{
                title: t('home.selectCategory'),
                size: 'xl',
            }}
        >
            {loading ?
                (
                    <LoadingBox/>
                ) : (
                    <div className={classnames(styles.list)}>
                        {categories.map(r => (
                            <MovieCategoryBox key={r.id + '_movie-category'} {...r} />
                        ))}
                    </div>
                )}
        </Card>
    )
}

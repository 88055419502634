import { User } from 'types'
import moment from 'moment'
import Error from './error'
import * as React from 'react'
import { isUndefined } from 'lodash'
import useCall from 'hooks/useCall'
import { getUserRole } from 'utils/auth'
import Text from 'components/atoms/text'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Avatar from 'components/atoms/avatar'
import Card from 'components/molecules/card'
import Master from 'components/layouts/master'
import WaitTemplate, { styles } from 'components/templates/wait'
import RemainingConversationDuration from 'components/atoms/remaining-conversation-duration'

export default function Wait() {
    const call = useCall()
    const userRole = getUserRole()
    const { t } = useTranslation('common')
    const { id } = useParams<{ id: string }>()

    const [isStarted, setStarted] = React.useState(false)

    // const sendMessage = () => {
    //     chat.methods?.createConversation(userRole === 'Student' ? call.currentCall.appointment?.teacher : call.currentCall.appointment?.student.)
    // }

    if (isUndefined(call.currentCall) || call.currentCall.callId !== parseInt(id)) {
        return <Error statusCode={404} />
    }

    return (
        <Master title={t('wait.title')}>
            <WaitTemplate>
                <div className={styles.wrapper}>
                    <Card
                        heading={{
                            title: t('wait.waitTitle'),
                            subTitle: t('wait.subTitle'),
                            size: 'lg',
                            divider: true,
                        }}
                    >
                        <div className={styles.body}>
                            <div className={styles.avatar}>
                                <Avatar user={userRole === 'Student' ? call.currentCall.appointment?.teacher as User : call.currentCall.appointment?.student as User}
                                    size={15} />
                            </div>

                            <div
                                className={styles.userName}>{userRole === 'Student' ? call.currentCall.appointment?.teacher?.name : call.currentCall.appointment?.student?.name}</div>
                            <div className={styles.betweenTimeWrapper}>
                                {moment(call.currentCall.appointment?.startDate).format('kk:mm')} - {moment(call.currentCall.appointment?.endDate).format('kk:mm')}
                            </div>

                            {!isStarted ? (
                                <RemainingConversationDuration
                                    className={styles.remainingDuration}
                                    duration={moment(call.currentCall.appointment?.startDate).diff(moment(), 'second')}
                                    onEnd={() => setStarted(true)}
                                />
                            ) : (
                                <Text type='default' weight='medium' size='lg'>{t('wait.title')}...</Text>
                            )}
                        </div>
                    </Card>
                </div>
            </WaitTemplate>
        </Master>
    )
}

import * as React from 'react'
import Text from '../../atoms/text'
import Button from 'components/atoms/button'
import PasswordInput from 'components/molecules/password-input'
import ReactCodeInput from 'react-verification-code-input'
import {useTranslation} from 'react-i18next'

import styles from './style.module.css'

type NewPasswordCardProps = {
    recoverEmail: string
    onConfirm: (password: string, code: string) => void
    loading?: boolean
    hideRecoverCode?: boolean
}

const NewPasswordCard: React.FC<NewPasswordCardProps> = ({recoverEmail, loading, onConfirm, hideRecoverCode}) => {
    const {t} = useTranslation('organisms')
    const passwordInputRef = React.useRef<HTMLInputElement>(null)
    const passwordAgainInputRef = React.useRef<HTMLInputElement>(null)

    const [password, setPassword] = React.useState('')
    const [passwordAgain, setPasswordAgain] = React.useState('')
    const [code, setCode] = React.useState('')

    const [isSame, setSame] = React.useState(false)

    React.useEffect(() => {
        setSame(password.length > 1 && passwordAgain.length > 1 && password === passwordAgain)
    }, [password, passwordAgain, isSame])

    const handleNewPassword = () => {
        if (password.length < 6 || !isSame || code.length !== 6) return
        onConfirm(password, code)
        setPassword('')
        setPasswordAgain('')
    }

    const handleEnterFirstInput = () => {
        passwordAgainInputRef.current?.focus()
    }

    const handleCompleteCode = () => {
        passwordInputRef.current?.focus()
    }

    return (
        <>
            <div className={styles.inputs}>
                <Text>{t('newPasswordCard.passwordRecoveryCode')}</Text>
                {!hideRecoverCode && <ReactCodeInput onComplete={handleCompleteCode} onChange={setCode} className={styles.input} fields={6} fieldWidth={52} fieldHeight={52} autoFocus/>}
                <PasswordInput
                    value={password}
                    onChangeText={setPassword}
                    label={t('newPasswordCard.password')}
                    name={'password'}
                    autoComplete={'new-password'}
                    maxLength={64}
                    status={password.length > 1 && password.length < 6 ? 'danger' : 'default'}
                    enterKeyPress={handleEnterFirstInput}
                    ref={passwordInputRef}
                />
                <PasswordInput
                    value={passwordAgain}
                    onChangeText={setPasswordAgain}
                    label={t('newPasswordCard.rePassword')}
                    name={'password'}
                    autoComplete={'new-password'}
                    maxLength={64}
                    status={passwordAgain.length > 1 && !isSame ? 'danger' : 'default'}
                    enterKeyPress={handleNewPassword}
                    ref={passwordAgainInputRef}
                />
                <Text>{t('newPasswordCard.condition')}</Text>
                <Text type={"dimmed"} weight={"medium"}>{recoverEmail}</Text>
            </div>

            <div className={styles.actions}>
                <Button
                    loading={loading}
                    onClick={handleNewPassword}
                    type="danger-2"
                    variation="block"
                    disabled={password.length < 6 || !isSame || (!hideRecoverCode && code.length !== 6)}
                >
                    {t('newPasswordCard.changePassword')}
                </Button>
            </div>
        </>
    )
}

export default NewPasswordCard

import * as React from 'react'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

type ShareModalProps = {
    url: string
    isVisible: boolean
    onClick: () => void
    onClose: () => void
}

const ShareModal: React.FC<ShareModalProps> = ({ url, isVisible, onClick, onClose }) => {
    const { t } = useTranslation('organisms')

    return (
        <Modal isVisible={isVisible} onModalClose={onClose} size={ModalSize.Small} type={ModalType.Centered}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('share'),
                    }}
                    type="darken"
                >
                    <div className={styles.actions}>
                        <FacebookShareButton url={url} onShareWindowClose={onClick}>
                            <FacebookIcon />
                        </FacebookShareButton>
                        <TwitterShareButton url={url} onShareWindowClose={onClick}>
                            <TwitterIcon />
                        </TwitterShareButton>
                        <TelegramShareButton url={url} onShareWindowClose={onClick}>
                            <TelegramIcon />
                        </TelegramShareButton>
                        <WhatsappShareButton url={url} onShareWindowClose={onClick}>
                            <WhatsappIcon />
                        </WhatsappShareButton>
                    </div>
                    <Button onClick={onClose} type="dimmed" variation="block">
                        {t('give-up')}
                    </Button>
                </Card>
            </div>
        </Modal>
    )
}

export default ShareModal

import * as React from 'react'
import useApi from 'hooks/useApi'
import { isUndefined } from 'lodash'
import classnames from 'classnames'
import { RefreshLine } from '../../icons'
import SwiperCore, { Virtual } from 'swiper'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react/'
import WordCard from 'components/organisms/word-card'
import { WordTutorialCategoryQueriesWordTutorial as Word, WordTutorialCategoryQueriesWordTutorialCategoryInList as Category } from '@mohsininsignia/advancerapiclient'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import styles from './style.module.css'

SwiperCore.use([Virtual])

export default function WordCarousel({ category, slideWidth }: { category: Category; slideWidth: string | number }) {
    const api = useApi()
    const { t } = useTranslation('organisms')

    const [loading, setLaoding] = React.useState<boolean>(false)
    const [words, setWords] = React.useState<Word[]>(category.wordTutorials?.slice(0, 4))
    const [favoriteLoading, setFavoriteLoading] = React.useState<number | null>(null)

    const refresh = React.useCallback(async () => {
        setLaoding(true)

        try {
            const { data } = await api.WordTutorialCategory.refreshWordTutorialsInCategory(category.id)
            setWords(data.wordTutorials.slice(0, 4))

            // for animation
            await new Promise(r => setTimeout(r, 500))
        } catch (error) {}

        setLaoding(false)
    }, [api.WordTutorialCategory, category.id])

    const handleFavoriteWord = async (wordId: number, isFavorited: boolean) => {
        setFavoriteLoading(wordId)
        try {
            if (isFavorited) {
                await api.WordTutorial.favoriteWordTutorial(wordId)
            } else {
                await api.WordTutorial.unfavoriteWordTutorial(wordId)
            }
            setWords(x =>
                x.map(y => {
                    if (y.id === wordId) {
                        y.isFavorited = isFavorited
                    }
                    return y
                })
            )
        } catch {}
        setFavoriteLoading(null)
    }

    return (
        <div className={classnames(styles['word-carousel'])}>
            <div className={classnames(styles.heading)}>
                <div className={classnames(styles['heading-title'])}>{category.title}</div>
                <div
                    className={classnames(styles['refresh-button'], {
                        [styles.loading]: loading,
                    })}
                    onClick={refresh}
                >
                    <RefreshLine />
                </div>
            </div>
            <div className={classnames(styles.carousel)}>
                <Swiper spaceBetween={16} slidesPerView={'auto'}>
                    {!isUndefined(words) &&
                        (words.length > 0 ? (
                            words.map(word => (
                                <SwiperSlide style={{ width: slideWidth }} key={word.id} virtualIndex={word.id}>
                                    <WordCard word={word} category={category} onFavoriteChange={handleFavoriteWord} loading={favoriteLoading === word.id} />
                                </SwiperSlide>
                            ))
                        ) : (
                            <div className={styles.emptyWord}>{t('wordCarousel.empty')}</div>
                        ))}
                </Swiper>
            </div>
        </div>
    )
}

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type MessagesTemplateProps = {
    children: React.ReactNode
}
export { styles }

export default function MessagesTemplate({ children }: MessagesTemplateProps) {
    return <div className={classnames(styles.wrapper)}>{children}</div>
}

import * as React from 'react'

function SvgTurkish(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={400} height={400} viewBox="0 0 400 400" {...props}>
            <defs>
                <clipPath clipPathUnits="userSpaceOnUse" id="turkish_svg__a">
                    <path d="M150 300C67.157 300 0 232.843 0 150S67.157 0 150 0s150 67.157 150 150-67.157 150-150 150z" />
                </clipPath>
            </defs>
            <g clipPath="url(#turkish_svg__a)" transform="matrix(1.33333 0 0 -1.33333 0 400)">
                <path d="M-75 300h450V0H-75z" fill="#e30a17" />
                <path d="M84.375 225c41.421 0 75-33.579 75-75s-33.579-75-75-75-75 33.579-75 75 33.579 75 75 75" fill="#fff" />
                <path d="M103.125 210c33.137 0 60-26.863 60-60s-26.863-60-60-60-60 26.863-60 60 26.863 60 60 60" fill="#e30a17" />
                <path d="M143.75 150l67.838-22.042-41.927 57.707v-71.33l41.927 57.707z" fill="#fff" />
            </g>
        </svg>
    )
}

export default SvgTurkish

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export type WordsTemplateProps = {
    children: React.ReactNode
}

export default function WordsTemplate({ children }: WordsTemplateProps) {
    return <div className={classnames(styles.words)}>{children}</div>
}

import * as React from 'react'
import {isString} from 'lodash'
import Text from '../../atoms/text'
import Card from '../../molecules/card'
import useAppInfo from 'hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'
import Input from 'components/atoms/input'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/atoms/checkbox'
import {isValidPhoneNumber} from 'react-phone-number-input'
import InputPhoneNumber from '../../atoms/input-phone-number'
import PasswordInput from 'components/molecules/password-input'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import { ArrowLeftSLine, ArrowRightSLine } from 'components/icons'
import SimpleImageSelector from 'components/atoms/simple-image-selector'
import { TeacherSpecialities } from '../teacher-specialities-selection-card'

import styles from './style.module.css'

export type TeacherRegisterProps = {
    name: string
    email: string
    password: string
    phone: string
    avatar?: string
    bio: string
    ytUrl: string
}

type TeacherRegisterCardProps = {
    loading?: boolean
    initialValues?: TeacherRegisterProps
    specialities?: TeacherSpecialities | undefined
    onspecialitiesClicked: (user: TeacherRegisterProps) => void
    onConfirm: (user: TeacherRegisterProps, kvkk: boolean) => void
}

const TeacherRegisterCard = ({ loading, initialValues, specialities, onspecialitiesClicked, onConfirm }: TeacherRegisterCardProps) => {
    const { t } = useTranslation()
    const appInfo = useAppInfo()

    const [name, setName] = React.useState<string>(initialValues?.name ?? '')
    const [email, setEmail] = React.useState<string>(initialValues?.email ?? '')
    const [password, setPassword] = React.useState<string>(initialValues?.password ?? '')
    const [phone, setPhone] = React.useState<string>(initialValues?.phone ?? '')
    const [avatar, setAvatar] = React.useState<string | undefined>(initialValues?.avatar)
    const [bio, setBio] = React.useState<string>(initialValues?.bio ?? '')
    const [ytUrl, setYtUrl] = React.useState<string>(initialValues?.ytUrl ?? '')
    const [checked, setChecked] = React.useState<boolean>(false)
    const [emailCheck, setEmailCheck] = React.useState(false)

    const [kvkkModal, setKvkkModal] = React.useState(false)

    const registerHandler = () => {
        onConfirm(
            {
                name,
                email,
                password,
                phone,
                avatar,
                bio,
                ytUrl,
            },
            checked
        )
    }

    const specialitiesHandler = () => {
        onspecialitiesClicked({
            name,
            email,
            password,
            phone,
            avatar,
            bio,
            ytUrl,
        })
    }

    const confirmedForm = React.useMemo(() => {
        return (
            name.length > 1 &&
            email.length > 1 &&
            password.length > 1 &&
            isString(phone) &&
            isValidPhoneNumber(phone) &&
            isString(avatar) &&
            avatar.length > 1 &&
            bio.length > 1 &&
            ytUrl.length > 1 &&
            checked
        )
    }, [avatar, bio, checked, email, name, password, phone, ytUrl])

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputs}>
                <Input label={t('organisms:teacherAuth.name')} value={name} onChangeText={setName} name="name" />
                <Input
                    label={t('organisms:teacherAuth.email')}
                    value={email}
                    onChangeText={setEmail}
                    name="email"
                    type="email"
                    regexType={'mail'}
                    onRegexFailed={setEmailCheck}
                    status={email === '' || email === undefined || emailCheck ? 'default' : 'danger'}
                />
                <PasswordInput label={t('organisms:teacherAuth.password')} value={password} onChangeText={setPassword} name="password" />
                <InputPhoneNumber label={t('studentRegister.phoneNumber')} value={phone} onChangeText={setPhone} />
                <SimpleImageSelector initialImage={avatar} onImageCropped={setAvatar} />
                <Input multiline rows={6} maxCharCount={500} label={t('organisms:teacherAuth.bio')} value={bio} onChangeText={setBio} />
                <Button onClick={specialitiesHandler} type="danger-3" alignment="left" rightIcon={<ArrowRightSLine />} rightIconPlacement="absolute">
                    <div className="truncated" style={{ paddingRight: '1rem' }}>
                        {specialities
                            ? Object.values(specialities)
                                  .filter(x => x.length)
                                  .map(e => e.map(x => t(`organisms:filters.${x}`)))
                                  .join(',')
                            : null ?? t('common:profile.specialities')}
                    </div>
                </Button>
                <Input label={t('organisms:teacherAuth.youtubeLink')} value={ytUrl} onChangeText={setYtUrl} name="link" />
            </div>

            <div className={styles.controls}>
                <Checkbox checked={checked} onChange={setChecked} />
                <div onClick={() => setKvkkModal(x => !x)}>
                    <Text weight={'medium'} decoration={'underline'} type={'danger'} size={'xs'} clickable>
                        {t('organisms:studentRegister.confirmationSubject')}
                    </Text>
                </div>
                <div onClick={() => setChecked(r => !r)}>
                    <Text weight={'medium'} size={'xs'}>
                        {t('organisms:studentRegister.confirmationText')}
                    </Text>
                </div>
            </div>

            <div className={styles.action}>
                <Button loading={loading} onClick={registerHandler} type="gradient" variation="block" disabled={!confirmedForm || !emailCheck}>
                    {t('organisms:teacherAuth.register')}
                </Button>
            </div>

            <Modal isVisible={kvkkModal} onModalClose={() => setKvkkModal(false)} size={ModalSize.Large} type={ModalType.Centered}>
                <div className={styles.modal}>
                    <Card
                        heading={{
                            leading: (
                                <div className={styles.back} onClick={() => setKvkkModal(x => !x)}>
                                    <ArrowLeftSLine />
                                </div>
                            ),
                            title: t('organisms:legalDocumentModalCard.privacyPolicy'),
                            alignment: 'center',
                            size: 'lg',
                            divider: true,
                        }}
                    >
                        <div className={styles.contentWrapper}>
                            <Text size="sm">
                                <ReactMarkdown>{appInfo.legalDocuments?.find(x => x.title === 'Gizlilik Sözleşmesi')?.content ?? ''}</ReactMarkdown>
                            </Text>
                        </div>
                    </Card>
                </div>
            </Modal>
        </div>
    )
}

export default TeacherRegisterCard

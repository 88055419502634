import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ReportModalCard from '../report-modal-card'
import ReportContentModalCard from '../report-content-modal-card'

export interface reportOptionsType {
    id: string
    text: string
}

export type ReportModalCardProps = {
    isVisible: boolean
    onConfirm: (title: string, description?: string) => void
    onCancel: () => void
}

const ReportAppointmentModalCard = ({ isVisible, onCancel, onConfirm }: ReportModalCardProps) => {
    const { t } = useTranslation('organisms')

    const [showReportCard, setShowReportCard] = React.useState(false)
    const [showReportModalCard, setShowReportModalCard] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState('')
    const [text, setText] = React.useState('')

    React.useEffect(() => {
        setShowReportCard(isVisible)
    }, [isVisible])

    const closeHandler = () => {
        setShowReportCard(false)
        setShowReportModalCard(false)
        setText('')
        setSelectedOption('')
        onCancel()
    }

    const onBackHandler = () => {
        setShowReportModalCard(false)
        setShowReportCard(true)
    }

    const selectHandler = (id: string) => {
        setShowReportCard(false)
        setShowReportModalCard(true)
        setSelectedOption(t('reportAppointmentModalCard.option' + id))
    }

    const confirm = () => {
        setShowReportModalCard(false)
        onConfirm(selectedOption, text)
        setText('')
    }

    return (
        <>
            <ReportModalCard
                isVisible={showReportCard}
                onModalClose={() => setShowReportCard(false)}
                onCancelButtonClick={() => setShowReportCard(false)}
                onSelect={selectHandler}
                options={[
                    { id: '1', text: t('reportAppointmentModalCard.option1') },
                    { id: '2', text: t('reportAppointmentModalCard.option2') },
                    { id: '3', text: t('reportAppointmentModalCard.option3') },
                ]}
            />
            <ReportContentModalCard
                isVisible={showReportModalCard}
                onBackButtonClick={onBackHandler}
                onCancelButtonClick={closeHandler}
                onChangeText={setText}
                onModalClose={closeHandler}
                onReportButtonClick={confirm}
                reasonForReport={selectedOption}
            />
        </>
    )
}

export default ReportAppointmentModalCard

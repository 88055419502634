import * as React from 'react'

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 363 261" {...props}>
            <defs>
                <clipPath id="home_svg__a">
                    <path fill="none" d="M0 12h363v261H0z" />
                </clipPath>
                <clipPath id="home_svg__b">
                    <path fill="#252736" d="M0 0h707v274H0z" transform="translate(606 120)" />
                </clipPath>
            </defs>
            <g
                clipPath="url(#home_svg__a)"
                style={{
                    isolation: 'isolate',
                }}
                transform="translate(0 -12)"
            >
                <g clipPath="url(#home_svg__b)" transform="translate(-606 -120)">
                    <g transform="translate(496.637 120)">
                        <path
                            fill="#ebebeb"
                            d="M0 345.406h527.498v.264H0zm418.654 16.975h55.989v.264h-55.989zm-131.104-3.303h61.886v.264H287.55zm113.559-1.519h20.245v.264h-20.245zm-295.114-3.196h45.565v.264h-45.565zm-22.165 0h16.289v.264H83.83zm54.87 4.452h57.181v.264H138.7z"
                        />
                        <path fill="#f0f0f0" d="M292.466 336.48h-11.088V204.574h11.088zm-4.262 8.789H68.301v-8.788h219.903z" />
                        <path fill="#f5f5f5" d="M64.049 204.574h217.329V336.48H64.049z" />
                        <path fill="#e0e0e0" d="M295.452 204.574h-11.457v-8.313h11.457z" />
                        <path fill="#ebebeb" d="M61.074 196.261h222.921v8.313H61.074z" />
                        <rect width={199.89} height={45.502} fill="#252736" rx={5.722} transform="translate(72.763 214.808)" />
                        <rect width={199.89} height={45.502} fill="#252736" rx={5.722} transform="translate(72.763 273.898)" />
                        <rect width={98.22} height={6.235} fill="#f5f5f5" rx={1.302} transform="rotate(180 110.912 109.835)" />
                        <rect width={98.22} height={6.235} fill="#f5f5f5" rx={1.302} transform="rotate(180 110.912 139.381)" />
                        <path fill="#e6e6e6" d="M438.34 247.85h33.443v6.393H438.34zm-19.454 97.556h-6.172V126.6h6.172z" />
                        <path fill="#f5f5f5" d="M438.351 254.233H391.34v-6.393h47.011z" />
                        <path fill="#e6e6e6" d="M438.34 306.719h33.443v6.393H438.34z" />
                        <path fill="#f5f5f5" d="M438.351 313.112H391.34v-6.393h47.011z" />
                        <path fill="#e6e6e6" d="M438.34 130.102h33.443v6.393H438.34z" />
                        <path fill="#f5f5f5" d="M438.351 136.495H391.34v-6.393h47.011z" />
                        <path fill="#e6e6e6" d="M438.34 188.971h33.443v6.393H438.34z" />
                        <path fill="#f5f5f5" d="M438.351 195.375H391.34v-6.393h47.011z" />
                        <path fill="#e6e6e6" d="M465.897 345.406h-6.172V126.6h6.172z" />
                        <path fill="#f5f5f5" d="M438.34 345.406h-6.172V126.6h6.172zm-40.828 0h-6.172V126.6h6.172z" />
                        <path fill="#e0e0e0" d="M371.454 177.915H92.724V29.931h278.73z" />
                        <path fill="#f5f5f5" d="M367.476 177.915H85.687V29.931h281.789z" />
                        <path fill="#252736" d="M357.369 41.588v124.658H95.772V41.588z" />
                    </g>
                    <ellipse cx={204.553} cy={11.943} fill="#f5f5f5" rx={204.553} ry={11.943} transform="translate(555.833 489.164)" />
                    <path
                        fill="#ff435b"
                        d="M905.785 340.726H613.256a3.524 3.524 0 01-3.513-3.513 3.524 3.524 0 013.513-3.513h292.529a3.524 3.524 0 013.513 3.513 3.524 3.524 0 01-3.513 3.513z"
                    />
                    <path
                        fill="#263238"
                        d="M905.786 340.726h-68.48a3.524 3.524 0 01-3.513-3.513 3.524 3.524 0 013.513-3.513h68.48a3.524 3.524 0 013.513 3.513 3.524 3.524 0 01-3.513 3.513z"
                        opacity={0.3}
                    />
                    <path fill="#263238" d="M673.38 349.536h224.978v24.486H673.38z" />
                    <path fill="#252736" d="M673.38 349.536h224.978v24.486H673.38z" opacity={0.1} />
                    <path fill="#263238" d="M895.088 496.233h6.731l-.274-155.506h-9.326z" />
                    <path fill="#252736" d="M895.088 496.233h6.731l-.274-155.506h-9.326z" opacity={0.1} />
                    <path fill="#263238" d="M833.054 496.233h-6.741l15.234-155.506h9.326z" />
                    <path fill="#252736" d="M833.054 496.233h-6.741l15.234-155.506h9.326z" opacity={0.2} />
                    <path fill="#263238" d="M671.038 496.233h6.731l-.278-155.507h-9.326z" />
                    <path fill="#252736" d="M671.038 496.233h6.731l-.278-155.507h-9.326z" opacity={0.1} />
                    <path fill="#263238" d="M609.005 496.233h-6.742l15.235-155.507h9.326z" />
                    <path fill="#252736" d="M609.005 496.233h-6.742l15.235-155.507h9.326z" opacity={0.2} />
                    <path fill="#263238" d="M798.482 333.7h-15.53l-6.214-30.363h15.53z" />
                    <path
                        fill="#263238"
                        d="M819.085 327.444h-67.372a3.787 3.787 0 01-3.819-4.062l2.574-42.654a4.378 4.378 0 014.3-4.062h67.383a3.8 3.8 0 013.819 4.062l-2.574 42.654a4.389 4.389 0 01-4.311 4.062z"
                    />
                    <path
                        fill="#ff435b"
                        d="M818.031 327.444h-67.372a3.787 3.787 0 01-3.819-4.062l2.577-42.653a4.378 4.378 0 014.3-4.062H821.1a3.8 3.8 0 013.819 4.062l-2.574 42.654a4.389 4.389 0 01-4.314 4.061z"
                    />
                    <path
                        fill="#252736"
                        d="M750.649 325.332a1.73 1.73 0 01-1.255-.528 1.762 1.762 0 01-.454-1.3l2.574-42.653a2.268 2.268 0 012.2-2.11h67.383a1.688 1.688 0 011.709 1.825l-2.574 42.653a2.258 2.258 0 01-2.2 2.11z"
                    />
                    <path d="M780.237 276.667l4.378 14.992 13-14.992z" opacity={0.2} />
                    <g transform="translate(628.733 217.069)">
                        <path fill="#263238" d="M46.778 23.201c.106.812.612 1.424 1.15 1.382s.876-.738.77-1.551-.612-1.424-1.139-1.382-.875.759-.781 1.551z" />
                        <path fill="#a02724" d="M47.665 24.414a25.878 25.878 0 003.65 5.855c-1.161 1.171-3.281.7-3.281.7z" />
                        <path
                            fill="#263238"
                            d="M44.088 20.11a.664.664 0 01-.243 0 .5.5 0 01-.264-.633 4.653 4.653 0 013.27-2.838.475.475 0 01.549.39.485.485 0 01-.39.559 3.6 3.6 0 00-2.532 2.258.506.506 0 01-.39.264z"
                        />
                        <path
                            fill="#b55b52"
                            d="M22.872 35.934l6.33 4.853 8.64 6.615a8.947 8.947 0 00-.844 3.165c-.306 2.426-.612 4.22 4.779 5.708a3.07 3.07 0 012.3 2.395c.211 1.435-.665 3.165-5.644 4.02a22.883 22.883 0 01-14.063-2.395c-2.11-1.055-2.11-2.321-1.224-4.463 2.659-6.088 1.372-13.853-.274-19.898z"
                        />
                        <path fill="#ff435b" d="M15.603 57.752a43.961 43.961 0 003-7.3s16.036-.876 21.332.707 8.588 8.44 8.588 8.44z" />
                        <path
                            fill="#b55b52"
                            d="M22.313 69.684c-.39 1.182-.823 2.627-1.234 3.956l-1.192 4.115c-.738 2.743-1.456 5.5-2.015 8.24a78.614 78.614 0 00-1.361 8.123 38.547 38.547 0 00-.232 7.564 17.058 17.058 0 00.485 3.165c.063.211.116.443.19.644l.211.57a2.678 2.678 0 00-.169-.3 5.044 5.044 0 00-.348-.443 2.521 2.521 0 00-.464-.433c-.211-.148-.243-.127-.2-.074a5.18 5.18 0 00.992.559c.475.211 1.055.422 1.635.622 1.192.4 2.511.77 3.872 1.129 5.444 1.372 11.383 2.437 17.175 3.671l-.19 7.311a93.122 93.122 0 01-9.326.77 89.678 89.678 0 01-9.495-.158 45.591 45.591 0 01-4.99-.612 30.808 30.808 0 01-2.711-.6 16.6 16.6 0 01-3.165-1.171 11.353 11.353 0 01-2.11-1.319 9.6 9.6 0 01-1.277-1.224 9.909 9.909 0 01-.675-.9c-.105-.179-.222-.359-.316-.538l-.137-.253-.728-1.488c-.222-.5-.4-.981-.591-1.477a29.656 29.656 0 01-1.435-5.7c-1.055-7.332-.222-13.873.876-20.235.591-3.165 1.287-6.33 2.11-9.337.422-1.53.865-3.059 1.34-4.568s.981-2.965 1.593-4.621z"
                        />
                        <path fill="#ff435b" d="M12.554 56.444c9.97-2.637 15.888 5.528 12.512 18.99s-4.5 13.968-4.5 13.968L.004 85.973c2.528-13.187 2.327-26.839 12.55-29.529z" />
                        <path
                            fill="#b55b52"
                            d="M44.763 107.874l-1.551-.137c-4.41 0-5.169 1.2-7.881 3.724l-1.055 5.549c7.121 3.64 10.94-.359 10.951-.359h6.594l-2.321-5.275a5.8 5.8 0 00-4.737-3.5zM20.15 25.437c1.793 9.611 2.353 15.329 7.86 19.623 8.271 6.446 19.665.316 20.783-9.685 1-9-2.11-23.21-12.048-25.731a13.588 13.588 0 00-16.6 15.793z"
                        />
                        <path
                            fill="#263238"
                            d="M39.193 28.792c6.857-.549 4.853-7.775 2.732-9.284 3.218-1.224 5.433-6.33 2.342-9.833C46.082 4.938 40.891-.769 31.786.085 25.14.697 24.401 4.21 24.401 4.21s-7.965-.833-11.278 5.739c-2.827 5.634.6 8.64.6 8.64s-5.95 1.73-4.3 9.495c1.751 8.377 8.187 6.435 8.187 6.435s-1.445 7.68 7.258 7.786c7.522.095 7.617-5.539 7.617-5.539s9.746-.906 6.708-7.974z"
                        />
                        <path
                            fill="#b55b52"
                            d="M34.087 29.351a8.936 8.936 0 004.22 4.79c2.511 1.287 4.431-1.382 3.988-4.125-.4-2.458-2.543-5.613-5.191-5.76s-3.957 2.542-3.017 5.095zm102.282 237.733l9.379-3.809-6.625-22.524-9.379 3.8z"
                        />
                        <path d="M132.962 255.479l9.474-3.482-3.313-11.246-9.379 3.8z" opacity={0.2} />
                        <path
                            fill="#263238"
                            d="M144.936 259.52l-10.726 3.724a.717.717 0 00-.475.9l2.427 8.672a1.8 1.8 0 002.268 1.118c3.7-1.372 5.2-2.11 9.78-4.093a144.529 144.529 0 0013.2-6.333c3.576-2.11 1.424-5.708-.316-5.117-4.125 1.393-10.888 2.3-14.316 1.118a2.838 2.838 0 00-1.842.011zM61.042 137.144s70.885 6.33 72.721 26.723c3.693 41.493 9.358 81.5 9.358 81.5l-14.422 5.275s-17.65-45.576-17.418-78.07c-20.446-5.771-74.208 2.7-83-13.071-7.765-13.926-.949-23.927-.949-23.927z"
                        />
                        <path
                            fill="#ff435b"
                            d="M127.622 253.917l17.956-6.066-.781-8.176-20.245 6.52zm21.27 6.035a12.249 12.249 0 01-2.659.538.232.232 0 01-.232-.116.242.242 0 010-.253c.232-.317 2.3-3.1 3.619-3.06a.918.918 0 01.738.454 1.3 1.3 0 01.137 1.308 2.743 2.743 0 01-1.6 1.129zm-2.11 0c1.825-.253 3.07-.739 3.355-1.34a.823.823 0 00-.116-.844.412.412 0 00-.359-.232c-.76-.032-2.068 1.329-2.933 2.416z"
                        />
                        <path
                            fill="#ff435b"
                            d="M146.285 260.479a.211.211 0 01-.116 0 .243.243 0 01-.179-.137c0-.116-1.213-2.785-.58-4.051a1.192 1.192 0 01.833-.633.9.9 0 011.055.39c.58.971-.148 3.45-.9 4.347a.242.242 0 01-.116.084zm0-4.326a.707.707 0 00-.411.369c-.38.749.084 2.353.433 3.249.58-.992 1-2.838.622-3.471-.074-.116-.2-.253-.538-.179z"
                        />
                        <path fill="#b55b52" d="M86.183 273.783l10.022-.475 1.635-23.664-10.022.475z" />
                        <path
                            fill="#263238"
                            d="M96.437 271.6l-11.352-.359a.717.717 0 00-.77.665l-.833 8.957a1.8 1.8 0 001.751 1.867c3.956.053 5.845-.116 10.835 0 3.07.095 9.115.549 13.325 0s3.566-4.674 1.73-4.832c-4.336-.348-10.149-3.344-12.924-5.676a2.8 2.8 0 00-1.762-.623z"
                        />
                        <path
                            fill="#ff435b"
                            d="M100.003 273.466a12.784 12.784 0 01-2.68-.443.232.232 0 01-.074-.422c.327-.222 3.249-2.11 4.473-1.561a.9.9 0 01.527.686 1.3 1.3 0 01-.338 1.266 2.658 2.658 0 01-1.908.474zm-2.026-.77c1.793.422 3.165.411 3.619 0a.855.855 0 00.19-.844.433.433 0 00-.253-.338c-.7-.316-2.405.475-3.64 1.182z"
                        />
                        <path
                            fill="#ff435b"
                            d="M97.334 273.035a.242.242 0 01-.116 0 .253.253 0 01-.116-.2c0-.116-.137-3.028.907-3.977a1.139 1.139 0 011.055-.3.9.9 0 01.833.739c.19 1.108-1.382 3.165-2.405 3.735a.211.211 0 01-.158.003zm1.509-4.02a.675.675 0 00-.517.19c-.622.559-.76 2.226-.76 3.165.9-.717 1.952-2.289 1.825-3.017 0-.137-.105-.3-.443-.348z"
                        />
                        <path d="M87.818 250.12l10.033-.475-.949 13.536-9.991-.011z" opacity={0.2} />
                        <path
                            fill="#263238"
                            d="M37.494 136.011s76.382 11.679 75.168 29.266c-2.184 31.65-12.8 93.5-12.8 93.5l-15.044.211s-3.555-63.447 2.11-86.573c-18.789 0-71.792-.654-79.019-10.307-8-10.666.222-27.43.222-27.43z"
                        />
                        <path
                            fill="#ff435b"
                            d="M83.049 260.174l18.209-.285.9-9.622-19.982.781zM50.945 55.382c-6.151-1.2-33.886-.855-33.886-.855a11.078 11.078 0 00-11.31 9.822c-.971 8.556 4.463 27.673 4.653 37.421.232 11.974-2.057 16.827-2.289 32.948l52.929 2.426c0-32.42-1.414-59.259-2.69-72.13-.454-4.532-1.256-8.444-7.407-9.632z"
                        />
                        <path
                            fill="#252736"
                            d="M50.945 55.382c-6.151-1.2-33.886-.855-33.886-.855a11.078 11.078 0 00-11.31 9.822c-.971 8.556 4.463 27.673 4.653 37.421.232 11.974-2.057 16.827-2.289 32.948l52.929 2.426c0-32.42-1.414-59.259-2.69-72.13-.454-4.532-1.256-8.444-7.407-9.632z"
                            opacity={0.3}
                        />
                        <path
                            fill="#b55b52"
                            d="M58.194 65.591c1.656 5.7 3.323 11.552 5.043 17.27s3.482 11.468 5.465 16.88c.485 1.382 1.055 2.669 1.519 4l.812 1.867.105.232v.179-.07a3.986 3.986 0 00-.675-.886c-.359-.348-.5-.38-.528-.38a2.628 2.628 0 00.8.5 13.11 13.11 0 001.593.644c1.192.411 2.532.781 3.893 1.15 5.518 1.4 11.542 2.479 17.407 3.735l-.19 7.311a89.666 89.666 0 01-9.495.77 84.576 84.576 0 01-9.643-.179 45.367 45.367 0 01-5.085-.654c-.886-.169-1.8-.38-2.8-.665a15.509 15.509 0 01-3.376-1.393 10.94 10.94 0 01-2.321-1.783 8.7 8.7 0 01-1.35-1.815l-.359-.665-1.192-2.258c-.7-1.477-1.456-2.965-2.11-4.431-1.3-2.965-2.437-5.9-3.576-8.841s-2.11-5.887-3.091-8.841c-1.952-5.94-3.714-11.816-5.275-17.935z"
                        />
                        <path fill="#ff435b" d="M43.16 59.714c7.237-7.385 16.49-3.355 20.467 9.98S66.887 84 66.887 84l-19.444 7.543c-4.589-12.702-11.689-24.307-4.283-31.829z" />
                        <path
                            fill="#b55b52"
                            d="M98.8 107.874l-1.551-.137c-4.41 0-5.159 1.2-7.87 3.724l-1.055 5.549c7.121 3.64 10.94-.359 10.94-.359h6.6l-2.332-5.275a5.781 5.781 0 00-4.732-3.502z"
                        />
                        <rect width={72.985} height={9.727} fill="#ff435b" rx={1.875} transform="rotate(180 40.364 88.14)" />
                        <path fill="#ff435b" d="M10.74 284.572h-3l3.454-108.3h4.146z" />
                        <path fill="#252736" d="M10.74 284.572h-3l3.454-108.3h4.146z" opacity={0.4} />
                        <path fill="#ff435b" d="M38.296 284.572h3l-3.443-108.3h-4.146z" />
                        <path fill="#252736" d="M38.296 284.572h3l-3.443-108.3h-4.146z" opacity={0.4} />
                        <path fill="#ff435b" d="M50.176 284.572h-3l3.453-108.3h4.146z" />
                        <path fill="#252736" d="M50.176 284.572h-3l3.453-108.3h4.146z" opacity={0.4} />
                        <path fill="#ff435b" d="M77.732 284.572h3l-3.443-108.3h-4.146z" />
                        <path fill="#252736" d="M77.732 284.572h3l-3.443-108.3h-4.146z" opacity={0.4} />
                        <path
                            d="M11.194 176.272h4.146l-.2 4.6h-4.094zm26.797 4.6h-4.093l-.19-4.6h4.136zm16.774-4.6l-.19 4.6h-4.093l.148-4.6zm22.672 4.6h-4.1l-.19-4.6h4.146z"
                            fill="#ff435b"
                            opacity={0.3}
                        />
                    </g>
                    <path
                        fill="#ff435b"
                        d="M907.03 260.957H738.052a9.495 9.495 0 01-9.569-10.181l6.446-106.945a10.993 10.993 0 0110.8-10.181h168.939a9.5 9.5 0 019.569 10.181l-6.457 106.987a10.982 10.982 0 01-10.75 10.139z"
                    />
                    <path fill="#ff435b" d="M760.66 238.349l15.582 53.309 46.23-53.309z" />
                    <path
                        fill="#252736"
                        d="M898.791 217.924h-94.7a5.328 5.328 0 01-5.37-5.708l3.619-59.945a6.161 6.161 0 016.056-5.708h94.7a5.275 5.275 0 015.37 5.708l-3.619 59.945a6.161 6.161 0 01-6.056 5.708z"
                        opacity={0.1}
                    />
                    <path
                        fill="#252736"
                        d="M848.13 243.782h-94.707a5.328 5.328 0 01-5.359-5.708l3.623-59.947a6.161 6.161 0 016.045-5.708h94.707a5.275 5.275 0 015.359 5.708l-3.619 59.945a6.161 6.161 0 01-6.045 5.708z"
                        opacity={0.1}
                    />
                    <path
                        d="M752.168 142.956a3.249 3.249 0 01-3.028 3.165 2.743 2.743 0 01-2.574-3.165 3.27 3.27 0 013.028-3.165 2.743 2.743 0 012.574 3.165zm10.328 0a3.26 3.26 0 01-3.028 3.165 2.743 2.743 0 01-2.585-3.165 3.27 3.27 0 013.028-3.165 2.754 2.754 0 012.585 3.165zm10.402 0a3.249 3.249 0 01-3.028 3.165 2.743 2.743 0 01-2.574-3.165 3.27 3.27 0 013.028-3.165 2.743 2.743 0 012.574 3.165z"
                        fill="#252736"
                        opacity={0.5}
                    />
                    <path
                        fill="#263238"
                        d="M905.722 259.966H736.789a9.78 9.78 0 01-9.833-10.466l6.453-106.945a11.246 11.246 0 0111.057-10.434H913.36a9.78 9.78 0 019.829 10.466l-6.446 106.945a11.257 11.257 0 01-11.021 10.434zM744.466 132.659a10.708 10.708 0 00-10.55 9.938l-6.457 106.945a9.231 9.231 0 009.305 9.906h168.958a10.719 10.719 0 0010.55-9.938l6.446-106.945a9.252 9.252 0 00-9.358-9.906z"
                        opacity={0.3}
                    />
                    <path
                        fill="#263238"
                        d="M814.243 197.447c-.717 1.519-1.371 2.817-2.11 4.22s-1.477 2.669-2.237 3.988c-1.551 2.627-3.165 5.275-4.9 7.744q-1.308 1.9-2.68 3.766c-.96 1.224-1.867 2.469-2.891 3.661a74.091 74.091 0 01-6.446 7.005c-.58.57-1.213 1.055-1.825 1.656a20.512 20.512 0 01-1.92 1.593l-2.026 1.53-.622.433a8.444 8.444 0 01-.855.475 8.659 8.659 0 01-9-.928 9.008 9.008 0 01-1.357-1.33l-.285-.38-.137-.19-.053-.084-.19-.338-.76-1.371c-.232-.443-.39-.865-.58-1.3a34.171 34.171 0 01-1.625-4.906 65.625 65.625 0 01-1.54-9.273 80.047 80.047 0 01-.4-9.157 3.64 3.64 0 017.132-.928v.127a134.448 134.448 0 005.18 15.825 25.15 25.15 0 001.635 3.165c.137.211.274.464.411.644l.4.485.095.127-.053-.084-.116-.158a3.292 3.292 0 00-.57-.58 3.756 3.756 0 00-1.435-.717 3.851 3.851 0 00-1.593 0 3.67 3.67 0 00-.791.264c-.137 0-.253.137-.38.2s-.316.222-.179.105l1.319-1.287c.443-.4.876-.886 1.308-1.35s.876-.918 1.3-1.414a110.718 110.718 0 009.474-13.23 193.413 193.413 0 004.22-7.174l2.036-3.65 1.931-3.566.105-.19a7.279 7.279 0 0112.987 6.552z"
                    />
                    <path fill="#ffc3bd" d="M770.799 203.798l-5.138-2.943 5.528-5.644a31.556 31.556 0 014.484 4.041 3 3 0 01-.844 4.737 4.357 4.357 0 01-4.03-.191z" />
                    <path fill="#ffc3bd" d="M764.49 194.805l2.8-1.994a1.709 1.709 0 012.374.359l1.53 2.036-5.528 5.644-1.741-3.967a1.709 1.709 0 01.57-2.078z" />
                    <path
                        fill="#252736"
                        d="M803.177 192.72a7.839 7.839 0 016.1-5.433 47.779 47.779 0 016.214-.95 166.007 166.007 0 0118.874.338c3.292.222 6.657.686 9.337 1.129h.095a5.433 5.433 0 014.473 6.235c-1.319 7.617-5.191 20.868-5.613 33.37-.559 16.416-.306 25.868-.591 29.825l-42.253-.812c-2.945-40.118.716-54.618 3.364-63.702z"
                    />
                    <path
                        fill="#263238"
                        d="M844.923 187.181h-.137c-2.827-.464-6.33-.96-9.843-1.192l-1.878-.105c-1.846 7.638-12.85 20.488-16.184 39.963-1.287-16.279-2.279-33.3-.39-39.932a83.988 83.988 0 00-8.925.749 9.083 9.083 0 00-6.689 6.224c-2.6 7.638-7.554 24.75-4.5 66.823h46.955c.306-4.22-.791-13.082.907-26.723 1.635-13.209 3.988-31.186 5.37-39.214a5.739 5.739 0 00-4.686-6.593z"
                    />
                    <path
                        fill="#252736"
                        d="M815.878 257.518a.274.274 0 01-.285-.253c-.992-11.869.865-28.907.886-29.086a.274.274 0 01.306-.243.285.285 0 01.253.306c0 .169-1.878 17.165-.886 28.97a.274.274 0 01-.253.306z"
                        opacity={0.2}
                    />
                    <path d="M843.267 203.882c-1.741 7.891-.812 18.821 1.055 28.791 1.382-11.194 3.27-25.721 4.653-34.709-1.928-1.288-4.328-.38-5.708 5.918z" opacity={0.3} />
                    <path
                        fill="#ffc3bd"
                        d="M816.385 186.285c5.475-1.2 6.425-4.579 6.035-8.313a23.735 23.735 0 00-.454-2.775l6.836-5.591 5.032-4.093c-1.371 6.056-2.88 17.1 1.255 21.227 0 0-3.376 5.539-12.765 4.864-7.933-.569-5.939-5.319-5.939-5.319z"
                    />
                    <path fill="#e0e0e0" d="M824.709 190.209l-1.519 1.435 4.853 1.825-.633-1.846z" />
                    <path fill="#f5f5f5" d="M828.423 196.044s-1.91-4.821-3.714-5.845a20.045 20.045 0 009.4-5.2l2.88 1.15s.908 5.588-8.566 9.895z" />
                    <path fill="#e0e0e0" d="M822.051 189.829l1.139 1.815-5.106.443 1.023-1.614z" />
                    <path fill="#f5f5f5" d="M815.52 194.682a22.989 22.989 0 016.53-4.853c-2.775-.823-2.943-4.642-2.943-4.642l-2.69.517s-4.937 4.801-.897 8.978z" />
                    <path d="M821.966 175.186a24.271 24.271 0 01.454 2.785c2.553-.464 5.971-3.165 6.33-5.75a11.838 11.838 0 00.074-2.616z" opacity={0.2} />
                    <path fill="#263238" d="M813.664 147.577c-2.448 3.007-2.215 13.884 2.965 14.01s1.487-19.496-2.965-14.01z" />
                    <path
                        fill="#ffc3bd"
                        d="M835.976 155.489c-.517 8.24-.411 13.082-4.568 17.344-6.246 6.4-16.374 2.722-18.325-5.475-1.741-7.385-.6-19.612 7.448-22.957a11.109 11.109 0 0115.445 11.088z"
                    />
                    <path
                        fill="#263238"
                        d="M831.925 148.632c-5.18 5.138 1.551 8.9-1.055 11.668 4.885 2.553 3.091 4.431 3.091 4.431a6.414 6.414 0 000 4.347c5.507-1.414 15.73-19.707 4.568-22.514-6.678 2.976-6.604 2.068-6.604 2.068z"
                    />
                    <path
                        fill="#263238"
                        d="M834.046 152.325c-4.906.76-11.077-1.582-14.907-3.566s-4.389 1.762-2.975 2.9c-2.458.084-5.57-1.857-4.8-6.193.707-3.956 5.887-7.7 13.261-4.357 5.222 2.363 11.658-.749 14.169 1.983s-1.478 8.716-4.748 9.233z"
                    />
                    <path fill="#263238" d="M837.401 146.951c3.292-.433 3.429-2.511 2.511-6.151 3.555 5.053 2.11 6.6 0 7.828-2.353-.787-2.511-1.677-2.511-1.677z" />
                    <path fill="#ffc3bd" d="M838.192 161.809a7.533 7.533 0 01-3.6 3.682c-2.036.918-3.07-.96-2.521-3.165.5-1.994 2.11-4.864 4.22-4.842s2.819 2.289 1.901 4.325z" />
                    <path
                        fill="#263238"
                        d="M823.116 157.98c0 .665-.359 1.213-.791 1.224s-.749-.538-.749-1.224.359-1.213.791-1.213.749.537.749 1.213zm-7.553.063c0 .675-.359 1.213-.8 1.224s-.791-.538-.781-1.213.348-1.213.791-1.213.79.537.79 1.202z"
                    />
                    <path fill="#ed847e" d="M818.41 158.275a24.266 24.266 0 01-3.165 5.76 3.851 3.851 0 003.165.58z" />
                    <path
                        fill="#263238"
                        d="M821.069 166.419a5.56 5.56 0 003.872-2.247.195.195 0 10-.327-.211 5.381 5.381 0 01-4.325 2.11.2.2 0 10.011.39 4.91 4.91 0 01.769-.042zm5.708-11.109a.3.3 0 00.179-.074.38.38 0 00.074-.549 3.882 3.882 0 00-3.165-1.6.38.38 0 00-.348.422.411.411 0 00.433.369 3.017 3.017 0 012.5 1.287.39.39 0 00.327.148zm-14.274.327a.39.39 0 00.3-.211 3.165 3.165 0 012.226-1.815.369.369 0 00.327-.443.411.411 0 00-.443-.338 3.946 3.946 0 00-2.827 2.237.4.4 0 00.179.527.475.475 0 00.243.042zm38.359 36.316c1.287 2.711 2.363 5.275 3.418 7.912s1.994 5.328 2.933 8.018a134.645 134.645 0 014.452 16.753c.243 1.466.517 2.9.7 4.41l.137 1.118.074.559v.675a17.091 17.091 0 010 3.281 14.77 14.77 0 01-2.395 6.678 14.97 14.97 0 01-4.79 4.484 20.047 20.047 0 01-5.085 2.11 27.165 27.165 0 01-4.853.865 34.71 34.71 0 01-9.273-.369 3.629 3.629 0 01-.19-7.121l.38-.084c1.055-.243 2.437-.538 3.619-.855s2.384-.686 3.5-1.055a15.614 15.614 0 005.328-2.954 3.5 3.5 0 00.918-1.393 2.87 2.87 0 000-1.129 7.1 7.1 0 00-.158-.844l-.38-1.72c-.243-1.2-.591-2.448-.907-3.671-.654-2.479-1.393-4.958-2.237-7.448s-1.709-4.969-2.627-7.448-1.888-4.958-2.87-7.385-2.015-4.98-3-7.29v-.106a7.272 7.272 0 1113.272-5.95z"
                    />
                    <path fill="#ffc3bd" d="M832.843 244.362l-4.653 2.237 8.039 2.859s5.359-1.709 2.764-4.684l-1.445-.454c-2.321-.759-3.46-.677-4.705.042z" />
                    <path fill="#ffc3bd" d="M828.813 252.275l3.534.675a2.469 2.469 0 002.6-1.213l1.287-2.31-8.039-2.827-1.055 2.2a2.448 2.448 0 001.677 3.471z" />
                </g>
            </g>
        </svg>
    )
}

export default SvgHome

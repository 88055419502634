import * as React from 'react'
import { isUndefined } from 'lodash'
import useCall from 'hooks/useCall'
import { getUserRole } from 'utils/auth'
import Card from '../../molecules/card'
import { useTranslation } from 'react-i18next'
import CallAnimation from '../../atoms/call-animation'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import Button from '../../atoms/button'
import styles from './style.module.css'
import useApi from 'hooks/useApi'

const OutgoingCallCard = () => {
    const { t } = useTranslation('organisms')
    const api = useApi()
    const call = useCall()
    const userRole = getUserRole()
    const [loading, setLoading] = React.useState(false)
    const [isCalling, setCalling] = React.useState(true)

    const stopCalling = async () => {
        if (!isUndefined(call.hasOutGoingCall)) {
            setLoading(true)

            try {
                // setCalling(false)

                await api.Teacher.rejectCall(call.currentCall?.callId, call.currentCall?.appointment?.teacherId)
            } catch { }
            setLoading(false)
        }
    }

    return (
        <Modal
            isVisible={!isUndefined(call.hasOutGoingCall) && isCalling && call.hasOutGoingCall && !call.teacherAcceptedCall}
            onModalClose={stopCalling}
            size={ModalSize.Small}
            type={ModalType.Fullscreen}
            backdropUnClickable
            escUnClickable
        >
            <Card className={styles.container}>
                <div className={styles.wrapper}>
                    <CallAnimation />
                    <p className={styles.callText}>{t('outgoingCallModal.header')}</p>
                    <p className={styles.callDescriptionText}>
                        {userRole === 'Student' ? call.currentCall?.appointment?.teacher?.name : call.currentCall?.appointment?.student?.name}
                    </p>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button variation={'block'} onClick={stopCalling} loading={loading}>
                        {t('organisms:incomingCallModal.give-up')}
                    </Button>
                    {/* <Button variation={'block'} type="gradient" onClick={acceptCalling} loading={loading}>
                        {t('organisms:incomingCallModal.accept')}
                    </Button> */}
                </div>
            </Card>
        </Modal>
    )
}

export default OutgoingCallCard

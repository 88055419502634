import * as React from 'react'
import Card from 'components/molecules/card'
import { TeacherQueriesGetDetailsQueryResult as Teacher } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import { useTranslation } from 'react-i18next'

export default function TeacherSpecialitiesCard({ teacher }: { teacher: Teacher }) {
    const { t } = useTranslation('organisms')
    return (
        <Card heading={{ title: t('teacherAuth.specialities'), size: 'lg', alignment: 'left', divider: true }}>
            <div className={styles.specialities}>
                <div className={styles.col}>
                    <h3>{t('teachingStyles.title')}</h3>
                    <ul>
                        {teacher.specalities.teachingStyles.map(r => (
                            <li key={r}>{t('teachingStyles.' + r)}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.separator} />
                <div className={styles.col}>
                    <h3>{t('lessonTypes.title')}</h3>
                    <ul>
                        {teacher.specalities.lessonTypes.map(r => (
                            <li key={r}>{t('lessonTypes.' + r)}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.separator} />
                <div className={styles.col}>
                    <h3>{t('languageLevels.title')}</h3>
                    <ul>
                        {teacher.specalities.languageLevel.map(r => (
                            <li key={r}>{t('languageLevels.' + r)}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </Card>
    )
}

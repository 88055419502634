import * as React from 'react'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import AppleIcon from 'components/icons/AppleIcon'
// import GoogleIcon from 'components/icons/GoogleIcon'
// import FacebookIcon from 'components/icons/FacebookIcon'

import styles from './style.module.css'
import Config from 'config';
import GoogleIcon from 'components/icons/GoogleIcon';
import jwtDecode from 'jwt-decode';

export type StudentAuthServiceSelectorCardProps = {
  onGoogleLoginClick: () => void
  onFacebookLoginClick: () => void
  onAppleLoginClick: () => void
  onDirectLoginClick: () => void
  onDirectRegisterClick: () => void
  onTeacherLoginClick: () => void
  onLoginGoogleAsStudent: (name: string, email: string) => void
}

const clientId = Config.googleApiKey.clientId;
const StudentAuthServiceSelectorCard = ({
  onDirectLoginClick,
  onDirectRegisterClick,
  onTeacherLoginClick,
  onLoginGoogleAsStudent
}: StudentAuthServiceSelectorCardProps) => {
  const { t } = useTranslation('organisms')

  // const onGoogleLoginClickHandler = () => {
  //     if (typeof onGoogleLoginClick === 'function') {
  //         onGoogleLoginClick()
  //     }
  // }
  //
  // const onFacebookLoginClickHandler = () => {
  //     if (typeof onFacebookLoginClick === 'function') {
  //         onFacebookLoginClick()
  //     }
  // }
  //
  // const onAppleLoginClickHandler = () => {
  //     if (typeof onAppleLoginClick === 'function') {
  //         onAppleLoginClick()
  //     }
  // }

  const onDirectLoginClickHandler = () => {
    if (typeof onDirectLoginClick === 'function') {
      onDirectLoginClick()
    }
  }

  const onDirectRegisterClickHandler = () => {
    if (typeof onDirectRegisterClick === 'function') {
      onDirectRegisterClick()
    }
  }

  const onTeacherLoginClickHandler = () => {
    if (typeof onTeacherLoginClick === 'function') {
      onTeacherLoginClick()
    }

  }

  const handleFailure = (result: any) => {
    console.log(result);
  };
  const onLoginGoogleAsStudentHandler = (googleData: any) => {
    const googleDataRes: any = jwtDecode(googleData?.credential);
    console.log(googleDataRes.name, googleDataRes.email);
    if (typeof onLoginGoogleAsStudent === 'function') {
      onLoginGoogleAsStudent(googleDataRes.name, googleDataRes.email)
    }
  }
  const handleLogin = async (googleData: any) => {
    console.log(googleData);
    const res = await fetch('/api/Student/LoginGoogleStudent', {
      method: 'POST',
      body: JSON.stringify({
        token: googleData.tokenId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await res.json();
    localStorage.setItem('loginData', JSON.stringify(data));
  };
  return (
    <div className={styles.container}>
      <div className='button-center'>

        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin
            width='320px'
            theme='filled_black'
            size='large'

            onSuccess={onLoginGoogleAsStudentHandler}
            onError={() => {
              console.log('Login Failed');
            }}
          />

        </GoogleOAuthProvider>
      </div>
      <br />

      {/* <Button size={'default'} type={'default'} alignment={'left'} onClick={onGoogleLoginClickHandler}>
               <GoogleIcon />
               {t('studentAuth.googleLogin')}
            </Button> */}

      {/*<Button size={'default'} type={'default'} alignment={'left'} onClick={onFacebookLoginClickHandler}>*/}
      {/*    <FacebookIcon />*/}
      {/*    {t('studentAuth.facebookLogin')}*/}
      {/*</Button>*/}

      {/*<Button size={'default'} type={'default'} alignment={'left'} onClick={onAppleLoginClickHandler}>*/}
      {/*    <AppleIcon />*/}
      {/*    {t('studentAuth.appleLogin')}*/}
      {/*</Button>*/}

      {/*<div className={styles.hr} />*/}

      <Button size={'default'} type={'default'} onClick={onDirectRegisterClickHandler}>
        {t('studentAuth.directRegister')}
      </Button>

      <Button size={'default'} type={'default'} onClick={onDirectLoginClickHandler}>
        {t('studentAuth.directLogin')}
      </Button>

      <div className={styles.hr} />

      <Button size={'large'} type={'danger'} onClick={onTeacherLoginClickHandler}>
        {t('studentAuth.teacherLogin')}
      </Button>
    </div>
  )
}

export default StudentAuthServiceSelectorCard

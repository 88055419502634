import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export type ErrorTemplateProps = {
    children: React.ReactNode
}

export default function ErrorTemplate({ children }: ErrorTemplateProps) {
    return <div className={classnames(styles.container)}>{children}</div>
}

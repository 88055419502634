import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export type TeachersTemplateProps = {
    children: React.ReactNode
}

export default function TeacherDetailTemplate({ children }: TeachersTemplateProps) {
    return <div className={classnames(styles['teacher-detail'])}>{children}</div>
}

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export type WaitTemplateProps = {
    children: React.ReactNode
}

export default function WaitTemplate({ children }: WaitTemplateProps) {
    return (
        <div className={classnames(styles.container)}>
            {children}
        </div>
    )
}

import * as React from 'react'
import InputSaveButton from 'components/atoms/input-save-button'
import InputReverseButton from 'components/atoms/input-reverse-button'
import Input, {IInput, InputProps, InputWrapperProps, ITextarea} from 'components/atoms/input'

import styles from './style.module.css'

type InputWithActionsProps = {
    onSubmit: () => void
    onReverse: () => void
    loading?: boolean
    value?: string
    initialValue?: string
    onChangeText?: (text: string) => void
}

type InputWithActionsTypes = InputWithActionsProps & (InputProps & InputWrapperProps & (IInput | ITextarea))

const InputWithActions = ({onSubmit, onReverse, loading, value, initialValue, onChangeText, ...props}: InputWithActionsTypes) => {
    const onChangeHandler = (text: string) => {
        onChangeText && onChangeText(text)
    }

    return (
        <Input
            value={value}
            onChangeText={onChangeHandler}
            {...props}
            trailingComponent={
                <div className={styles.actions}>
                    {initialValue !== value && <InputSaveButton loading={loading} onSubmit={onSubmit}/>}
                    {initialValue !== value && <InputReverseButton onReverse={onReverse}/>}
                </div>
            }
        />
    )
}

export default InputWithActions

import * as React from 'react'
import {isNumber} from 'lodash'
import useApi from 'hooks/useApi'
import classNames from 'classnames'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import Input from 'components/atoms/input'
import useDebounce from 'hooks/useDebounce'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Search2Line} from 'components/icons'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import PageHeading from 'components/molecules/page-heading'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProfileTemplate, {styles} from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import FavoriteWordButton from 'components/molecules/favorite-word-button'
import {WordTutorialQueriesFavoriteWordTutorial as Words, WordTutorialQueriesListMyFavoritesPagination as Pagination} from '@mohsininsignia/advancerapiclient'

export default function FavoriteWords() {
    const {t} = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [words, setWords] = React.useState<Words[]>([])
    const [pagination, setPagination] = React.useState<Pagination>({isNextPageAvailable: false})
    const [loading, setLoading] = React.useState<boolean>(true)

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    React.useEffect(() => {
            ;(async () => {
                setLoading(true)
                try {
                    const {data} = await api.WordTutorial.listMyFavoriteWordTutorials(pagination.lastId ?? undefined, debouncedQuery.trim() !== '' ? debouncedQuery : undefined)
                    setWords(data.favoritedWordTutorials)
                    setPagination(data.pagination)
                } catch {
                }
                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [api.WordTutorial, debouncedQuery])

    const paginate = async () => {
        if (!pagination.isNextPageAvailable || !isNumber(pagination.lastId) || words.length === 0) return
        try {
            const {data} = await api.WordTutorial.listMyFavoriteWordTutorials(pagination.lastId, query)

            setWords([...words, ...data.favoritedWordTutorials])
            setPagination(data.pagination)
        } catch {
        }
    }

    const favoriteChangeHandler = async (wordId: number, isFavorited: boolean) => {
        try {
            if (isFavorited) {
                await api.WordTutorial.favoriteWordTutorial(wordId)
            } else {
                await api.WordTutorial.unfavoriteWordTutorial(wordId)
            }
        } catch {
        }
    }

    if (!auth.isLoggedIn) {
        router.push({pathname: '/profile'})
        return <></>
    }

    return (
        <Master title={`${t('favoriteWords.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                <div>
                    <div className={styles.list}>
                        <PageHeading
                            title={t('favoriteWords.title')}
                            trailing={<Input onChangeText={setQuery}
                                             leadingComponent={<Search2Line className={classnames(styles.searchIcon, {[styles.searched]: query.length > 0})}/>}
                                             placeholder={t('favoriteWords.search')}/>}
                        />

                        {loading && <LoadingBox/>}

                        {!loading && !words.length && <ErrorBox text={t('favoriteWords.notFound')}/>}

                        {!loading && (
                            <InfiniteScroll next={paginate} hasMore={pagination.isNextPageAvailable} loader={<LoadingBox/>} dataLength={words.length}>
                                <div className={classNames(styles['result-grid'])}>
                                    {words.map((word) => (
                                        <FavoriteWordButton word={word} key={word.id + '_word'}
                                                            onChangeFavorite={isFavorited => favoriteChangeHandler(word.wordTutorialId, isFavorited)}/>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </ProfileTemplate>
        </Master>
    )
}

import * as React from 'react'
import {isUndefined} from 'lodash'
import classNames from 'classnames'
import useMedia from 'hooks/useMedia'
import {getVideoId} from 'utils/video-helpers'
import YoutubePlayer from 'components/molecules/youtube-player'
import WordTutorialResultCard from 'components/molecules/word-tutorial-result-card'
import {WordTutorialCategoryQueriesWordTutorial as Word} from '@mohsininsignia/advancerapiclient'
import WordTutorialVideoSubtitleCard from 'components/atoms/word-tutorial-video-subtitle-card'

import styles from './style.module.css'

type WordTutorialSearchResultProps = {
    words: Word[]
}

const WordTutorialSearchResult = ({words}: WordTutorialSearchResultProps) => {
    const isMobileOrTablet = useMedia('(max-width: 1024px)')

    const [selectedWord, setSelectedWord] = React.useState<Word>()
    const [subtitleEn, setSubtitleEn] = React.useState('')
    const [subtitleTr, setSubtitleTr] = React.useState('')

    const onSelect = (word: Word) => {
        setSelectedWord(selectedWord && selectedWord.id === word.id ? undefined : word)
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.active]: !isMobileOrTablet && selectedWord,
            })}
        >
            <div className={styles.list}>
                {words.map(word => (
                    <WordTutorialResultCard
                        key={word.id}
                        word={word}
                        onSelect={onSelect}
                        isAnotherSelected={selectedWord && selectedWord.id !== word.id}
                        isSelected={selectedWord && selectedWord.id === word.id}
                    />
                ))}
            </div>
            {!isMobileOrTablet && !isUndefined(selectedWord) && (
                <div className={styles.videoWrapper}>
                    <div>
                        <div className={styles.video}>
                            <YoutubePlayer
                                videoId={getVideoId(selectedWord.youtubeVideoUrl)}
                                enSrtSPath={selectedWord.subtitles.find(x => x.languageCode === 'en')?.subtitlePath}
                                trSrtPath={selectedWord.subtitles.find(x => x.languageCode === 'tr')?.subtitlePath}
                                onEnTextChanged={setSubtitleEn}
                                onTrTextChanged={setSubtitleTr}
                            />
                        </div>
                        <WordTutorialVideoSubtitleCard title={subtitleEn} description={subtitleTr}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default WordTutorialSearchResult

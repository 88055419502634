import classnames from 'classnames'
import Card from 'components/molecules/card'
import { TeacherQueriesGetDetailsQueryResult as Teacher } from '@mohsininsignia/advancerapiclient'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

export default function TeacherAboutCard({ teacher }: { teacher: Teacher }) {
    const { t } = useTranslation('organisms')

    return (
        <Card heading={{ title: t('about'), size: 'lg', alignment: 'left', divider: true }}>
            <div className={classnames(styles['teacher-about-text'])}>{teacher.description}</div>
        </Card>
    )
}

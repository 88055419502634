import * as React from 'react'
import useApi from 'hooks/useApi'
import { isUndefined } from 'lodash'
import useAuth from 'hooks/useAuth'
import { useHistory } from 'react-router-dom'
import {
    AcceptCallDTO,
    AppointmentFinishedDTO,
    AppointmentStartedDTO,
    AppointmentStartingDTO,
    CallTimeoutDTO,
    DirectCallDTO,
    DirectCallStartedDTO,
    FreeCallDTO,
    RejectCallDTO,
    TeacherNotJoinedToAppointmentDTO
} from 'types/chatProvider'
import { LiveCommandsJoinToRoomAsStudentCommandResult, LiveCommandsJoinToRoomAsTeacherCommandResult } from '@mohsininsignia/advancerapiclient'

export type CallContextProps = {
    latestFinishedAppointmentId: number | undefined
    teacherAcceptedCall: boolean

    hasInComingCall: boolean
    hasOutGoingCall: boolean

    currentCall: FreeCallDTO | DirectCallDTO | undefined
    setCurrentCall: React.Dispatch<React.SetStateAction<FreeCallDTO | DirectCallDTO | undefined>>

    startCall: (call: FreeCallDTO | DirectCallDTO) => void
    callTimeOut: (call: CallTimeoutDTO) => void
    acceptCall: (call: AcceptCallDTO) => void
    rejectCall: (call: RejectCallDTO) => void
    startAppointment: (appointment: DirectCallStartedDTO | AppointmentStartedDTO) => void
    finishAppointment: (appointment: AppointmentFinishedDTO) => void
    teacherNotJoined: (appointment: TeacherNotJoinedToAppointmentDTO) => void
}

export const CallContext = React.createContext<Partial<CallContextProps>>({})

export const CallProvider: React.FC = ({ children }) => {
    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [hasInComingCall, setInComingCall] = React.useState<boolean>(false)
    const [hasOutGoingCall, setOutGoingCall] = React.useState<boolean>(false)
    const [currentCall, setCurrentCall] = React.useState<FreeCallDTO | DirectCallDTO | undefined>(undefined)
    const [teacherAcceptedCall, setTeacherAcceptedCall] = React.useState(false)
    const [latestFinishedAppointmentId, setLatestFinished] = React.useState<number | undefined>(undefined)
    const [room, setRoom] = React.useState<LiveCommandsJoinToRoomAsStudentCommandResult | LiveCommandsJoinToRoomAsTeacherCommandResult | undefined>(undefined)
    // const [meetingUrl, setMeetingUrl] = React.useState<string>('')
    const resetCall = React.useCallback(() => {
        auth.userFunctions?.setCurrentCall?.(undefined)
        setCurrentCall(undefined)
        setInComingCall(false)
        setOutGoingCall(false)
        setTeacherAcceptedCall(false)

    }, [auth.userFunctions])

    React.useEffect(() => {
        (async () => {
            if (auth.user?.currentCall && auth.user.currentCall.callId && isUndefined(currentCall)) {

                const { data } = await api.Live.getCall(auth.user.currentCall.callId)
                setCurrentCall(data)
                data.isTeacherAccepted && setTeacherAcceptedCall(data.isTeacherAccepted)

            }
        })()
    }, [api.Live, auth.user?.currentCall, currentCall])

    React.useEffect(() => {
        if (!isUndefined(currentCall) && !isUndefined(currentCall.appointment) && !isUndefined(auth.user)) {
            switch (auth.user.userRole) {
                case 'Teacher':
                    if (currentCall.appointment.teacherId === auth.user.teacherId) {
                        setInComingCall(true)
                    }
                    break
                case 'Student':
                    if (currentCall.appointment.studentId === auth.user.studentId) {
                        setOutGoingCall(true)

                        if (auth.user.canMakeFreeCall) {
                            auth.userFunctions?.setCanFreeCall?.(false)
                        }
                    }
                    break
                default:
                    resetCall()
                    break
            }
        }
    }, [auth.user, auth.userFunctions, currentCall, resetCall])

    React.useEffect(() => {
        (async () => {
            if (teacherAcceptedCall && !isUndefined(currentCall) && !isUndefined(currentCall.appointment)) {
                setInComingCall(false)
                setOutGoingCall(false)
                // let Id = currentCall.appointment?.id
                // joinRoom(Id);

                // await router.push({ pathname: '/wait/' + currentCall?.callId })
            }
        })()
    }, [currentCall, router, teacherAcceptedCall])

    const startAppointment = (appointment: DirectCallStartedDTO | AppointmentStartedDTO) => {
        switch (appointment.appointmentType as any) {
            case "FREE":
                //FREE
                break;
            case "PAID_DIRECT":
                //PAID_DIRECT
                break;
            case "PAID":
                //PAID
                break;
        }
        const isCallAccepted = teacherAcceptedCall
        if (isCallAccepted && !isUndefined(currentCall) && !isUndefined(currentCall.appointment)) {
            setLatestFinished(undefined)
            let Id = currentCall.appointment.id;
            joinRoom(Id);
            // router.push({ pathname: '/' })
        }
    }

    const finishAppointment = (appointment: AppointmentFinishedDTO) => {
        if (currentCall?.appointment?.id === appointment.id) {
            setLatestFinished(appointment.id)
            resetCall()
        }
    }

    const startCall = (call: FreeCallDTO | DirectCallDTO | AppointmentStartingDTO) => {
        resetCall()
        setCurrentCall(call)
        auth.userFunctions?.setCurrentCall?.(call)

    }

    const acceptCall = async (call: AcceptCallDTO) => {
        if (currentCall?.callId === call.callId) {
            setTeacherAcceptedCall(true)
            const isCallAccepted = teacherAcceptedCall
            if (isCallAccepted && !isUndefined(currentCall) && !isUndefined(currentCall.appointment)) {
                let Id = currentCall.appointment.id;
                joinRoom(Id);
                // router.push({ pathname: '/' })
            }
        }
    }

    const closeCall = (call: RejectCallDTO) => {
        if (currentCall?.callId === call.callId) {
            resetCall()

            //Teacher Go Offline
            if (auth.user?.userRole === 'Teacher') {
                auth.userFunctions?.setOnlineStatus?.(false)
            }
            //Student Can Free Call
            if (currentCall.appointment?.appointmentType === "FREE" as any &&
                auth.user?.userRole === 'Student') {
                auth.userFunctions?.setCanFreeCall?.(true)
            }
        }
    }

    const rejectCall = (call: RejectCallDTO) => {
        closeCall(call)
    }

    const closePage = React.useCallback(async () => {
        router.replace({ pathname: '/' })
    }, [router])
    const callTimeOut = (call: CallTimeoutDTO) => {
        closeCall(call)
    }
    const joinRoom = React.useCallback(async (appintmentId) => {
        try {
            switch (auth.user?.userRole) {
                case 'Teacher':
                    const { data: teacherRoom } = await api.Live.joinToRoomAsTeacher(appintmentId)
                    setRoom(teacherRoom)
                    console.log('teacherRoom', teacherRoom)
                    let URL: any = teacherRoom.startUrl
                    console.log("URL: ", URL);
                    let userAgent = navigator.userAgent;
                    let browserName;
                    if (URL) {

                        if (userAgent.match(/chrome|chromium|crios/i)) {
                            browserName = "chrome";
                        } else if (userAgent.match(/firefox|fxios/i)) {
                            browserName = "firefox";
                        } else if (userAgent.match(/safari/i)) {
                            browserName = "safari";
                        } else if (userAgent.match(/opr\//i)) {
                            browserName = "opera";
                        } else if (userAgent.match(/edg/i)) {
                            browserName = "edge";
                        } else {
                            browserName = "No browser detection";
                        }
                        if (browserName === "safari" || browserName === "firefox" || browserName === "opera") {
                            window.location.href = URL
                        } else {
                            window.location.href = URL

                            // window.open(URL, '_blank', "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212");

                            if (window) {
                                window.focus();
                            }
                            // window.history.back();
                            // Close the new window/tab after it opens
                            if (window) {
                                window.addEventListener('load', () => {
                                    window.close();
                                });
                            }
                        }
                        // setMeetingUrl(URL);
                        // const newWindow = window.open(URL, '_blank', "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212");
                        // window.location.href = URL
                    }


                    break
                case 'Student':
                    const { data: studentRoom } = await api.Live.joinToRoomAsStudent(appintmentId)
                    setRoom(studentRoom)
                    console.log('studentRoom', studentRoom);
                    console.log("Pop successfully applied1");
                    let URLStudent: any = studentRoom.joinUrl;
                    console.log("URLStudent: ", URLStudent);
                    // setMeetingUrl(URLStudent);
                    let userAgentStudent = navigator.userAgent;
                    let browserNameStn;
                    if (URLStudent) {
                        if (userAgentStudent.match(/chrome|chromium|crios/i)) {
                            browserNameStn = "chrome";
                        } else if (userAgentStudent.match(/firefox|fxios/i)) {
                            browserNameStn = "firefox";
                        } else if (userAgentStudent.match(/safari/i)) {
                            browserNameStn = "safari";
                        } else if (userAgentStudent.match(/opr\//i)) {
                            browserNameStn = "opera";
                        } else if (userAgentStudent.match(/edg/i)) {
                            browserNameStn = "edge";
                        } else {
                            browserNameStn = "No browser detection";
                        }
                        if (browserNameStn === "safari" || browserNameStn === "opera") {
                            window.location.href = URLStudent
                        } else {
                            if (browserNameStn === "firefox") {
                                window.location.href = URLStudent
                                // router.replace({ pathname: '/profile/appointments' })
                            } else {
                                window.location.href = URLStudent
                                // window.open(URLStudent, '_blank', "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212");
                                if (window) {
                                    window.focus();
                                } else {
                                    // Handle case where new window was blocked
                                    console.log('New window was blocked by the browser');
                                }
                                router.replace({ pathname: '/profile/appointments' })
                            }
                        }
                    }

                    break
            }
        } catch {
            await closePage()
        }
    }, [api.Live, auth.user?.userRole, closePage, router])


    const teacherNotJoined = (appointment: TeacherNotJoinedToAppointmentDTO) => {
        if (currentCall?.appointment?.id === appointment.appointmentId) {

            if (currentCall.appointment?.appointmentType === "FREE" as any &&
                auth.user?.userRole === 'Student' && currentCall.appointment?.studentId === auth.user.studentId) {
                auth.userFunctions?.setCanFreeCall?.(true)
            }

            resetCall()
        }
    }

    React.useEffect(() => {
        if (teacherAcceptedCall && !isUndefined(currentCall) && !isUndefined(currentCall.appointment) && !isUndefined(currentCall.appointment.teacherId && !isUndefined(room))) {
            let Id = currentCall.appointment.id;
            joinRoom(Id);
            // router.push({ pathname: '/' })
        }
    }, [teacherAcceptedCall])
    return (
        <CallContext.Provider
            value={{
                latestFinishedAppointmentId,
                teacherAcceptedCall,
                currentCall,
                setCurrentCall,
                hasInComingCall,
                hasOutGoingCall,
                acceptCall,
                startCall,
                callTimeOut,
                rejectCall,
                startAppointment,
                finishAppointment,
                teacherNotJoined
            }}>{children}</CallContext.Provider>
    )
}

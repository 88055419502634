import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import { useTranslation } from 'react-i18next'

type StatusBadgeProps = {
    text?: string
    status: 'online' | 'offline' | 'occupied'
}

export default function StatusBadge({ text, status }: StatusBadgeProps) {
    const { t } = useTranslation('atoms')

    const labels = {
        online: t('online'),
        offline: t('offline'),
        occupied: t('occupied'),
    }

    return (
        <div className={classnames(styles['status-badge'], styles[status])}>
            <div className={classnames(styles.indicator)} />
            <div className={classnames(styles.label)}>{text || labels[status]}</div>
        </div>
    )
}

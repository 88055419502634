import * as React from 'react'
import useApi from 'hooks/useApi'
import { isUndefined } from 'lodash'
import useAuth from 'hooks/useAuth'
import { toast } from 'react-toastify'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import { InputProps } from 'components/atoms/input'
import NewPasswordCard from '../new-password-card'
import { ArrowLeftSLine, CloseLine } from 'components/icons'
import ForgottenPasswordCard from '../forgot-password-card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import LoginWithDirectAuthCard from '../login-with-direct-auth-card'
import StudentAuthServiceSelectorCard from '../student-auth-service-selector-card'
import StudentRegisterCard, { StudentRegisterProps } from '../student-register-card'
import TeacherAuthServiceSelectorCard from '../teacher-auth-service-selector-card'
import TeacherRegisterCard, { TeacherRegisterProps } from '../teacher-register-card'
import TeacherSpecialitiesSelectionCard, { TeacherSpecialities } from '../teacher-specialities-selection-card'
import StudentSpecialitiesSelectionCard, { StudentSpecialities } from '../student-specialities-selection-card'


import styles from './style.module.css'
import Config from '../../../config'

const AuthModal = () => {
    const { t } = useTranslation('common')

    const api = useApi()
    const auth = useAuth()

    const [recoverEmail, setRecoverEmail] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [activePage, setActivePage] = React.useState<string>('service-selection')
    const [teacherRegister, setTeacherRegister] = React.useState<TeacherRegisterProps | undefined>(undefined)
    const [studentRegister, setStudentRegister] = React.useState<StudentRegisterProps | undefined>(undefined)
    const [teacherSpecialities, setTeacherSpecialities] = React.useState<TeacherSpecialities | undefined>(undefined)
    const [studentSpecialities, setStudentSpecialities] = React.useState<StudentSpecialities | undefined>(undefined)
    const [loginStatus, setLoginStatus] = React.useState<InputProps['status']>('default')

    const [pages, setPages] = React.useState<{ [key: string]: any } | undefined>(undefined)

    const responseGoogle = (response: any) => {
        console.log(response);
    }
    React.useEffect(() => {
        setPages({
            'service-selection': {
                title: t('authModal.loginAsStudent'),
                prev: null,
            },
            'student-direct-login': {
                title: t('authModal.loginAsStudent'),
                prev: 'service-selection',
            },
            'student-register': {
                title: t('authModal.registerAsStudent'),
                prev: 'service-selection',
            },
            'teacher-selection': {
                title: t('authModal.loginAsTeacher'),
                prev: 'service-selection',
            },
            'teacher-login': {
                title: t('authModal.loginAsTeacher'),
                prev: 'teacher-selection',
            },
            'teacher-register': {
                title: t('authModal.registerAsTeacher'),
                prev: 'teacher-selection',
            },
            'teacher-specialities': {
                title: t('authModal.specialities'),
                prev: 'teacher-register',
            },
            'student-specialities': {
                title: t('authModal.specialities'),
                prev: 'student-register',
            },
            'forgot-password': {
                title: t('authModal.forgotPassword'),
                prev: 'service-selection',
            },
            'new-password': {
                title: t('authModal.newPassword'),
                prev: 'forgot-password',
            },
        })
    }, [t])
    React.useEffect(() => {
        if (auth.modalShown) {
            setLoginStatus('default')
        }
    }, [auth.modalShown])

    const handleGoogleLogin = async () => {
        try {
            window.location.href = Config.api.baseUrl + '/api/Student/LoginWithGoogle'
        } catch { }
    }

    const handleAppleLogin = async () => {
        try {
            window.location.href = Config.api.baseUrl + '/api/Student/LoginWithApple'
        } catch { }
    }

    const handleFacebookLogin = async () => {
        try {
            window.location.href = Config.api.baseUrl + '/api/Student/LoginWithFacebook'
        } catch { }
    }

    const handleStudentLogin = async (email: string, password: string) => {
        setLoading(true)
        setLoginStatus('default')

        try {
            await api.Student.loginWithDirectAuthAsStudent(email, password)
            setLoginStatus('success')

            toast.success(t('authModal.successLogin'))
            modalCloseHandler()
        } catch {
            setLoginStatus('danger')
        }

        setLoading(false)
    }
    const handleLoginGoogleAsStudent = async (nameSurname: string, email: string) => {
        setLoading(true)
        setLoginStatus('default')

        try {
            await api.Student.loginGoogleAsStudent(nameSurname, email)
            setLoginStatus('success')

            toast.success(t('authModal.successLogin'))
            modalCloseHandler()
        } catch {
            setLoginStatus('danger')
        }

        setLoading(false)
    }
    const handleStudentRegistration = async (user: StudentRegisterProps, kvkk: boolean) => {
        if (!kvkk) return
        setLoading(true)

        try {
            await api.Student.registerAsStudent(
                user.name,
                user.email,
                user.phoneNumber,
                user.password,
                studentSpecialities?.accents?.join('|'),
                studentSpecialities?.languageLevels?.join('|')
            )

            toast.success(t('authModal.successRegister'))
            modalCloseHandler()
        } catch { }

        setLoading(false)
    }

    const handleTeacherLogin = async (email: string, password: string) => {
        setLoading(true)
        setLoginStatus('default')

        try {
            await api.Teacher.loginWithDirectAuthAsTeacher(email, password)
            setLoginStatus('success')

            toast.success(t('authModal.successLogin'))
            modalCloseHandler()
        } catch {
            setLoginStatus('danger')
        }

        setLoading(false)
    }

    const handleTeacherRegister = async (user: TeacherRegisterProps, kvkk: boolean) => {
        if (!kvkk) return

        setLoading(true)

        try {
            const avatar: any = user.avatar ? await fetch(user.avatar).then(r => r.blob()) : null
            await api.Teacher.registerAsTeacher(
                avatar,
                user.name,
                user.email,
                user.password,
                user.phone,
                user.bio,
                user.ytUrl,
                teacherSpecialities?.accents?.join('|'),
                teacherSpecialities?.teachingStyles?.join('|'),
                teacherSpecialities?.lessonTypes?.join('|'),
                teacherSpecialities?.languageLevels?.join('|')
            )

            toast.success(t('authModal.successRegister'))
            modalCloseHandler()
        } catch { }

        setLoading(false)
    }

    const handlePasswordReset = async (email: string) => {
        setLoading(true)
        try {
            await api.AllAccounts.lostPassword(email)

            toast.success(t('authModal.sendMailMessage'))
            setRecoverEmail(email)
            setActivePage('new-password')
        } catch { }

        setLoading(false)
    }

    const handleNewPassword = async (newPassword: string, recoverCode: string) => {
        setLoading(true)

        try {
            await api.AllAccounts.recoverPassword(recoverEmail, recoverCode, newPassword)
            setRecoverEmail('')

            modalCloseHandler()
        } catch { }

        setLoading(false)
    }

    const handleStudentBeforeSpecialitiesSelection = async (user: StudentRegisterProps) => {
        setStudentRegister(user)
        setActivePage('student-specialities')
    }

    const handleTeacherBeforeSpecialitiesSelection = async (user: TeacherRegisterProps) => {
        setTeacherRegister(user)
        setActivePage('teacher-specialities')
    }

    const handleStudentSpecialitiesSelection = async (specialities: StudentSpecialities) => {
        setStudentSpecialities(specialities)
        setActivePage('student-register')
    }

    const handleTeacherSpecialitiesSelection = async (specialities: TeacherSpecialities) => {
        setTeacherSpecialities(specialities)
        setActivePage('teacher-register')
    }

    const modalCloseHandler = () => {
        auth.setModalShown?.(false)
        setActivePage('service-selection')
    }

    const Leading = () => {
        if (activePage === 'service-selection') return null

        return (
            <button
                className={styles.headingButton}
                onClick={() => {
                    setLoginStatus('default')
                    // @ts-ignore
                    setActivePage(pages[activePage].prev)
                }}
            >
                <ArrowLeftSLine />
            </button>
        )
    }

    const Trailing = () => {
        return (
            <button className={styles.headingButton} onClick={() => auth.setModalShown?.(false)}>
                <CloseLine />
            </button>
        )
    }

    if (isUndefined(pages)) return <></>

    return (
        <Modal isVisible={auth.modalShown ?? false} onModalClose={modalCloseHandler} size={ModalSize.Small} type={ModalType.Fullscreen}>
            <div className={styles.wrapper}>
                <Card
                    heading={{
                        size: 'xl',
                        // @ts-ignore
                        title: pages[activePage].title,
                        leading: <Leading />,
                        trailing: <Trailing />,
                    }}
                    type="darken"
                    paddingles
                >
                    <div className={styles.cardBody}>
                        {activePage === 'service-selection' && (
                            <StudentAuthServiceSelectorCard
                                onDirectLoginClick={() => setActivePage('student-direct-login')}
                                onDirectRegisterClick={() => setActivePage('student-register')}
                                onTeacherLoginClick={() => setActivePage('teacher-selection')}
                                onAppleLoginClick={handleAppleLogin}
                                onFacebookLoginClick={handleFacebookLogin}
                                onGoogleLoginClick={handleGoogleLogin}
                                onLoginGoogleAsStudent={handleLoginGoogleAsStudent}
                            />
                        )}
                        {activePage === 'forgot-password' && <ForgottenPasswordCard loading={loading} onConfirm={handlePasswordReset} />}
                        {activePage === 'new-password' && <NewPasswordCard recoverEmail={recoverEmail} loading={loading} onConfirm={handleNewPassword} />}
                        {activePage === 'student-direct-login' && (
                            <LoginWithDirectAuthCard
                                loading={loading}
                                onRegister={() => setActivePage('student-register')}
                                onForgotPassword={() => setActivePage('forgot-password')}
                                onConfirm={handleStudentLogin}
                                status={loginStatus}
                            />
                        )}
                        {activePage === 'student-register' && (
                            <StudentRegisterCard
                                loading={loading}
                                initialValues={studentRegister}
                                onDirectLoginClick={() => setActivePage('student-direct-login')}
                                onConfirm={handleStudentRegistration}
                                specialities={studentSpecialities}
                                onspecialitiesClicked={handleStudentBeforeSpecialitiesSelection}
                            />
                        )}
                        {activePage === 'teacher-selection' && (
                            <TeacherAuthServiceSelectorCard
                                onDirectLoginClick={() => setActivePage('teacher-login')}
                                onDirectRegisterClick={() => setActivePage('teacher-register')}
                            />
                        )}
                        {activePage === 'teacher-login' && (
                            <LoginWithDirectAuthCard
                                loading={loading}
                                onRegister={() => setActivePage('teacher-register')}
                                onForgotPassword={() => setActivePage('forgot-password')}
                                onConfirm={handleTeacherLogin}
                                status={loginStatus}
                            />
                        )}
                        {activePage === 'teacher-register' && (
                            <TeacherRegisterCard
                                loading={loading}
                                initialValues={teacherRegister}
                                specialities={teacherSpecialities}
                                onspecialitiesClicked={handleTeacherBeforeSpecialitiesSelection}
                                onConfirm={handleTeacherRegister}
                            />
                        )}
                        {activePage === 'teacher-specialities' && (
                            <TeacherSpecialitiesSelectionCard specialities={teacherSpecialities} onConfirm={handleTeacherSpecialitiesSelection} />
                        )}

                        {activePage === 'student-specialities' && (
                            <StudentSpecialitiesSelectionCard specialities={studentSpecialities} onConfirm={handleStudentSpecialitiesSelection} />
                        )}
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default AuthModal

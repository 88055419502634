export function YouTubeGetID(url: string) {
    if(!url) return null
    const splitUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    return (splitUrl[2] !== undefined) ? splitUrl[2].split(/[^0-9a-z_-]/i)[0] : splitUrl[0]
}

export function getCoverPhoto(id: string) {
    if(id.length >= 10){
        return `https://i1.ytimg.com/vi/${id}/sddefault.jpg`
    }

    return '/img/yt_broken.png'
}

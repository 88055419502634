import * as React from 'react'
import classnames from 'classnames'
import {getMessageTime, getUtcToTimezone} from 'utils/time-helpers'

import styles from './style.module.css'

type ConversationBubbleProps = {
    message: string
    time: Date
    alignToRight: boolean
}

const ConversationBubble = ({message, time, alignToRight}: ConversationBubbleProps) => {
    const bodyWrapperStyles = alignToRight ? styles.rightWrapper : styles.leftWrapper

    return (
        <div
            className={classnames({
                [styles.alignRight]: alignToRight,
                [styles.alignLeft]: !alignToRight,
            })}
        >
            <div className={bodyWrapperStyles}>
                <div className={styles.body}>
                    <div className={classnames(styles.message, alignToRight ? styles.colorWhite : styles.colorDark)}>{message}</div>
                </div>
                <div className={styles.timeWrapper}>
                    <div className={classnames(styles.time, alignToRight ? styles.colorWhite : styles.colorGray)}>{getMessageTime(getUtcToTimezone(time).toDate())}</div>
                </div>
            </div>
        </div>
    )
}

export default ConversationBubble

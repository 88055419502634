import {enUS, tr} from 'date-fns/locale'
import Cookies from 'universal-cookie'
import i18n from 'i18next'

const cookies = new Cookies()

export function FormatLocale() {
    let lang = cookies.get('lang')
    lang = localStorage.getItem('lang')

    i18n.on('languageChanged', function (lng) {
        lang = lng
    })

    return {locale: lang === 'en' ? enUS : tr}
}

import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import Textarea from 'components/atoms/textarea'
import { ArrowLeftSLine } from 'components/icons'
import { TextChange } from 'components/atoms/input'

import styles from './style.module.css'

export type ReportContentModalCardProps = {
    isVisible: boolean
    reasonForReport: string
    onModalClose: () => void
    onCancelButtonClick: () => void
    onReportButtonClick: () => void
    onChangeText: TextChange
    onBackButtonClick: () => void
}

const ReportContentModalCard = ({
    isVisible,
    reasonForReport,
    onModalClose,
    onReportButtonClick,
    onCancelButtonClick,
    onBackButtonClick,
    onChangeText,
}: ReportContentModalCardProps) => {
    const { t } = useTranslation('organisms')

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onCancelButtonClickHandler = () => {
        if (typeof onCancelButtonClick === 'function') {
            onCancelButtonClick()
        }
    }

    const onReportButtonClickHandler = () => {
        if (typeof onReportButtonClick === 'function') {
            onReportButtonClick()
        }
    }

    const onChangeTextHandler = (text: string) => {
        if (typeof onChangeText === 'function') {
            onChangeText(text)
        }
    }

    const onBackButtonClickHandler = () => {
        if (typeof onBackButtonClick === 'function') {
            onBackButtonClick()
        }
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} size={ModalSize.Small} type={ModalType.Centered}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('reportContentModalCard.title'),
                        size: 'lg',
                        divider: true,
                        leading: <ArrowLeftSLine className={styles.arrowLeft} onClick={onBackButtonClickHandler} />,
                    }}
                >
                    <div className={styles.reasonWrapper}>
                        <Text size={'sm'} type={'default'} weight={'bold'}>
                            {t('reportContentModalCard.reason')}
                        </Text>

                        <Text size={'sm'} type={'dimmed-light'} weight={'medium'}>
                            {reasonForReport}
                        </Text>
                    </div>

                    <div className={styles.headerWrapper}>
                        <Text size={'sm'} type={'default'} weight={'bold'}>
                            {t('reportContentModalCard.header')}
                        </Text>

                        <Text size={'sm'} type={'dimmed-light'} weight={'medium'}>
                            {t('reportContentModalCard.optional')}
                        </Text>
                    </div>

                    <Textarea style={{ height: 123 }} onChangeText={onChangeTextHandler} max={1000} placeholder={t('reportContentModalCard.placeholder')} />

                    <div className={styles.descriptionWrapper}>
                        <Text size={'sm'} type={'dimmed-light'} weight={'medium'}>
                            {t('reportContentModalCard.description')}
                        </Text>
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'dimmed'} onClick={onCancelButtonClickHandler}>
                            {t('reportContentModalCard.cancel')}
                        </Button>
                        <Button size={'large'} type={'gradient'} onClick={onReportButtonClickHandler}>
                            {t('reportContentModalCard.report')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ReportContentModalCard

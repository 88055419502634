import * as React from 'react'
import {isNumber} from 'lodash'
import useApi from 'hooks/useApi'
import classNames from 'classnames'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import Input from 'components/atoms/input'
import useDebounce from 'hooks/useDebounce'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Search2Line} from 'components/icons'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import PageHeading from 'components/molecules/page-heading'
import VideoPlayer from 'components/organisms/video-player'
import InfiniteScroll from 'react-infinite-scroll-component'
import VideoRecordCard from 'components/molecules/video-record-card'
import ProfileTemplate, {styles} from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import {VideoRecordQueriesListVideoRecordsPagination as Pagination, VideoRecordQueriesVideoRecordListItem as VideoRecord} from '@mohsininsignia/advancerapiclient'

export default function VideoRecords() {
    const {t} = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const router = useHistory()

    const [videoRecords, setVideoRecords] = React.useState<VideoRecord[]>([])
    const [pagination, setPagination] = React.useState<Pagination>({isNextPageAvailable: false, lastVideoRecordIdInList: 0})
    const [loading, setLoading] = React.useState<boolean>(true)
    const [video, setVideo] = React.useState<string | undefined>(undefined)

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    React.useEffect(() => {
            ;(async () => {
                setLoading(true)
                try {
                    const {data} = await api.VideoRecord.listVideoRecords(pagination.lastVideoRecordIdInList !== 0 ? pagination.lastVideoRecordIdInList : undefined, debouncedQuery.trim() !== '' ? debouncedQuery : undefined)

                    setVideoRecords(data.videoRecords)
                    setPagination(data.pagination)
                } catch {
                }
                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [api.VideoRecord, debouncedQuery])

    const paginate = async () => {
        if (!pagination.isNextPageAvailable || !isNumber(pagination.lastVideoRecordIdInList) || videoRecords.length === 0) return

        try {
            const {data} = await api.VideoRecord.listVideoRecords(pagination.lastVideoRecordIdInList, query)

            setVideoRecords([...videoRecords, ...data.videoRecords])
            setPagination(data.pagination)
        } catch {
        }
    }

    if (!auth.isLoggedIn) {
        router.push({pathname: '/profile'})
        return <></>
    }

    return (
        <Master title={`${t('videoRecords.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                <div>
                    <div className={classNames(styles.list)}>
                        <PageHeading
                            title={t('videoRecords.title')}
                            trailing={
                                <Input
                                    onChangeText={setQuery}
                                    placeholder={t('videoRecords.search')}
                                    leadingComponent={
                                        <Search2Line
                                            className={classnames(styles.searchIcon, {[styles.searched]: query.length > 0})}
                                        />
                                    }
                                />
                            }
                        />

                        {loading && <LoadingBox/>}

                        {!loading && videoRecords.length === 0 && <ErrorBox text={t('videoRecords.notFound')}/>}

                        {!loading && (
                            <InfiniteScroll next={paginate} hasMore={pagination.isNextPageAvailable} loader={<LoadingBox/>} dataLength={videoRecords.length}>
                                <div className={classNames(styles['result-grid'])}>
                                    {videoRecords.map((r) => (
                                        <VideoRecordCard video={r} key={`${r.id}_video-record`} onClick={setVideo}/>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
                <VideoPlayer video={video} onClose={() => setVideo(undefined)}/>
            </ProfileTemplate>
        </Master>
    )
}

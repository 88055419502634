import * as React from 'react'
import {CSSProperties} from 'react'
import classnames from 'classnames'
import CardBody, {CardBodyProps} from 'components/atoms/card-body'
import CardHeading, {CardHeadingProps} from 'components/atoms/card-heading'

import styles from './style.module.css'

type CardProps = {
    heading?: CardHeadingProps
    children: React.ReactNode
    onClick?: Function
    borderType?: 'none' | 'default' | 'danger'
    type?: 'default' | 'darken'
    style?: CSSProperties
    shadowless?: boolean
    className?: string
    actionSheet?: boolean
}

const Card = ({heading, children, onClick, borderType = 'none', type = 'default', style, shadowless, actionSheet, className, ...props}: CardProps & CardBodyProps) => {
    const onClickHandler = () => {
        onClick && onClick()
    }

    return (
        <div
            className={classnames(
                styles.card,
                {
                    [styles[`border-${borderType}`]]: borderType,
                    [styles['shadowless']]: shadowless,
                    [styles['actionSheet']]: actionSheet,
                },
                styles[`type-${type}`],
                className
            )}
            style={style}
            onClick={onClickHandler}
        >
            {heading && <CardHeading {...heading} />}
            <CardBody {...props}>{children}</CardBody>
        </div>
    )
}

export default Card

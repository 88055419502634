import Error from '../error'
import * as React from 'react'
import useAuth from 'hooks/useAuth'
import {useTranslation} from 'react-i18next'
import Master from 'components/layouts/master'
import ProfileTemplate from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import ProfilePageTeacher from 'components/organisms/profile-page-teacher'
import ProfilePageStudent from 'components/organisms/profile-page-student'

export default function Profile() {
    const auth = useAuth()
    const {t} = useTranslation('common')

    React.useEffect(() => {
        if (auth.isLoggedIn) {
            auth.setModalShown?.(false)
        } else {
            auth.setModalShown?.(true)
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn])

    if (!auth.isLoggedIn) {
        return <Error statusCode={401}/>
    }

    return (

        <Master title={`${t('profile.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                {auth.user && (auth.user.userRole === 'Student' ? <ProfilePageStudent student={auth.user}/> : <ProfilePageTeacher teacher={auth.user}/>)}
            </ProfileTemplate>
        </Master>
    )
}

import * as React from 'react'
import classnames from 'classnames'
import Text from 'components/atoms/text'
import { use100vh } from 'react-div-100vh'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import { Link, useHistory } from 'react-router-dom'
import { CheckboxCircleFill, CloseLine } from 'components/icons'
import PaymentStatusTemplate, { styles } from 'components/templates/payment-status'

const PaymentStatusScreen: React.FC<{ status: 'success' | 'error' }> = ({ status }) => {
    const height = use100vh()
    const history = useHistory()
    const { t } = useTranslation('common')
    const [icon, setIcon] = React.useState<React.ReactNode>(<></>)
    const [title, setTitle] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')

    React.useEffect(() => {
        switch (status) {
            case 'success':
                setIcon(<CheckboxCircleFill />)
                setTitle(t('paymentStatusScreen.successful'))
                setDescription(t('paymentStatusScreen.successfulDescription'))
                setTimeout(() => {
                    history.push({ pathname: '/' })
                }, 3000)
                break
            case 'error':
                setIcon(<CloseLine />)
                setTitle(t('paymentStatusScreen.unsuccessful'))
                setDescription(t('paymentStatusScreen.unsuccessfulDescription'))
                break
        }
    }, [status, history, t])

    return (
        <Master title={`${title} ${t('title')}`}>
            <PaymentStatusTemplate>
                <div className={styles.container} style={{ height: `calc(${height ?? '100vh'} - 9rem)` }}>
                    <div
                        className={classnames(styles.icon, {
                            [styles.success]: status === 'success',
                            [styles.error]: status === 'error',
                        })}
                    >
                        {icon}
                    </div>
                    <div className={styles.texts}>
                        <Text size="2xl" weight={'medium'}>
                            {title}
                        </Text>
                        <Text size="xl" type={'dimmed'}>
                            {description}
                        </Text>
                    </div>

                    <Link to={'/'}>
                        <Button type="gradient" variation="wide">
                            {t('paymentStatusScreen.home')}
                        </Button>
                    </Link>
                </div>
            </PaymentStatusTemplate>
        </Master>
    )
}

export default PaymentStatusScreen

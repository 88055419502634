import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { AppInfoProvider } from './providers/appInfo'
import { ApiProvider } from './providers/api'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import 'styles/base.css'
import 'swiper/swiper.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ApiProvider>
                <AppInfoProvider>
                    <App />
                </AppInfoProvider>
            </ApiProvider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()

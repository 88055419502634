import Error from '../error'
import * as React from 'react'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import BigNumber from 'bignumber.js'
import { getUserRole } from 'utils/auth'
import usePayment from 'hooks/usePayment'
import { useHistory } from 'react-router-dom'
import Button from 'components/atoms/button'
import Text from 'components/atoms/text'
import { isEmpty, isNumber, isUndefined } from 'lodash'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import LoadingBox from 'components/atoms/loading-box'
import PaymentModal from 'components/organisms/payment-modal'
import ProfileTemplate, { styles } from 'components/templates/profile'
import ProfileNavigation from 'components/organisms/profile-navigation'
import SubscriptionSelection, { SubscriptionOnChange } from 'components/organisms/subscription-selection'
import { PaymentQueriesListDropDownSubscriptionOptionsQueryResult, PaymentQueriesListSubscriptionOptionsQueryResult as Payments } from '@mohsininsignia/advancerapiclient'
import Input from 'components/atoms/input'
import { toast } from 'react-toastify'
import Card from 'components/molecules/card'
import useMedia from 'hooks/useMedia'
import styless from './style.module.css'
import InputWithActions from 'components/molecules/input-with-actions'
import AccordionCard from 'components/molecules/accordion-card'


export default function SubscriptionPlans() {
    const { t } = useTranslation('common')
    const isMobile = useMedia('(max-width: 640px)')

    const api = useApi()
    const auth = useAuth()
    const role = getUserRole()
    const router = useHistory()
    const payment = usePayment()

    const [referralId, setReferralId] = React.useState<number | undefined>()
    const [referralCode, setReferralCode] = React.useState<string | undefined>('')
    const [IdentityNumber, setIdentityNumber] = React.useState<any | undefined>('')
    const [RegistrationAddress, setRegistrationAddress] = React.useState<string | undefined>('')
    const [City, setCity] = React.useState<string | undefined>('')
    const [Country, setCountry] = React.useState<string | undefined>('')
    const [ZipCode, setZipCode] = React.useState<string | undefined>('')
    const [initialReferralCode, setInitialReferralCode] = React.useState<string | undefined>('')
    const [isReferralApplied, setReferralApplied] = React.useState<boolean>(false)
    const [referralDiscount, setReferralDiscount] = React.useState<number | null | undefined>(undefined)
    const [totalDisPrice, setTotalDisPrice] = React.useState<number | undefined>(undefined)

    const [loading, setLoading] = React.useState(true)
    const [payments, setPayments] = React.useState<PaymentQueriesListDropDownSubscriptionOptionsQueryResult | any>(undefined)
    const [minutesPerDayPossibilities, setMinutesPerDayPossibilities] = React.useState<number | undefined>(undefined)
    const [dayPossibilities, setDayPossibilities] = React.useState<number | undefined>(undefined)
    const [checkedPackage, setCheckedPackage] = React.useState<number | undefined>(undefined)
    const [totalPrice, setTotalPrice] = React.useState<BigNumber | undefined>(undefined)
    const [disabledSubscription, setDisabledSubscription] = React.useState<boolean>(false)
    const [referralTotalMinutes, setReferralTotalMinutes] = React.useState<number>(0)
    React.useEffect(() => {
        ; (async () => {
            setLoading(true)
            if (auth.user?.userRole === 'Student') {
                try {
                    const data = await api.Payments.listDropDownSubscriptionOptions()
                    setPayments(data.data)
                } catch {
                    router.replace({ pathname: '/profile' })
                }
            }
            setLoading(false)
        })()
    }, [api.Payments, auth.user?.userRole, router])

    React.useEffect(() => {
        if (!isEmpty(IdentityNumber) &&
            !isEmpty(RegistrationAddress) &&
            !isEmpty(City) &&
            !isEmpty(Country) &&
            !isEmpty(ZipCode) && (!isEmpty(IdentityNumber) && IdentityNumber?.length >= 5)) {
            setDisabledSubscription(true)
        } else {
            setDisabledSubscription(false)
        }
    })

    const onChangeIdentity = async (i: string) => {
        setIdentityNumber(i)
    }
    const onChangeAddress = async (i: string) => {
        setRegistrationAddress(i)
    }
    const onChangeCity = async (i: string) => {
        setCity(i)
    }
    const onChangeCountry = async (i: string) => {
        setCountry(i)
    }
    const onChangeZipCode = async (i: string) => {
        setZipCode(i)
    }
    const subscriptionChangeHandler = ({ subscriptionId }: SubscriptionOnChange) => {
        setCheckedPackage(subscriptionId)

        if (referralCode) {
            if (referralDiscount) {
                setTotalDisPrice(200)
            }
        }
    }

    const onReferralCodeChange = async (v: string) => {
        console.log('v',v)
        setReferralCode(v)
        if (v) {
            var res = await api.Referrals.validationReferral(v, checkedPackage)
            if (res.data.valid) {
                setReferralId(res.data.id)
                setReferralApplied(true)
                setReferralDiscount(res.data.discount)
                console.log(totalPrice)

                if (res.data.discount && totalPrice) {
                    setTotalDisPrice(totalPrice.toNumber() - (totalPrice.toNumber() / 100) * res.data.discount)
                }
            } else {
                setReferralApplied(false)
            }
        }
    }

    const onReferralCodeSubmit = async () => {
        if (referralCode) {
            var res = await api.Referrals.validationReferral(referralCode, checkedPackage)
            if (res.data.valid) {
                setReferralId(res.data.id)
                toast.success(t('subscription.referralSuccessToast'))
                setReferralApplied(true)
                setReferralDiscount(res.data.discount)

                if (referralDiscount && totalPrice) {
                    setTotalDisPrice(totalPrice.toNumber() - (totalPrice.toNumber() / 100) * referralDiscount)
                }

                console.log(res.data.discount)
                console.log(totalPrice)
            } else {
                toast.error(t('subscription.referralDangerToast'))
            }
        }
    }

    const onReferralCodeReverse = async () => {
        setReferralCode(initialReferralCode)
        setInitialReferralCode(initialReferralCode)
        setReferralId(undefined)
        setReferralApplied(false)
    }
    const findPrice = () => {
        const foundItem = payments.packageDurationPossibilities.find((item: { id: number | undefined }) => item.id === checkedPackage)
        return foundItem ? foundItem.totalDiscounted : null;
    }

    const onSelectionComplete = async () => {
        if (referralCode) {
            try {
                var res = await api.Referrals.validationReferral(referralCode, checkedPackage)
                if (res.data.valid) {
                    setReferralId(res.data.id)
                    setReferralApplied(true)
                    setReferralDiscount(res.data.discount)

                    if (referralDiscount && totalPrice) {
                        setTotalDisPrice(findPrice() - (findPrice() / 100) * referralDiscount)
                    }

                    if (
                        // isNumber(minutesPerDayPossibilities) &&
                        // isNumber(dayPossibilities) &&
                        isNumber(checkedPackage) &&
                        // !isUndefined(totalDisPrice) &&
                        !isUndefined(payments) &&
                        !isUndefined(IdentityNumber) &&
                        !isUndefined(RegistrationAddress) &&
                        !isUndefined(City) &&
                        !isUndefined(Country) &&
                        !isUndefined(ZipCode)
                    ) {
                        payment.setProduct?.({
                            id: checkedPackage,
                            title: t('plans.title', { count: payments.packageDurationPossibilities[checkedPackage] }),
                            content: t('plans.content'),
                            description: t('plans.totalPrice', { count: referralDiscount ? findPrice() - referralDiscount * 0.01 * findPrice() : findPrice() }),
                            price: referralDiscount ? findPrice() - referralDiscount * 0.01 * findPrice() : findPrice(),
                            type: 'subscription',
                            // minutesPerDay: minutesPerDayPossibilities,
                            // daysPerWeek: dayPossibilities,
                            // packageDuration: payments.packageDurationPossibilities[checkedPackage].durationAsMonths,
                            activePremium: auth.user?.userRole === 'Student' && auth.user.isPremium,
                            referralId: referralId,
                            billingAddress: {
                                identityNumber: IdentityNumber ? IdentityNumber : '',
                                registrationAddress: RegistrationAddress ? RegistrationAddress : '',
                                city: City ? City : '',
                                country: Country ? Country : '',
                                zipCode: ZipCode ? ZipCode : '',
                            },
                        })
                        payment.showModal?.()
                    }
                } else {
                    toast.error(t('subscription.referralDangerToast'))
                }
            } catch (e) { }
        } else {
            console.log('doing');
            if (isNumber(checkedPackage) && !isUndefined(payments) &&
                !isUndefined(IdentityNumber) &&
                !isUndefined(RegistrationAddress) &&
                !isUndefined(City) &&
                !isUndefined(Country) &&
                !isUndefined(ZipCode)) {
                console.log(checkedPackage);
                payment.setProduct?.({
                    id: checkedPackage,
                    title: t('plans.title', { count: payments.packageDurationPossibilities[checkedPackage] }),
                    content: t('plans.content'),
                    description: t('plans.totalPrice', { count: findPrice() }),
                    // price: totalPrice.toNumber(),
                    type: 'subscription',
                    // minutesPerDay: minutesPerDayPossibilities,
                    // daysPerWeek: dayPossibilities,
                    // packageDuration: payments.packageDurationPossibilities[checkedPackage].durationAsMonths,
                    activePremium: auth.user?.userRole === 'Student' && auth.user.isPremium,
                    billingAddress: {
                        identityNumber: IdentityNumber ? IdentityNumber : '',
                        registrationAddress: RegistrationAddress ? RegistrationAddress : '',
                        city: City ? City : '',
                        country: Country ? Country : '',
                        zipCode: ZipCode ? ZipCode : '',
                    },
                })
                payment.showModal?.()
            }
            payment.showModal?.()
        }
    }

    React.useEffect(() => {
        if (!isReferralApplied) {
            setReferralCode('')
        }
    }, [isReferralApplied])
    if (role !== 'Student') {
        return <Error statusCode={401} />
    }

    return (
        <Master title={`${t('subscription.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation />

                {loading && <LoadingBox />}

                {!loading && payments && (
                    <div>
                        <div className={classnames(styles.narrow, styles.list)}>
                            <SubscriptionSelection
                                options={payments}
                                disabled={loading}
                                onChange={subscriptionChangeHandler}
                                setReferralTotalMinutes={setReferralTotalMinutes}
                                initialDayPossibilities={dayPossibilities!!}
                                initialMinutesPerDayPossibilities={minutesPerDayPossibilities!!}
                                setReferralApplied={setReferralApplied}
                                initialIndex={checkedPackage!!}
                            />

                            {isNumber(checkedPackage) ? (
                                <div style={{ zIndex: 'var(--z-index-1)' }}>
                                    <InputWithActions
                                        value={referralCode}
                                        initialValue={initialReferralCode}
                                        onSubmit={onReferralCodeSubmit}
                                        onReverse={onReferralCodeReverse}
                                        onChangeText={onReferralCodeChange}
                                        label={t('subscription.referralCode')}
                                    /></div>
                            ) : (
                                ''
                            )}
                            {isReferralApplied && referralDiscount ? (
                                <Card borderType="default">
                                    <div className={classnames(styless.wrapper)}>
                                        <Text size={isMobile ? '2xl' : 'xl'} type={'default'} weight={'medium'}>
                                            {`Referral Discount`}
                                            <Text size={isMobile ? '2xl' : 'xl'} type={'dimmed'} weight={'medium'}>
                                                {`% ${referralDiscount}`}
                                            </Text>
                                        </Text>

                                        <Text size={isMobile ? '2xl' : 'xl'} type={'dimmed'} weight={'medium'}>

                                        </Text>
                                        <div style={{ display: 'flex', flexDirection: 'column', }}>

                                            <Text size={isMobile ? '4xl' : '2xl'} type={'danger'} weight={isMobile ? 'medium' : 'bold'}>
                                                {`${findPrice() - referralDiscount * 0.01 * findPrice()}`} ₺<small>{` /monthly`}</small>
                                            </Text>
                                            <Text size={isMobile ? '4xl' : '2xl'} type={'dimmed'} weight={'medium'}>
                                                <small>{referralTotalMinutes} minutes</small>
                                            </Text>
                                        </div>
                                    </div>
                                </Card>
                            ) : (
                                ''
                            )}
                            {isNumber(checkedPackage) ? (
                                <AccordionCard
                                    title={t('subscription.BillingAddress')}
                                    subtitle={t('subscription.BillingAddressSubtitle')}
                                    isIndicatorShown
                                >
                                    <Input type={"text"} label={t('subscription.IdentityNumber')} minLength={5} required onChangeText={onChangeIdentity} value={IdentityNumber} />
                                    {(IdentityNumber?.length < 5) ?
                                        <Text size={'xs'} type={'danger'} weight={'default'}>
                                            {t('subscription.IdentityNumberLength')}
                                        </Text> :
                                        ""
                                    }

                                    <br />
                                    <Input type={"text"} label={t('subscription.RegistrationAddress')} required onChangeText={onChangeAddress} value={RegistrationAddress} /><br />
                                    <Input type={"text"} label={t('subscription.City')} required onChangeText={onChangeCity} value={City} /><br />
                                    <Input type={"text"} label={t('subscription.Country')} required onChangeText={onChangeCountry} value={Country} /><br />
                                    <Input type={"text"} label={t('subscription.ZipCode')} required onChangeText={onChangeZipCode} value={ZipCode} />
                                </AccordionCard>
                            ) : (
                                ''
                            )}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={styles.buttonWrapper}>
                                    <Button
                                        size={'large'}
                                        type={'gradient'}
                                        onClick={onSelectionComplete}
                                        variation={'block'}
                                        loading={loading}
                                        disabled={!disabledSubscription ?? false}
                                    >
                                        {t('subscription.beSubscription')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <PaymentModal />
            </ProfileTemplate>
        </Master>
    )
}

import * as React from 'react'
import useApi from 'hooks/useApi'
import Card from 'components/molecules/card'
import Master from 'components/layouts/master'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../utils/auth'
import { useHistory, useParams } from 'react-router-dom'
import YoutubePlayer from 'components/molecules/youtube-player'
import TeacherInfoCard from 'components/organisms/teacher-info-card'
import TeacherAboutCard from 'components/organisms/teacher-about-card'
import { TeacherQueriesGetDetailsQueryResult } from '@mohsininsignia/advancerapiclient'
import TeacherDetailTemplate, { styles } from 'components/templates/teacher-detail'
import TeacherSpecialitiesCard from '../../../components/organisms/teacher-specialities-card'
import RateAppointmentModalCard from 'components/organisms/rate-appointment-modal-card'
import RatingDisplay from 'components/molecules/rating-display/RatingDisplay'
import useAuth from 'hooks/useAuth'
import { isUndefined } from 'lodash'

export default function TeacherDetail() {
    const api = useApi()
    const auth = useAuth()
    const userRole = getUserRole()
    const router = useHistory()
    const { t } = useTranslation('common')
    const [teacher, setTeacher] = React.useState<TeacherQueriesGetDetailsQueryResult | undefined>()
    const [showRateCard, setShowRateCard] = React.useState(false)
    const { id } = useParams<{ id: string }>()
    const [studentID, setStudentID] = React.useState<number | undefined>(0)
    const [allowReview, setAllowReview] = React.useState<boolean>(true)
    const [seed, setSeed] = React.useState<number>(1)
    const writeReview = () => {
        setShowRateCard(true)
    }

    // const closePage = React.useCallback(async () => {
    //     await router.replace({ pathname: '/' })
    // }, [router])

    React.useEffect(() => {
        const getStudentData = async () => {
            if (userRole === 'Student') {
                const { data } = await api.Student.getMyDetailsAsStudent()
                setStudentID(data.studentId)
            }
        }
        getStudentData()
    }, [api.Student, userRole])

    const rateAppointment = async (rate: number, text?: string) => {
        try {
            await api.Teacher.teacherRatePost(teacher?.teacherId, studentID, rate, text)
            setShowRateCard(false)
            // await closePage()
        } catch { }
    }

    React.useEffect(() => {
        ; (async () => {
            try {
                const { data } = await api.Teacher.getTeacherDetails(parseInt(id))
                setTeacher(data)
            } catch (error) {

            }
        })()
    }, [api.Teacher, id, showRateCard])

    React.useEffect(() => {
        // for (let i = 0; i < 3; i++) {
        try {
            if (userRole === 'Student' && !isUndefined(studentID) && studentID > 0) {
                api.Teacher.checkStudentReview(teacher?.teacherId, studentID).then(res => {
                    console.log('res,res');
                    setAllowReview(res.data)
                })
            }
        } catch (error) {
            console.log('error',error);
        }
        // }
        setSeed(Math.random())
    }, [api.Teacher, studentID, teacher?.teacherId, userRole, showRateCard])

    if (!teacher) return <></>

    if (userRole === 'Teacher') {
        router.push({ pathname: '/' })
        return <></>
    }



    return (
        <>
            <Master title={`${teacher.name}${t('title')}`} key={seed}>
                <TeacherDetailTemplate>
                    <div className={styles['teacher-boxes']}>
                        <TeacherInfoCard teacher={teacher} />
                    </div>
                    <div className={styles['teacher-boxes']}>
                        <TeacherAboutCard teacher={teacher} />
                        <div className={styles.teacherSpecialitiesCard}>
                            <TeacherSpecialitiesCard teacher={teacher} />
                        </div>
                    </div>
                    <div className={styles['teacher-boxes']}>
                        <div>
                            <Card heading={{ divider: true, title: t('videoHeader'), alignment: 'left' }}>
                                <YoutubePlayer videoUrl={teacher.trailerVideo.videoUrl} />
                            </Card>
                        </div>
                    </div>
                </TeacherDetailTemplate>
            </Master>
            <div style={{ width: '100%' }}>
                <RatingDisplay writeReview={writeReview} allowReview={allowReview} key={seed} />
                {/* <RatingDisplay writeReview={writeReview} allowReview={false} key={seed} /> */}
            </div>
            <RateAppointmentModalCard isVisible={showRateCard} onModalClose={() => setShowRateCard(false)} onSubmitButtonClick={rateAppointment} />

        </>
    )
}

/**
 * ## Youtube Player
 * must contain one of these:
 * - videoId
 * - videoUrl
 *
 * For example to use:
 * ```
 * <YoutubePlayer videoId='123456' />
 * ```
 * or
 * ```
 * <YoutubePlayer videoUrl='https://youtube.com/embed/123456' />
 * ```
 */

import axios from 'axios'
import * as React from 'react'
import classnames from 'classnames'
import YouTube from 'react-youtube'
import SrtParser2 from 'srt-parser-2'
import useInterval from 'hooks/useInterval'
import Button from 'components/atoms/button'
import { hmsToSecond } from 'utils/time-helpers'
import { isFunction } from 'lodash'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { getCoverPhoto, YouTubeGetID } from 'utils/youtube-helpers'
import { FullscreenExitLine, FullscreenLine, PauseCircleFill, PlayCircleFill, RabbitFast, TurtleSlow } from 'components/icons'

import styles from './style.module.css'
import PlayerStates from "youtube-player/dist/constants/PlayerStates";
import useHover from "../../../hooks/useHover";

type YoutubePlayerProps = {
    videoId?: string
    enSrtSPath?: string
    trSrtPath?: string
    enSrtString?: string
    trSrtString?: string
    onVideoEnd?: (id: string) => void
    onEnTextChanged?: (text: string) => void
    onTrTextChanged?: (text: string) => void
    onFullScreenChanged?: (fullScreen: boolean) => void
    loop?: 0 | 1
    videoUrl?: string
}

type SubtitleProps = {
    id: string; startTime: string; endTime: string; text: string
}

export default function YoutubePlayer(
    {
        videoId,
        enSrtSPath,
        trSrtPath,
        enSrtString,
        trSrtString,
        onVideoEnd,
        onEnTextChanged,
        onTrTextChanged,
        onFullScreenChanged,
        loop = 0,
        videoUrl = '',
    }: YoutubePlayerProps) {
    const [currentTime, setCurrentTime] = React.useState(0)
    const [isVideoPlay, setIsPlay] = React.useState(false)
    const [isVideoSlow, setIsSlow] = React.useState(false)
    const [isVideoEnd, setVideoEnd] = React.useState(false)
    const [isVideoFullScreen, setIsFullScreen] = React.useState(false)
    const [isVideoRendered, setVideoRendered] = React.useState(false)
    const [isVisibleButtons, setIsVisibleButtons] = React.useState(true)

    const [enText, setEn] = React.useState('')
    const [trText, setTr] = React.useState('')

    const [parsedEn, setParsedEn] = React.useState<SubtitleProps[]>([])
    const [parsedTr, setParsedTr] = React.useState<SubtitleProps[]>([])

    const videoRef = React.useRef<YouTube | null>(null)

    const buttonsRef = React.useRef<HTMLDivElement>(null)
    const isHover = useHover(buttonsRef)

    const computedVideoId = React.useMemo(() => videoId ?? YouTubeGetID(videoUrl), [videoId, videoUrl])

    const parseString = (str: string) => {
        const parser = new SrtParser2()
        let parsedStr = parser.fromSrt(str)

        return parsedStr.map(col => {
            let computedCol = col
            computedCol.startTime = hmsToSecond(computedCol.startTime)
            computedCol.endTime = hmsToSecond(computedCol.endTime)
            return computedCol
        })
    }

    React.useEffect(() => {
        if (enSrtString) setParsedEn(parseString(enSrtString))
        if (trSrtString) setParsedTr(parseString(trSrtString))
    }, [enSrtString, trSrtString])

    // Handle Remote Subtitles
    React.useEffect(() => {
        (async () => {
            // try {
            //     if (enSrtSPath) {
            //         const { data: enData } = await axios.get(enSrtSPath)
            //         setParsedEn(parseString(enData))
            //     }
            //     if (trSrtPath) {
            //         const { data: trData } = await axios.get(trSrtPath)
            //         setParsedTr(parseString(trData))
            //     }
            // } catch (error) {
            //     console.log(error)
            // }
        })()
    }, [enSrtSPath, trSrtPath])

    React.useEffect(() => {
        setIsVisibleButtons(true)
    }, [isHover])

    useInterval(async () => {
        if (isVideoPlay && videoRef.current) {
            const e = videoRef.current.getInternalPlayer().getCurrentTime()
            setCurrentTime(await e)

            const findEn = parsedEn.find(async x => parseFloat(x.startTime) < await e && parseFloat(x.endTime) > await e)
            const findTr = parsedTr.find(async x => parseFloat(x.startTime) < await e && parseFloat(x.endTime) > await e)

            onEnTextChangedHandler(findEn ? findEn.text : '')
            onTrTextChangedHandler(findTr ? findTr.text : '')

        }
    }, isVideoPlay ? 200 : null)

    const onReady = (event: { target: YouTubePlayer, data: number }) => {
        event.target.playVideo();
    }

    const setHideButtons = async (delay?: number) => {
        if (delay) {
            const timeout = setTimeout(async () => {
                if (buttonsRef.current && await videoRef.current?.getInternalPlayer().getPlayerState() === PlayerStates.PLAYING) {
                    buttonsRef.current.style.opacity = '0'
                    setIsVisibleButtons(false)
                }
            }, delay)

            if (await videoRef.current?.getInternalPlayer().getPlayerState() !== PlayerStates.PLAYING) {
                clearTimeout(timeout)
            }
        } else {
            if (buttonsRef.current) {
                buttonsRef.current.style.opacity = '0'
                setIsVisibleButtons(false)
            }
        }
    }

    const setVisibleButtons = (hideDelay?: number) => {
        if (buttonsRef.current) {
            buttonsRef.current.style.opacity = '1'
            setIsVisibleButtons(true)
        }

        if (hideDelay && isVideoPlay) {
            setHideButtons(hideDelay)
        }
    }

    const onPause = () => {
        setVisibleButtons()
    }

    const onPlay = (event: { target: YouTubePlayer, data: number }) => {
        event.target.unMute()
        setHideButtons(3000)
    }

    const onStateChange = (event: { target: YouTubePlayer; data: number }) => {
        setIsPlay(event.data === 1)
    }

    const onRateChange = (event: { target: YouTubePlayer; data: number }) => {
        setIsSlow(event.data !== 1)
    }

    const onVideoEndHandler = () => {
        setVideoEnd(true)
        setIsPlay(false)
        setVisibleButtons()

        if (isFunction(onVideoEnd) && computedVideoId) {
            onVideoEnd(computedVideoId)
        }
    }

    const onEnTextChangedHandler = (text: string) => {
        if (isFunction(onEnTextChanged) && text !== enText) {
            onEnTextChanged(text)
        }
        setEn(text)
    }

    const onTrTextChangedHandler = (text: string) => {
        if (isFunction(onTrTextChanged) && text !== trText) {
            onTrTextChanged(text)
        }
        setTr(text)
    }

    const coverClick = () => {
        if (!isVisibleButtons) {
            setVisibleButtons(3000)
        }
    }

    const onPlayPauseClickHandler = React.useCallback(() => {
        if (videoRef && videoRef.current) {
            if (!isVideoPlay) {
                videoRef.current.getInternalPlayer().playVideo()
            } else {
                videoRef.current.getInternalPlayer().pauseVideo()
            }
        } else {
            setVideoRendered(true)
        }
    }, [isVideoPlay])

    const onSlowFastHandler = React.useCallback((event: any) => {
        event.stopPropagation()
        if (isVisibleButtons) {
            if (isVideoSlow) {
                videoRef.current?.getInternalPlayer().setPlaybackRate(1)
            } else {
                videoRef.current?.getInternalPlayer().setPlaybackRate(0.5)
            }
        } else {
            setVisibleButtons(3000)
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [isVideoSlow, isVisibleButtons])

    const onFullScreenHandler = React.useCallback((event: any) => {
        event.stopPropagation()
        if (isVisibleButtons) {
            setIsFullScreen(!isVideoFullScreen)
            if (isFunction(onFullScreenChanged)) {
                onFullScreenChanged(!isVideoFullScreen)
            }
        } else {
            setVisibleButtons(3000)
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [isVideoFullScreen, isVisibleButtons, onFullScreenChanged])

    const renderButtons = React.useMemo(() => {
        return (
            <>
                <Button onClick={onSlowFastHandler} type="danger-2" variation="circle">
                    {isVideoSlow ? <RabbitFast /> : <TurtleSlow />}
                </Button>

                <Button onClick={onPlayPauseClickHandler} type="danger-2" variation="circle">
                    {isVideoPlay ? <PauseCircleFill /> : <PlayCircleFill />}
                </Button>

                <Button onClick={onFullScreenHandler} type="danger-2" variation="circle">
                    {isVideoFullScreen ? <FullscreenExitLine /> : <FullscreenLine />}
                </Button>
            </>
        )
    }, [isVideoFullScreen, isVideoPlay, isVideoSlow, onFullScreenHandler, onPlayPauseClickHandler, onSlowFastHandler])

    return (
        <div className={classnames(styles.container, { [styles.fullscreen]: isVideoFullScreen })}>

            <div className={styles.cover} onClick={onPlayPauseClickHandler}>
                {!isVideoPlay && (currentTime === 0 || isVideoEnd) && computedVideoId && (
                    <img src={getCoverPhoto(computedVideoId)} alt="video-cover" />
                )}

                <div className={styles.buttonsWrapper} ref={buttonsRef} onClick={coverClick}>
                    {renderButtons}
                </div>

            </div>

            <div className={styles.youtubeWrapper}>
                {isVideoRendered && (
                    <YouTube
                        className={styles.youtube}
                        videoId={computedVideoId!}
                        ref={(e) => videoRef.current = e}
                        onPlaybackRateChange={onRateChange}
                        onStateChange={onStateChange}
                        onPlay={onPlay}
                        onPause={onPause}
                        onEnd={onVideoEndHandler}
                        onReady={onReady}
                        opts={{
                            playerVars: {
                                autoplay: 0,
                                controls: 0,
                                modestbranding: 1,
                                rel: 0,
                                fs: 0,
                                showinfo: 0,
                                iv_load_policy: 3,
                                playsinline: 1,
                                mute: 1,
                                loop: loop,
                            },
                        }}
                    />
                )}
            </div>
        </div>
    )
}

import * as React from 'react'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Checkbox from 'components/atoms/checkbox'

import styles from './style.module.css'
import classnames from 'classnames'

type SubscriptionPackageDurationItemProps = {
    price: string | undefined
    description: string | undefined
    pricePer: string | undefined
    discountRate?: number
    TotalMinutes?: number
    isChecked?: boolean
    onChange?: (isChecked: boolean) => void
    disabled?: boolean
    setReferralTotalMinutes?: any
}

const SubscriptionPackageDurationItem = ({ price, pricePer, discountRate, isChecked, onChange, disabled, description, TotalMinutes, setReferralTotalMinutes }: SubscriptionPackageDurationItemProps) => {
    const isMobile = useMedia('(max-width: 640px)')

    const { t } = useTranslation('molecules')

    const onChangeHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        if (typeof onChange === 'function') {
            onChange(!isChecked)
        }
        setReferralTotalMinutes(TotalMinutes)
    }

    const renderDiscountLabel = React.useMemo(() => {
        // if (discountRate && discountRate > 0) {
        return (
            <div className={styles.discountWrapper}>
                <Text size={isMobile ? 'xs' : 'xs'} type={'white'} weight={isMobile ? 'medium' : 'bold'}>{`%${discountRate} ${t('subscription.discount')}`}</Text>
            </div>
        )
        // }
        // return
    }, [discountRate, isMobile, t])

    return (
        <Card borderType={discountRate && discountRate > 0 ? 'danger' : 'default'}>
            <div className={classnames(styles.wrapper, { [styles.disabled]: disabled })} onClick={e => onChangeHandler(e)}>
                <div className={styles.headerTextWrapper}>
                    <Text size={isMobile ? 'lg' : 'xl'} type={'default'} weight={'medium'}>
                        {`${pricePer} ${t('subscription.monthly')}`}
                    </Text>
                    <Text size={isMobile ? 'sm' : 'base'} type={'dimmed'} weight={'medium'}>
                        {description}
                    </Text>
                </div>
                <Checkbox circle={true} checked={isChecked ?? false} onChange={() => { }} />
                <div className={styles.headerTextWrapper}>

                    <Text size={isMobile ? 'lg' : '2xl'} type={'danger'} weight={isMobile ? 'medium' : 'bold'}>
                        {price} ₺<small>{` /${t('subscription.monthly')}`}</small>
                    </Text>
                    <Text size={isMobile ? 'lg' : 'base'} type={'dimmed'} weight={'medium'}>
                        {TotalMinutes} {t('subscription.totalMinutes')}
                    </Text>
                </div>
                <br hidden={isMobile ? false : true} />
                {renderDiscountLabel}
            </div>
        </Card>
    )
}

export default SubscriptionPackageDurationItem

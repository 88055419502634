import * as React from 'react'

export default function useHover<T extends HTMLElement = HTMLElement>(elementRef: React.RefObject<T>): boolean {
    const [value, setValue] = React.useState<boolean>(false)

    const handleMouseOver = () => setValue(true)
    const handleMouseOut = () => setValue(false)

    React.useEffect(() => {
        const node = elementRef?.current
        if (node) {
            node.addEventListener('mouseover', handleMouseOver)
            node.addEventListener('mouseout', handleMouseOut)

            return () => {
                node.removeEventListener('mouseover', handleMouseOver)
                node.removeEventListener('mouseout', handleMouseOut)
            }
        }
    }, [elementRef])

    return !!value
}

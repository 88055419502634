import semver from 'semver'
import Config from './config'
import * as React from 'react'
import Providers from 'providers'
import useApi from 'hooks/useApi'
import useMedia from 'hooks/useMedia'
import useAppInfo from 'hooks/useAppInfo'
import { Device } from '@capacitor/device'
import ScrollToTop from 'hooks/scrollToTop'
import { Route, Switch } from 'react-router-dom'
import PageTop from 'components/organisms/page-top'
import * as CapacitorApp from '@capacitor/app/dist/esm'
import AuthModal from 'components/organisms/auth-modal'
import PageBottom from 'components/organisms/page-bottom'
import { SplashScreen } from '@capacitor/splash-screen/dist/esm'
import { StatusBar, Style } from '@capacitor/status-bar/dist/esm'

import Home from 'screens/home'
import Wait from 'screens/wait'
import Live from 'screens/live'
import Error from 'screens/error'
import Help from 'screens/profile/help'
import Messages from 'screens/messages'
import CalendarScreen from 'screens/calendar'
import Profile from 'screens/profile/profile'
import Earnings from 'screens/profile/earnings'
import Teachers from 'screens/teachers/teachers'
import SubscriptionPlans from 'screens/profile/plans'
import Subscription from 'screens/profile/subscription'
import Appointments from 'screens/profile/appointments'
import VideoRecords from 'screens/profile/video-records'
import PaymentStatusScreen from 'screens/payment-status'
import BlockedUsers from 'screens/profile/blocked-users'
import FavoriteWords from 'screens/profile/favorite-words'
import Appointment from 'screens/teachers/[id]/appointment'
import ChatMessages from 'components/organisms/chat-messages'
import WordTutorialDetail from 'screens/word-tutorials/[slug]'
import FavoriteTeachers from 'screens/profile/favorite-teachers'
import TeacherDetail from 'screens/teachers/[id]/teacher-detail'
import WordTutorials from 'screens/word-tutorials/word-tutorials'
import IncomingCallCard from './components/organisms/incoming-call-card'
import OutgoingCallCard from './components/organisms/outgoing-call-card'
import CourseFee from 'screens/CourseFee'

export default function App() {
    const api = useApi()
    const appInfo = useAppInfo()

    const isMobile = useMedia('(max-width: 1024px)')

    const [loading, setLoading] = React.useState(true)
    const [updateRequired, setUpdateRequired] = React.useState(false)

    React.useEffect(
        () => {
        // localStorage.clear()
        localStorage.removeItem('token')
        localStorage.removeItem('platform')
        localStorage.removeItem('loginData')

        
            ;(async () => {
                setLoading(true)

                if ((window as any).plugins) {
                    const anyWin = window as any
                    anyWin.plugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 })

                    const notificationOpenedCallback = function (jsonData: any) {
                        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
                    }

                    const iosSettings: any = {
                        kOSSettingsKeyAutoPrompt: false,
                        kOSSettingsKeyInAppLaunchURL: false,
                    }
                    try {
                        anyWin.plugins.OneSignal.startInit(Config.oneSignal.appId)
                            .handleNotificationOpened(notificationOpenedCallback)
                            .iOSSettings(iosSettings)
                            .inFocusDisplaying(anyWin.plugins.OneSignal.OSInFocusDisplayOption.Notification)
                            .endInit()

                        anyWin.plugins.OneSignal.promptForPushNotificationsWithUserResponse()

                        anyWin.plugins.OneSignal.addSubscriptionObserver(async (state: any) => {
                            if (!state.from.subscribed && state.to.subscribed) {
                                appInfo.setPlayerId?.(state.to.userId)
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    console.warn('OneSignal Plugins not available!')
                }

                const { platform } = await Device.getInfo()
                localStorage.setItem('platform',platform )

                if (platform !== 'web') {
                    if (platform === 'ios') {
                        await StatusBar.setStyle({ style: Style.Light })
                    }

                    if (platform === 'android') {
                        await StatusBar.setOverlaysWebView({ overlay: true })
                    }

                    await SplashScreen.show({
                        autoHide: false,
                    })
                }

                setUpdateRequired(false)

                let appStatus: { minimumAndroidVersion: string; minimumIOSVersion: string } | null = null

                try {
                    const { data } = await api.AppInfo.getAppStatus()
                    appStatus = data
                    // Sentry.captureMessage(JSON.stringify(data))
                    appInfo.setCurrentAndroidVersion?.(data.currentAndroidVersion)
                    appInfo.setCurrentIOSVersion?.(data.currentIOSVersion)
                    appInfo.setMinimumAndroidVersion?.(data.minimumAndroidVersion)
                    appInfo.setMinimumIOSVersion?.(data.minimumIOSVersion)
                    appInfo.setInMaintenance?.(data.inMaintenance)
                } catch (e) {
                    console.log(e)
                    appInfo.setInMaintenance?.(true)
                }

                if (appStatus !== null) {
                    if (platform !== 'web') {
                        try {
                            const { version } = await CapacitorApp.App.getInfo()

                            if (
                                (platform === 'android' && semver.lt(version, appStatus.minimumAndroidVersion!)) ||
                                (platform === 'ios' && semver.lt(version, appStatus.minimumIOSVersion!))
                            ) {
                                setUpdateRequired(true)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }

                try {
                    const { data } = await api.LegalDocument.getAllLegalDocuments()
                    if (data.legalDocuments) {
                        appInfo.setLegalDocuments?.(data.legalDocuments)
                    }
                } catch (e) {
                    console.log(e)
                }

                if (platform !== 'web') {
                    await SplashScreen.hide()
                    await StatusBar.show()

                    CapacitorApp.App.addListener('backButton', ({ canGoBack }) => {
                        if (!canGoBack) {
                            CapacitorApp.App.exitApp()
                        } else {
                            window.history.back()
                        }
                    })
                }

                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    if (loading) return null

    if (updateRequired) return <Error statusCode={426} initProviders />

    if (appInfo.inMaintenance) return <Error statusCode={503} initProviders />

    return (
        <Providers>
            <ScrollToTop />
            <PageTop />

            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/wait/:id">
                    <Wait />
                </Route>
                <Route exact path="/live/:id">
                    <Live />
                </Route>
                <Route exact path="/messages">
                    <Messages />
                </Route>
                <Route exact path="/teachers">
                    <Teachers />
                </Route>
                <Route exact path="/teachers/:id">
                    <TeacherDetail />
                </Route>
                <Route exact path="/teachers/:id/appointment">
                    <Appointment />
                </Route>
                <Route exact path="/word-tutorials">
                    <WordTutorials />
                </Route>
                <Route exact path="/word-tutorials/:slug">
                    <WordTutorialDetail />
                </Route>
                <Route exact path="/calendar">
                    <CalendarScreen />
                </Route>

                <Route exact path="/profile">
                    <Profile />
                </Route>
                <Route exact path="/profile/appointments">
                    <Appointments />
                </Route>
                <Route exact path="/profile/blocked-users">
                    <BlockedUsers />
                </Route>
                <Route exact path="/profile/earnings">
                    <Earnings />
                </Route>
                <Route exact path="/profile/favorite-teachers">
                    <FavoriteTeachers />
                </Route>
                <Route exact path="/profile/favorite-words">
                    <FavoriteWords />
                </Route>
                <Route exact path="/profile/help">
                    <Help />
                </Route>
                <Route exact path="/profile/subscription">
                    <Subscription />
                </Route>
                <Route exact path="/profile/subscription/plans">
                    <SubscriptionPlans />
                </Route>
                <Route exact path="/coursefee">
                    <CourseFee />
                </Route>
                <Route exact path="/profile/video-records">
                    <VideoRecords />
                </Route>
                <Route exact path="/profile/blocked-users">
                    <BlockedUsers />
                </Route>
                <Route exact path={['/odeme-basarili', '/payment-success']}>
                    <PaymentStatusScreen status={'success'} />
                </Route>
                <Route exact path={['/odeme-basarisiz', '/payment-error']}>
                    <PaymentStatusScreen status={'error'} />
                </Route>
                <Route>
                    <Error statusCode={404} />
                </Route>
            </Switch>

            {isMobile && <PageBottom />}
            <ChatMessages />
            <IncomingCallCard />
            <OutgoingCallCard />
            <AuthModal />
        </Providers>
    )
}

import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import useMedia from 'hooks/useMedia'
import { getUserRole } from 'utils/auth'
import { Disclosure } from '@headlessui/react'
import Avatar from 'components/atoms/avatar'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Divider from 'components/atoms/divider'
import QuestionIcon from '../../icons/Question'
import UserSettingsIcon from '../../icons/UserSettings'
import { Link, NavLink, useHistory } from 'react-router-dom'
import UserUnfollowIcon from 'components/icons/UserUnfollow'
import { NavigationItemProps } from 'components/atoms/navigation-item'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import { AB, ArrowDownSLine, ArrowRightSLine, CustomerService2Line, ListCheck, LogoutCircleLine, QuestionLine, TodoLine, UserSettingsLine, UserSharedLine } from 'components/icons'

import styles from './style.module.css'

const ProfileNavigation = () => {
    const { t } = useTranslation()
    const router = useHistory()
    const api = useApi()
    const auth = useAuth()
    const userRole = getUserRole()
    const isMobile = useMedia('(max-width: 1024px)')

    const [loading, setLoading] = React.useState<boolean>(false)
    const [showExportModal, setShowExportModal] = React.useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false)

    const exportModalConfirm = async () => {
        setLoading(true)
        try {
            switch (userRole) {
                case 'Student':
                    await api.Student.startToExportMyAllInformationsAsStudent()
                    break
                case 'Teacher':
                    await api.Teacher.startToExportMyAllInformationsAsTeacher()
                    break
            }
            toast.success(t('organisms:exportModal.success'))
        } catch {
        }
        setLoading(false)
        setShowExportModal(false)
    }

    const deleteModalConfirm = async () => {
        setLoading(true)
        try {
            switch (userRole) {
                case 'Student':
                    await api.Student.deleteMyAccountAsStudent()
                    break
                case 'Teacher':
                    await api.Teacher.deleteMyAccountAsTeacher()
                    break
            }
            toast.success(t('organisms:deleteModal.success'))
        } catch {
        }
        setLoading(false)
        setShowDeleteModal(false)
    }

    const logout = () => {
        if (openConfirmationModal) {
            auth.logout?.()
            setOpenConfirmationModal(false)
            toast.success(t('organisms:studentNavigation.logoutMsg'))
        }
    }

    let primaryLinks = React.useMemo<NavigationItemProps[]>(() => {
        if (auth.user?.userRole === 'Student') {
            return [
                {
                    label: t('organisms:studentNavigation.myAppointments'),
                    icon: <ListCheck />,
                    url: '/profile/appointments',
                },
                {
                    label: t('organisms:studentNavigation.favoriteTeachers'),
                    icon: <CustomerService2Line />,
                    url: '/profile/favorite-teachers',
                },
                { label: t('organisms:studentNavigation.favoriteWords'), icon: <AB />, url: '/profile/favorite-words' },
                {
                    label: t('organisms:studentNavigation.lessonRecords'),
                    icon: <TodoLine />,
                    url: '/profile/video-records',
                },
                { label: t('organisms:studentNavigation.myProfile'), icon: <UserSettingsLine />, url: '/profile' },
            ]
        } else if (auth.user?.userRole === 'Teacher') {
            return [
                { label: t('organisms:studentNavigation.myEarnings'), icon: <ListCheck />, url: '/profile/earnings' },
                { label: t('organisms:studentNavigation.favoriteWords'), icon: <AB />, url: '/profile/favorite-words' },
                {
                    label: t('organisms:studentNavigation.lessonRecords'),
                    icon: <TodoLine />,
                    url: '/profile/video-records',
                },
                { label: t('organisms:studentNavigation.myProfile'), icon: <UserSettingsLine />, url: '/profile' },
            ]
        }

        return []
    }, [t, auth.user])

    const secondaryLinks = React.useMemo<NavigationItemProps[]>(
        () => [
            {
                label: t('organisms:studentNavigation.exportMyInfo'),
                icon: <UserSharedLine />,
                url: '',
                active: false,
                isLink: false,
                handler: () => setShowExportModal(true),
            },
            // { label: t('organisms:studentNavigation.blockedUsers'), icon: <UserSearchLine />, url: '', active: false },
            {
                label: t('organisms:studentNavigation.deleteMyAccount'),
                icon: <UserUnfollowIcon />,
                url: '',
                active: false,
                handler: () => setShowDeleteModal(true),
            },
        ],
        [t]
    )

    const footerLinks = React.useMemo<NavigationItemProps[]>(
        () => [
            { label: t('organisms:studentNavigation.help'), icon: <QuestionLine />, url: '/profile/help' },
            { label: t('organisms:studentNavigation.logout'), icon: <LogoutCircleLine />, url: '', handler: () => { setOpenConfirmationModal(true) } },
        ], // eslint-disable-next-line react-hooks/exhaustive-deps
        [t]
    )

    if (!auth.isLoggedIn)
        return (
            <div>
                <Card>
                    <div />
                </Card>
            </div>
        )

    return (
        <div>
            {openConfirmationModal ? (
                <ConfirmModalCard
                    title={t('common:authModal.title')}
                    description={t('common:authModal.description')}
                    confirmButtonText={t('common:authModal.confirm')}
                    cancelButtonText={t('common:authModal.cancel')}
                    isVisible={true}
                    onModalClose={() => setOpenConfirmationModal(false)}
                    onConfirmButtonClick={() => logout()}
                    onCancelButtonClick={() => setOpenConfirmationModal(false)}
                    loading={loading}
                />
            ) : null}
            <Card>
                <Link to={'/profile'}>
                    <div className={classnames(styles.user)}>
                        <Avatar user={auth.user} rounded={true} size={3.5} />
                        <div className={classnames(styles.username)}>{auth.user?.name}</div>
                    </div>
                </Link>
                <Divider />
                {auth.user?.userRole === 'Student' && (
                    <Link to="/profile/subscription">
                        <Button type={'gradient'} variation={'block'}>
                            {t('organisms:studentNavigation.manageSubscription')}
                        </Button>
                    </Link>
                )}
                {auth.user?.userRole === 'Teacher' && (
                    <Link to="/profile/appointments">
                        <Button type={'gradient'} variation={'block'}>
                            {t('organisms:studentNavigation.myAppointments')}
                        </Button>
                    </Link>
                )}
                {!isMobile ? (
                    <div className={classnames(styles['links'], styles['primary-links'])}>
                        {primaryLinks.map(r => (
                            <NavLink key={r.label} to={r.url ?? ''} className={classnames(styles['link'], styles['primary-link'])} activeClassName={styles.active} exact>
                                {r.icon}
                                <span>{r.label}</span>
                                {r.active ? <ArrowRightSLine /> : undefined}
                            </NavLink>
                        ))}
                    </div>
                ) : (
                    <div className={classnames(styles['links'], styles['primary-links'])}>
                        <div className={styles.disclosureWrapper}>
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className={classnames(styles.disclosureButton, { [styles.disclosureButtonOpen]: open })}>
                                            <span>{primaryLinks.find(x => x.url === router.location.pathname)?.label ?? t('organisms:studentNavigation.userDetails')}</span>
                                            <ArrowDownSLine className={classnames(styles.chevron, { [styles.open]: open })} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className={styles.disclosureOptions}>
                                            {open && <Divider className={styles.hr} />}
                                            {primaryLinks.map(r => (
                                                <NavLink
                                                    key={r.label}
                                                    to={r.url ?? ''}
                                                    className={classnames(styles['link'], styles['primary-link'])}
                                                    activeClassName={styles.active}
                                                    exact
                                                >
                                                    <span className={styles.disclosureOptionsText}>{r.label}</span>

                                                    {r.active ? <ArrowRightSLine /> : undefined}
                                                </NavLink>
                                            ))}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                        <Button
                            type={'danger'}
                            leftIcon={<QuestionIcon />}
                            className={styles.actionButton}
                            onClick={() => {
                                router.push('/profile/help')
                            }}
                        >
                            {t('organisms:studentNavigation.help')}
                        </Button>
                        <div className={styles.disclosureWrapper}>
                            <Disclosure>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className={classnames(styles.disclosureButton, { [styles.disclosureButtonOpen]: open })}>
                                            <span>
                                                <UserSettingsIcon className={styles.settingsIcon} />
                                                {t('organisms:studentNavigation.userOperations')}
                                            </span>
                                            <ArrowDownSLine className={classnames(styles.chevron, { [styles.open]: open })} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className={styles.disclosureOptions}>
                                            {open && <Divider className={styles.hr} />}
                                            {secondaryLinks.map(r => (
                                                <div
                                                    onClick={r.handler}
                                                    key={r.label + '_link'}
                                                    className={classnames(styles['link'], styles['secondary-link'], { [styles.active]: r.active })}
                                                >
                                                    <span className={styles.disclosureOptionsText}>{r.label}</span>
                                                    {r.active ? <ArrowRightSLine /> : undefined}
                                                </div>
                                            ))}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>

                        <Button
                            type={'dimmed'}
                            className={styles.actionButton}
                            leftIcon={<LogoutCircleLine />}
                            onClick={() => { setOpenConfirmationModal(true) }}
                        >
                            {t('organisms:studentNavigation.logout')}
                        </Button>
                    </div>
                )}

                {!isMobile && (
                    <>
                        <Divider />
                        <div className={classnames(styles['links'])}>
                            {secondaryLinks.map(r => (
                                <div onClick={r.handler} key={r.label + '_link'} className={classnames(styles['link'], styles['secondary-link'], { [styles.active]: r.active })}>
                                    {r.icon}
                                    <span>{r.label}</span>
                                    {r.active ? <ArrowRightSLine /> : undefined}
                                </div>
                            ))}
                        </div>
                        <Divider />
                        <div className={classnames(styles['links'])}>
                            {footerLinks.map((r, i) => {
                                if (r.handler) {
                                    return (
                                        <div
                                            className={classnames(styles['link'], styles['secondary-link'], { [styles['danger-link']]: i === 0 }, { [styles.active]: r.active })}
                                            onClick={r.handler}
                                            key={r.label}
                                        >
                                            {r.icon}
                                            <span>{r.label}</span>
                                            {r.active ? <ArrowRightSLine /> : undefined}
                                        </div>
                                    )
                                }
                                return (
                                    <NavLink key={r.label} to={r.url ?? ''} className={classnames(styles['link'], styles['secondary-link'], { [styles['danger-link']]: i === 0 })}>
                                        {r.icon}
                                        <span>{r.label}</span>
                                        {r.active ? <ArrowRightSLine /> : undefined}
                                    </NavLink>
                                )
                            })}
                        </div>
                    </>
                )}
            </Card>
            <ConfirmModalCard
                title={t('organisms:exportModal.title')}
                description={t('organisms:exportModal.description')}
                confirmButtonText={t('organisms:exportModal.confirmButtonText')}
                isVisible={showExportModal}
                onModalClose={() => setShowExportModal(false)}
                onConfirmButtonClick={exportModalConfirm}
                onCancelButtonClick={() => setShowExportModal(false)}
                loading={loading}
            />
            <ConfirmModalCard
                title={t('organisms:deleteModal.title')}
                description={t('organisms:deleteModal.description')}
                confirmButtonText={t('organisms:deleteModal.confirmButtonText')}
                isVisible={showDeleteModal}
                onModalClose={() => setShowDeleteModal(false)}
                onConfirmButtonClick={deleteModalConfirm}
                onCancelButtonClick={() => setShowDeleteModal(false)}
                loading={loading}
            />

        </div>
    )
}

export default ProfileNavigation

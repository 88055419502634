import * as React from 'react'
import classNames from 'classnames'
import Text from 'components/atoms/text'
import Input, { InputProps } from 'components/atoms/input'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Divider from 'components/atoms/divider'
import PasswordInput from 'components/molecules/password-input'

import styles from './style.module.css'

type StudentLoginWithDirectAuthCardProps = {
    loading?: boolean
    onRegister: () => void
    onForgotPassword: () => void
    onConfirm: (email: string, password: string) => void
    status?: InputProps['status']
}

const LoginWithDirectAuthCard = ({ loading, onRegister, onForgotPassword, onConfirm, status }: StudentLoginWithDirectAuthCardProps) => {
    const { t } = useTranslation('organisms')
    const [email, setEmail] = React.useState<string | undefined>(undefined)
    const [password, setPassword] = React.useState<string | undefined>(undefined)
    const passwordInputRef = React.createRef<HTMLInputElement>()
    const [emailCheck, setEmailCheck] = React.useState(false)

    const handleLogin = () => {
        email && password && onConfirm(email, password)
    }

    const handleEmailEnter = () => {
        passwordInputRef.current?.focus()
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.button}>
                <Input
                    label={t('directAuth.email')}
                    onChangeText={setEmail}
                    type="email"
                    name="email"
                    enterKeyPress={handleEmailEnter}
                    regexType={'mail'}
                    onRegexFailed={setEmailCheck}
                    status={email === '' || email === undefined || emailCheck ? 'default' : 'danger'}
                />
                <PasswordInput
                    label={t('directAuth.password')}
                    onChangeText={setPassword}
                    name="password"
                    status={status ?? 'default'}
                    enterKeyPress={handleLogin}
                    ref={passwordInputRef}
                />
            </div>

            <div>
                <button onClick={onForgotPassword} className={styles.cleanButton}>
                    <Text type="danger" weight="medium">
                        {t('directAuth.forgotPassword')}
                    </Text>
                </button>
            </div>

            <Button loading={loading} onClick={handleLogin} variation="block" type="gradient" disabled={!emailCheck}>
                {t('directAuth.login')}
            </Button>

            <Divider />

            <div className={styles.registerWrapper}>
                <button onClick={onRegister} className={classNames(styles.cleanButton, styles.registerLink)}>
                    <Text type="dimmed" weight="medium">
                        {t('directAuth.registerPrefix')}
                    </Text>
                    <Text type="danger" weight="medium">
                        {t('directAuth.register')}
                    </Text>
                </button>
            </div>
        </div>
    )
}

export default LoginWithDirectAuthCard

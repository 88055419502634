import * as React from 'react'

function SvgLogoDark(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.51 109.36" {...props}>
            <g data-name="katman 2">
                <g data-name="katman 5">
                    <path
                        d="M167.75 66.11h-16.31l-3.11 7.53H140l15.66-35.12h8l15.71 35.12h-8.53zm-2.56-6.17l-5.57-13.45-5.57 13.45zm24.88-21.42h16c11.49 0 19.37 6.92 19.37 17.56S217.51 73.64 206 73.64h-16zM205.62 67c7 0 11.54-4.16 11.54-10.88s-4.56-10.89-11.54-10.89h-7.42V67zm65.52-28.48l-15.2 35.12h-8l-15.18-35.12h8.78l10.69 25.08 10.83-25.08zm31.91 27.59h-16.31l-3.11 7.53h-8.33L291 38.52h8l15.71 35.12h-8.53zm-2.56-6.17l-5.57-13.45-5.57 13.45zm57.09-21.42v35.12h-6.67L333.4 52.31v21.33h-8V38.52h6.72l17.46 21.32V38.52zm13.14 17.56c0-10.59 8.13-18.17 19.06-18.17 6.08 0 11.14 2.21 14.45 6.23L399 49a11.3 11.3 0 00-8.83-4.11c-6.58 0-11.24 4.61-11.24 11.24s4.66 11.23 11.24 11.23a11.19 11.19 0 008.83-4.21l5.23 4.85c-3.31 4.06-8.37 6.27-14.5 6.27-10.88-.03-19.01-7.61-19.01-18.19zm73.19 11.03v6.53h-27.19V38.52h26.54V45H424.8v7.62h16.3V59h-16.3v8.12zm35.87 6.53L473 63.85h-7.47v9.79h-8.13V38.52h15.2c9.38 0 15.25 4.86 15.25 12.74a11.43 11.43 0 01-7.22 11.09l7.87 11.29zm-7.62-28.5h-6.62v12.24h6.62c5 0 7.47-2.31 7.47-6.12s-2.51-6.12-7.47-6.12z"
                        fill="#252736"
                        data-name="katman 1"
                    />
                    <g data-name="katman 4">
                        <path fill="#e51d2a" d="M58.78 91.56H31.23l26.76-49.44-9.85-18.11-38.1 67.55h-.15v.26L0 109.36h68.88l-10.1-17.8z" data-name="katman 2" />
                        <path fill="#ed6d1a" d="M61.68 0L51.63 17.82l43.14 73.74H85.8l-3.5-6.26-20.35-36.34L40.62 85.3h25.54l3.29 6.26 9.67 17.8H124L61.68 0z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SvgLogoDark

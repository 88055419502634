import * as React from 'react'
import useChat from 'hooks/useChat'
import {DeleteBinLine} from 'components/icons'
import {ChatUser} from 'types/chatProvider'
import ConversationListItem from 'components/molecules/conversation-list-item'
import {ConversationWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types'
import {SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType} from 'react-swipeable-list'

import styles from './style.module.css'
import 'react-swipeable-list/dist/styles.css'

type ConversationListProps = {
    conversations: ConversationWithUserMetadata<ChatUser | null>[]
    swipeable?: boolean
    deletable?: boolean
    onDelete?: (id: number) => void
}

const ConversationList = ({swipeable, deletable, conversations, onDelete}: ConversationListProps) => {
    const chat = useChat()

    const trailingActions = React.useCallback((conversationId: string) => (
        <TrailingActions>
            <SwipeAction onClick={() => chat.methods?.deleteConversation(conversationId)} destructive>
                <div className={styles.delete}>
                    <DeleteBinLine/>
                </div>
            </SwipeAction>
        </TrailingActions>
    ), [chat.methods])

    // const items = React.useMemo(() => {
    //     return conversations.map((conversation, index) => {
    //         if (!conversation.lastMessage) return undefined
    //         return swipeable ? (
    //             <SwipeableListItem key={conversation.conversationId.toString() + '_conversation'} trailingActions={trailingActions(conversation.conversationId)}>
    //                 <ConversationListItem conversation={conversation} index={index} deletable={deletable} topBorder={index !== 0} onDelete={onDelete}/>
    //             </SwipeableListItem>
    //         ) : (
    //             <ConversationListItem index={index} deletable={deletable} onDelete={onDelete} topBorder={index !== 0} key={conversation.conversationId.toString() + '_conversation'}
    //                                   conversation={conversation}/>
    //         )
    //     })
    // }, [conversations, deletable, onDelete, swipeable, trailingActions])
    //
    // return (
    //     <div className={styles.wrapper}>
    //         {swipeable ? (
    //             <SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
    //                 {items}
    //             </SwipeableList>
    //         ) : (
    //             items
    //         )}
    //     </div>
    // )

    return (
        <div className={styles.wrapper}>
            {conversations.map((conversation, index) => {
                return (
                    <SwipeableList fullSwipe={false} type={ListType.IOS} key={conversation.conversationId.toString() + '_conversation'}>
                        <SwipeableListItem trailingActions={trailingActions(conversation.conversationId)}>
                            <ConversationListItem
                                conversation={conversation}
                                index={index}
                                deletable={deletable}
                                topBorder={index !== 0}
                                onDelete={onDelete}
                            />
                        </SwipeableListItem>
                    </SwipeableList>
                )
            })}
        </div>
    )
}

export default ConversationList

import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useChat from 'hooks/useChat'
import { Link, useHistory } from 'react-router-dom'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import { CustomerService2Line } from 'components/icons'
import TeacherInfo from 'components/molecules/teacher-info'
import YoutubePlayer from 'components/molecules/youtube-player'
import { TeacherQueriesTeacherListItem as Teacher } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'

type TeacherBoxProps = {
    teacher: Teacher
}

export default function TeacherBox({ teacher }: TeacherBoxProps) {
    const { t } = useTranslation('organisms')

    const api = useApi()
    const auth = useAuth()
    const chat = useChat()
    const router = useHistory()

    const handleSendMessage = () => {
        if (!auth.isLoggedIn) {
            auth.setModalShown?.(true)
            return
        }

        if (teacher.teacherGuid) {
            chat.methods?.createConversation(teacher.teacherGuid.toString())
        }
    }

    const handleCallTeacher = async () => {
        debugger;
        if (!auth.isLoggedIn) {
            auth.setModalShown?.(true)
            return
        }

        if (auth.user?.userRole === 'Student') {
            auth.user.canMakeFreeCall ? await api.Live.requestFreeCall(teacher.teacherId) : await api.Live.directCall(teacher.teacherId)
        }
    }

    const handleAppointment = () => {
        // if (!auth.isLoggedIn) {
        //     auth.setModalShown?.(true)
        //     return
        // }

        router.push({ pathname: `/teachers/${teacher.teacherId}/appointment` })
    }

    return (
        <>
            <Card>
                <div className={styles['teacher-box']}>
                    <div className={styles.teacher}>
                        <TeacherInfo teacher={teacher} />
                    </div>
                    <div className={styles.info}>
                        <Link to={`/teachers/${teacher.teacherId}`}>
                            <p>{teacher.description}</p>
                        </Link>
                        <div className={styles.actions}>
                            <Button onClick={handleAppointment} disabled={!teacher.hasFreeAppointmentRanges}>
                                {!teacher.hasFreeAppointmentRanges ? t('teacherBox.notAvailable') : t('teacherBox.makeAppointment')}
                            </Button>

                            <Button onClick={handleCallTeacher}
                                type="gradient"
                                alignment="center"
                                leftIcon={<CustomerService2Line />}
                                leftIconPlacement="relative"
                                disabled={!teacher.isOnline}
                            >
                                {!teacher.isOnline ? t('teacherBox.offline') : t('teacherBox.callTeacher')}
                            </Button>

                            <Button onClick={handleSendMessage} type="danger" loading={chat.state?.creatingConversation}>
                                {t('teacherBox.sendMessage')}
                            </Button>
                        </div>

                    </div>
                    {teacher.trailerVideo && (
                        <div className={styles.video}>
                            <YoutubePlayer videoUrl={teacher.trailerVideo.videoUrl} />
                        </div>
                    )}
                </div>
            </Card>
        </>

    )
}

import * as React from 'react'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import {useTranslation} from 'react-i18next'

import styles from './style.module.css'

export interface IInput extends Omit<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChangeText'>, 'placeholder'> {
}

export interface ITextarea extends Omit<Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChangeText'>, 'placeholder'> {
}

export interface TextChange {
    (text: string): void
}

export interface InputBaseProps {
    label?: string
    placeholder?: string
    onChangeText?: (text: string) => void
    status?: 'default' | 'success' | 'danger'
    multiline?: boolean
    rows?: number
    maxCharCount?: number
    background?: 'default' | 'gray'
    format?: string
    enterKeyPress?: () => void
    regexType?: 'iban' | 'mail'
    onRegexCheck?: (value: boolean) => void

    [key: string]: any
}

export type InputWrapperProps = {
    borderless?: boolean
    leadingComponent?: React.ReactNode
    trailingComponent?: React.ReactNode
}

export type InputProps = InputBaseProps & InputWrapperProps & (IInput | ITextarea)

const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((properties, ref) => {
    const {
        label,
        placeholder,
        value,
        leadingComponent,
        trailingComponent,
        borderless,
        onChange,
        onChangeText,
        onFocus,
        onBlur,
        status,
        multiline,
        rows,
        maxCharCount,
        background,
        format,
        enterKeyPress,
        disabled,
        className,
        regexType,
        onRegexFailed,
        ...props
    } = properties

    const {t} = useTranslation('atoms')
    const [val, setVal] = React.useState<string>(value as string)
    const [isFocused, setIsFocused] = React.useState<boolean>(false)
    const [isLabelActive, setIsLabelActive] = React.useState<boolean>(false)

    React.useEffect(() => {
        setVal(value as string)
    }, [value])

    React.useEffect(() => {
        setIsLabelActive((val != null && `${val}`.length > 0) || isFocused)
    }, [val, isFocused])

    React.useEffect(
        () => {
            const handleEnter = (event: any) => {
                if (event.keyCode === 13 && typeof enterKeyPress === 'function' && !disabled && isFocused) {
                    enterKeyPress()
                }
            }
            window.addEventListener('keydown', handleEnter)

            return () => {
                window.removeEventListener('keydown', handleEnter)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [val, disabled, enterKeyPress]
    )

    // Regex Validator
    React.useEffect(() => {
        let validator: RegExp | null = null

        switch (regexType) {
            case 'mail': {
                validator = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')
                onRegexFailed && onRegexFailed(validator.test(val))
                break
            }
            case 'iban': {
                validator = new RegExp('^([A-Z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$')
                onRegexFailed && onRegexFailed(validator.test(val))
                break
            }
        }
    }, [val, regexType, onRegexFailed])

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (maxCharCount && event.target.value.length > maxCharCount) {
            return
        }
        setVal(event.target.value)
        typeof onChange === 'function' && onChange(event as any)
        typeof onChangeText === 'function' && onChangeText(event.target.value)
    }

    const onFocusHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(true)
        onFocus && onFocus(event as any)
    }

    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event.target.value.length || event.target.value.length === 0) {
            setIsFocused(false)
        }
        onBlur && onBlur(event as any)
    }

    const Leading = () => {
        if (!leadingComponent) return null

        return (
            <>
                <div className={classnames(styles.leading)}>{leadingComponent}</div>
                <div className={styles['leading-divider']}/>
            </>
        )
    }

    const Trailing = () => {
        if (!trailingComponent) return null

        return <div className={styles.trailing}>{trailingComponent}</div>
    }

    return (
        <div className={styles['input-base']}>
            <div
                className={classnames(styles['input-wrapper'], {
                    [styles.multiline]: multiline,
                    [styles.borderless]: borderless,
                    [styles.success]: status === 'success',
                    [styles.danger]: status === 'danger',
                    [styles[`background-${background}`]]: background,
                    [styles[`disabled`]]: disabled,
                })}
            >
                <Leading/>
                <div className={classnames(styles['input-container'])}>
                    {label && (
                        <div
                            className={classnames(styles.label, {
                                [styles.active]: isLabelActive,
                                [styles.multiline]: multiline,
                            })}
                        >
                            {label}
                        </div>
                    )}
                    {multiline ? (
                        <>
                            {label && <div className={styles.labelCover}/>}
                            <textarea
                                className={classnames(styles.input, {[styles.labeled]: !!label}, className)}
                                value={value}
                                placeholder={placeholder}
                                rows={rows}
                                onChange={event => onChangeHandler(event)}
                                onFocus={onFocusHandler}
                                onBlur={onBlurHandler}
                                disabled={disabled}
                                ref={ref}
                                {...(props as any)}
                            />
                        </>
                    ) : format ? (
                        <NumberFormat
                            className={classnames(styles.input, {[styles.labeled]: !!label}, className)}
                            placeholder={placeholder}
                            onChange={onChangeHandler}
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            value={value as string}
                            format={format}
                            mask={''}
                            autoFocus={false}
                            disabled={disabled}
                            ref={ref}
                            {...(props as any)}
                        />
                    ) : (
                        <input
                            className={classnames(styles.input, {[styles.labeled]: !!label}, className)}
                            value={value}
                            placeholder={placeholder}
                            onChange={onChangeHandler}
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            disabled={disabled}
                            ref={ref}
                            {...(props as any)}
                        />
                    )}
                </div>
                <Trailing/>
            </div>
            {maxCharCount && (
                <div className={styles.maxCharCountWrapper}>
                    <div>{t('maximum-number-of-characters')}</div>
                    <div>
                        {' '}
                        {`${val ?? ''}`.length} / {maxCharCount}
                    </div>
                </div>
            )}
        </div>
    )
})

export default Input

import React from 'react'

type ImageWithFallbackProps = {
    fallbackSrc: string
}

const ImageWithFallback = ({ fallbackSrc, ...props }: ImageWithFallbackProps & JSX.IntrinsicElements['img']) => {
    let { src, srcSet, alt, className, ...rest } = props

    return (
        <div
            {...rest}
            className={className}
            dangerouslySetInnerHTML={{
                __html: `<img style="width: 100%;height: 100%;object-fit: cover;object-position: center" alt='${alt}' srcset='${
                    srcSet ?? src
                }' src='${src}' data-fallback='${fallbackSrc}' onerror='this.onerror=null;this.src=this.dataset.fallback;this.srcset=this.dataset.fallback;' />`,
            }}
        />
    )
}

export default ImageWithFallback

import * as React from 'react'
import classnames from 'classnames'
import { StarFill } from 'components/icons'
import { roundHalf } from 'utils/number-helpers'

import styles from './style.module.css'

type RatingProps = {
    rate: number
}

export default function Rating({ rate }: RatingProps) {
    return (
        <div className={classnames(styles.rating)}>
            <div className={classnames(styles['current-rating'])}>
                <StarFill />
                <span>{roundHalf(rate)}</span>
            </div>
            <div className={classnames(styles.info)}>
                <sub>/ 5.0</sub>
            </div>
        </div>
    )
}

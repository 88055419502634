import * as React from 'react'
import { isString } from 'lodash'
import Card from '../../molecules/card'
import Text from 'components/atoms/text'
import useAppInfo from 'hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'
import Input from 'components/atoms/input'
import { ArrowLeftSLine, ArrowRightSLine } from '../../icons'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Divider from 'components/atoms/divider'
import Checkbox from 'components/atoms/checkbox'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import InputPhoneNumber from '../../atoms/input-phone-number'
import PasswordInput from 'components/molecules/password-input'

import styles from './style.module.css'
import { StudentSpecialities } from '../student-specialities-selection-card'

export type StudentRegisterProps = {
    name: string
    email: string
    phoneNumber: string
    password: string
}

export type StudentRegisterCardProps = {
    loading?: boolean
    initialValues?: StudentRegisterProps
    specialities?: StudentSpecialities | undefined
    onspecialitiesClicked: (user: StudentRegisterProps) => void
    onConfirm: (user: StudentRegisterProps, kvkk: boolean) => void
    onDirectLoginClick: () => void
}

const StudentRegisterCard = ({ loading, initialValues, specialities, onspecialitiesClicked, onDirectLoginClick, onConfirm }: StudentRegisterCardProps) => {
    const { t } = useTranslation('organisms')

    const appInfo = useAppInfo()
    const [name, setName] = React.useState<string>(initialValues?.name ?? '')
    const [email, setEmail] = React.useState<string>(initialValues?.email ?? '')
    const [phoneNumber, setPhoneNumber] = React.useState<string>(initialValues?.phoneNumber ?? '')
    const [password, setPassword] = React.useState<string>(initialValues?.password ?? '')
    const [checked, setChecked] = React.useState<boolean>(false)
    const [kvkkModal, setKvkkModal] = React.useState(false)
    const [emailCheck, setEmailCheck] = React.useState(false)

    const handleRegister = () => {
        if (typeof onConfirm === 'function') {
            onConfirm({ name, email, phoneNumber, password }, checked)
        }
    }

    const onDirectLoginClickHandler = () => {
        if (typeof onDirectLoginClick === 'function') {
            onDirectLoginClick()
        }
    }

    const confirmedForm = React.useMemo(() => {
        return name.length > 1 && email.length > 1 && password.length > 1 && isString(phoneNumber) && isValidPhoneNumber(phoneNumber) && checked
    }, [checked, email, name, password, phoneNumber])

    const specialitiesHandler = () => {
        onspecialitiesClicked({
            name,
            email,
            phoneNumber,
            password,
        })
    }

    return (
        <div className={styles.container}>
            <Input label={t('studentRegister.name')} value={name} onChangeText={setName} maxLength={64} name="name" />

            <Input
                label={t('studentRegister.email')}
                value={email}
                onChangeText={setEmail}
                type="email"
                name="email"
                regexType={'mail'}
                onRegexFailed={setEmailCheck}
                status={email === '' || email === undefined || emailCheck ? 'default' : 'danger'}
            />

            <InputPhoneNumber label={t('studentRegister.phoneNumber')} value={phoneNumber} onChangeText={setPhoneNumber} />

            <PasswordInput label={t('studentRegister.password')} value={password} onChangeText={setPassword} maxLength={64} name="password" />

            <Button onClick={specialitiesHandler} type="danger-3" alignment="left" rightIcon={<ArrowRightSLine />} rightIconPlacement="absolute">
                <div className="truncated" style={{ paddingRight: '1rem' }}>
                    {specialities
                        ? Object.values(specialities)
                              .filter(x => x.length)
                              .map(e => e.map(x => t(`organisms:filters.${x}`)))
                              .join(',')
                        : null ?? t('common:profile.specialities')}
                </div>
            </Button>

            <div className={styles.controls}>
                <Checkbox checked={checked} onChange={setChecked} />
                <div onClick={() => setKvkkModal(x => !x)}>
                    <Text weight={'medium'} decoration={'underline'} size={'xs'} type={'danger'} clickable>
                        {t('studentRegister.confirmationSubject')}
                    </Text>
                </div>
                <div onClick={() => setChecked(r => !r)}>
                    <Text weight={'medium'} size={'xs'}>
                        {t('studentRegister.confirmationText')}
                    </Text>
                </div>
            </div>

            <div className={styles.buttonsWrapper}>
                <Button loading={loading} size={'default'} type={'gradient'} onClick={handleRegister} disabled={!confirmedForm || !emailCheck}>
                    {t('studentRegister.register')}
                </Button>

                <Divider />

                <div className={styles.loginWrapper} onClick={onDirectLoginClickHandler}>
                    {t('studentRegister.loginText')}
                    <div className={styles.pinkedText}>{t('studentRegister.login')}</div>
                </div>
            </div>

            <Modal isVisible={kvkkModal} onModalClose={() => setKvkkModal(false)} size={ModalSize.Large} type={ModalType.Centered}>
                <div className={styles.modal}>
                    <Card
                        heading={{
                            leading: (
                                <div className={styles.back} onClick={() => setKvkkModal(x => !x)}>
                                    <ArrowLeftSLine />
                                </div>
                            ),
                            title: t('legalDocumentModalCard.privacyPolicy'),
                            alignment: 'center',
                            size: 'lg',
                            divider: true,
                        }}
                    >
                        <div className={styles.contentWrapper}>
                            <Text size="sm">
                                <ReactMarkdown>{appInfo.legalDocuments?.find(x => x.title === 'Gizlilik Sözleşmesi')?.content ?? ''}</ReactMarkdown>
                            </Text>
                        </div>
                    </Card>
                </div>
            </Modal>
        </div>
    )
}

export default StudentRegisterCard

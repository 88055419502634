import * as React from 'react'
import Text from 'components/atoms/text'
import Toggle from 'components/atoms/toggle'
import {CheckLine, CloseLine} from 'components/icons'
import {useTranslation} from 'react-i18next'

import styles from './style.module.css'

export type TeacherAvailabilityStatus = 'online' | 'offline'

type TeacherAvailabilityCardProps = {
    status: TeacherAvailabilityStatus
    onChange: (status: TeacherAvailabilityStatus) => void
    loading?: boolean
}

const TeacherAvailabilityCard: React.FC<TeacherAvailabilityCardProps> = ({status, onChange, loading}) => {
    const {t} = useTranslation('organisms')

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{t('teacherAvailability.update')}</div>
            <div className={styles.body}>
                <Text weight="medium">
                    {t('teacherAvailability.online')}
                </Text>
                <Toggle
                    active={status === 'online'}
                    onChange={() => {
                        if (!loading) {
                            onChange(status === 'online' ? 'offline' : 'online')
                        }
                    }}
                    type="colorful"
                    activeIcon={<CheckLine/>}
                    inactiveIcon={<CloseLine/>}
                    loading={loading}
                />
                <Text type="dimmed" weight="medium">{t('teacherAvailability.offline')}</Text>
            </div>
        </div>
    )
}

export default TeacherAvailabilityCard

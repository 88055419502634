import * as React from 'react'
import { HistoryLine } from 'components/icons'

import styles from './style.module.css'

type InputReverseButtonProps = {
    onReverse: () => void
}

const InputReverseButton = ({ onReverse }: InputReverseButtonProps) => {
    return (
        <button onClick={onReverse} className={styles.button}>
            <HistoryLine />
        </button>
    )
}

export default InputReverseButton

import * as React from 'react'
import {TextChange} from 'components/atoms/input'

import styles from './style.module.css'

interface ITextarea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
}

type TextareaProps = {
    placeholder?: string
    maxCharCount?: number
    maxCharCountLabel?: string
    showCharCount?: boolean
    onChangeText: TextChange
}

const Textarea = (
    {
        value,
        placeholder,
        maxCharCount,
        maxCharCountLabel = 'Maksimum karakter Sayısı',
        showCharCount,
        onChange,
        onChangeText,
        ...props
    }: TextareaProps & ITextarea) => {
    const [val, setVal] = React.useState<string | number | readonly string[]>('')

    React.useEffect(() => {
        if (value) {
            setVal(value)
        }
    }, [value])

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (showCharCount && maxCharCount && event.target.value.length > maxCharCount) {
            return
        }
        setVal(event.target.value)
        onChange && onChange(event)
        onChangeText && onChangeText(event.target.value)
    }, [maxCharCount, onChange, onChangeText, showCharCount])

    return (
        <div className={styles.wrapper}>
            <textarea className={styles.textarea} placeholder={placeholder} value={val} onChange={onChangeHandler} {...props} />
            {showCharCount && maxCharCount !== null && (
                <div className={styles.count}>
                    <div>{maxCharCountLabel}</div>
                    <div className={`${val ?? ''}`.length === maxCharCount ? 'text-rose-500' : ''}>
                        {`${val ?? ''}`.length} / {maxCharCount}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Textarea

import * as React from 'react'
import Cookies from 'universal-cookie'

type ThemeType = 'light' | 'dark'

export type ThemeContextType = {
    theme: ThemeType
    toggleTheme: () => void
}

export const ThemeContext = React.createContext({})

export const Theme = ({ children }: { children: React.ReactNode }) => {
    const cookies = new Cookies()
    const [theme, setTheme] = React.useState<ThemeType>(cookies.get('theme') ?? 'dark')

    React.useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [theme])

    const toggleTheme = () => {
        const changedTheme = theme === 'light' ? 'dark' : 'light'
        setTheme(changedTheme)

        cookies.set('theme', changedTheme, { path: '/' })
    }

    return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

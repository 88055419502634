import * as React from 'react'
import useApi from 'hooks/useApi'
import { isUndefined } from 'lodash'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'
import useAppInfo from 'hooks/useAppInfo'
import { useHistory } from 'react-router-dom'
import { DecodedJwtData, MyUser } from 'types'
import { DirectCallDTO, FreeCallDTO } from 'types/chatProvider'
import { getUserRole } from 'utils/auth'
import { Device } from '@capacitor/device'

export interface AuthContextProps {
    modalShown: boolean
    toggleModal: () => void
    setModalShown: React.Dispatch<React.SetStateAction<boolean>>
    isLoggedIn: boolean
    logout: () => void
    user: MyUser | undefined
    setUser: React.Dispatch<React.SetStateAction<MyUser | undefined>>
    userFunctions: {
        setCurrentCall: (currentCall: FreeCallDTO | DirectCallDTO | undefined) => void
        setOnlineStatus: (status: boolean) => void
        setCanFreeCall: (isFree: boolean) => void
    }
    decodedJwt: DecodedJwtData | null
}

export const AuthContext = React.createContext<Partial<AuthContextProps>>({})

const cookies = new Cookies()

export const Auth = ({ children }: { children: React.ReactNode }) => {
    const api = useApi()
    const router = useHistory()
    const appInfo = useAppInfo()
    const [devicePlatform, setDevicePlatform] = React.useState('')


    let tokenCookie: any
    if (devicePlatform!=='ios') {
        tokenCookie = new Cookies().get('token')
    }

    if (devicePlatform === 'ios') {
        tokenCookie = localStorage.getItem('token')
    }

    const [modalShown, setModalShown] = React.useState<boolean>(false)
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(!isUndefined(tokenCookie))
    const [user, setUser] = React.useState<MyUser | undefined>(undefined)
    const [decodedJwt, setDecodedJwt] = React.useState<DecodedJwtData | undefined>(undefined)

    const toggleModal = () => {
        setModalShown(r => (isLoggedIn ? false : !r))
    }

    const setOnlineStatus = (isOnline: boolean) => {
        setUser(x => {
            if (x) {
                return { ...x, isOnline }
            }
            return undefined
        })
    }

    const setCanFreeCall = (canMakeFreeCall: boolean) => {
        setUser(x => {
            if (x) {
                return { ...x, canMakeFreeCall }
            }
            return undefined
        })
    }

    const setCurrentCall = (currentCall: FreeCallDTO | DirectCallDTO | undefined) => {
        setUser(x => {
            if (x) {
                return { ...x, currentCall }
            }
            return undefined
        })
    }

    const logout = React.useCallback(async () => {
        const role = getUserRole()
        if (role === 'Teacher') {
            await api.Teacher.loginOutWithDirectAuthAsTeacher()
        }
        cookies.remove('token', { path: '/' })
        // localStorage.remove('token')
        localStorage.removeItem('token')
        
        setDecodedJwt(undefined)
        setIsLoggedIn(false)
        setUser(undefined)
        router.replace({ pathname: '/' })
    }, [api.Teacher, router])

    React.useEffect(() => {
        ;(async () => {
            const { platform } = await Device.getInfo()
            setDevicePlatform(platform)
        })()
    }, [])
    React.useEffect(
        () => {
            ;(async () => {
                if (!isUndefined(tokenCookie) && !isUndefined(decodedJwt) && isUndefined(user)) {
                    setIsLoggedIn(true)

                    const role = decodedJwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                    try {
                        if (role === 'Student') {
                            const { data } = await api.Student.getMyDetailsAsStudent()
                            setUser({ userRole: 'Student', guid: data.studentGuid, ...data })
                        } else if (role === 'Teacher') {
                            const { data } = await api.Teacher.getMyDetailsAsTeacher()
                            setUser({ userRole: 'Teacher', guid: data.teacherGuid, ...data })
                        }
                    } catch {
                        await logout()
                        return
                    }

                    try {
                        !isUndefined(appInfo.playerId) && (await api.OneSignal.setPlayerId(appInfo.playerId))
                    } catch {}
                }
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [tokenCookie, decodedJwt, api.Student, api.Teacher, user, logout]
    )

    if (devicePlatform === 'ios') {
        localStorage.setItem('platform', devicePlatform)
        tokenCookie = localStorage.getItem('token')
    }

    React.useEffect(() => {
        if (!isUndefined(tokenCookie)) {
            console.log('tokenCookie',tokenCookie);
            if (tokenCookie==null) {
                logout()
            } else{

                const decodedJwt = jwt_decode<DecodedJwtData>(tokenCookie)
                console.log('decodedJwt',decodedJwt);
                setDecodedJwt(decodedJwt)
                setIsLoggedIn(!isUndefined(decodedJwt))
            }
        } else {
            setDecodedJwt(undefined)
            setIsLoggedIn(false)
        }
    }, [tokenCookie])

    return (
        <AuthContext.Provider
            value={{
                modalShown,
                toggleModal,
                setModalShown,
                isLoggedIn,
                user,
                setUser,
                logout,
                decodedJwt,
                userFunctions: {
                    setCurrentCall,
                    setOnlineStatus,
                    setCanFreeCall,
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

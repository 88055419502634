import * as React from 'react'
import classNames from 'classnames'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import { WordTutorialCategoryQueriesGetDetailsQueryResult as WordTutorialCategory } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import SvgArrowRightSLine from '../../icons/ArrowRightSLine'
import SvgArrowLeftSLine from '../../icons/ArrowLeftSLine'

type WordCategoryPaginationProps = {
    word: WordTutorialCategory
    count?: string
    onPrev: () => void
    onNext: () => void
}

const WordCategoryPagination = ({ word, count, onPrev, onNext }: WordCategoryPaginationProps) => {
    const { t } = useTranslation('organisms')
    const isMobile = useMedia('(max-width: 640px)')

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.left, styles.actions)}>
                {!isMobile ? (
                    <Button onClick={onPrev} variation="wide" disabled={word.previousWordTutorial === null}>
                        {t('wordCategoryPagination.prev')}
                    </Button>
                ) : (
                    <div onClick={onPrev}>
                        <SvgArrowLeftSLine className={styles.arrows} />
                    </div>
                )}
                {count && <div className={styles.count}>{count}</div>}
            </div>
            <div className={styles.translations}>
                <Text size="lg" weight="medium">
                    {word.currentWordTutorial.translations[0].content}
                </Text>
                <Text size="sm" weight="bold" type="warning">
                    {word.currentWordTutorial.translations[1].content}
                </Text>
            </div>
            <div className={classNames(styles.right, styles.actions)}>
                {!isMobile ? (
                    <Button onClick={onNext} type="gradient" variation="wide" disabled={word.nextWordTutorial === null}>
                        {t('wordCategoryPagination.next')}
                    </Button>
                ) : (
                    <div onClick={onNext}>
                        <SvgArrowRightSLine className={styles.arrows} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default WordCategoryPagination

import * as React from 'react'
import Star from '../../icons/Star'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

type RateableProps = {
    rate?: number
    displayText?: boolean
    onRateChanged?: (rate: number) => void
}

const Rateable = ({ rate, onRateChanged, displayText }: RateableProps) => {
    const { t } = useTranslation('atoms')
    const [localStarRate, setLocalStarRate] = React.useState(rate ?? 0)
    const totalStar = 5

    const onLocalStarRateChanged = (starRate: number) => {
        setLocalStarRate(starRate)

        if (typeof onRateChanged === 'function') {
            onRateChanged(starRate)
        }
    }

    const renderStarRate = () => {
        const starList: JSX.Element[] = []

        for (let x = 1; x <= totalStar; x++) {
            let func: any = undefined

            func = onLocalStarRateChanged.bind(null, x)

            starList.push(<Star className={localStarRate >= x ? styles.filledStar : styles.nonFilledStar} key={`starRate_${x}`} onClick={func} />)
        }

        return starList
    }

    const displayStarRate = () => {
        const starList: JSX.Element[] = []

        for (let x = 1; x <= totalStar; x++) {
            let func: any = undefined

            // func = onLocalStarRateChanged.bind(null, x)

            starList.push(<Star className={localStarRate >= x ? styles.filledStar : styles.nonFilledStar} key={`starRate_${x}`} onClick={func} />)
        }

        return starList
    }

    React.useEffect(() => {
        renderStarRate()
        displayStarRate()
    }, [displayStarRate, rate, renderStarRate])
    return (
        <>
            {displayText ? <div className={styles.wrapper}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>{renderStarRate()}</div>
                <div className={styles.textWrapper}>{t('starRate.' + localStarRate.toString())}</div>
            </div> :
                <div className={styles.wrapper}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '10rem' }}>{displayStarRate()}</div>
                </div>}
        </>
    )
}

export default Rateable

import * as React from 'react'
import { Loader2Line } from 'components/icons'

import styles from './style.module.css'
import classnames from 'classnames'

export default function LoadingBox({ type }: { type?: 'default' | 'pure' }) {
    return (
        <div className={classnames(styles.wrapper, { [styles[`type-${type}`]]: type })}>
            <Loader2Line />
        </div>
    )
}

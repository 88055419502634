import Error from './error'
import * as React from 'react'
import useChat from 'hooks/useChat'
import useAuth from '../hooks/useAuth'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorBox from '../components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import InfiniteScroll from 'react-infinite-scroll-component'
import ConversationList from 'components/organisms/conversation-list'
import MessagesTemplate, { styles } from 'components/templates/messages'

export default function Messages() {
    const { t } = useTranslation('common')

    const chat = useChat()
    const auth = useAuth()

    const loadPastConversations = () => {
        if (chat.state?.isMoreConversationsAvailable) {
            chat.methods?.loadPastConversations()
        }
    }

    React.useEffect(() => {
            if (auth.isLoggedIn) {
                auth.setModalShown?.(false)
            } else {
                auth.setModalShown?.(true)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn])

    if (!auth.isLoggedIn) {
        return <Error statusCode={401}/>
    }

    return (
        <Master title={t('messages.title')}>
            <MessagesTemplate>
                {chat.state?.isConversationsLoading && <LoadingBox />}
                {chat.state?.conversations.length === 0 && (
                    <div className={styles.noMessageWrapper}>
                        <ErrorBox size={'sm'} type={'danger'} text={t('messages.noMessage')} />
                    </div>
                )}
                {chat.state?.conversations && (
                    <>
                        <InfiniteScroll
                            className={styles.infiniteScroll}
                            next={loadPastConversations}
                            hasMore={chat.state.isMoreConversationsAvailable ?? false}
                            dataLength={chat.state.conversations.length ?? 0}
                            loader={<LoadingBox />}
                        >
                            {chat.state.conversations && <ConversationList conversations={chat.state.conversations} swipeable={true} />}
                        </InfiniteScroll>
                    </>
                )}
            </MessagesTemplate>
            {chat.state && chat.state.conversations.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.buttonWrapper}>
                        <Button variation={'wide'} size={'small'} type={'gradient'} onClick={chat.methods?.markAllMessagesAsRead}>
                            {t('messages.buttonText')}
                        </Button>
                    </div>
                </div>
            )}
        </Master>
    )
}

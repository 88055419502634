// import * as React from 'react'
// import {isFunction, isNumber} from 'lodash'
// import classnames from "classnames"
// import useInterval from 'hooks/useInterval'
// import {useTranslation} from 'react-i18next'
// import {secondsToTime} from 'utils/time-helpers'

// import styles from './style.module.css'

// interface RemainingConversationDurationProps {
//     duration: number
//     onEnd?: () => void
//     className?: string
//     maxDuration?: number
// }



// const RemainingConversationDuration: React.FC<RemainingConversationDurationProps> = ({duration, onEnd, className, maxDuration}) => {
//     const {t} = useTranslation('atoms')
//     const [seconds, setSeconds] = React.useState<number>(duration)

//     useInterval(
//         () => {
//             setSeconds(r => --r)
//         },
//         seconds > 0 ? 1000 : null
//     )

//     React.useEffect(() => {
//         if (seconds <= 0 && isFunction(onEnd)) {
//             onEnd()
//         }
//     }, [onEnd, seconds])

//     React.useEffect(() => {
//         if (duration < 0) {
//             setSeconds(0)
//         }
//     }, [duration])

//     if ((isNumber(maxDuration) && seconds > maxDuration)) return null

//     return (
//         <div className={classnames(styles.wrapper, className)}>
//             {t('reamaining-time')}: {secondsToTime(seconds)}
//         </div>
//     )
// }

// export default RemainingConversationDuration




import * as React from 'react';
import {isFunction, isNumber} from 'lodash';
import classnames from "classnames";
import useInterval from 'hooks/useInterval';
import {useTranslation} from 'react-i18next';
import {secondsToTime} from 'utils/time-helpers';

import styles from './style.module.css';

interface RemainingConversationDurationProps {
    duration: number;
    onEnd?: () => void;
    className?: string;
    maxDuration?: number;
}

const RemainingConversationDuration: React.FC<RemainingConversationDurationProps> = ({duration, onEnd, className, maxDuration}) => {
    const {t} = useTranslation('atoms');
    const endTimeRef = React.useRef<Date | null>(null);
    const [seconds, setSeconds] = React.useState<number>(duration);

    React.useEffect(() => {
        // Set or reset the end time based on the updated duration
        endTimeRef.current = new Date(new Date().getTime() + duration * 1000);
    }, [duration]);

    useInterval(() => {
        if (endTimeRef.current) {
            const now = new Date();
            const newSeconds = Math.floor((endTimeRef.current.getTime() - now.getTime()) / 1000);
            setSeconds(newSeconds);
        }
    }, seconds > 0 ? 1000 : null);

    React.useEffect(() => {
        if (seconds <= 0) {
            if (isFunction(onEnd)) {
                onEnd();
            }
            endTimeRef.current = null; // Reset end time to avoid further calculations
        }
    }, [onEnd, seconds]);

    if (isNumber(maxDuration) && seconds > maxDuration) return null;

    return (
        <div className={classnames(styles.wrapper, className)}>
            {t('reamaining-time')}: {secondsToTime(seconds)}
        </div>
    );
    }

export default RemainingConversationDuration;

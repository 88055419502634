import * as React from 'react'

function SvgSendPlane2Line(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3.741 1.408l18.462 10.154a.5.5 0 010 .876L3.741 22.592A.5.5 0 013 22.154V1.846a.5.5 0 01.741-.438zM5 13v6.617L18.85 12 5 4.383V11h5v2H5z" />
        </svg>
    )
}

export default SvgSendPlane2Line

import * as React from 'react'
import * as Api from 'utils/api-service';
import {
    AllAccounts,
    AppInfo,
    Appointment,
    Chat,
    CustomerService,
    LegalDocument,
    Live, OneSignal,
    Payments,
    Scheduler,
    Student,
    Teacher,
    VideoRecord,
    WordTutorial,
    WordTutorialCategory,
    Referrals
} from 'utils/api-service'


export interface ApiContextInterface {
    AllAccounts: typeof AllAccounts;
    AppInfo: typeof AppInfo;
    Appointment: typeof Appointment;
    Chat: typeof Chat;
    CustomerService: typeof CustomerService;
    LegalDocument: typeof LegalDocument;
    Live: typeof Live;
    Payments: typeof Payments;
    OneSignal: typeof OneSignal;
    Scheduler: typeof Scheduler;
    Student: typeof Student;
    Teacher: typeof Teacher;
    VideoRecord: typeof VideoRecord;
    WordTutorial: typeof WordTutorial;
    WordTutorialCategory: typeof WordTutorialCategory;
    Referrals: typeof Referrals
}

export const ApiContext = React.createContext<ApiContextInterface | any>({})

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
    return <ApiContext.Provider value={Api}>{children}</ApiContext.Provider>
}

import * as React from 'react'

import styles from './style.module.css'

export { styles }

const WordTutorialDetailTemplate: React.FC = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>{children}</div>
        </div>
    )
}

export default WordTutorialDetailTemplate

import * as React from 'react'

function SvgBookmark3Fill(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M4 2h16a1 1 0 011 1v19.276a.5.5 0 01-.704.457L12 19.03l-8.296 3.702A.5.5 0 013 22.276V3a1 1 0 011-1zm8 11.5l2.939 1.545-.561-3.272 2.377-2.318-3.286-.478L12 6l-1.47 2.977-3.285.478 2.377 2.318-.56 3.272L12 13.5z" />
        </svg>
    )
}

export default SvgBookmark3Fill

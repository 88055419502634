import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type CardHeadingProps = {
    title: string
    subTitle?: string
    size?: 'xl' | 'lg' | 'md' | 'sm'
    alignment?: 'center' | 'left' | 'right'
    divider?: boolean
    leading?: React.ReactNode
    trailing?: React.ReactNode
}

const CardHeading = ({ title, subTitle, size = 'lg', alignment = 'center', divider = false, leading, trailing }: CardHeadingProps) => {
    const Leading = () => {
        if (!leading) return null

        return <div className={classnames(styles.leading)}>{leading}</div>
    }

    const Trailing = () => {
        if (!trailing) return null

        return <div className={styles.trailing}>{trailing}</div>
    }

    return (
        <div className={classnames(styles['card-heading'], styles[size], styles[alignment], { [styles.divided]: divider })}>
            <Leading />
            <div className={classnames(styles.title)}>{title}</div>
            <div className={classnames(styles.title)}>{subTitle}</div>
            <Trailing />
        </div>
    )
}

export default CardHeading

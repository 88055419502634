import * as React from 'react'
import classNames from 'classnames'

import styles from './style.module.css'

type DefaultTextProps = {
    children: React.ReactNode
}

export type TextProps = {
    size?: '2xs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'
    type?: 'default' | 'dimmed' | 'warning' | 'danger' | 'danger-2' | 'white' | 'dimmed-light' | 'soft-blue'
    weight?: 'default' | 'medium' | 'bold'
    decoration?: 'default' | 'underline'
    clickable?: boolean
    className?: string
    onClick?: () => void
}

const Text = ({ size = 'base', type = 'default', weight = 'default', decoration = 'default', clickable, className, children, onClick }: TextProps & DefaultTextProps) => {
    return (
        <div
            onClick={() => onClick && onClick()}
            className={classNames(
                styles.text,
                styles[`size-${size}`],
                styles[`type-${type}`],
                styles[`weight-${weight}`],
                styles[`decoration-${decoration}`],
                { [styles.clickable]: clickable },
                className
            )}
        >
            {children}
        </div>
    )
}

export default Text

import * as React from 'react'
import useApi from 'hooks/useApi'
import { CloseLine } from '../../icons'
import Card from '../../molecules/card'
import Text from 'components/atoms/text'
import usePayment from 'hooks/usePayment'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

const PaymentModal: React.FC = () => {
    const api = useApi()
    const payment = usePayment()
    const [url, setUrl] = React.useState<string | undefined>(undefined)

    const hideModal = () => {
        setUrl(undefined)
        payment.cancelPayment?.()
        // localStorage.removeItem('iyziHash')
    }

    React.useEffect(() => {
        (async () => {
            if (typeof payment.product === 'undefined' || !payment.modalShown || payment.loading) return
            payment.setLoading?.(true)
            try {

                const { data } = await api.Payments.subscribe({
                    packageDurationId: payment.product.id,
                    // minutesPerDay: payment.product.minutesPerDay,
                    // daysPerWeek: payment.product.daysPerWeek,
                    billingAddress: payment.product.billingAddress,
                    referralId: payment.product.referralId
                })
                data.subscriptionFormContent && setUrl(data.subscriptionFormContent)
            } catch {
                payment.cancelPayment?.()
                setUrl(undefined)
            }

            payment.setLoading?.(false)
        })()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [payment.product, payment.modalShown, api.Payments]
    )

    React.useEffect(() => {
        return () => {
            setUrl(undefined)
            // localStorage.removeItem('iyziHash')
        }
    }, [])

    React.useEffect(() => {
        if (!payment.modalShown) {
            setUrl(undefined)
            // localStorage.removeItem('iyziHash')
        }
    }, [payment.modalShown])

    if (typeof payment.product === 'undefined') {
        return <></>
    }

    return (
        <Modal size={ModalSize.Large} isVisible={!!payment.modalShown} type={ModalType.Fullscreen} onModalClose={hideModal}>
            <div className={styles.container}>
                <Card
                    type={'default'}
                    heading={{
                        title: payment.product.title,
                        divider: true,
                        trailing: <CloseLine className={styles.closeLine} onClick={hideModal} />,
                    }}
                    shadowless
                >
                    <div className={styles.center}>
                        <Text weight={'medium'} size={'lg'}>
                            {payment.product.content}
                        </Text>
                    </div>
                    <div className={styles.center}>
                        <Text weight={'medium'}>{payment.product.description}</Text>
                    </div>
                </Card>

                <Card style={{ marginTop: '1rem' }} type={'default'} paddingles shadowless>
                    {typeof url !== 'undefined' && payment.modalShown && (
                        <>
                            <div id="iyzipay-checkout-form" className="responsive" />
                            <Script src={url} type={payment.product.type} />
                        </>
                    )}
                    {typeof url === 'undefined' && <ClearScript type={payment.product.type} />}
                </Card>
            </div>
        </Modal>
    )
}

export default PaymentModal

const useScript = (url: string, type: 'subscription') => {
    React.useEffect(() => {
        let cleanUrl = ''
        if (type === 'subscription') {
            cleanUrl = url
                .replace(/<script type="text\/javascript">(.*)<\/script>/, '$1')
                .replace(/(.*)<\/script><script type="text\/javascript">(.*)/, '$1 $2')
                .replace(/(.*)<\/script><script type="text\/javascript">(.*)/, '$1 $2')
        }

        const script = document.createElement('script')

        const text = document.createTextNode(cleanUrl)
        script.appendChild(text)

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url, type])
}

export function Script({ src, type }: { src: string; type: 'subscription' }) {
    useScript(src, type)

    return null
}

const ClearUseScript = (type: 'subscription') => {
    React.useEffect(() => {
        const cleanUrl =
            "if(typeof iyziInit !== 'undefined'){iyziInit = undefined}if(typeof iyziUcsInit !== 'undefined'){iyziUcsInit = undefined}if(typeof iyziSubscriptionInit !== 'undefined'){iyziSubscriptionInit = undefined}"

        const script = document.createElement('script')

        const text = document.createTextNode(cleanUrl)
        script.appendChild(text)

        document.body.appendChild(script)
        console.log('[script]'+script);
        console.log('[text]'+text);
        localStorage.removeItem('iyziHash')

        return () => {
            document.body.removeChild(script)
        }
    }, [type])
}

export function ClearScript({ type }: { type: 'subscription' }) {
    ClearUseScript(type)
    return null
}

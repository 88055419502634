import * as React from 'react'
import { useTranslation } from 'react-i18next'
import SegmentCardItem from 'components/atoms/segmented-card-item'

import styles from './style.module.css'

type SegmentedCardProps = {
    totalLessonMinutes: number
    monthlyEarnings: number
    totalEarnings: number
    totalHours: number
}

const SegmentedCard = ({ totalLessonMinutes, monthlyEarnings, totalEarnings, totalHours }: SegmentedCardProps) => {
    const { t } = useTranslation('molecules')

    return (
        <>
            <div className={styles.wrapper}>
                <SegmentCardItem text={t('segmentedCard.description')} />
            </div>
            <div className={styles.wrapper}>
                <SegmentCardItem label={t('segmentedCard.totalLessonTime')} text={totalLessonMinutes + t('segmentedCard.totalLessonTimeCurrency')} truncated />
                <SegmentCardItem label={t('segmentedCard.monthlyEarnings')} text={monthlyEarnings.toFixed(2) + '$'} truncated />
                <SegmentCardItem label={t('segmentedCard.totalEarnings')} text={totalEarnings.toFixed(2) + '$'} truncated />
                <SegmentCardItem label={t('segmentedCard.totalHours')} text={totalHours.toFixed(2)} truncated />
            </div>
        </>
    )
}

export default SegmentedCard

import * as React from 'react'

import styles from './style.module.css'
import PhoneFill from '../../icons/PhoneFill'

const CallAnimation = () => {
    return (
        <div className={styles.callAnimation}>
            <PhoneFill className={styles.icon} />
        </div>
    )
}

export default CallAnimation

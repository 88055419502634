import useAuth from 'hooks/useAuth'
import classNames from 'classnames'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import { CustomerService2Line } from 'components/icons'
import TeacherInfo from 'components/molecules/teacher-info'
import { TeacherQueriesGetDetailsQueryResult as Teacher, TeacherQueriesTeacherListItem as QueryTeacher } from '@mohsininsignia/advancerapiclient'
import useChat from 'hooks/useChat'
import useApi from 'hooks/useApi'
import styles from './style.module.css'
import { HubConnectionState } from '@microsoft/signalr'

export default function TeacherInfoCard({ teacher, arrangedButtons }: { teacher: Teacher | QueryTeacher; arrangedButtons?: boolean }) {
    const auth = useAuth()
    const chat = useChat()
    const { t } = useTranslation('organisms')
    const api = useApi()
    const openChat = () => {
        if (!auth.isLoggedIn) {
            auth.setModalShown?.(true)
            return
        }
        // chat.open
        if (teacher.teacherGuid) {
            chat.methods?.createConversation(teacher.teacherGuid.toString())
        }
    }

    const handleCallTeacher = async () => {
        if (!auth.isLoggedIn) {
            auth.setModalShown?.(true)
            return
        }

        if (auth.user?.userRole === 'Student') {
            auth.user.canMakeFreeCall ? await api.Live.requestFreeCall(teacher.teacherId) : await api.Live.directCall(teacher.teacherId)
        }
    }

    return (
        <div>
            <Card className={styles.card}>
                <div className={classNames(styles.wrapper)}>
                    <TeacherInfo vertical={true} teacher={teacher} />

                    {arrangedButtons ? (
                        <div className={classNames(styles.actionsGrid)}>
                            <Button as="anchor" href={`/teachers/${teacher.teacherId}/appointment`} disabled={!teacher.hasFreeAppointmentRanges}>
                                {t('teacherInfoCard.appointment')}
                            </Button>
                            <Button onClick={openChat} type="danger" disabled={chat.client?.ConnectionState !== HubConnectionState.Connected}>
                                {t('teacherInfoCard.sendMessage')}
                            </Button>

                            <Button
                                onClick={handleCallTeacher}
                                type="gradient"
                                alignment="center"
                                leftIcon={<CustomerService2Line />}
                                leftIconPlacement="relative"
                                disabled={!teacher.availableForFreeCall}
                            >
                                {t('teacherInfoCard.callTeacher')}
                            </Button>
                        </div>
                    ) : (
                        <div className={classNames(styles.actions)}>
                            <Button as="anchor" href={`/teachers/${teacher.teacherId}/appointment`} disabled={!teacher.hasFreeAppointmentRanges}>
                                {!teacher.hasFreeAppointmentRanges ? t('teacherInfoCard.notAvailable') : t('teacherInfoCard.appointment')}
                            </Button>
                            <Button
                                onClick={handleCallTeacher}
                                type="gradient"
                                alignment="center"
                                leftIcon={<CustomerService2Line />}
                                leftIconPlacement="relative"
                                disabled={!teacher.isOnline}
                            >
                                {!teacher.isOnline ? t('teacherInfoCard.offline') : t('teacherInfoCard.callTeacher')}
                            </Button>
                            <Button onClick={openChat} type="danger" disabled={chat.client?.ConnectionState !== HubConnectionState.Connected}>
                                {t('teacherInfoCard.sendMessage')}
                            </Button>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    )
}

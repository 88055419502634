export const TeachingStyles = [{ value: 'KIND_AND_PATIENT' }, { value: 'FUN_AND_GREGARIOUS' }, { value: 'SCHOLARLY_AND_KNOWLEDGEABLE' }]

export const LessonTypes = [
    { value: 'PRONUNCIATION_LESSONS' },
    { value: 'GRAMMAR_LESSONS' },
    { value: 'LESSONS_WITH_MOVIES' },
    { value: 'LESSONS_FOR_CHILDREN' },
    { value: 'BUSINESS_ENGLISH' },
    { value: 'UNIVERSITY_ENGLISH_PREPARATION' },
    { value: 'EXAM' },
    { value: 'TOEFL_PREPARATION' },
    { value: 'IELTS_PREPARATION' },
]

export const Accents = [{ value: 'NATIVE_US' }, { value: 'NATIVE_BRITISH' }, { value: 'TURKISH' }, { value: 'OTHER' }]

export const LanguageLevels = [{ value: 'BEGINNER' }, { value: 'INTERMEDIATE' }, { value: 'ADVANCED' }]

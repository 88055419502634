import * as React from 'react'
import Input from '../../atoms/input'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import useApi from 'hooks/useApi'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import styles from './style.module.css'

export type ContactModalProps = {
    isVisible: boolean
    onModalClose: (confirm?: boolean) => void
}

const ContactModal = ({ isVisible, onModalClose }: ContactModalProps) => {
    const api = useApi()

    const [title, setTitle] = React.useState<string | undefined>(undefined)
    const [content, setContent] = React.useState<string | undefined>(undefined)
    const [loading, setLoading] = React.useState(false)

    const { t } = useTranslation('organisms')

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onConfirmButtonClickHandler = async () => {
        setLoading(true)

        try {
            await api.CustomerService.sendContactMessage(title, content)
            toast.success(t('contactModal.toast-success'))
            onModalClose(true)
        } catch {}

        setLoading(false)
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalClose} type={ModalType.ActionSheet} size={ModalSize.Large}>
            <div className={styles.container}>
                <Card
                    type={'default'}
                    paddingles
                    actionSheet
                    heading={{
                        title: t('contactModal.contact'),
                        size: 'md',
                    }}
                >
                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'default'} weight={'medium'}>
                            {t('contactModal.header')}
                        </Text>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            {t('contactModal.min-3-character')}
                        </Text>
                        <Input onChangeText={setTitle} label={t('contactModal.title')} />
                    </div>

                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'default'} weight={'medium'}>
                            {t('contactModal.description')}
                        </Text>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            {t('contactModal.min-10-character')}
                        </Text>
                        <Input onChangeText={setContent} label={t('contactModal.description-text')} rows={5} multiline />
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'default'} onClick={onModalCloseHandler}>
                            {t('contactModal.give-up')}
                        </Button>
                        <Button
                            style={{ whiteSpace: 'nowrap' }}
                            size={'large'}
                            type={'danger-2'}
                            onClick={onConfirmButtonClickHandler}
                            loading={loading ?? false}
                            disabled={typeof title === 'undefined' || typeof content === 'undefined' || title.length < 3 || content.length < 10}
                        >
                            {t('contactModal.send')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ContactModal

import * as React from 'react'

function SvgWhiteBoardOff(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg id="Layer_1" enable-background="new 0 0 299.942 299.942" viewBox="0 0 299.942 299.942" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <g>
                        <path style={{fill:"white"}}
                            d="m293.411 203.876h-13.04v-176.04c0-3.606-2.914-6.52-6.52-6.52h-107.881l-7.875-17.474c-.017-.038-.038-.072-.056-.109-.043-.09-.089-.178-.136-.266-.056-.106-.112-.212-.174-.314-.041-.068-.086-.134-.13-.201-.076-.116-.152-.231-.234-.341-.034-.046-.072-.089-.107-.134-.099-.125-.2-.248-.307-.365-.025-.027-.052-.053-.078-.08-.124-.13-.251-.256-.384-.375-.017-.015-.034-.028-.051-.043-.148-.129-.3-.252-.458-.366-.009-.006-.018-.012-.027-.019-.171-.122-.346-.236-.527-.341-.003-.002-.006-.003-.009-.005-.19-.11-.385-.209-.584-.299-.001-.001-.003-.001-.004-.002-.002-.001-.005-.002-.007-.003-.189-.085-.382-.161-.579-.227-.031-.011-.063-.021-.095-.031-.163-.053-.328-.099-.496-.139-.059-.014-.118-.027-.177-.039-.139-.029-.278-.053-.419-.073-.082-.012-.165-.022-.247-.03-.118-.012-.237-.021-.356-.026-.102-.005-.203-.008-.304-.008s-.203.003-.304.008c-.118.006-.236.014-.354.026-.084.009-.168.019-.253.031-.137.019-.272.043-.407.071-.065.014-.13.028-.195.043-.158.038-.313.082-.467.131-.043.014-.086.027-.13.042-.179.061-.354.13-.526.207-.015.007-.03.011-.045.018-.009.004-.017.009-.026.013-.169.077-.334.162-.496.253-.036.02-.072.039-.107.059-.137.081-.27.168-.401.259-.052.036-.106.071-.157.108-.105.077-.206.161-.306.245-.068.057-.138.112-.203.171-.077.07-.15.145-.224.219-.08.08-.161.159-.237.243-.054.06-.105.124-.157.187-.088.105-.175.211-.256.321-.036.049-.069.1-.103.151-.091.132-.179.265-.26.402-.022.038-.043.077-.064.116-.088.156-.169.314-.244.476-.006.012-.013.024-.019.036l-7.873 17.474h-112.244c-3.606 0-6.52 2.914-6.52 6.52v176.04h-13.04c-3.606 0-6.52 2.914-6.52 6.52s2.914 6.52 6.52 6.52h19.56 22.683l-31.928 73.924c-1.428 3.293.085 7.133 3.397 8.567.841.365 1.721.535 2.582.535 2.53 0 4.929-1.474 5.985-3.938l34.159-79.088h174.198l38.431 79.348c1.121 2.328 3.456 3.677 5.875 3.677.945 0 1.923-.209 2.836-.652 3.24-1.565 4.597-5.47 3.038-8.711l-35.68-73.663h22.184 19.56c3.606 0 6.52-2.914 6.52-6.52s-2.915-6.519-6.52-6.519zm-260.8 0v-169.52h234.72v169.52z"
                            fill="#000000"
                        ></path>
                        <path style={{fill:"white"}}
                            d="m174.16 105.163c-1.858-1.878-4.668-2.445-7.113-1.447-2.445 1.011-4.036 3.397-4.036 6.038v74.563c0 3.606 2.914 6.52 6.52 6.52h73.904c2.628 0 5.014-1.591 6.018-4.01 1.011-2.432.463-5.222-1.389-7.094zm1.891 72.633v-52.199l51.743 52.199z"
                            fill="#000000"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SvgWhiteBoardOff

import * as React from 'react'
import classNames from 'classnames'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { getVideoId } from 'utils/video-helpers'
import FavoriteButton from 'components/atoms/favorite-button'
import { ArrowRightLine, PlayCircleFill } from 'components/icons'
import { WordTutorialCategoryQueriesWordTutorial as Word } from '@mohsininsignia/advancerapiclient'

import styles from './style.module.css'
import YoutubePlayer from '../youtube-player'

type WordTutorialResultCardProps = {
    word: Word
    isSelected?: boolean
    isAnotherSelected?: boolean
    onSelect: (word: Word) => void
}

const WordTutorialResultCard = ({ word, isSelected, isAnotherSelected, onSelect }: WordTutorialResultCardProps) => {
    const isTabletOrMobile = useMedia('(max-width: 1024px)')

    const [subtitleEn, setSubtitleEn] = React.useState('')
    const [subtitleTr, setSubtitleTr] = React.useState('')

    const onSelectHandler = (e: any) => {
        if (window.getSelection()?.toString().length) return

        if (!['button', 'svg', 'path'].includes(e.target.nodeName.toLowerCase())) onSelect(word)
    }

    const onButtonClickHandler = () => {
        onSelect(word)
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.selected]: isSelected,
                [styles.isAnotherSelected]: isAnotherSelected,
            })}
            onClick={onSelectHandler}
        >
            <FavoriteButton favorited={word.isFavorited} />
            <div className={styles.translations}>
                <Text size="xl" weight="medium">
                    {word.translations[0].content}
                </Text>
                <Text weight="bold" type="danger">
                    {word.translations[1].content}
                </Text>
            </div>
            <div className={styles.subtitles}>
                {isSelected && isTabletOrMobile && (
                    <>
                        <div className={styles.video}>
                            <YoutubePlayer
                                videoId={getVideoId(word.youtubeVideoUrl)}
                                enSrtSPath={word.subtitles.find(x => x.languageCode === 'en')?.subtitlePath}
                                trSrtPath={word.subtitles.find(x => x.languageCode === 'tr')?.subtitlePath}
                                onEnTextChanged={setSubtitleEn}
                                onTrTextChanged={setSubtitleTr}
                            />
                        </div>
                        <Text size="lg" type="dimmed">
                            {subtitleEn}
                        </Text>
                        <Text size="lg" weight="medium">
                            {subtitleTr}
                        </Text>
                    </>
                )}
                {!isSelected && (
                    <>
                        <Text size="lg" type="dimmed">
                            {word.subtitles.find(r => r.languageCode === 'en')?.lines[0].content}
                        </Text>
                        <Text size="lg" weight="medium" className={styles.subText}>
                            {word.subtitles.find(r => r.languageCode === 'tr')?.lines[0].content}
                        </Text>
                    </>
                )}
            </div>
            <div className={styles.right}>
                {isSelected && <ArrowRightLine />}
                {!isSelected && !isAnotherSelected && (
                    <Button onClick={onButtonClickHandler} type="gradient" variation="circle">
                        <PlayCircleFill />
                    </Button>
                )}
            </div>
        </div>
    )
}

export default WordTutorialResultCard

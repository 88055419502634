import * as React from 'react'
import classnames from 'classnames'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import { CustomerService2Line } from 'components/icons'
import { Home as HomeIllustration } from 'components/illustrations'
import { useHistory } from 'react-router-dom'
import { MdOutlinePayment } from 'react-icons/md'

import styles from './style.module.css'
import useAuth from 'hooks/useAuth'

const Jumbotron = ({ isLoggedIn, hasTrial }: { isLoggedIn: boolean; hasTrial?: boolean }) => {
    const { t } = useTranslation('common')
    const router = useHistory()
    const auth = useAuth()

    const courseFee = () => {
        if (!auth.user) {
            router.push({ pathname: '/coursefee' })
        } else {
            router.push({ pathname: '/profile/subscription' })
        }
    }
    return (
        <div className={classnames(styles.jumbotron)}>
            <Card>
                <div className={classnames(styles.wrapper)}>
                    <div className={classnames(styles.jumbotronCell, styles.illustration)}>
                        <HomeIllustration />
                    </div>
                    <div className={classnames(styles.jumbotronCell, styles.body)}>
                        <p>{t('home.promotionalText')}</p>
                        <Button
                            onClick={() => router.push({ pathname: '/teachers' })}
                            type="gradient"
                            alignment="center"
                            variation="block"
                            leftIcon={<CustomerService2Line />}
                            leftIconPlacement="relative"
                        >
                            {isLoggedIn && hasTrial ? t('home.trialButton') : t('home.findTeacher')}
                        </Button>
                        <br />
                        <Button
                            onClick={courseFee}
                            type="gradient"
                            alignment="center"
                            variation="block"
                            leftIcon={<MdOutlinePayment style={{ fontSize: '24px' }} />}
                            leftIconPlacement="relative"
                        >
                            {t('organisms:studentNavigation.manageSubscription')}
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Jumbotron

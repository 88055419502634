import * as React from 'react'
import useApi from 'hooks/useApi'
import { isUndefined } from 'lodash'
import useCall from 'hooks/useCall'
import { getUserRole } from 'utils/auth'
import Card from '../../molecules/card'
import Button from '../../atoms/button'
import { useTranslation } from 'react-i18next'
import useFlashTitle from 'hooks/useFlashTitle'
import CallAnimation from '../../atoms/call-animation'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'

import styles from './style.module.css'
import { useHistory } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { LiveCommandsJoinToRoomAsStudentCommandResult, LiveCommandsJoinToRoomAsTeacherCommandResult } from '@mohsininsignia/advancerapiclient'

const IncomingCallCard = () => {
    const { t } = useTranslation()
    const auth = useAuth()

    const router = useHistory()

    const call = useCall()
    const api = useApi()
    const userRole = getUserRole()
    const flashTitle = useFlashTitle()

    const [loading, setLoading] = React.useState(false)
    const [userGesture, setUserGesture] = React.useState(false)

    const audio = React.useRef(new Audio('/sound/incoming-call.mp3'))
    const [room, setRoom] = React.useState<LiveCommandsJoinToRoomAsStudentCommandResult | LiveCommandsJoinToRoomAsTeacherCommandResult | undefined>(undefined)

    React.useEffect(() => {
        const handler = () => {
            setUserGesture(true)
        }

        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    React.useEffect(() => {
        audio.current.loop = true
        audio.current.muted = true

        if (call.hasInComingCall && !call.teacherAcceptedCall) {
            flashTitle.on(`${userRole === 'Student' ? call.currentCall?.appointment?.teacher?.name : call.currentCall?.appointment?.student?.name} ${t('common:call')}`, 1000)

            audio.current.muted = false
            userGesture && audio.current.play();
        } else {
            flashTitle.off()
            audio.current.pause()
            audio.current.muted = true
        }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
        [userGesture, call.hasInComingCall, call.teacherAcceptedCall])
    const closePage = React.useCallback(async () => {
        await router.replace({ pathname: '/' })
    }, [router])
    const acceptCalling = async () => {
        // setLoading(true)
        if (!isUndefined(call.currentCall) && !isUndefined(call.currentCall.appointment)) {
            setLoading(true)

            try {
                await api.Teacher.acceptCall(call.currentCall.callId, call.currentCall.appointment.teacherId)
                // .then(async (res) => {
                // try {
                //     switch (auth.user?.userRole) {
                //         case 'Teacher':
                //             await api.Live.joinToRoomAsTeacher(call.currentCall?.appointment?.id)
                //             break
                //         case 'Student':
                //             await api.Live.joinToRoomAsStudent(call.currentCall?.appointment?.id)
                //             break
                //     }
                // } catch {
                //     await closePage()
                // }
                // })
            } catch {
            }

            setLoading(false)
        }
    }

    const stopCalling = async () => {
        if (!isUndefined(call.currentCall)) {
            setLoading(true)

            try {
                await api.Teacher.rejectCall(call.currentCall.callId, call.currentCall.appointment?.teacherId)
            } catch {
            }

            setLoading(false)
        }
    }

    return (
        <Modal isVisible={(!isUndefined(call.hasInComingCall) && call.hasInComingCall && !call.teacherAcceptedCall)} onModalClose={stopCalling} size={ModalSize.Small}
            type={ModalType.Fullscreen}
            backdropUnClickable escUnClickable>
            <Card className={styles.container}>
                <div className={styles.wrapper}>
                    <CallAnimation />
                    <p className={styles.callText}>{t('organisms:incomingCallModal.header')}</p>
                    <p className={styles.callDescriptionText}>{userRole === 'Student' ? call.currentCall?.appointment?.teacher?.name : call.currentCall?.appointment?.student?.name}</p>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button variation={'block'} onClick={stopCalling} loading={loading}>
                        {t('organisms:incomingCallModal.give-up')}
                    </Button>
                    <Button variation={'block'} type="gradient" onClick={acceptCalling} loading={loading}>
                        {t('organisms:incomingCallModal.accept')}
                    </Button>
                </div>
            </Card>
        </Modal>
    )
}

export default IncomingCallCard

import * as React from 'react'
import useApi from 'hooks/useApi'
import { isNumber } from 'lodash'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import HomeTemplate from 'components/templates/home'
import ErrorBox from '../components/atoms/error-box'
import { styles } from 'components/templates/profile'
import Jumbotron from 'components/organisms/jumbotron'
import LoadingBox from '../components/atoms/loading-box'
import PageFooter from 'components/organisms/page-footer'
import { ApiContextInterface } from 'providers/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import MovieCategories from 'components/organisms/movie-categories'
import AppointmentCard from 'components/molecules/appointment-card'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import TeacherAvailabilityCard from 'components/organisms/teacher-availability-card'
import { AppointmentQueriesListAppointmentsPagination as Pagination } from '@mohsininsignia/advancerapiclient/models/appointment-queries-list-appointments-pagination'
import { AppointmentQueriesAppointmentListItem as Appointment, WordTutorialCategoryQueriesWordTutorialCategoryInList as Category } from '@mohsininsignia/advancerapiclient'
import PageInfo from 'components/atoms/page-info'


  
export default function Home() {
    const { t } = useTranslation()

    const api = useApi() as ApiContextInterface
    const auth = useAuth()
    const router = useHistory()
    const userRole = getUserRole()

    const [loading, setLoading] = React.useState(false)
    const [categories, setCategories] = React.useState<Category[]>([])
    const [appointments, setAppointments] = React.useState<Appointment[]>([])
    const [previousAppointments, setPreviousAppointments] = React.useState<Appointment[]>([])
    const [teacherCalendarModalShown, setTeacherCalendarModalShown] = React.useState(false)
    const [pagination, setPagination] = React.useState<Pagination>({ isNextPageAvailable: false, lastId: null })


    React.useEffect(() => {
        const hasTimePassed=(targetTime:any)=> {
            // Parse the given time string to a Date object
            const targetDate = new Date(targetTime);
            
            // Get the current date and time
            const now = new Date();
            
            // Compare the target date with the current date
            return targetDate < now;
          }

        ;(async () => {
            setLoading(true)
            try {
                if (userRole === 'Teacher') {
                    const { data: appointmentData } = await api.Appointment.listMyAppointments()
                    const upcomingAppointments=await appointmentData.appointments.filter((ele:any)=>!hasTimePassed(ele.endDateTime))
                    const oldAppointments=await appointmentData.appointments.filter((ele:any)=>hasTimePassed(ele.endDateTime))
                    console.log(upcomingAppointments);
                    setAppointments(upcomingAppointments)
                    setPreviousAppointments(oldAppointments)
                    // setAppointments(appointmentData.appointments)
                    // setAppointments(upcomingAppointments)
                    // setAppointments(upcomingAppointments)
                    setPagination(appointmentData.pagination)
                } else {
                    const { data } = await api.WordTutorialCategory.listWordTutorialCategories()

                    setCategories(data.categories)
                }
            } catch {}
            setLoading(false)
        })()
    }, [api.Appointment, api.WordTutorialCategory, userRole])

    const changeOnlineStatus = React.useCallback(
        async (status: boolean) => {
            if (auth.user?.userRole === 'Teacher' && auth.user.isOnline !== status) {
                setLoading(true)
                try {
                    await api.Teacher.changeMyOnlineStatus(status)

                    auth.userFunctions?.setOnlineStatus?.(status)

                    if (status) setTeacherCalendarModalShown(true)
                } catch {}
                setLoading(false)
            }
        },
        [api.Teacher, auth.user, auth.userFunctions]
    )

    // const saveScrollPosition = () => {
    //     const scrollableDiv: any = document.getElementById('scrollableHomeDiv')
    //     return scrollableDiv.scrollTop
    // }

    // // Function to call after new data is loaded
    // const restoreScrollPosition = (scrollPosition: any) => {
    //     const scrollableDiv: any = document.getElementById('scrollableHomeDiv')
    //     scrollableDiv.scrollTop = scrollPosition
    // }

    const paginate = async () => {
        // const scrollPosition = saveScrollPosition()
        // Load new data here...
        if (!pagination.isNextPageAvailable || !isNumber(pagination.lastId) || appointments.length === 0) return true
        try {
            if (pagination.isNextPageAvailable && pagination.lastId) {
                const { data } = await api.Appointment.listMyAppointments(pagination.lastId)
                setPagination(data.pagination)
                setAppointments([...appointments, ...data.appointments])
            }
        } catch {}
        // restoreScrollPosition(scrollPosition)
    }

    const handleCancelAppointment = (id: number) => {
        setAppointments(x => x.filter(appointment => appointment.appointmentId !== id))
    }

    const Student = () => {
        return (
            <HomeTemplate>
                <Jumbotron isLoggedIn={auth.isLoggedIn ?? false} hasTrial={auth.user?.userRole === 'Student' && auth.user.canMakeFreeCall} />
                <MovieCategories categories={categories} loading={loading} />
            </HomeTemplate>
        )
    }

    const Teacher = () => {
        return (
            <div>
                <HomeTemplate isTeacher>
                    <TeacherAvailabilityCard
                        status={auth.user?.userRole === 'Teacher' && auth.user.isOnline ? 'online' : 'offline'}
                        onChange={status => changeOnlineStatus(status === 'online')}
                        loading={loading}
                    />

                    <ConfirmModalCard
                        isVisible={teacherCalendarModalShown}
                        title={t('organisms:appointmentConfirmation.title')}
                        description={t('organisms:appointmentConfirmation.description')}
                        confirmButtonText={t('organisms:appointmentConfirmation.buttonText')}
                        onModalClose={() => setTeacherCalendarModalShown(false)}
                        onCancelButtonClick={() => setTeacherCalendarModalShown(false)}
                        onConfirmButtonClick={() => router.push({ pathname: '/calendar' })}
                    />

                    {loading && <LoadingBox />}

                    {!loading && !appointments.length && <ErrorBox text={t('myAppointments.notFound')} />}

                    {!loading && (
                        <InfiniteScroll
                            next={paginate}
                            hasMore={pagination.isNextPageAvailable}
                            loader={<LoadingBox />}
                            dataLength={appointments.length}
                        >
                            <div className={styles.list}>
                                {appointments.map(appointment => (
                                    <AppointmentCard
                                        as="Teacher"
                                        appointment={appointment}
                                        key={appointment.appointmentId + '_appointment'}
                                        onCancelled={handleCancelAppointment}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                    {!loading && <hr/>}
                    {!loading && <PageInfo>{`${t('myAppointments.completedAppointments')}`}</PageInfo>}
                                        {!loading && (
                        <InfiniteScroll
                            next={paginate}
                            hasMore={pagination.isNextPageAvailable}
                            loader={<LoadingBox />}
                            dataLength={appointments.length}
                        >
                            <div className={styles.list}>
                                {previousAppointments.map(appointment => (
                                    <AppointmentCard
                                        as="Teacher"
                                        appointment={appointment}
                                        key={appointment.appointmentId + '_appointment'}
                                        onCancelled={handleCancelAppointment}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </HomeTemplate>
            </div>
        )
    }

    return (
        <>
            <Master title={`${t('common:home.title')}${t('common:title')}`}>{auth.user?.userRole === 'Teacher' ? <Teacher /> : <Student />}</Master>
            <PageFooter />
        </>
    )
}

import * as React from "react"
import Config from "../config"
import ReactDOM from "react-dom"
import jwt_decode from 'jwt-decode'
import {FoshChatClient} from "@fosh/chat-client"
import {HubConnectionState} from "@microsoft/signalr"
import {convertReceivedMsgToMsgWithUser, getOtherUserId} from "utils/chat-helpers"
import {
    AcceptCallDTO,
    AppointmentFinishedDTO,
    AppointmentStartedDTO,
    AppointmentStartingDTO,
    CallTimeoutDTO,
    ChatBlockDTO,
    ChatContextProps,
    ChatProviderProps,
    ChatProviderState,
    ChatUser,
    DecodedJwt,
    DirectCallDTO,
    DirectCallStartedDTO,
    FreeCallDTO,
    RejectCallDTO,
    TeacherNotJoinedToAppointmentDTO
} from "types/chatProvider"
import {ConversationDeletedData, MarkConversationAsReadData, MessageDeletedData, SystemMessageReceivedData} from "@fosh/chat-client/hubs/ChatHub.Types"
import {ConversationWithUserMetadata, MessageReceivedDataWithUserMetadata, PresenceUpdateDataWithUserMetadata} from "@fosh/chat-client/FoshChatClient.Types"
import moment from "moment";

export const ChatContext = React.createContext<Partial<ChatContextProps>>({
    state: {
        myJwt: null,
        decodedJwt: null,
        connectionState: HubConnectionState.Disconnected,

        // Conversations
        isConversationsLoading: false,
        isMoreConversationsAvailable: false,
        isPastConversationsLoading: false,
        conversations: [],

        // Conversation
        openedConversation: null,
        messagesOfOpenedConversation: [],
        isMoreMessagesAvailableInOpenedConversation: false,
        isMessagesLoading: false,
        isMoreMessagesLoading: false,
        creatingConversation: false,
        isMarkingAllMessagesAsRead: false,
        isSendingMessage: false,
        isDeletingConversation: false,

        // Presence
        isOtherUserOnline: false,
    },
    methods: {
        loadPastConversations: async () => {
        },
        createConversation: async () => {
        },
        openConversation: async () => {
        },
        closeConversation: () => {
        },
        connectToChat: async () => {
        },
        disconnectFromChat: async () => {
        },
        deleteConversation: async () => {
        },
        markAllMessagesAsRead: async () => {
        },
        loadPastMessages: async () => {
        },
        sendMessageToConversation: async () => {
        },
    }
})

export class Chat extends React.Component<ChatProviderProps, ChatProviderState> {
    private readonly _chatClient: FoshChatClient<ChatUser | null>

    public get ChatClient(): FoshChatClient<ChatUser | null> {
        return this._chatClient
    }

    constructor(props: ChatProviderProps) {
        super(props)

        this._chatClient = new FoshChatClient(Config.chat.appId, this.fetchChatUser.bind(this))
        this._chatClient.Events.on('connectionStateChanged', this.onConnectionStateChanged.bind(this))
        this._chatClient.Events.on('messageReceived', this.onMessageReceived.bind(this))
        this._chatClient.Events.on('presenceUpdate', this.precenseUpdated.bind(this))
        this._chatClient.Events.on('markAllMessagesAsRead', this.allMessagesMarkedAsRead.bind(this))
        this._chatClient.Events.on('markConversationAsRead', this.conversationMarkedAsRead.bind(this))
        this._chatClient.Events.on('conversationDeleted', this.conversationDeleted.bind(this))
        this._chatClient.Events.on('messageDeleted', this.messageDeleted.bind(this))
        this._chatClient.Events.on('systemMessageReceived', this.onSystemMessageReceived.bind(this))

        this.state = {
            myJwt: null,
            decodedJwt: null,
            connectionState: HubConnectionState.Disconnected,

            // Conversations
            isConversationsLoading: false,
            isMoreConversationsAvailable: false,
            isPastConversationsLoading: false,
            conversations: [],

            // Conversation
            openedConversation: null,
            messagesOfOpenedConversation: [],
            isMoreMessagesAvailableInOpenedConversation: false,
            isMessagesLoading: false,
            isMoreMessagesLoading: false,
            creatingConversation: false,
            isMarkingAllMessagesAsRead: false,
            isSendingMessage: false,
            isDeletingConversation: false,

            // Presence
            isOtherUserOnline: false,
        }
    }

    //region Events
    private async fetchChatUser(userId: string): Promise<ChatUser | null> {
        // console.log('FETCH CHAT USER!', userId)
        try {
            const {data} = await this.props.api.Chat.getUserInformations([userId])

            // console.log(data)
            if (data == null || data.userInformations.length === 0) {
                return null
            }

            const userData = data.userInformations.find(x => x.id === userId)!;

            return {
                id: userData.id ?? '',
                userId: userData.userId ?? '',
                avatar1X: userData.avatar1X ?? '',
                avatar2X: userData.avatar2X ?? '',
                avatar3X: userData.avatar3X ?? '',
                avatar4X: userData.avatar4X ?? '',
                visibleName: userData.visibleName ?? '',
                isBanned: userData.isBanned ?? false,
                didUserBannedMe: userData.didUserBannedMe ?? false
            }
        } catch (e) {
            throw e
        }
    }

    private async onConnectionStateChanged(newState: HubConnectionState) {
        this.setState({
            connectionState: newState,
        })

        if (newState === HubConnectionState.Connected) {
            await this.onChatConnected()
        }
    }

    private async onChatConnected() {
        // console.log('Connected to chat.')

        ReactDOM.flushSync(() => {
            this.setState({
                isConversationsLoading: true
            });
        });

        let foundConversations: ConversationWithUserMetadata<ChatUser | null>[] = [];
        let isMoreConversationsAvailable = false;

        try {
            const conversations = await this.ChatClient.getConversations(moment().add(5, 'seconds').toDate())
            if (conversations) {
                foundConversations = conversations.conversations;
                isMoreConversationsAvailable = conversations.isMoreConversationsAvailable;
            }
        } catch {
        }

        this.setState({
            isMoreConversationsAvailable,
            conversations: foundConversations,
            isConversationsLoading: false
        });
    }

    private async onMessageReceived(messageReceivedData: MessageReceivedDataWithUserMetadata<ChatUser | null>) {
        // console.log('onMessageReceived', messageReceivedData)

        if (this.state.openedConversation?.conversationId === messageReceivedData.conversationId) {

            await this.ChatClient.markConversationAsRead(messageReceivedData.conversationId)

            this.setState({
                messagesOfOpenedConversation: [...this.state.messagesOfOpenedConversation, convertReceivedMsgToMsgWithUser(messageReceivedData)]
            })
        }

        let isFound = false
        const newConversations = this.state.conversations.map(f => {
            if (f.conversationId === messageReceivedData.conversationId) {
                isFound = true

                f.updatedAt = messageReceivedData.messageTime
                f.unreadMessageCount += this.state.openedConversation?.conversationId === f.conversationId ? 0 : 1
                f.lastMessage = {
                    messageId: messageReceivedData.messageId,
                    message: messageReceivedData.message,
                    senderUserId: messageReceivedData.senderId,
                    sentAt: messageReceivedData.messageTime,
                }
            }

            return f
        })

        if (!isFound) {
            const createConversationResponse = await this.ChatClient.getConversation(messageReceivedData.senderId)

            this.setState({
                conversations: [createConversationResponse.conversation, ...this.state.conversations]
            });
        } else {
            this.setState({
                conversations: newConversations.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            });
        }
    }

    private async precenseUpdated(presenceUpdateData: PresenceUpdateDataWithUserMetadata<ChatUser | null>) {
        if (presenceUpdateData.isOnline) {
            this.setState({
                isOtherUserOnline: presenceUpdateData.isOnline
            })
        }

        if (this.state.openedConversation === null) {
            return
        }

        if (this.state.decodedJwt && presenceUpdateData.userId === getOtherUserId(this.state.decodedJwt.nameid, this.state.openedConversation.userIds)) {
            this.setState({
                isOtherUserOnline: presenceUpdateData.isOnline
            })
        }
    }

    private async allMessagesMarkedAsRead() {
        // console.log('All messages marked as read')

        const newConversations = this.state.conversations.map(c => {
            c.unreadMessageCount = 0
            return c
        })

        this.setState({
            conversations: newConversations
        })
    }

    private async conversationMarkedAsRead(markConversationAsReadData: MarkConversationAsReadData) {
        // console.log('markConversationAsRead', markConversationAsReadData)

        const newConversations = this.state.conversations.map(c => {
            if (c.conversationId === markConversationAsReadData.conversationId) {
                c.unreadMessageCount = 0
            }

            return c
        })

        this.setState({
            conversations: newConversations
        })
    }

    private async conversationDeleted(conversationDeletedData: ConversationDeletedData) {
        // console.log('conversationDeleted', conversationDeletedData)

        if (this.state.openedConversation?.conversationId === conversationDeletedData.conversationId) {
            this.setState({
                openedConversation: null
            })
        }

        const newConversations = this.state.conversations.filter(f => f.conversationId !== conversationDeletedData.conversationId)
        this.setState({
            conversations: newConversations
        })
    }

    private async messageDeleted(messageDeletedData: MessageDeletedData) {
        // console.log('messageDeleted', messageDeletedData)

        if (messageDeletedData.conversationId !== this.state.openedConversation?.conversationId) {
            return;
        }

        const newMessages = this.state.messagesOfOpenedConversation.filter(f => f.messageId !== messageDeletedData.messageId)
        this.setState({
            messagesOfOpenedConversation: newMessages
        })
    }

    private onSystemMessageReceived(systemMessageData: SystemMessageReceivedData) {
        // console.log({systemMessageData})

        switch (systemMessageData.type) {
            case 'FreeCall':
                const freeCall: FreeCallDTO = JSON.parse(systemMessageData.message)
                // Ücretsiz arama yapıldığında iki tarafada gönderilir
                // randevuya 45 saniye öncesinde başlar, öğretmen randevuya katılmazsa randevu startTimeından 3 saniye önce timeouta düşer
                // görüşme süresi 5 dakikadır

                this.props.call.startCall(freeCall);

                break
            case 'DirectCall':
                const directCall: DirectCallDTO = JSON.parse(systemMessageData.message)
                // Ücretli aramanın başlama anını iki tarafada bildirir
                // randevuya 45 saniye öncesinde başlar, öğretmen randevuya katılmazsa randevu startTimeından 3 saniye önce timeouta düşer
                // görüşme süresi 15 dakikadır

                this.props.call.startCall(directCall);

                break
            case 'DirectCallStarted':
                const startedDirectCall: DirectCallStartedDTO = JSON.parse(systemMessageData.message)
                // Direkt arama randevusunun odasının başladığını iki tarafada bildirir

                this.props.call.startAppointment(startedDirectCall);

                break
            case 'AppointmentStarting':
                const startingAppointment: AppointmentStartingDTO = JSON.parse(systemMessageData.message)
                // Appointmenta 30 saniye kala iki tarafada gönderilir.
                // Birisi seni arıyor ekranı gösterilmesi için

                this.props.call.startCall(startingAppointment);

                break
            case 'AppointmentStarted':
                const startedAppointment: AppointmentStartedDTO = JSON.parse(systemMessageData.message)
                // Normal bir Randevunun başlamasını iki tarafada bildirir

                this.props.call.startAppointment(startedAppointment);

                break
            case 'AppointmentFinished':
                const finishedAppointment: AppointmentFinishedDTO = JSON.parse(systemMessageData.message)
                // Herhangi bir randevunun bittiğini iki tarafada bildirir
                // Oylama penceresi bu geldikten sonra gösterilir

                this.props.call.finishAppointment(finishedAppointment)

                break
            case 'RejectCall':
                const rejectCall: RejectCallDTO = JSON.parse(systemMessageData.message)
                // Çağrıyı öğretmenin reddetmesi
                // İki tarafada gönderilir

                this.props.call.rejectCall(rejectCall);

                break
            case 'AcceptCall':
                const acceptCall: AcceptCallDTO = JSON.parse(systemMessageData.message)
                // Çağrıyı öğretmenin kabul etmesi
                // İki tarafada gönderilir

                this.props.call.acceptCall(acceptCall);

                break
            case 'CallTimeout':
                const timeoutCall: CallTimeoutDTO = JSON.parse(systemMessageData.message)
                // Ücretsiz aramada randevu başlangıç anında
                // Direkt aramada randevu başlangıç anında

                this.props.call.callTimeOut(timeoutCall);

                break
            case 'TeacherNotJoinedToAppointment':
                const teacherNotJoinedToAppointment: TeacherNotJoinedToAppointmentDTO = JSON.parse(systemMessageData.message)
                // Normal randevuda randevu başlangıç anından 2 dakika sonra öğretmen gelmediyse iki tarafada gönderilir
                // Öğrenci katılsada katılmasada parası iade oluyor
                // Öğrenciye 2 dakika sonra otomatik iptal edilecektir uyarısı gösterilecek.
                // Öğretmene öğrenci gelmediyse odada durmalısınız yoksa para iadesi gerçekleşir uyarısı gösterilmeli

                this.props.call.teacherNotJoined(teacherNotJoinedToAppointment);
                break
            case 'Block':
                const chatBlock: ChatBlockDTO = JSON.parse(systemMessageData.message)

                const conversationsWithBlock = this.state.conversations.map(x => {
                    x.userIds.map(y => {
                        if (y.user?.userId === chatBlock.userId) {
                            y.user.didUserBannedMe = true
                        }
                        return y
                    })
                    return x
                })

                if (this.state.openedConversation !== null) {
                    const openedConversationWithBlock = this.state.openedConversation
                    openedConversationWithBlock.userIds = openedConversationWithBlock.userIds.map(x => {
                        if (x.user?.userId === chatBlock.userId) {
                            x.user.didUserBannedMe = true
                        }
                        return x
                    })

                    this.setState({
                        openedConversation: openedConversationWithBlock
                    })
                }

                this.setState({
                    conversations: conversationsWithBlock,
                })

                break
            case 'UnBlock':
                const chatUnBlock: ChatBlockDTO = JSON.parse(systemMessageData.message)

                const conversationsWithUnblock = this.state.conversations.map(x => {
                    x.userIds.map(y => {
                        if (y.user?.userId === chatUnBlock.userId) {
                            y.user.didUserBannedMe = false
                        }
                        return y
                    })
                    return x
                })

                if (this.state.openedConversation !== null) {
                    const openedConversationWithUnBlock = this.state.openedConversation
                    openedConversationWithUnBlock.userIds = openedConversationWithUnBlock.userIds.map(x => {
                        if (x.user?.userId === chatUnBlock.userId) {
                            x.user.didUserBannedMe = false
                        }
                        return x
                    })

                    this.setState({
                        openedConversation: openedConversationWithUnBlock
                    })
                }

                this.setState({
                    conversations: conversationsWithUnblock,
                })
                break
        }
    }

    //endregion

    //region Private Methods
    private async subscribeToPresence(otherUserId: string) {
        if (this.state.openedConversation !== null && this.state.decodedJwt) {
            const otherUserId = getOtherUserId(this.state.decodedJwt.nameid, this.state.openedConversation.userIds)

            if (otherUserId && this.ChatClient) {
                await this.ChatClient.unsubscribeFromPresence([otherUserId])
            }
        }

        await this.ChatClient.subscribeToPresence([otherUserId])
    }

    private removeConversationFromConversationsIfAvailable(conversationId: string) {
        this.setState({
            conversations: this.state.conversations.filter(f => f.conversationId !== conversationId)
        })
    }

    //endregion

    //region Public Methods
    async connectToChat() {
        if (this.props.auth.isLoggedIn) {
            try {
                const {data} = await this.props.api.Chat.getChatJwt()

                try {
                    const decoded = jwt_decode<DecodedJwt>(data.chatJwt)
                    this.ChatClient.setUserJwt(data.chatJwt)
                    await this.ChatClient.Connect()

                    this.setState({
                        myJwt: data.chatJwt,
                        decodedJwt: decoded
                    });
                } catch (e) {
                    this.setState({
                        myJwt: null,
                        decodedJwt: null
                    });
                }
            } catch {
            }
        }
    }

    async disconnectFromChat() {
        if (!this.props.auth.isLoggedIn) {
            this.closeConversation()
            await this._chatClient.Disconnect()
        }
    }

    async loadPastMessages() {
        if (this.state.openedConversation !== null) {
            ReactDOM.flushSync(() => {
                this.setState({
                    isMoreMessagesLoading: true
                })
            })

            const firstMessageSentAt = this.state.messagesOfOpenedConversation[0].sentAt

            let isMoreMessagesAvailableInOpenedConversation = false;
            let messagesOfOpenedConversation = this.state.messagesOfOpenedConversation;

            if (this.ChatClient) {
                const pastMessages = await this.ChatClient.getConversationMessages(this.state.openedConversation?.conversationId, firstMessageSentAt)
                isMoreMessagesAvailableInOpenedConversation = pastMessages.isMoreMessagesAvailable;
                messagesOfOpenedConversation = [...pastMessages.messages.reverse(), ...messagesOfOpenedConversation];
            }

            this.setState({
                isMoreMessagesAvailableInOpenedConversation,
                messagesOfOpenedConversation,
                isMoreMessagesLoading: false
            });
        }
    }

    async markAllMessagesAsRead() {
        ReactDOM.flushSync(() => {
            this.setState({
                isMarkingAllMessagesAsRead: true
            })
        })

        await this.ChatClient.markAllMessagesAsRead()

        this.setState({
            isMarkingAllMessagesAsRead: false
        })
    }

    async loadPastConversations() {
        ReactDOM.flushSync(() => {
            this.setState({
                isPastConversationsLoading: true
            })
        })

        const lastConversationUpdatedAt = this.state.conversations[this.state.conversations.length - 1].updatedAt

        let isMoreConversationsAvailable = this.state.isMoreConversationsAvailable;
        let conversations = this.state.conversations;

        if (this.ChatClient) {
            const pastConversations = await this.ChatClient.getConversations(lastConversationUpdatedAt)
            isMoreConversationsAvailable = pastConversations.isMoreConversationsAvailable
            conversations = [...this.state.conversations, ...pastConversations.conversations]
        }

        this.setState({
            conversations,
            isMoreConversationsAvailable,
            isPastConversationsLoading: false
        })
    }

    async createConversation(otherUserId: string) {
        ReactDOM.flushSync(() => {
            this.setState({
                creatingConversation: true
            })
        })

        let conversations = this.state.conversations;
        let messagesOfOpenedConversation = this.state.messagesOfOpenedConversation;
        let isMoreMessagesAvailableInOpenedConversation = this.state.isMoreMessagesAvailableInOpenedConversation;
        let openedConversation = this.state.openedConversation;

        if (this.ChatClient) {
            const conversationResult = await this.ChatClient.getConversation(otherUserId)
            const messagesInConversation = await this.ChatClient.getConversationMessages(conversationResult.conversation.conversationId, new Date())
            await this.subscribeToPresence(otherUserId)

            this.removeConversationFromConversationsIfAvailable(conversationResult.conversation.conversationId)
            conversations = [conversationResult.conversation, ...this.state.conversations];

            messagesOfOpenedConversation = messagesInConversation.messages.reverse();
            isMoreMessagesAvailableInOpenedConversation = messagesInConversation.isMoreMessagesAvailable;
            openedConversation = conversationResult.conversation;
        }

        this.setState({
            conversations,
            messagesOfOpenedConversation,
            isMoreMessagesAvailableInOpenedConversation,
            openedConversation,
            creatingConversation: false
        })
    }

    async deleteConversation(conversationId: string) {
        ReactDOM.flushSync(() => {
            this.setState({
                isDeletingConversation: true
            })
        })

        await this.ChatClient.deleteConversation(conversationId);

        this.setState({
            conversations: this.state.conversations.filter(c => c.conversationId !== conversationId),
            isDeletingConversation: false
        })
    }

    async openConversation(conversation: ConversationWithUserMetadata<ChatUser | null>) {
        ReactDOM.flushSync(() => {
            this.setState({
                isMessagesLoading: true
            })
        })

        const foundConversation = this.state.conversations.find(f => f.conversationId === conversation.conversationId)

        let openedConversation = this.state.openedConversation;
        let messagesOfOpenedConversation = this.state.messagesOfOpenedConversation;
        let isMoreMessagesAvailableInOpenedConversation = this.state.isMoreMessagesAvailableInOpenedConversation;
        let otherUserId: string | undefined;

        if (foundConversation && this.state.decodedJwt) {
            otherUserId = getOtherUserId(this.state.decodedJwt.nameid, foundConversation.userIds)

            openedConversation = foundConversation;
        }

        if (this.ChatClient) {
            const messagesInConversation = await this.ChatClient.getConversationMessages(conversation.conversationId, new Date())

            messagesOfOpenedConversation = messagesInConversation.messages.reverse();
            isMoreMessagesAvailableInOpenedConversation = messagesInConversation.isMoreMessagesAvailable;
        }

        await this.ChatClient.markConversationAsRead(conversation.conversationId)

        this.setState({
            openedConversation,
            messagesOfOpenedConversation,
            isMoreMessagesAvailableInOpenedConversation,
            isMessagesLoading: false
        })

        if (foundConversation && this.state.decodedJwt && otherUserId) {
            await this.subscribeToPresence(otherUserId)
        }
    }

    closeConversation() {
        this.setState({
            openedConversation: null
        })
    }

    async sendMessageToConversation(conversationId: string, message: string) {
        if (this.state.isSendingMessage) return

        ReactDOM.flushSync(() => {
            this.setState({
                isSendingMessage: true
            })
        })

        await this.ChatClient.sendMessage(conversationId, message)

        this.setState({
            isSendingMessage: false
        })
    }

    //endregion

    //region React Lifecycle
    async componentDidMount() {
        if (this.props.auth.isLoggedIn) {
            await this.connectToChat()
        }
    }

    async componentDidUpdate(prevProps: Readonly<ChatProviderProps>) {
        if (prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn) {
            if (this.props.auth.isLoggedIn) {
                await this.connectToChat()
            } else {
                await this.disconnectFromChat()
            }
        }
    }

    //endregion

    render() {
        return (
            <ChatContext.Provider value={
                {
                    state: this.state,
                    client: this._chatClient,
                    methods: {
                        loadPastConversations: this.loadPastConversations.bind(this),
                        createConversation: this.createConversation.bind(this),
                        openConversation: this.openConversation.bind(this),
                        closeConversation: this.closeConversation.bind(this),
                        deleteConversation: this.deleteConversation.bind(this),
                        connectToChat: this.connectToChat.bind(this),
                        disconnectFromChat: this.disconnectFromChat.bind(this),
                        markAllMessagesAsRead: this.markAllMessagesAsRead.bind(this),
                        loadPastMessages: this.loadPastMessages.bind(this),
                        sendMessageToConversation: this.sendMessageToConversation.bind(this),
                    },
                }
            }>
                {this.props.children}
            </ChatContext.Provider>
        )
    }
}

import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { useTranslation } from 'react-i18next'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { ArrowRightSLine, CloseLine } from 'components/icons'

import styles from './style.module.css'

export interface reportOptionsType {
    id: string
    text: string
}

export type ReportModalCardProps = {
    isVisible: boolean
    onModalClose: () => void
    onCancelButtonClick: () => void
    options: reportOptionsType[]
    onSelect: (id: string) => void
}

const ReportModalCard = ({ isVisible, onModalClose, onCancelButtonClick, onSelect, options }: ReportModalCardProps) => {
    const { t } = useTranslation('organisms')

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onCancelButtonClickHandler = () => {
        if (typeof onCancelButtonClick === 'function') {
            onCancelButtonClick()
        }
    }

    const onSelectHandler = (selected: string) => {
        if (typeof onSelect === 'function') {
            onSelect(selected)
        }
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} size={ModalSize.Small} type={ModalType.ActionSheet}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('reportModalCard.title'),
                        size: 'lg',
                        divider: true,
                        trailing: <CloseLine className={styles.closeLine} onClick={onModalCloseHandler} />,
                    }}
                >
                    <div className={styles.optionsWrapper}>
                        {options.map(option => (
                            <div tabIndex={1} key={option.id} onClick={() => onSelectHandler(option.id)}>
                                <Text size={'lg'} type={'default'} weight={'medium'}>
                                    {option.text}
                                </Text>
                                <ArrowRightSLine className={styles.arrowRight} />
                            </div>
                        ))}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button size={'large'} type={'dimmed'} onClick={onCancelButtonClickHandler}>
                            {t('reportModalCard.cancel')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ReportModalCard

import moment from 'moment'
import * as React from 'react'
import {format} from 'date-fns'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import {useTranslation} from 'react-i18next'
import Calendar, {DateSelected} from 'components/atoms/calendar'
import Modal, {ModalSize, ModalType} from 'components/atoms/modal'

import styles from './style.module.css'

type CalendarModalCardProps = {
    isVisible: boolean
    selectedDate?: Date
    onModalClose: () => void
    onDateSelected: DateSelected
    loading?: boolean
}

const CalendarModalCard = ({isVisible, selectedDate, onModalClose, onDateSelected, loading}: CalendarModalCardProps) => {
    const {t} = useTranslation('organisms')
    const [date, setDate] = React.useState<Date>(new Date())

    const onConfirm = () => {
        onDateSelected(date)
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalClose} size={ModalSize.Large} type={ModalType.Centered}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title: t('calendarModalCard.title'),
                        divider: true,
                    }}
                >
                    <Calendar
                        emphasizedDate={selectedDate}
                        label={t('calendarModalCard.label')}
                        fromDate={moment().toDate()}
                        toDate={moment().add('14', 'days').toDate()}
                        well
                        onDateSelected={setDate}
                        required
                    />
                    <div className={styles.actions}>
                        <Button onClick={onModalClose} variation="wide">
                            {t('calendarModalCard.dismiss')}
                        </Button>
                        <Button
                            loading={loading}
                            disabled={selectedDate && format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')}
                            onClick={onConfirm}
                            variation="wide"
                            type="gradient"
                        >
                            {t('calendarModalCard.duplicate')}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default CalendarModalCard

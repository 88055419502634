import * as React from 'react'
import Text from 'components/atoms/text'
import Card from 'components/molecules/card'
import Button from 'components/atoms/button'
import Modal, {ModalSize, ModalType} from 'components/atoms/modal'
import {useTranslation} from 'react-i18next'
import {CheckboxCircleFill} from 'components/icons'

import styles from './style.module.css'

type InformationModalCardProps = {
    title: string
    description: string
    isVisible: boolean
    onModalClose: () => void
}

const InformationModalCard = ({title, description, isVisible, onModalClose}: InformationModalCardProps) => {
    const {t} = useTranslation('organisms')

    const onModalCloseHandler = () => {
        onModalClose()
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} size={ModalSize.Small} type={ModalType.Centered}>
            <div className={styles.container}>
                <Card
                    heading={{
                        title,
                        size: 'lg',
                        divider: true,
                    }}
                >
                    <div className={styles.wrapper}>
                        <div className={styles.icon}>
                            <CheckboxCircleFill/>
                        </div>
                        <Text type="dimmed">{description}</Text>
                        <div className={styles.actions}>
                            <Button variation="block" onClick={onModalClose}>{t('closeModal')}</Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default InformationModalCard

import * as React from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
import { DayPicker } from 'react-day-picker'
import { FormatLocale } from 'helpers/formatLocale'

import 'react-day-picker/src/style.css'
import styles from './style.module.css'

export interface DateSelected {
    (date: Date): void
}

type CalendarProps = {
    onDateSelected: DateSelected
    label?: string
    emphasizedDate?: Date
    selectedDate?: Date
    availableDates?: Date[]
    fromDate?: Date
    toDate?: Date
    well?: boolean // darker in dark mode
    required?: boolean
    disabledDays?: Date[]
}

const Calendar = ({ label, selectedDate, emphasizedDate, availableDates, onDateSelected, toDate, fromDate, well, required, disabledDays }: CalendarProps) => {
    const [selected, setSelected] = React.useState<Date>(selectedDate ?? new Date())

    React.useEffect(() => {
        onDateSelected(selected)
    }, [onDateSelected, selected])

    const handleSelect = (time: any | undefined) => {
        if (selected !== time) {
            setSelected(time)
        }
    }

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.well]: well,
            })}
        >
            <DayPicker
                locale={FormatLocale().locale}
                mode="single"
                selected={selected}
                fromDate={fromDate}
                toDate={toDate}
                onSelect={handleSelect}
                required={required}
                disabled={disabledDays}
                modifiers={{
                    available: availableDates ?? [],
                    emphasized: emphasizedDate ? [emphasizedDate] : [],
                }}
                modifiersClassNames={{
                    today: styles.day_today,
                    selected: styles.day_selected,
                    available: styles.day_available,
                    emphasized: styles.day_emphasized,
                    disabled: styles.day_disabled
                }}
                classNames={{
                    root: styles.root,
                    month: styles.month,
                    caption: styles.caption,
                    button: styles.button,
                    table: styles.table,
                    cell: styles.cell,
                    head_cell: styles.head_cell,
                    day: styles.day,
                    day_today: styles.day_today,
                }}
                components={{
                    CaptionLabel: (props: any) => (
                        <div className={styles['label-wrapper']}>
                            <div className={styles.label}>{label}</div>
                            <div className={styles['label-month']}>{format(props.displayMonth, 'LLLL yyyy', FormatLocale())}</div>
                        </div>
                    ),
                }}
            />
        </div>
    )
}

export default Calendar

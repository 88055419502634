import * as React from 'react'
import useChat from 'hooks/useChat'
import Text from 'components/atoms/text'
import Card from 'components/molecules/card'
import {useTranslation} from 'react-i18next'
// import {DeleteBinLine} from 'components/icons'
import {ChatUser} from 'types/chatProvider'
import ConversationList from '../conversation-list'
import {ConversationWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types'

import styles from './style.module.css'

const ConversationDropdown = ({conversations}: { conversations: ConversationWithUserMetadata<ChatUser | null>[] }) => {
    const chat = useChat()
    const {t} = useTranslation('organisms')

    // const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

    const DeleteButton = () => {
        return null
        // return (
        //     <button onClick={() => setIsDeleting(r => !r)} className={styles.deleteButton}>
        //         {isDeleting ? t('cancel') : <DeleteBinLine/>}
        //     </button>
        // )
    }

    return (
        <div className={styles.wrapper}>
            <Card
                paddingles
                heading={{
                    title: t('messages'),
                    size: 'md',
                    divider: true,
                    trailing: conversations.length > 0 ? <DeleteButton/> : null,
                }}
            >
                {conversations.length === 0 ? (
                    <div className={styles.noMessageHeader}>
                        <Text size={'sm'} type={'warning'} weight={'medium'} decoration={'default'}>
                            {t('no-message')}
                        </Text>
                    </div>
                ) : (
                    <>
                        <div className={styles.scroller}>
                            <ConversationList conversations={conversations}/>
                        </div>
                        <div className={styles['bottom-action']}>
                            <button onClick={chat.methods?.markAllMessagesAsRead} className={styles['mark-button']}>
                                {t('mark-all-as-read')}
                            </button>
                        </div>
                    </>
                )}
            </Card>
        </div>
    )
}

export default ConversationDropdown

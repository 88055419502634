import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import Text from 'components/atoms/text'

export type WordTutorialVideoSubtitleCardProps = {
    title: string
    description?: string
    isVideoFullscreen?: boolean
}

const WordTutorialVideoSubtitleCard = ({ title, description, isVideoFullscreen }: WordTutorialVideoSubtitleCardProps) => {
    return (
        <div className={classnames(styles.card, { [styles.fullscreen]: isVideoFullscreen })}>
            <Text size={'lg'} type={'warning'} weight={'default'}>
                {title}
            </Text>
            {description ? (
                <Text size={'lg'} type={'default'} weight={'medium'}>
                    {description}
                </Text>
            ) : undefined}
        </div>
    )
}

export default WordTutorialVideoSubtitleCard

import {ChatUser} from 'types/chatProvider'
import {ConversationUserIdWithUserMetadata, MessageDataWithUserMetadata, MessageReceivedDataWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types'

export const convertReceivedMsgToMsgWithUser = (data: MessageReceivedDataWithUserMetadata<ChatUser | null>): MessageDataWithUserMetadata<ChatUser | null> => {
    return {
        user: data.user,
        message: data.message,
        messageId: data.messageId,
        sentAt: data.messageTime,
        senderUserId: data.senderId
    };
};

export const getOtherUserId = (myUserId: string, userIds: ConversationUserIdWithUserMetadata<ChatUser | null>[]) => {
    const otherUserIds = userIds.filter(f => f.userId !== myUserId);

    if (otherUserIds.length > 0) {
        return otherUserIds[0].userId;
    }
}

import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type CardBodyProps = {
    children: React.ReactNode
    paddingles?: boolean
}

const CardBody = ({ paddingles, ...props }: CardBodyProps) => {
    return (
        <div
            className={classnames(styles.cardBody, {
                [styles['no-padding']]: paddingles,
            })}
            {...props}
        />
    )
}

export default CardBody

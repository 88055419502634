import * as React from 'react'
import useApi from 'hooks/useApi'
import useDebounce from 'hooks/useDebounce'
import { useTranslation } from 'react-i18next'
import Master from 'components/layouts/master'
import ErrorBox from 'components/atoms/error-box'
import LoadingBox from 'components/atoms/loading-box'
import WordsTemplate from 'components/templates/words'
import InfiniteScroll from 'react-infinite-scroll-component'
import WordCarousel from 'components/organisms/word-carousel'
import WordSearchBar from 'components/organisms/word-search-bar'
import WordTutorialSearchResult from 'components/organisms/word-tutorial-search-result'
import {
    WordTutorialCategoryQueriesSearchWordTutorialsPagination as SearchPagination,
    WordTutorialCategoryQueriesWordTutorial as Word,
    WordTutorialCategoryQueriesWordTutorialCategoryInList as Categories,
} from '@mohsininsignia/advancerapiclient'

const WordTutorials: React.FC = () => {
    const api = useApi()
    const { t } = useTranslation('common')

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    const [results, setResults] = React.useState<Word[]>([])
    const [wordPagination, setWordPagination] = React.useState<SearchPagination>({ isNextPageAvailable: false })
    const [loading, setLoading] = React.useState<boolean>(false)

    const [categories, setCategories] = React.useState<Categories[]>([])
    const [loadingCategories, setLoadingCategories] = React.useState(true)

    React.useEffect(() => {
        ;(async () => {
            setLoadingCategories(true)

            try {
                const { data } = await api.WordTutorialCategory.listWordTutorialCategories()
                setCategories(data.categories)
            } catch {}

            setLoadingCategories(false)
        })()
    }, [api.WordTutorialCategory])

    React.useEffect(() => {
        ;(async () => {
            if (debouncedQuery !== '') {
                setLoading(true)
                try {
                    const { data } = await api.WordTutorialCategory.searchInWordTutorialCategory(undefined, debouncedQuery, undefined)

                    setWordPagination(data.pagination)
                    setResults(data.wordTutorials)
                } catch {}

                setLoading(false)
            }
        })()
    }, [debouncedQuery, api.WordTutorialCategory])

    const paginate = async () => {
        if (!wordPagination?.isNextPageAvailable || !wordPagination?.lastId) return
        setLoading(true)

        try {
            const { data } = await api.WordTutorialCategory.searchInWordTutorialCategory(
                undefined,
                query,
                wordPagination?.isNextPageAvailable && wordPagination?.lastId ? wordPagination.lastId : undefined
            )

            setWordPagination(data.pagination)
            setResults([...results, ...data.wordTutorials])
        } catch {}

        setLoading(false)
    }

    return (
        <Master title={`${t('categories.title')}${t('title')}`}>
            <WordsTemplate>
                <WordSearchBar query={query} onChangeText={setQuery} />
                {debouncedQuery === '' && categories.map(category => <WordCarousel key={category.id + '_category'} category={category} slideWidth={336} />)}

                {debouncedQuery !== '' && !loading && !results.length && <ErrorBox text={t('categories.notFound')} />}
                {/* {query && results.map(r => <WordResultCard key={r.id} word={r} />)} */}

                {debouncedQuery !== '' && results.length > 0 && (
                    <InfiniteScroll next={paginate} hasMore={wordPagination.isNextPageAvailable} loader={<LoadingBox />} dataLength={results.length}>
                        <WordTutorialSearchResult words={results} />
                    </InfiniteScroll>
                )}

                {(loadingCategories || loading) && <LoadingBox />}
            </WordsTemplate>
        </Master>
    )
}

export default WordTutorials

import * as React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './style.module.css'
import {Loader2Line} from 'components/icons'
import {Link} from 'react-router-dom'

interface IAnchor extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

type TButton = IAnchor | IButton

type ButtonType =
    | 'default'
    | 'dimmed'
    | 'gradient'
    | 'danger'
    | 'danger-2'
    | 'danger-3'
    | 'warning'
    | 'success'
    | 'success-2'
    | 'dimmed-link'
    | 'warning-link'
    | 'danger-link'
    | 'pink'
    | 'pink-link'
    | 'gray-link'
    | 'light'
type ButtonVariation = 'default' | 'block' | 'circle' | 'wide'
type ButtonIconPlacement = 'absolute' | 'relative'

export type ButtonProps = {
    as?: 'button' | 'anchor'
    type?: ButtonType
    size?: 'small' | 'default' | 'large'
    variation?: ButtonVariation
    alignment?: 'center' | 'left' | 'right'
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
    leftIconPlacement?: ButtonIconPlacement
    rightIconPlacement?: ButtonIconPlacement
    disabled?: boolean
    loading?: boolean
}

const propTypes = {
    as: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    variation: PropTypes.oneOf(['default', 'block', 'circle', 'wide']),
    alignment: PropTypes.oneOf(['center', 'left', 'right']),
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    leftIconPlacement: PropTypes.oneOf(['absolute', 'relative']),
    rightIconPlacement: PropTypes.oneOf(['absolute', 'relative']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
}

const defaultProps = {
    as: 'button',
    type: 'default',
    size: 'default',
    variation: 'default',
    alignment: 'center',
    leftIcon: null,
    rightIcon: null,
    leftIconPlacement: 'absolute',
    rightIconPlacement: 'absolute',
    disabled: false,
    loading: false,
}

const Button = ({
                    as,
                    type,
                    size,
                    variation,
                    alignment,
                    leftIcon,
                    rightIcon,
                    leftIconPlacement,
                    rightIconPlacement,
                    children,
                    disabled,
                    loading,
                    onClick,
                    className,
                    ...props
                }: ButtonProps & TButton) => {
    /**
     * Determines is button anchor or not
     *
     * @param event
     */

    const onClickHandler = (event: any) => {
        if (typeof onClick === 'function' && !disabled && !loading) {
            onClick(event)
        }
    }

    const Wrapper = (props: TButton) => {
        if (as === 'button') return <button {...(props as IButton)} />
        const {href, ...linkProps} = props as IAnchor
        if (href) {
            if (disabled) {
                return (
                    <div>
                        <span {...(linkProps as IAnchor)} />
                    </div>
                )
            }
            return (
                <Link to={href}>
                    <span {...(linkProps as IAnchor)} />
                </Link>
            )
        }
        return <></>
    }

    const Icon = ({icon, placement, location}: { icon: React.ReactNode; location: 'left' | 'right'; placement: 'absolute' | 'relative' }) => {
        if (icon === null) return null

        return <span className={classnames(styles.icon, styles[placement], styles[location])}>{icon}</span>
    }

    return (
        <Wrapper
            className={classnames(styles.button, styles[`type-${type}`], styles[`variation-${variation}`], styles[`alignment-${alignment}`], styles[`size-${size}`], {
                [styles.disabled]: disabled,
                [styles.loading]: loading,
                [styles.leftPadding]: leftIconPlacement === 'absolute' && !!leftIcon,
                [styles.rightPadding]: rightIconPlacement === 'absolute' && !!rightIcon,
            }, className)}
            onClick={onClickHandler}
            {...props}
        >
            {leftIconPlacement && <Icon icon={leftIcon} placement={leftIconPlacement} location={'left'}/>}
            {loading ? <Loader2Line className={styles.loadingIcon}/> : children}
            {rightIconPlacement && <Icon icon={rightIcon} placement={rightIconPlacement} location={'right'}/>}
        </Wrapper>
    )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button

import * as React from 'react'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import {getUserRole} from 'utils/auth'
import Text from 'components/atoms/text'
import {useHistory} from 'react-router-dom'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import {useTranslation} from 'react-i18next'
import Master from 'components/layouts/master'
import ProfileTemplate, {styles} from 'components/templates/profile'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import ProfileNavigation from 'components/organisms/profile-navigation'
import Error from "../error";

export default function Subscription() {
    const {t} = useTranslation('common')

    const api = useApi()
    const auth = useAuth()
    const role = getUserRole()
    const router = useHistory()

    const [loading, setLoading] = React.useState(false)
    const [monthlyMinute, setMonthlyMinute] = React.useState<number | undefined>(undefined)
    const [monthlyPrice, setMonthlyPrice] = React.useState<number | undefined>(undefined)

    const [cancelSubscriptionVisible, setCancelSubscriptionVisible] = React.useState(false)

    const cancelSubscriptionHandle = async () => {
        setLoading(true)

        try {
            await api.Payments.cancelCurrentSubscription(Date.now().toString())
            setCancelSubscriptionVisible(true)
            router.go(0)
        } catch {
        }

        setLoading(false)
    }

    const onClickSubscription = async () => {
        await router.push({pathname: '/profile/subscription/plans'})
    }

    React.useEffect(() => {
        (async () => {
            if (auth.user?.userRole === 'Student') {
                if (auth.user.isPremium) {
                    setMonthlyMinute((auth.user.premiumInformation?.daysPerWeek ?? 0) * (auth.user.premiumInformation?.minutesPerDay ?? 0))
                    setMonthlyPrice(auth.user.premiumInformation?.priceAfterDurationDiscount)
                } else {
                    await router.replace({pathname: '/profile/subscription/plans'})
                }
            }
        })()
    }, [auth.user, role, router])

    if (auth.user?.userRole !== 'Student') {
        return <Error statusCode={401}/>
    }

    return (
        <Master title={`${t('subscription.title')}${t('title')}`}>
            <ProfileTemplate>
                <ProfileNavigation/>

                <div>
                    <div className={classnames(styles.narrow, styles.list)}>
                        <Card
                            heading={{
                                title: t('subscription.yourCurrentPlan'),
                                size: 'xl',
                                divider: true,
                            }}
                        >
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Text type={'white'} size={'xl'} weight={'medium'}>
                                    {t('subscription.monthlyMinute', {count: monthlyMinute})}
                                </Text>
                                <Text type={'dimmed-light'}>
                                    {auth.user?.userRole === 'Student' && auth.user.premiumInformation?.paymentDurationAsMonths} {t('subscription.monthlyPrice', {count: monthlyPrice})}
                                </Text>
                            </div>
                        </Card>

                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {/* <Button variation={'wide'} onClick={() => setCancelSubscriptionVisible(true)} type={'pink-link'}>
                                {t('subscription.cancelSubscription')}
                            </Button> */}
                            <Button variation={'wide'} onClick={onClickSubscription} type={'gradient'}>
                                {t('subscription.changeSubscription')}
                            </Button>
                        </div>
                    </div>
                </div>
            </ProfileTemplate>
            <ConfirmModalCard
                title={t('subscription.cancelSubscriptionTitle')}
                description={t('subscription.cancelSubscriptionDescription')}
                confirmButtonText={t('subscription.cancelSubscriptionConfirm')}
                isVisible={cancelSubscriptionVisible}
                onModalClose={() => setCancelSubscriptionVisible(false)}
                onCancelButtonClick={() => setCancelSubscriptionVisible(false)}
                onConfirmButtonClick={cancelSubscriptionHandle}
                loading={loading}
            />
        </Master>
    )
}

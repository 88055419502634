import { Device } from '@capacitor/device'
import jwt_decode from 'jwt-decode'
import { DecodedJwtData, UserRole } from 'types'
import Cookies from 'universal-cookie'



export function getUser(_cookie?: any) {


let platform = detectOS();
console.log("The user's operating system is: " + platform);

    let token: string | null = '' 
    if (platform !== 'ios') {
        let cookies = new Cookies(_cookie)
        token = cookies.get('token')
    }
    if (platform === 'ios') {
        token = localStorage.getItem('token')
    }
console.log('[token]',token);
    if (token) {
        return jwt_decode<DecodedJwtData>(token)
    }

    return null
}

export  function getUserRole(_cookie?: any): UserRole {
    // const { platform } = await Device.getInfo()

    let user = getUser(_cookie)

    // @ts-ignore
    return user ? user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : null
}


function detectOS() {
	let userAgent = window.navigator.userAgent,
		platform = window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod'],
		os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'Mac OS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'ios';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	return os;
}


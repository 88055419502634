import * as React from 'react'
import { isNumber, isUndefined } from 'lodash'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import classnames from 'classnames'
import { getUserRole } from 'utils/auth'
import Button from 'components/atoms/button'
import Master from 'components/layouts/master'
import { useTranslation } from 'react-i18next'
import ProgressBar from 'components/atoms/progress-bar'
import { useHistory, useParams } from 'react-router-dom'
import ShareModal from 'components/organisms/share-modal'
import WordListCard from 'components/organisms/word-list-card'
import YoutubePlayer from 'components/molecules/youtube-player'
import WordCategoryPagination from 'components/organisms/word-category-pagination'
import WordTutorialDetailTemplate, { styles } from 'components/templates/word-tutorial-detail'
import { Bookmark3Fill, Filter3Line, HeartFill, ShareForwardFill, TextWrap } from 'components/icons'
import SubtitleLanguageSelector, { SubtitleStatus } from 'components/organisms/subtitle-language-selector'
import {
    WordTutorialCategoryQueriesGetDetailsQueryResult as WordTutorialCategory,
    WordTutorialCategoryQueriesSearchWordTutorialsPagination as WordTutorialPagination,
    WordTutorialCategoryQueriesWordTutorial as WordTutorialResult,
    WordTutorialCategoryQueriesWordTutorialCategoryInList as Category,
} from '@mohsininsignia/advancerapiclient'
import { numberFormat } from 'utils/number-helpers'
import useQuery from 'hooks/useQuery'
import useDebounce from 'hooks/useDebounce'
import WordCard from 'components/organisms/word-card'
import { Swiper, SwiperSlide } from 'swiper/react/'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import styless from './../../components/organisms/word-carousel/style.module.css'

const WordTutorialDetail: React.FC = () => {
    const router = useHistory()
    const query = useQuery()
    const role = getUserRole()

    const { slug: querySlug } = useParams<{ slug: string }>()
    const querySelected = query.get('selected')

    const api = useApi()
    const { toggleModal } = useAuth()
    const { t } = useTranslation('common')

    // const [loading, setLaoding] = React.useState<boolean>(false)

    // const [Dquery, setQuery] = React.useState<string>('')
    // const debouncedQuery = useDebounce<string>(Dquery, 500)

    const [word, setWord] = React.useState<WordTutorialCategory | undefined>(undefined)
    const [recommendedVideos, setRecommendedVideos] = React.useState<WordTutorialResult[]>([])
    const [selectedCategory, setSelectedCategory] = React.useState<any>()

    const [favoriteLoading, setFavoriteLoading] = React.useState<number | null>(null)

    const handleFavoriteWord = async (wordId: number, isFavorited: boolean) => {
        setFavoriteLoading(wordId)
        try {
            if (isFavorited) {
                await api.WordTutorial.favoriteWordTutorial(wordId)
            } else {
                await api.WordTutorial.unfavoriteWordTutorial(wordId)
            }
            setRecommendedVideos(x =>
                x.map(y => {
                    if (y.id === wordId) {
                        y.isFavorited = isFavorited
                    }
                    return y
                })
            )
        } catch { }
        setFavoriteLoading(null)
    }

    const [shareShown, setShareShown] = React.useState(false)
    const [subtitlesShown, setSubtitlesShown] = React.useState(false)
    const [subtitleStatus, setSubtitleStatus] = React.useState<SubtitleStatus>(SubtitleStatus.BothTurkishAndEnglish)

    const [isShared, setIsShared] = React.useState(word?.currentWordTutorial.isShared)
    const [isLiked, setIsLiked] = React.useState(word?.currentWordTutorial.isLiked)
    const [isFavorited, setIsFavorited] = React.useState(word?.currentWordTutorial.isFavorited)

    const [searchShown, setSearchShown] = React.useState(false)
    const [searchQuery, setSearchQuery] = React.useState('')
    const [searchLoading, setSearchLoading] = React.useState(false)
    const [searchResults, setSearchResults] = React.useState<WordTutorialResult[]>([])
    const [searchPagination, setSearchPagination] = React.useState<WordTutorialPagination>({ isNextPageAvailable: false })
    const [isVideoFullscreen, setVideoFullscreen] = React.useState(false)

    const [subtitleEn, setSubtitleEn] = React.useState('')
    const [subtitleTr, setSubtitleTr] = React.useState('')

    const toggleLike = async () => {
        if (!role) {
            toggleModal?.()
            return
        }

        setIsLiked(!isLiked)
        if (isLiked) {
            await api.WordTutorial.unlikeWordTutorial(word?.currentWordTutorial.id)
        } else {
            await api.WordTutorial.likeWordTutorial(word?.currentWordTutorial.id)
        }
    }

    const share = async () => {
        setShareShown(false)
        if (!isShared) {
            try {
                await api.WordTutorial.wordTutorialShared(word?.currentWordTutorial.id)
                setIsShared(true)
            } catch { }
        }
    }

    const toggleFavorite = async () => {
        if (!role) {
            toggleModal?.()
            return
        }

        setIsFavorited(!isFavorited)
        if (isFavorited) {
            await api.WordTutorial.unfavoriteWordTutorial(word?.currentWordTutorial.id)
        } else {
            await api.WordTutorial.favoriteWordTutorial(word?.currentWordTutorial.id)
        }
    }

    const search = async (query: string) => {
        setSearchLoading(true)
        setSearchQuery(query)
        try {
            if (query) {
                const { data } = await api.WordTutorialCategory.searchInWordTutorialCategory(querySlug as string, query)
                setSearchPagination(data.pagination)
                setSearchResults(data.wordTutorials)
            } else {
                setSearchPagination({ isNextPageAvailable: false })
                setSearchResults([])
            }
        } catch (error) { }
        setSearchLoading(false)
    }

    const paginate = async () => {
        if (!searchPagination || !searchPagination.isNextPageAvailable || !searchPagination.lastId || searchQuery !== '' || searchLoading) return

        setSearchLoading(true)
        try {
            const { data } = await api.WordTutorialCategory.searchInWordTutorialCategory(querySlug as string, searchQuery, searchPagination.lastId)
            setSearchPagination(data.pagination)
            setSearchResults([...searchResults, ...data.wordTutorials])
            setSearchLoading(false)
        } catch (error) { }
        setSearchLoading(false)
    }

    const navigateToWord = async (slug?: string) => {
        setSearchShown(false)
        if (slug) {
            router.push(`/word-tutorials/${querySlug}?selected=${slug}`)
        }
    }

    const wordTutorialCompleteHandler = async () => {
        if (!word?.currentWordTutorial.isWatched) {
            await api.WordTutorial.wordTutorialVideoWatched(word?.currentWordTutorial.id)
        }

        if (typeof word?.nextWordTutorial !== 'undefined') {
            navigateToWord(word.nextWordTutorial.slug)
        }
    }

    React.useEffect(
        () => {
            ; (async () => {
                setSubtitlesShown(false)
                try {
                    const { data } = await api.WordTutorialCategory.getWordTutorialCategoryDetails(querySlug!, querySelected ?? undefined)
                    const s: any = data
                    if (data) {
                        if (querySelected && query.get('selected') !== querySelected) {
                            query.set('selected', querySelected)
                            router.push({ pathname: `/word-tutorials/${querySlug}`, search: query.toString() })
                        }
                        // data.
                        setSelectedCategory(s?.categories)

                        if (s?.categories?.wordTutorials && s?.categories?.wordTutorials.length > 4) {
                            var rVideos = []

                            for (let i = 0; rVideos.length < 4; i++) {
                                var rVideo = s?.categories?.wordTutorials[Math.floor(Math.random() * s?.categories?.wordTutorials.length)]
                                // eslint-disable-next-line no-loop-func
                                var isExist = rVideos.filter(rv => rv.id === rVideo.id)
                                if (!isExist.length) {
                                    rVideos.push(rVideo)
                                }
                            }
                            setRecommendedVideos(rVideos)
                        } else {
                            setRecommendedVideos(s?.categories?.wordTutorials)
                        }

                        setWord(data)
                        setIsLiked(data.currentWordTutorial.isLiked)
                        setIsFavorited(data.currentWordTutorial.isFavorited)
                        setIsShared(data.currentWordTutorial.isShared)
                    }
                }
                catch (e) {
                    console.log('e',e)
                }
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [subtitleStatus, querySelected, querySlug, api.WordTutorialCategory, router]
    )

    const computedLikeCount = React.useMemo(() => {
        if (!word) return undefined

        return word.currentWordTutorial.likeCount + (isLiked ? (!word.currentWordTutorial.isLiked ? 1 : 0) : word.currentWordTutorial.isLiked ? -1 : 0)
    }, [isLiked, word])

    const computedSharedCount = React.useMemo(() => {
        if (!word) return undefined

        return word.currentWordTutorial.shareCount + (isShared ? (!word.currentWordTutorial.isShared ? 1 : 0) : word.currentWordTutorial.isShared ? -1 : 0)
    }, [isShared, word])

    if (!word) return <Master title={t('wordTutorials.pleaseWait')} />

    return (
        <Master title={word.currentWordTutorial.translations[0].content}>
            <WordTutorialDetailTemplate>
                <Button onClick={() => setSearchShown(true)} leftIcon={<Filter3Line />} type="default" alignment="left" variation="block">
                    {t('wordTutorials.showWords')}
                </Button>
                <WordCategoryPagination word={word} onPrev={() => navigateToWord(word.previousWordTutorial?.slug)} onNext={() => navigateToWord(word.nextWordTutorial?.slug)} />
                <ProgressBar count={word.completedWordTutorialsInCurrentCategory} total={word.totalWordTutorialsInCategory} />
                <YoutubePlayer
                    videoUrl={word.currentWordTutorial.youtubeVideoUrl}
                    enSrtSPath={word.currentWordTutorial.subtitles.length ? word.currentWordTutorial.subtitles[0].subtitlePath : undefined}
                    trSrtPath={word.currentWordTutorial.subtitles.length ? word.currentWordTutorial.subtitles[1].subtitlePath : undefined}
                    onEnTextChanged={setSubtitleEn}
                    onTrTextChanged={setSubtitleTr}
                    onVideoEnd={wordTutorialCompleteHandler}
                    onFullScreenChanged={isFullScreen => {
                        setVideoFullscreen(isFullScreen)
                    }}
                />
                <div>
                    <div className={styles.buttons}>
                        {isNumber(computedLikeCount) && (
                            <Button onClick={toggleLike} leftIcon={<HeartFill />} type={isLiked ? 'gradient' : 'dimmed'} alignment="left">
                                {numberFormat(computedLikeCount)} {t('wordTutorials.likes')}
                            </Button>
                        )}

                        {isNumber(computedSharedCount) && (
                            <Button onClick={() => setShareShown(true)} leftIcon={<ShareForwardFill />} type={isShared ? 'gradient' : 'dimmed'} alignment="left">
                                {numberFormat(computedSharedCount)} {t('wordTutorials.shares')}
                            </Button>
                        )}

                        <Button onClick={toggleFavorite} leftIcon={<Bookmark3Fill />} type={isFavorited ? 'gradient' : 'dimmed'} alignment="left">
                            {t('wordTutorials.save')}
                        </Button>

                        <Button onClick={() => setSubtitlesShown(r => !r)} leftIcon={<TextWrap />} type={subtitlesShown ? 'gradient' : 'dimmed'} alignment="left">
                            {t('wordTutorials.subtitles')}
                        </Button>
                        <Button onClick={() => setSearchShown(true)} leftIcon={<Filter3Line />} type="default" alignment="left">
                            {t('wordTutorials.wordList')}
                        </Button>
                    </div>
                </div>

                {/* {subtitleStatus !== SubtitleStatus.None && (
                        <WordTutorialVideoSubtitleCard
                            title={subtitleEn}
                            isVideoFullscreen={isVideoFullscreen}
                            description={subtitleStatus === SubtitleStatus.BothTurkishAndEnglish ? subtitleTr : ''}
                        />
                    )} */}


                <div className={styles.mobileActions}>
                    {isNumber(computedLikeCount) && (
                        <button className={classnames({ [styles['active-button']]: isLiked })} onClick={toggleLike}>
                            <HeartFill className={classnames(styles.icon, { [styles['active-icon']]: isLiked })} />
                            {numberFormat(computedLikeCount)}
                        </button>
                    )}

                    {isNumber(computedSharedCount) && (
                        <button className={classnames({ [styles['active-button']]: isShared })} onClick={() => setShareShown(true)}>
                            <ShareForwardFill />
                            {numberFormat(computedSharedCount)}
                        </button>
                    )}

                    <button onClick={() => setSubtitlesShown(r => !r)}>
                        <TextWrap />
                        {t('wordTutorials.subtitles')}
                    </button>
                    <button className={classnames({ [styles['active-button']]: isFavorited })} onClick={toggleFavorite}>
                        <Bookmark3Fill className={classnames(styles.icon, { [styles['active-icon']]: isFavorited })} />
                        {isFavorited ? t('wordTutorials.saved') : t('wordTutorials.save')}
                    </button>
                </div>

                <WordListCard
                    loading={searchLoading}
                    words={searchResults}
                    onChangeText={search}
                    onPageEnd={paginate}
                    pagination={searchPagination}
                    onSelect={w => navigateToWord(w.slug)}
                    onClose={() => setSearchShown(false)}
                    isVisible={searchShown}
                    initialQuery={searchQuery}
                />

                <SubtitleLanguageSelector selected={subtitleStatus} onSelect={setSubtitleStatus} isVisible={subtitlesShown} onClose={() => setSubtitlesShown(false)} />

                <ShareModal isVisible={shareShown} onClose={() => setShareShown(false)} onClick={share} url={window.location.href} />
            </WordTutorialDetailTemplate>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {recommendedVideos ? (
                    <div className={classnames(styless['word-carousel'])} style={{ maxWidth: '90vw' }}>
                        <div className={classnames(styless.heading)}>
                            <div className={classnames(styless['heading-title'])}>{selectedCategory?.title}</div>
                        </div>
                        <div className={classnames(styless.carousel)} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Swiper spaceBetween={16} slidesPerView={'auto'} style={{ display: 'flex', justifyContent: 'center' }}>
                                {!isUndefined(recommendedVideos) &&
                                    (recommendedVideos?.length > 0 ? (
                                        recommendedVideos.map((word: any) => (
                                            <SwiperSlide style={{ width: 'auto' }} key={word.id} virtualIndex={word.id}>
                                                <WordCard word={word} category={selectedCategory} onFavoriteChange={handleFavoriteWord} loading={favoriteLoading === word.id} />
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <div className={styles.emptyWord}>{t('wordCarousel.empty')}</div>
                                    ))}
                            </Swiper>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </Master>
    )
}

export default WordTutorialDetail
